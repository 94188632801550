import {
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  Progress,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import React from 'react'
import type Stripe from 'stripe'
import { formatDate } from '../../../lib/dayjs'
import { LightBgCard } from '../../ui/Card'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { humanize } from '../accounts/facets/filter-cloud'
import { BillingHelp } from './components/billing-help'
import { SubscriptionStatus } from './components/subscription-status'
import { plansByVersion } from './plans'
import { PlanData } from './PricingTable'
import { BaseEntitlements, EntitlementsV2, EntitlementsV3, V2SubscriptionItems } from './v2'

export function friendlyNumber(num: number) {
  return new Intl.NumberFormat().format(num)
}

export type Plan = 'growth' | 'business' | 'free' | 'starter' | 'pro' | 'starter_plus' | 'team'

export type SubscriptionMetricts = {
  maa: {
    count: number
    period_start: Date
    period_end: Date
    progression?: Array<{ date: string; maa: number }>
  }
}

export interface Entitlements extends BaseEntitlements {
  retention: number
  billable_seats: number
  maa: number | null
  ui_retention: number
}

export type AllEntitlements = Partial<Entitlements> &
  Partial<EntitlementsV2> &
  Partial<EntitlementsV3> &
  Partial<AutomationEntitlements> &
  Partial<AIAgentsEntitlements>

export type AutomationEntitlements = {
  automation_action_runs: number
  slack_alerts_monthly: number
  auto_outbound_runs: number
}

export type AIAgentsEntitlements = {
  ai_agents_beta?: boolean
}

export interface KoalaSubscription {
  id: string
  project_id: string
  expires_at: string
  status: string
  trial_ended: boolean
  previous_trial?: boolean
  current_period_start: Date
  current_period_end: Date
  trialing: boolean
  trial_available?: boolean
  plan: Plan | null
  product_sku:
    | 'free_plan'
    | 'team_plan'
    | 'team_invoiced_plan'
    | 'business_plan'
    | 'unlimited_plan'
    | 'pro_plan'
    | 'starter_plan'
    | 'starter_invoiced_plan'
    | 'pro_invoiced_plan'
  unlimited: boolean
  metrics?: SubscriptionMetricts
  entitlements?: AllEntitlements
  v2_subscription_items?: V2SubscriptionItems
  past_due?: boolean
  stripe_customer_id?: string
  updated_at: Date
  version: 'v1' | 'v2' | 'v3' | 'v4' | null
  goodies?: {
    member?: number
    accounts?: number
  }
  usage_range: {
    start: Date
    end: Date
  }
}

interface Props {
  live_subscription?: Stripe.Subscription & { quantity: number; plan: Stripe.Plan }
  live_customer?: Stripe.Customer
  koala_subscription: KoalaSubscription
  plan?: Plan | 'unlimited'
}

export default function Show(props: Props) {
  const sub = props.live_subscription
  const currentPlan: PlanData | null = props.plan ? plansByVersion(props.koala_subscription.version)[props.plan] : null

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb paths={[{ path: projectPath('/settings/billing'), title: 'Billing' }]} offscreen />

      <SettingsHeader>
        <PageTitle>Billing</PageTitle>
        <PageDescription>Manage your workspace's plan and billing information.</PageDescription>
      </SettingsHeader>

      <Stack spacing={8}>
        <BillingHelp />

        <Divider />

        <SubscriptionStatus
          koala_subscription={props.koala_subscription}
          live_subscription={props.live_subscription}
          live_customer={props.live_customer}
          plan={props.plan}
        />

        <Stack spacing={2}>
          <Heading size="md">Current Plan</Heading>
          <LightBgCard padding={0} overflow="hidden">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              gap={4}
              padding={4}
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              <Stack spacing={1}>
                <Heading size="sm">
                  {humanize(props.plan || (props.koala_subscription.unlimited ? 'Unlimited' : 'Free'))} Plan
                </Heading>
                <Text fontSize="xs" color="gray.600">
                  {currentPlan?.description}
                </Text>
              </Stack>

              {props.plan !== 'business' &&
                props.plan !== 'unlimited' &&
                !props.koala_subscription.product_sku.includes('invoiced') && (
                  <>
                    {props.plan === 'free' ? (
                      <Flex flex="none" gap={2}>
                        <Button as={Link} href={projectPath('/settings/plans')} size="sm" variant="outline">
                          Compare plans
                        </Button>
                        <Button as={Link} href={projectPath('/settings/plans')} size="sm" colorScheme="purple">
                          Upgrade
                        </Button>
                      </Flex>
                    ) : (
                      <Flex flex="none" gap={2}>
                        <Button as={Link} href={projectPath('/settings/plans')} size="sm" variant="outline">
                          Manage plan
                        </Button>
                      </Flex>
                    )}
                  </>
                )}
            </Flex>
            <Stack
              spacing={3}
              padding={4}
              borderBottomWidth={sub?.cancel_at_period_end ? '0' : '1px'}
              borderColor="gray.200"
            >
              <List fontSize="sm" spacing="2">
                {currentPlan?.features?.map((feature) => (
                  <ListItem key={feature} display="flex">
                    <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                    {feature}
                  </ListItem>
                ))}
              </List>
            </Stack>

            {sub?.cancel_at_period_end && (
              <Stack padding={4} bg="orange.50" borderTop="1px solid" borderColor="orange.200">
                <Text fontSize="xs" fontWeight="semibold">
                  Your plan will be downgraded on {formatDate(sub.current_period_end * 1000)}
                </Text>
                <Text fontSize="xs">
                  Any billing changes you make will automatically void your scheduled downgrade (including new invites
                  to your workspace).
                </Text>
              </Stack>
            )}
          </LightBgCard>
        </Stack>

        {props.koala_subscription.metrics?.maa && !!props.koala_subscription.entitlements?.maa && (
          <Stack spacing={4}>
            <Heading size="md">Usage</Heading>
            <LightBgCard padding={0}>
              <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={4} padding={4}>
                <Stack spacing={1}>
                  <HStack>
                    <Heading size="sm">{formatDate(props.koala_subscription.current_period_start)}</Heading>
                    <Text>-</Text>
                    <Heading size="sm">{formatDate(props.koala_subscription.current_period_end)}</Heading>
                  </HStack>
                  <Text fontSize="xs" color="gray.600">
                    Usage limits are reset at the end of your billing period.
                  </Text>
                </Stack>
              </Flex>
              <Divider />
              <Stack p="4" pb="6">
                <HStack w="100%" justifyContent={'space-between'}>
                  <Heading fontWeight={'normal'} size="xs">
                    Monthly Active Accounts
                  </Heading>
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    {friendlyNumber(props.koala_subscription.metrics.maa.count ?? 0)} /{' '}
                    {friendlyNumber(props.koala_subscription.entitlements.maa)} MAA
                  </Text>
                </HStack>
                <Progress
                  value={props.koala_subscription.metrics.maa.count ?? 0}
                  max={props.koala_subscription.entitlements.maa}
                  size="xs"
                  rounded="full"
                  colorScheme="purple"
                />
              </Stack>
            </LightBgCard>
          </Stack>
        )}
      </Stack>
    </PageLayout>
  )
}
