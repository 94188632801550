import React, { FormEvent, useState, useMemo } from 'react'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import Avatar from './Avatar'
import {
  useDisclosure,
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Button,
  Flex,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  Spinner
} from '@chakra-ui/react'
import { toast } from 'sonner'
import { postForm } from '../../lib/api'
import { Prospect } from '../data/use-prospects'
import { useAppDep } from '../data/use-app-dep'
import { ProfileRecord } from '../../types/Profile'
import { App } from '../../types/App'
import { TextEllipsis } from './text-ellipsis'
import { projectPath } from './ProjectsContext'
import { useCurrentUser } from './UserContext'
import { ComboboxWithSearch } from './ComboboxWithSearch'

import pluralize from 'pluralize'
import capitalize from 'lodash/capitalize'

interface AddToSalesloftProps extends UseDisclosureProps {
  app?: App
  selectedProfiles: Prospect[] | ProfileRecord[] | []
  profileType?: 'Profile' | 'ProspectedProfile'
  profileLabel?: string
  skipAnonymousProfiles?: boolean | false
}

export type SalesloftCadence = {
  cadence_id: number
  name: string
}

export type SalesloftCadenceResponse = {
  data: {
    cadences: SalesloftCadence[]
  }
}

export function AddToSalesloftCadenceModal(props: AddToSalesloftProps) {
  const { selectedProfiles, skipAnonymousProfiles, profileType, profileLabel } = props
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const currentUser = useCurrentUser()
  const [saving, setSaving] = React.useState(false)

  const [currentCadence, setCurrentCadence] = React.useState<SalesloftCadence | null>(null)
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(currentUser?.email ?? null)

  const profilesWithoutEmail = (selectedProfiles as any[]).filter((p) => !p.email)
  const profilesToAdd = skipAnonymousProfiles ? (selectedProfiles as any[]).filter((p) => p.email) : selectedProfiles
  const targetLabel = profileLabel || (profileType == 'Profile' ? 'people' : 'prospects')
  const targetPluralizedLabel = pluralize(targetLabel, profilesToAdd.length, profilesToAdd.length > 1)

  const {
    data: cadenceData,
    isLoading: loadingCadences,
    isError: cadenceError,
    error: CadenceErrorData
  } = useAppDep<'cadences', SalesloftCadence[]>('Salesloft', 'cadences', { cached: true })

  const {
    data: usersData,
    isLoading: loadingUsers,
    isError: usersError
  } = useAppDep<'users', any[]>('Salesloft', 'users', { cached: true })

  const selectedOwner = useMemo(
    () =>
      usersData?.data?.users?.find((s) => {
        return s.email.toString() === selectedOwnerEmail?.toString()
      }) ?? null,
    [usersData, selectedOwnerEmail]
  )

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSaving(true)
      try {
        const form = e.target as HTMLFormElement
        const data = new FormData(form)

        const profileIds = profilesToAdd.map((p) => p.id) ?? []
        profileIds.forEach((profileId) => data.append('profile_id[]', profileId))

        postForm(projectPath(`/salesloft/actions/cadences?profile_type=${props.profileType ?? 'Profile'}`), data)
          .then(() => {
            toast.success(`${capitalize(targetLabel)} added to cadence!`, {
              description: `${targetPluralizedLabel} has been added to ${currentCadence?.name} in Salesloft.`
            })
            setSaving(false)
            onClose()
          })
          .catch((e) => {
            toast.error(`Error adding ${targetLabel} to cadence!`, {
              description: `${e.message}: ${e.body?.error}`
            })
            setSaving(false)
          })
      } catch (error) {
        console.error(error)
      } finally {
        setSaving(false)
      }
    },
    [currentCadence?.name, onClose, props.profileType, profilesToAdd, targetLabel, targetPluralizedLabel]
  )

  const [showMoreProfiles, setShowMoreProfiles] = useState(false)
  const toggleShowMoreProfiles = () => {
    setShowMoreProfiles(!showMoreProfiles)
  }

  return (
    <Modal {...disclosure} size="md" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="md" display="flex" gap={2} pb={1}>
          <CompanyAvatar
            size="20px"
            domain={'outreach.ai'}
            mr={2}
            src="https://asset.brandfetch.io/idLO9lFZj5/id8bueqGAT.jpeg"
          />
          Add to Salesloft cadence
        </ModalHeader>
        <ModalBody pb={6}>
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={4}>
              {loadingCadences && <Spinner size="sm" />}
              {cadenceError && (
                <Text p="4" bg="orange.50" m="2">
                  {' '}
                  {(CadenceErrorData as any)?.body?.error}
                </Text>
              )}
              {!cadenceError && !loadingCadences && (
                <FormControl>
                  <input type="hidden" name="salesloft[cadence_id]" value={currentCadence?.cadence_id} />
                  <FormLabel>Cadence</FormLabel>

                  <ComboboxWithSearch
                    items={cadenceData?.data?.cadences ?? []}
                    selectedItem={currentCadence}
                    onChange={(selectedItem) => {
                      setCurrentCadence(selectedItem)
                    }}
                    filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                    itemToString={(item) => item?.name || ''}
                    placeholder="Select a cadence"
                  />
                </FormControl>
              )}
              {loadingUsers && <Spinner size="sm" />}
              {usersError && (
                <Text p="4" bg="orange.50" m="2">
                  We couldn't find any user email to display. Make sure to check the permissions in your Salesloft
                  workspace.
                </Text>
              )}
              {!usersError && !loadingUsers && (
                <FormControl>
                  <input type="hidden" name="salesloft[user_id]" value={selectedOwner?.user_id} />
                  <FormLabel>Owner</FormLabel>
                  <ComboboxWithSearch
                    items={usersData?.data.users ?? ['']}
                    selectedItem={selectedOwner}
                    onChange={(selectedItem) => {
                      setSelectedOwnerEmail(selectedItem.email)
                    }}
                    filterItem={(a, val) => (a?.email ?? '').toLowerCase().includes(val)}
                    itemToString={(item) => item?.email || ''}
                    placeholder="Select an owner"
                  />
                </FormControl>
              )}
            </Stack>
            <Stack fontSize="sm" spacing={2} mt={4}>
              <Text fontSize="xs" color="gray.500" fontWeight="bold">
                SELECTED {pluralize(targetLabel, profilesToAdd.length).toUpperCase()}:
              </Text>
              <Flex direction="column" alignItems="space-between" gap={3}>
                {profilesWithoutEmail && profilesWithoutEmail.length > 0 && !skipAnonymousProfiles && (
                  <Stack spacing="4" p="2">
                    <Text fontSize="sm" color="gray.600" lineHeight="1.4">
                      You've selected {pluralize(targetLabel, profilesWithoutEmail.length, true)} whose emails have not
                      been enriched yet. They will be enriched if you proceed and will use enrichment credits in the
                      future.
                    </Text>
                    <Text whiteSpace={'pre-wrap'} fontSize="xs" lineHeight="1.4" p="4" bg="purple.50" color="gray.700">
                      Note: we're currently offering unlimited Koala Waterfall enrichment credits during our open beta.
                    </Text>
                  </Stack>
                )}
                {profilesToAdd && (
                  <>
                    {profilesToAdd.length > 5 ? (
                      <>
                        {profilesToAdd.slice(0, 4).map((selectedProfile) => (
                          <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProfile.name}
                                src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                              />
                              <Text>{selectedProfile.name}</Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProfile.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                        {showMoreProfiles &&
                          profilesToAdd.slice(4, profilesToAdd.length).map((selectedProfile) => (
                            <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                              <HStack>
                                <Avatar
                                  size="xs"
                                  name={selectedProfile.name}
                                  src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                                />
                                <Text>{selectedProfile.name}</Text>
                              </HStack>
                              <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                {selectedProfile.title}
                              </TextEllipsis>
                            </Flex>
                          ))}
                        <Text onClick={toggleShowMoreProfiles} color="purple.500" align="center" cursor="pointer">
                          {showMoreProfiles
                            ? `- Hide ${profilesToAdd.length - 4} ${targetLabel}`
                            : `+ Show ${profilesToAdd.length - 4} more ${targetLabel}`}
                        </Text>
                      </>
                    ) : (
                      <>
                        {profilesToAdd.map((selectedProfile) => (
                          <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProfile.name}
                                src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                              />
                              <Text>{selectedProfile.name}</Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProfile.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Flex>
            </Stack>
            {profilesWithoutEmail && profilesWithoutEmail.length > 0 && skipAnonymousProfiles && (
              <Stack spacing="4" p="2">
                <Text fontSize="xs" color="gray.500">
                  {pluralize(`anonymous ${targetLabel}`, profilesWithoutEmail.length, true)} selected won't be sequenced
                </Text>
              </Stack>
            )}
            <Flex w="100%" pt="4">
              <Button
                w="100%"
                size="sm"
                colorScheme={'purple'}
                type="submit"
                isDisabled={cadenceError || !currentCadence || usersError || !selectedOwner || !profilesToAdd.length}
                isLoading={saving || loadingCadences || loadingUsers}
              >
                Add {targetPluralizedLabel} to Salesloft Cadence
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
