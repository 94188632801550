import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconCheck,
  IconCircle,
  IconCircleDashed,
  IconDotsVertical,
  IconSettings,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import router from '../../../lib/router'
import { PageMeta } from '../../../types/PageMeta'
import { Play, PlayItem } from '../../../types/Play'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { useCurrentUser } from '../../ui/UserContext'
import { UserSelector } from '../../ui/UserSelector'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { SearchBar } from '../accounts/facets/search-bar'
import { mergeParams } from '../icps/types'
import { PlayItemsTable } from './components/play-items-table'

interface Props {
  play: Play
  play_items: PlayItem[]
  stats: {
    total: number
    pending: number
    completed: number
    disqualified: number
  }
  status?: 'pending' | 'completed' | 'disqualified' | 'snoozed'
  assignee_id?: string
  page_meta: PageMeta
}

export default function Show({ play, play_items, stats, status, assignee_id, page_meta }: Props) {
  const deletionModal = useDisclosure()
  const user = useCurrentUser()

  const params = new URLSearchParams(window.location.search)
  const selectedStatus = status || params.get('status') || 'pending'
  const statCount = stats[selectedStatus] || 0

  const [assigneeId, setAssigneeId] = useState<string | null>(assignee_id || null)
  const [searchQuery, setSearchQuery] = useState('')

  const [items, setItems] = useState<PlayItem[]>(play_items ?? [])

  // Remove item when the status is different from the selected status
  // If it's the same status, we don't need to remove it
  const onRemoveItem = useCallback(
    (itemId: string, status: string) => {
      if (status !== selectedStatus) {
        setItems((items) => items.filter((item) => item.id !== itemId))
      }
    },
    [selectedStatus]
  )

  const onUpdateItem = useCallback((itemId: string, updatedItem: PlayItem) => {
    setItems((items) => items.map((item) => (item.id === itemId ? { ...item, ...updatedItem } : item)))
  }, [])

  const changeStatus = useCallback((status: string) => {
    const url = mergeParams(window.location.toString(), { status, page: '1' })
    router.visit(url)
  }, [])

  // Update items for incoming play_items changes
  useUpdateEffect(() => {
    setItems(play_items || [])
  }, [play_items])

  useUpdateEffect(() => {
    if (assigneeId !== assignee_id) {
      const path = mergeParams(window.location.toString(), { user: assigneeId || '' })
      router.visit(path)
    }
  }, [assigneeId, user?.id, assignee_id])

  return (
    <PageLayout size="full">
      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/plays'), title: 'Plays' },
            { path: projectPath(`/plays/${play.id}`), title: play.name, isCurrentPage: true }
          ]}
        />
      </TopBarContent>

      <PageTitle skipRendering>Play - {play.name}</PageTitle>

      <Flex gap={4} alignItems="flex-start" justifyContent="space-between" flexWrap="wrap">
        <Stack flex="1" minW="400px" spacing={2}>
          <Stack spacing={0}>
            <Heading flex="none" size="md">
              {play.name}
            </Heading>
            <Text fontSize="sm" color="gray.600">
              {play.description}
            </Text>
          </Stack>
          <HStack color="gray.600" fontSize="xs" spacing={1} divider={<MiddotDivider />}>
            <Text>
              Updated <TimeAgo time={play.updated_at} />
            </Text>
            {play.updated_by_user ? (
              <Text>Updated by {play.updated_by_user.name || play.updated_by_user.email}</Text>
            ) : play.created_by_user ? (
              <Text>Created by {play.created_by_user.name || play.created_by_user.email}</Text>
            ) : null}
          </HStack>
        </Stack>

        <HStack flex="none">
          <Flex flex="none" alignItems="center" gap={1} ml="auto">
            <Text fontSize="sm" color="gray.600">
              Assignee:
            </Text>
            <UserSelector selectedUserId={assigneeId} onChange={setAssigneeId} allowUnassigned />
          </Flex>
          <Button
            size="sm"
            leftIcon={<IconSettings size={15} />}
            as={Link}
            href={projectPath(`/plays/${play.id}/edit`)}
            variant="outline"
          >
            Edit Play
          </Button>
          <Menu size="sm" autoSelect={false} placement="bottom-end">
            <MenuButton
              as={IconButton}
              aria-label="Actions"
              icon={<IconDotsVertical size={16} />}
              variant="ghost"
              size="sm"
            />
            <MenuList zIndex="popover">
              <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletionModal.onOpen}>
                Delete Play
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      <Grid width="100%" templateColumns={['repeat(1, 1fr)', 'repeat(auto-fit, minmax(120px, 1fr))']} gap={2}>
        <Stat label="Total" value={stats.total} />
        <Stat label="Pending" value={stats.pending} />
        <Stat label="Completed" value={stats.completed} />
        <Stat label="Disqualified" value={stats.disqualified} />
      </Grid>

      <Card p={0}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={6}
          paddingX={4}
          paddingY={4}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <Heading size="sm">
            {play.target_type === 'companies' ? 'Companies' : 'People'} ({statCount.toLocaleString()})
          </Heading>
        </Flex>

        <Flex
          alignItems="center"
          gap={4}
          paddingX={4}
          paddingY={2.5}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <SegmentedControl size="sm">
            <Button
              isActive={selectedStatus === 'pending'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="pending" />}
              onClick={() => changeStatus('pending')}
            >
              Pending
            </Button>
            <Button
              isActive={selectedStatus === 'completed'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="completed" />}
              onClick={() => changeStatus('completed')}
            >
              Completed
            </Button>
            <Button
              isActive={selectedStatus === 'disqualified'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="disqualified" />}
              onClick={() => changeStatus('disqualified')}
            >
              Disqualified
            </Button>
          </SegmentedControl>

          <Box flex="none" minW="300px">
            <SearchBar size="sm" value={searchQuery} onChange={setSearchQuery} inputProps={{ isDisabled: true }} />
          </Box>
        </Flex>

        <PlayItemsTable
          items={items}
          recordType={play.target_type}
          status={selectedStatus}
          assigneeId={assigneeId}
          onRemoveItem={onRemoveItem}
          onUpdateItem={onUpdateItem}
        />

        {items.length > 0 && (
          <TableFooter
            pageMeta={page_meta}
            page={page_meta.current_page}
            nextPath={mergeParams(window.location.toString(), {
              page: (page_meta.current_page + 1).toString()
            })}
            prevPath={mergeParams(window.location.toString(), {
              page: (page_meta.current_page - 1).toString()
            })}
            px={4}
          />
        )}
      </Card>

      <DeleteConfirmation
        isOpen={deletionModal.isOpen}
        onClose={deletionModal.onClose}
        title="Delete Play"
        deletePath={projectPath(`/plays/${play.id}`)}
        confirmLabel="Yes, delete this play"
      >
        Are you sure you want to delete this play?
      </DeleteConfirmation>
    </PageLayout>
  )
}

function Stat(props: { label: string; value?: number | null }) {
  return (
    <Card px={3} py={3}>
      <Flex flex="1 1 0%" gap={2} direction="column" justifyContent="space-between">
        <Stack spacing={1.5}>
          <Heading size="xs" fontWeight="normal" color="gray.600">
            {props.label}
          </Heading>
          <Text fontSize="xl" fontWeight="semibold" lineHeight={1}>
            {(props.value || 0).toLocaleString()}
          </Text>
        </Stack>
      </Flex>
    </Card>
  )
}

function StatusIcon(props: { status: string }) {
  switch (props.status) {
    case 'completed':
      return <CircleIcon icon={IconCheck} iconSize={3.5} size={5} colorScheme="green" />
    case 'disqualified':
      return <CircleIcon icon={IconX} iconSize={3.5} size={5} colorScheme="red" />
    case 'pending':
      return <Icon as={IconCircleDashed} boxSize={5} color="gray.300" />
    default:
      return <Icon as={IconCircle} boxSize={5} color="gray.400" />
  }
}
