import { Button, Flex, Link, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { IconPlus } from '@tabler/icons-react'
import React from 'react'
import { Play } from '../../../types/Play'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SettingsHeader from '../../ui/SettingsHeader'
import { TopBarContent } from '../../ui/TopBarContext'

interface IndexProps {
  plays: Play[]
}

export default function Index(props: IndexProps) {
  return (
    <PageLayout size="full">
      <TopBarContent>
        <Breadcrumb paths={[{ path: projectPath('/plays'), title: 'Plays' }]} />
      </TopBarContent>

      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <Stack spacing={0}>
            <PageTitle>Plays</PageTitle>
            <PageDescription>Encode and execute on repeatable sales strategies.</PageDescription>
          </Stack>
          <Button
            size="sm"
            as={Link}
            href={projectPath('/plays/new')}
            iconSpacing={1.5}
            leftIcon={<IconPlus size={16} />}
            colorScheme="purple"
          >
            New Play
          </Button>
        </Flex>
      </SettingsHeader>

      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.plays.map((play) => (
            <Tr key={play.id}>
              <Td>
                <Link href={projectPath(`/plays/${play.id}`)}>{play.name}</Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageLayout>
  )
}
