import { Button, Divider, Heading, HStack, Link, Stack, Text, Tooltip } from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import dayjs from 'dayjs'
import pluralize from 'pluralize'
import React from 'react'
import Stripe from 'stripe'
import { GrayCard, LightBgCard } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { MiddotDivider } from '../../../ui/Middot'
import { TimeAgo } from '../../../ui/TimeAgo'
import { humanize } from '../../accounts/facets/filter-cloud'
import { billingManagePath } from '../lib/path-helpers'
import { KoalaSubscription } from '../show'

interface Props {
  subscription: KoalaSubscription
  live_customer?: Stripe.Customer
  live_subscription: Stripe.Subscription & { quantity: number; plan: Stripe.Plan }
  payment_methods?: Stripe.PaymentMethod[]
  live_subscriptions: Array<Stripe.Subscription & { quantity: number; plan: Stripe.Plan }>
}

export function SubscriptionSettings(props: Props) {
  return (
    <Stack w="100%">
      <HStack w="100%" justifyContent={'space-between'} pb="2">
        <Heading size="sm">Subscription Details</Heading>
        <Tooltip label="Manage your plan, credit card settings and view your invoices.">
          <Button
            size="sm"
            colorScheme={'purple'}
            bg="purple.50"
            color="gray.700"
            _hover={{
              textDecoration: 'none'
            }}
            as={Link}
            isExternal
            href={billingManagePath()}
            leftIcon={<CompanyAvatar domain={'stripe.com'} rounded="full" size="4" />}
            rightIcon={<IconExternalLink size={14} />}
          >
            Manage Subscription
          </Button>
        </Tooltip>
      </HStack>

      <LightBgCard w="100%" bg="white" p="8">
        <Stack spacing="6" fontSize={'sm'} color="gray.500">
          <Stack divider={<Divider />} spacing="4">
            {props.live_subscriptions.map((sub) => (
              <Stack spacing="4" key={sub.id} as={GrayCard}>
                <Stack spacing="0">
                  <Heading size="xs" color="gray.700">
                    Subscription Status
                  </Heading>
                  <HStack>
                    {sub.plan?.product && (
                      <Text>{humanize((sub.plan?.product as any)?.name?.replace('Plan', '') ?? '')} Plan</Text>
                    )}
                    {!sub.plan?.product && sub.items.data.length > 0 && <Text>Usage Charges</Text>}
                    <MiddotDivider />
                    <Text>{humanize(sub.status)}</Text>(
                  </HStack>
                </Stack>

                <Stack spacing="0">
                  <Heading color="gray.700" size="xs">
                    Current Billing Period
                  </Heading>
                  <HStack>
                    <TimeAgo mode="calendar" time={dayjs.unix(sub.current_period_start).toDate()} />
                    <Text>-</Text>
                    <TimeAgo mode="calendar" time={dayjs.unix(sub.current_period_end).toDate()} />
                  </HStack>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Stack spacing="0">
            <Heading color="gray.700" size="xs">
              Billing Email
            </Heading>
            <Text>{props.live_customer?.email}</Text>
          </Stack>

          <Stack spacing="0">
            <Heading color="gray.700" size="xs">
              Invoice History
            </Heading>
            <Link display="inline-flex" alignItems="center" gap={1} fontSize="sm" isExternal href={billingManagePath()}>
              View in Stripe
              <IconExternalLink size={14} />
            </Link>
          </Stack>

          <Stack spacing="0">
            <Heading color="gray.700" size="xs">
              Payment Methods
            </Heading>
            {(props.payment_methods?.length ?? 0) > 0 && (
              <Link
                display="inline-flex"
                alignItems="center"
                gap={1}
                fontSize="sm"
                isExternal
                href={billingManagePath()}
              >
                {pluralize('payment method', props.payment_methods?.length ?? 0, true)} on file. View in Stripe{' '}
                <IconExternalLink size={14} />
              </Link>
            )}

            {(props.payment_methods?.length ?? 0) === 0 && (
              <Stack>
                <Text pb="2">No payment method on file</Text>
                <Button colorScheme={'purple'} as={Link} fontSize="xs" isExternal href={billingManagePath()}>
                  Add a payment method
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </LightBgCard>
    </Stack>
  )
}
