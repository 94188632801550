import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Stack,
  Text,
  TextProps,
  Tooltip,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconCircleMinus,
  IconCirclePlus,
  IconCompass,
  IconDatabaseSearch,
  IconDots,
  IconExternalLink,
  IconEyeOff,
  IconFlag,
  IconHighlight,
  IconRefresh
} from '@tabler/icons-react'
import Autolinker from 'autolinker'
import { FastAverageColor } from 'fast-average-color'
import ms from 'ms'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { useLocalStorage, useMountedState } from 'react-use'
import createPersistedState from 'use-persisted-state'
import { colord } from '../../../../../lib/colors'
import { flatGet } from '../../../../../lib/flatGet'
import { formatFriendlyCurrency, formatPercent } from '../../../../../lib/number-format'
import { ordinalSuffix } from '../../../../../lib/ordinal-suffix'
import { hashCode } from '../../../../../lib/string-to-hash'
import type { Account } from '../../../../../types/Account'
import { Apps } from '../../../../../types/App'
import { Company } from '../../../../../types/Profile'
import { useClaimAccount } from '../../../../data/use-claim-account'
import { PersistedFieldDefinition, useFieldDefinitions } from '../../../../data/use-field-definitions'
import { useHiddenAccounts, useHideAccount, useRestoreAccount } from '../../../../data/use-hide-account'
import { useReindexRecord } from '../../../../data/use-reindex-record'
import { useUnclaimAccount } from '../../../../data/use-unclaim-account'
import { BubbleTag } from '../../../../ui/BubbleTag'
import { DetailsCard } from '../../../../ui/Card'
import CompanyAvatar from '../../../../ui/CompanyAvatar'
import { HelpTooltip } from '../../../../ui/HelpTooltip'
import { CrunchbaseIcon, LinkedinBoxIcon, SalesforceIcon, TwitterIcon } from '../../../../ui/icons'
import { HubSpotIcon } from '../../../../ui/icons/HubspotIcons'
import { MiddotDivider } from '../../../../ui/Middot'
import { usePermission } from '../../../../ui/PermissionsContext'
import SelectInput from '../../../../ui/SelectInput'
import { TimeAgo } from '../../../../ui/TimeAgo'
import { useEntitlements } from '../../../../ui/useEntitlements'
import { useOverflow } from '../../../../ui/useOverflow'
import { useCurrentUser } from '../../../../ui/UserContext'
import { salesNavigatorCompanyLink } from '../../../account_views/components/ImportContactsSideSheet'
import { KeyFieldsModal } from '../../../field_definitions/components/KeyFieldsModal'
import { Breakdown, grade, LetterGrade } from '../../../icps/icp/breakdown'
import { getScoreLabel, scoreLabelColor } from '../Intent/IntentSummary'
import { SuggestionsModal } from '../SuggestionsModal'
import { AISummaryCard } from '@app/components/ui/AISummaryCard'
import { ShareInSlackModal } from './ShareInSlackModal'

interface Props {
  account: Account
  apps: Apps
  scoringEnabled?: boolean
}

interface Assignee {
  name?: string
  email?: string
  id?: string
}

const gradients = [
  'linear(to-br, orange.100, purple.300, blue.200)',
  'linear(to-br, gray.300, cyan.500, yellow.300)',
  'linear(to-br, orange.100, purple.300)',
  'linear(to-br, pink.100, purple.300)',
  'linear(to-br, red.300, yellow.300, orange.300)',
  'linear(to-br, teal.300, yellow.400)',
  'linear(to-br, orange.100, purple.300)',
  'linear(to-br, teal.300, green.500, orange.200)'
]

const fac = new FastAverageColor()

export function useAverageColor(url?: string) {
  const [color, setColor] = React.useState<string>()
  const [colors, setColors] = React.useState<null | string[]>(null)
  const mounted = useMountedState()

  React.useEffect(() => {
    if (!url) {
      return
    }

    fac
      .getColorAsync(url, {
        ignoredColor: [
          [255, 255, 255, 255] // white
          // [0, 0, 0, 255] // black
        ]
      })
      .then((color) => {
        if (color?.hex && mounted()) {
          const harmonies = colord(color?.hex)
            .harmonies('analogous')
            .map((c) => c.toHex())
          setColor(color.hex)
          setColors(harmonies)
        }
      })
      .catch(() => {
        // do nothing
      })
  }, [mounted, url])

  return { color, colors }
}

function useGradient(id: string) {
  const hash = React.useMemo(() => hashCode(id), [id])
  const gradient = gradients[hash % gradients.length]
  return gradient
}

export function usePersistedGroup(recordId: string, initial: string | null) {
  return createPersistedState<string | null>(`koala:group:${recordId}`)(initial)
}

const emptyArray = []

export function SummaryCard(props: Props) {
  const [assignee, setAssignee] = React.useState<Assignee | undefined>(props.account.assignee)
  const user = useCurrentUser()
  const scoreLabel = getScoreLabel(props.account.intent?.score)
  const fit = grade(props.account.fit_grade, props.account.fit_grade_letter)
  const claimedByYou = assignee?.id === user.id
  const company = props.account.company
  const gradient = useGradient(props.account.domain)
  const { colors } = useAverageColor(props.account.company.logo)
  const smaller = useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false })
  const editFieldsModal = useDisclosure()
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const entitlements = useEntitlements()

  const { data: fieldDefns, isLoading, refetch } = useFieldDefinitions('account')

  const keyFields = useMemo(() => {
    return (fieldDefns?.fields || []).filter((field) => field.key_field)
  }, [fieldDefns])

  const traitGroups = props.account.traits || emptyArray
  const [selectedGroupId, setSelectedGroupId] = usePersistedGroup(props.account.id, traitGroups[0]?.group_id || null)
  const selectedGroup = useMemo(
    () =>
      traitGroups.find((g) => {
        if (g.group_id === selectedGroupId) return true
        if (g.name === selectedGroupId) return true
        if (!g.group_id && !selectedGroupId) return true
        return false
      }),
    [traitGroups, selectedGroupId]
  )

  return (
    <DetailsCard padding={0}>
      <Box
        roundedTop="inherit"
        roundedBottom="none"
        height="64px"
        bg="white"
        bgGradient={
          colors === null
            ? `linear(to-br, gray.100, gray.200)`
            : colors.length
              ? `linear(to-br, ${colors.join(', ') || ', '})`
              : gradient
        }
        marginTop="-1px"
        marginX="-1px"
        padding={4}
      />

      <Stack paddingX={[5, 5, 6]} paddingBottom={[5, 5, 6]} paddingTop={0} spacing={6}>
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Flex gap={4} alignItems="flex-end" flexWrap="wrap">
            <CompanyAvatar
              name={company.name}
              domain={company.domain}
              size={['xl', '2xl']}
              bg="white"
              shadow="0 1px 4px 0 rgba(0, 0, 0, 0.08), 0 0 0 4px var(--chakra-colors-gray-50)"
              rounded="xl"
              flex="none"
              marginTop={-10}
            />
            <Stack display={['none', 'none', 'none', 'flex']} spacing={0} paddingBottom={0.5}>
              <Heading size="lg" fontWeight="bold" letterSpacing="tight">
                {company.name || company.domain}
              </Heading>
              <HStack spacing={1} fontSize="sm" color="gray.600" divider={<MiddotDivider />}>
                <Link fontWeight="semibold" isExternal href={`https://${company.domain}`}>
                  {company.domain}
                </Link>
                {assignee && (
                  <Text>
                    Claimed by{' '}
                    <Text as="span" fontWeight="semibold">
                      {claimedByYou ? 'You' : assignee.name || assignee.email}
                    </Text>
                  </Text>
                )}
              </HStack>
            </Stack>
          </Flex>
          <HStack>
            <Button
              size={smaller ? 'sm' : 'md'}
              variant="outline"
              as={Link}
              isExternal
              href={`https://${company.domain}`}
              rightIcon={<Icon as={IconExternalLink} boxSize={4} />}
              iconSpacing={1.5}
            >
              Visit Website
            </Button>
            <ShareInSlack account={props.account} size={smaller ? 'sm' : 'md'} />
            <CompanyMenu
              account={props.account}
              assignee={assignee}
              onChangeAssignee={setAssignee}
              onEditHighlights={canEditProject ? editFieldsModal.onOpen : undefined}
              size={smaller ? 'sm' : 'md'}
            />
          </HStack>
        </HStack>
        <Flex direction="column" gap={6}>
          <Stack display={['flex', 'flex', 'flex', 'none']} spacing={0}>
            <Heading size="lg" fontWeight="bold" letterSpacing="tight">
              {company.name || company.domain}
            </Heading>
            <HStack spacing={1} fontSize="sm" color="gray.600" divider={<MiddotDivider />}>
              <Link fontWeight="semibold" isExternal href={`https://${company.domain}`}>
                {company.domain}
              </Link>
              {assignee && (
                <Text>
                  Claimed by{' '}
                  <Text as="span" fontWeight="semibold">
                    {claimedByYou ? 'You' : assignee.name || assignee.email}
                  </Text>
                </Text>
              )}
            </HStack>
          </Stack>
          <Flex flexDir="column" gap={2} position="relative" role="group">
            {isLoading && (
              <Flex alignItems="center" minHeight="80px">
                <Spinner size="sm" />
              </Flex>
            )}
            {keyFields.length > 0 && (
              <Box position="relative">
                <Box bg="gray.100" height="1px" position="absolute" top="50%" left={0} right={0} />

                <Flex
                  display="inline-flex"
                  bg="white"
                  alignItems="baseline"
                  gap={2}
                  pr={2}
                  position="relative"
                  zIndex={1}
                >
                  <Text fontSize="13px" fontWeight="semibold">
                    Highlights
                  </Text>
                  {hasTraitFields(keyFields) && traitGroups.length > 1 && (
                    <Box maxW="200px">
                      <SelectInput
                        size="xs"
                        variant="ghost"
                        placeholder="Select a grouping"
                        usePortal
                        triggerProps={{
                          color: 'gray.500',
                          _hover: {
                            color: 'gray.700'
                          }
                        }}
                        items={traitGroups}
                        selectedItem={selectedGroup || null}
                        itemToString={(i) => {
                          return i.name || i.group_id
                        }}
                        onSelectedItemChange={({ selectedItem }) => {
                          setSelectedGroupId(selectedItem?.group_id || null)
                        }}
                      />
                    </Box>
                  )}
                </Flex>

                {canEditProject && (
                  <Tooltip label="Edit highlighted fields">
                    <IconButton
                      size="xs"
                      variant="outline"
                      position="absolute"
                      right={0}
                      top="50%"
                      transform="translateY(-50%)"
                      aria-label="Edit highlight fields"
                      bg="white"
                      visibility="hidden"
                      _groupHover={{ visibility: 'visible' }}
                      icon={<IconHighlight size={16} />}
                      onClick={editFieldsModal.onOpen}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
            <Grid
              gridAutoRows="auto"
              gridTemplateColumns={['1fr', 'repeat(auto-fit, minmax(160px, 1fr))']}
              gap={[2, 2, 3]}
            >
              {keyFields.map((field) => {
                // filter out defaults that shouldn't appear when empty, just for consistent behavior for now. we can change this later
                if (field.data_source === 'fit_grade' && !props.scoringEnabled) {
                  return null
                }

                if (
                  field.data_source === 'focus_time_trend.month.current.value' &&
                  !props.account.focus_time_trend?.month?.current?.value
                ) {
                  return null
                }

                if (field.data_source === 'last_seen_at' && !props.account.last_seen_at) {
                  return null
                }

                const isSalesforce = [
                  'salesforce_data.',
                  'salesforce_contact_data.',
                  'salesforce_lead_data.',
                  'salesforce_opportunity.'
                ].some((key) => field.data_source?.startsWith(key))

                const isHubSpot = ['hubspot_data.', 'hubspot_contact_data.'].some((key) =>
                  field.data_source?.startsWith(key)
                )

                const icon = isSalesforce ? (
                  <SalesforceIcon color="salesforce" boxSize={4} />
                ) : isHubSpot ? (
                  <HubSpotIcon color="hubspot" boxSize={4} />
                ) : undefined

                return (
                  <SummaryField key={field.id} label={field.label} icon={icon} info={field.description}>
                    {field.data_source === 'intent.strength' ? (
                      <Box color={`${scoreLabelColor[scoreLabel || 'none']}.500`} fontWeight="semibold">
                        {scoreLabel || 'Not enough data'}
                      </Box>
                    ) : field.data_source === 'fit_grade' ? (
                      <>
                        {props.account.fit_grade ? (
                          <Tooltip
                            label={`This account is in the ${ordinalSuffix(props.account.fit_grade)} percentile of accounts.`}
                          >
                            <Box
                              display="inline-flex"
                              textAlign="left"
                              alignItems="center"
                              gap={1}
                              color={`${fit.color}.500`}
                            >
                              <LetterGrade
                                flex="none"
                                fontSize="inherit"
                                display="inline"
                                value={props.account.fit_grade}
                                label={props.account.fit_grade_letter}
                                tooltip={false}
                              />
                              <Text>({props.account.fit_grade}%)</Text>
                              <Breakdown domain={props.account.domain} showInfo />
                            </Box>
                          </Tooltip>
                        ) : (
                          'Not scored yet'
                        )}
                      </>
                    ) : field.data_source === 'visitor_stats.visitors.month' ? (
                      <>{pluralize('visitor', props.account.visitor_stats?.visitors?.month ?? 0, true)}</>
                    ) : field.data_source === 'focus_time_trend.month.current.value' ? (
                      <>{ms(props.account.focus_time_trend?.month?.current?.value ?? 0, { long: true })}</>
                    ) : field.data_source === 'last_seen_at' ? (
                      <TimeAgo time={props.account.last_seen_at} fallback="—" />
                    ) : field.data_source ? (
                      <HighlightedField field={field} record={props.account} selectedGroup={selectedGroup?.group_id} />
                    ) : null}
                  </SummaryField>
                )
              })}
            </Grid>

            <KeyFieldsModal recordType="account" apps={props.apps} {...editFieldsModal} onChange={() => refetch()} />
          </Flex>
          {entitlements?.ai_agents_beta && <AISummaryCard account={props.account} target="companies" />}
        </Flex>
      </Stack>
    </DetailsCard>
  )
}

type SummaryFieldProps = React.PropsWithChildren<{
  icon?: any
  label?: string
  info?: string | null
}>

export function SummaryField({ children, icon, label, info, ...rest }: SummaryFieldProps & TextProps) {
  return (
    <Box display="flex" position="relative" borderWidth="1px" rounded="lg" paddingY={[2, 2.5]} paddingX={3}>
      <Flex flex="1" flexDirection={['row', 'column']} gap={1} maxW="100%">
        <Flex alignItems="flex-start" gap={1}>
          {icon}
          <Text fontSize="xs" color="gray.600" paddingRight={info ? 3 : undefined}>
            {label}
          </Text>
        </Flex>

        <Box fontSize="sm" fontWeight="semibold" lineHeight={1.24} color="gray.800" {...rest}>
          {children}
        </Box>
      </Flex>
      {info && (
        <HelpTooltip
          placement="top"
          position={['static', 'absolute']}
          marginLeft={2}
          right={1.5}
          top={1.5}
          variant="info"
        >
          {info}
        </HelpTooltip>
      )}
    </Box>
  )
}

interface ShareInSlackProps {
  account: Account
  size?: 'sm' | 'md'
}

function ShareInSlack(props: ShareInSlackProps) {
  const modal = useDisclosure()

  return (
    <>
      <IconButton
        aria-label="Share in Slack"
        size={props.size ?? 'md'}
        variant="outline"
        icon={<Image src="https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg" boxSize={4} />}
        onClick={modal.onToggle}
      />
      <ShareInSlackModal account={props.account} {...modal} />
    </>
  )
}

interface CompanyMenuProps {
  account: Account
  assignee?: Assignee
  size?: 'sm' | 'md'
  onChangeAssignee?: (user: Assignee | undefined) => void
  onEditHighlights?: () => void
}

function CompanyMenu(props: CompanyMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const suggestions = useDisclosure()
  const user = useCurrentUser()
  const claim = useClaimAccount()
  const unclaim = useUnclaimAccount()
  const hide = useHideAccount()
  const unhide = useRestoreAccount()
  const hiddenAccounts = useHiddenAccounts()
  const reindexAccount = useReindexRecord()

  const claimedByYou = props.assignee?.id === user.id
  const domain = useMemo(() => props.account.company.domain!, [props.account.company.domain])

  const isHidden = useMemo(
    () => hiddenAccounts.data?.filters.domains?.filters?.facets?.['company.domain']?.['not']?.includes(domain),
    [domain, hiddenAccounts.data]
  )

  const company = props.account.company || {}
  const clearbit = (props.account.clearbit || {}) as Company
  const twitterHandle = company.twitter?.handle || clearbit.twitter?.handle
  const linkedinHandle = company.linkedin?.handle || clearbit.linkedin?.handle
  const crunchbaseHandle = company.crunchbase?.handle || clearbit.crunchbase?.handle

  return (
    <>
      <Menu isOpen={isOpen} placement="bottom-end" onOpen={onOpen} onClose={onClose}>
        <MenuButton
          as={IconButton}
          size={props.size ?? 'md'}
          variant="outline"
          aria-label="More"
          icon={<Icon as={IconDots} boxSize={5} />}
        />
        <Portal>
          <MenuList zIndex="popover">
            <MenuItem
              icon={claimedByYou ? <IconCircleMinus size={16} /> : <IconCirclePlus size={16} />}
              isDisabled={!claimedByYou && Boolean(props.assignee)}
              onClick={() =>
                claimedByYou
                  ? unclaim.mutateAsync({ domain: domain }).then(() => props.onChangeAssignee?.(undefined))
                  : claim
                      .mutateAsync({ assigneeId: user.id!, domain: domain })
                      .then(() => props.onChangeAssignee?.(user))
              }
            >
              {claimedByYou ? 'Unclaim' : 'Claim'}
            </MenuItem>
            <MenuItem
              icon={<IconEyeOff size={16} />}
              onClick={() => {
                if (isHidden) {
                  unhide.mutateAsync({ domain: domain }).then(() => hiddenAccounts.refetch())
                } else {
                  hide.mutateAsync({ domain: domain }).then(() => hiddenAccounts.refetch())
                }
              }}
            >
              {isHidden ? 'Unhide' : 'Hide'}
            </MenuItem>
            <MenuDivider />
            {linkedinHandle && (
              <MenuItem
                icon={<LinkedinBoxIcon size={16} />}
                as={Link}
                href={`https://linkedin.com/company/${linkedinHandle.replace(/(linkedin\.com\/|company\/)/gi, '')}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
                onClick={() => {
                  window.ko?.track('LinkedIn Visit Action', {
                    app: 'linkedin',
                    company: company
                  })
                }}
              >
                View on LinkedIn
              </MenuItem>
            )}
            {twitterHandle && (
              <MenuItem
                icon={<TwitterIcon size={16} />}
                as={Link}
                href={`https://twitter.com/${twitterHandle}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
                onClick={() => {
                  window.ko?.track('Twitter Visit Action', {
                    app: 'twitter',
                    company: company
                  })
                }}
              >
                View on Twitter
              </MenuItem>
            )}
            {crunchbaseHandle && (
              <MenuItem
                icon={<CrunchbaseIcon size={16} />}
                as={Link}
                href={`https://crunchbase.com/${crunchbaseHandle}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
                onClick={() => {
                  window.ko?.track('Crunchbase Visit Action', {
                    app: 'crunchbase',
                    company: company
                  })
                }}
              >
                View on Crunchbase
              </MenuItem>
            )}
            <MenuItem
              icon={<IconCompass size={16} />}
              as={Link}
              href={salesNavigatorCompanyLink(props.account)}
              _hover={{ textDecoration: 'none' }}
              isExternal
              onClick={() => {
                window.ko?.track('Salesnav Visit Action', {
                  app: 'salesnav',
                  company: company
                })
              }}
            >
              Find in Sales Navigator
            </MenuItem>
            <MenuDivider />
            {props.onEditHighlights && (
              <MenuItem icon={<IconHighlight size={16} />} onClick={props.onEditHighlights}>
                Edit highlight fields
              </MenuItem>
            )}
            <MenuItem icon={<IconFlag size={16} />} onClick={suggestions.onOpen}>
              Suggest edits
            </MenuItem>
            {user.isInternalUser && props.account.id && (
              <MenuItem
                closeOnSelect={false}
                icon={
                  reindexAccount.isLoading ? (
                    <Center boxSize={4}>
                      <Spinner boxSize={3.5} thickness="1px" color="gray.700" />
                    </Center>
                  ) : (
                    <IconRefresh size={16} />
                  )
                }
                onClick={() => reindexAccount.mutate({ record_id: props.account.id, record_type: 'Account' })}
              >
                Reindex account{' '}
                <Badge variant="regular" colorScheme="orange">
                  Internal
                </Badge>
              </MenuItem>
            )}
            {user.isInternalUser && props.account.domain && (
              <MenuItem
                icon={<IconDatabaseSearch size={16} />}
                as={Link}
                href={`/admin/kdb/${props.account.domain}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
              >
                View admin page{' '}
                <Badge variant="regular" colorScheme="orange">
                  Internal
                </Badge>
              </MenuItem>
            )}
          </MenuList>
        </Portal>
      </Menu>
      <SuggestionsModal isOpen={suggestions.isOpen} onClose={suggestions.onClose} company={props.account.company} />
    </>
  )
}

function hasTraitFields(fields: PersistedFieldDefinition[]): boolean {
  // need to detect if any of the fields have grouping (e.g. account trait groups)
  return fields.some((f) => f.data_source?.includes('_traits_values'))
}

interface HighlightedFieldProps {
  field: PersistedFieldDefinition
  record: Account
  selectedGroup?: string | null
  fieldValue?: unknown
}

export function HighlightedField({ field, record, selectedGroup, fieldValue }: HighlightedFieldProps) {
  const { scrollRef, overflowBottom } = useOverflow()
  const [shouldShowAll, setShouldShowAll] = useLocalStorage('shouldShowAllTraitText', false)

  // TODO support crm lookups too (we don't have the data on the account object currently)
  // We need to reshape the lookup key for traits since OS differs a bit from how we shape the actual model on the account
  const lookup_key = field.data_source?.replace(
    /(?:account|profile)_traits_values\.(\w+)(\.value)?$/,
    (_match, p1, _p2) => {
      const full = ['traits']

      const [root, ...rest] = p1.split('__')

      if (root) {
        if (root.endsWith('.value')) {
          full.push(root)
        } else {
          full.push(`${root}.value`)
        }
      }

      if (rest?.length) {
        full.push(...rest)
      }

      return full.join('.')
    }
  )

  const data_type = field.data_type

  const data = lookup_key?.includes('traits') ? record.traits : record

  // nothing to look up
  if (!fieldValue && (!lookup_key || !data)) return <Text color="gray.400">&mdash;</Text>

  let values: unknown

  if (lookup_key?.includes('traits')) {
    const traits = (Array.isArray(record.traits) ? record.traits : []).filter((group) => {
      return group.group_id == selectedGroup || group.name === selectedGroup || (!selectedGroup && !group.group_id)
    })

    values = flatGet(traits, lookup_key)
  } else {
    values = flatGet(data, lookup_key)
  }

  // just grab the first value
  // todo handle multiple values...
  let value = Array.isArray(values) ? values[0] : values
  if (!value || !!fieldValue) {
    value = fieldValue
  }
  const isLongText = typeof value === 'string' && value.length >= 55

  return (
    <Box fontSize="sm">
      {typeof value === 'undefined' || value === null || (typeof value === 'string' && value.trim() === '') ? (
        <Text color="gray.300">&mdash;</Text>
      ) : data_type === 'string' ? (
        <Tooltip label={overflowBottom ? String(value) : undefined} maxW="320px">
          <Box>
            <Text
              ref={scrollRef}
              noOfLines={shouldShowAll ? undefined : 3}
              sx={{
                '.url-description': {
                  textDecoration: 'underline'
                }
              }}
              dangerouslySetInnerHTML={{
                __html: Autolinker.link(Array.isArray(value) ? value.map(String).join(', ') : String(value), {
                  truncate: { length: 64, location: 'end' },
                  newWindow: true,
                  sanitizeHtml: true,
                  stripPrefix: false,
                  stripTrailingSlash: true,
                  email: false,
                  phone: false,
                  mention: false,
                  hashtag: false,
                  urls: {
                    ipV4Matches: false,
                    tldMatches: true,
                    schemeMatches: true
                  },
                  className: 'url-description'
                })
              }}
            />
            {isLongText && (
              <Button size="xs" variant="link" onClick={() => setShouldShowAll(!shouldShowAll)}>
                {!shouldShowAll ? 'Show full text' : 'Show less'}{' '}
              </Button>
            )}
          </Box>
        </Tooltip>
      ) : data_type === 'number' ? (
        <Text css={{ fontVariantNumeric: 'tabular-nums' }}>
          {Array.isArray(value)
            ? value
                .map((v) => parseNumber(v))
                .sort(sortNumbers)
                .join(', ')
            : parseNumber(value)}
        </Text>
      ) : data_type === 'currency' ? (
        <Text css={{ fontVariantNumeric: 'tabular-nums' }}>
          {Array.isArray(value)
            ? value
                .sort(sortNumbers)
                .map((v) => formatFriendlyCurrency(v))
                .join(', ')
            : formatFriendlyCurrency(value)}
        </Text>
      ) : data_type === 'percent' ? (
        <Text css={{ fontVariantNumeric: 'tabular-nums' }}>
          {Array.isArray(value)
            ? value
                .map((v) => formatPercent(v))
                .sort(sortNumbers)
                .join(', ')
            : formatPercent(value)}
        </Text>
      ) : data_type === 'boolean' ? (
        <BubbleTag variant="subtleBorder" colorScheme={['true', 't', '1'].includes(String(value)) ? 'green' : 'red'}>
          {['true', 't', '1'].includes(String(value)) ? 'Yes' : 'No'}
        </BubbleTag>
      ) : data_type === 'date' ? (
        <TimeAgo time={value} mode="full" />
      ) : null}
    </Box>
  )
}

function parseNumber(value: any) {
  try {
    return Number(value).toLocaleString()
  } catch {
    return String(value)
  }
}

// sorts number-like values as numbers
function sortNumbers(a: string | number, b: string | number) {
  return Number(a) - Number(b)
}
