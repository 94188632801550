import {
  Button,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
  UseDisclosureProps,
  Box,
  Text,
  ButtonGroup,
  DrawerContent,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  Tooltip
} from '@chakra-ui/react'
import { IconChevronDown, IconPlayerPlay } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { post } from '../../../../lib/api'
import { AIAgent } from '../../../../types/AIAgent'
import { Company } from '../../../../types/Profile'
import { useCreateAIAgent, useUpdateAIAgent } from '../../../data/use-ai-agents'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { AIAgentResponse } from './AIAgentResponse'
import { toast } from 'sonner'

const TARGET_OPTIONS = [
  {
    label: 'Companies',
    value: 'companies',
    info: 'Research companies and organizations'
  },
  {
    label: 'People',
    value: 'people',
    info: 'Research individual people'
  }
]

const MODEL_OPTIONS = [
  {
    label: 'GPT 4o (Comprehensive)',
    value: 'gpt-4o',
    info: '(5 AI Credits) For more complex tasks, or tasks that require better fact checking. Use this for more nuanced research that requires more reasoning.'
  },
  {
    label: 'GPT-4o-mini (Faster)',
    value: 'gpt-4o-mini',
    info: '(1 AI Credit) Great for most tasks. We recommend using this model in 90% of cases. Responses will return faster.'
  },
  {
    label: 'Perplexity (Experimental)',
    value: 'perplexity',
    info: '(10 AI Credits) Use the Perplexity API to search the web for answers. This model gives you fast, straightforward answers'
  },
  {
    label: 'Perplexity Pro (Experimental)',
    value: 'perplexity-pro',
    info: '(25 AI Credits) Use the Perplexity API to search the web for answers. This model tackles complex questions that need deeper research and provides more sources.'
  },
  {
    label: 'Claude 3.5 Sonnet',
    value: 'claude-3-5-sonnet-latest',
    info: '(5 AI Credits) Use the Claude 3.5 Sonnet API to search the web for answers. This model is great for complex questions that require more reasoning.'
  },
  {
    label: 'Claude 3.5 Haiku',
    value: 'claude-3-5-haiku-latest',
    info: '(2 AI Credits) Use the Claude 3.5 Haiku API to search the web for answers. This a well balanced model for most tasks.'
  }
]

interface Props {
  ai_agent: AIAgent
  errors?: {
    [key: string]: string[]
  }
  onSubmit?: (agent: AIAgent) => Promise<void>
  onCancel?: () => void
  showTarget?: boolean
  showDeleteOption?: boolean
  company?: Company
  setSkipCache?: (skipCache: boolean) => void
}

export function AIAgentForm({
  ai_agent,
  errors,
  onSubmit,
  onCancel,
  showTarget = true,
  showDeleteOption = true,
  company,
  setSkipCache
}: Props) {
  const [agent, setAgent] = useState(ai_agent)
  const [submitting, setSubmitting] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [name, setName] = React.useState(agent?.name ?? '')
  const [model, setModel] = React.useState(agent?.model || 'gpt-4o')
  const [target, setTarget] = React.useState(agent?.target || 'companies')
  const [showAdvanced, setShowAdvanced] = useState(false)
  const nameError = name === (agent?.name || '') ? errors?.name?.join(', ') : ''

  const [question, setQuestion] = React.useState(agent?.question ?? '')
  const questionError = question === (agent?.question || '') ? errors?.question?.join(', ') : ''

  const { hasPermission: canEditAgents } = usePermission({ on: 'project', action: 'can_edit' })

  const [loadingResponse, setLoadingResponse] = useState(false)
  const [response, setResponse] = useState<any>(null)

  const runAgent = useCallback(async () => {
    setLoadingResponse(true)
    setResponse(null)

    try {
      const path = projectPath(`/assistants/preview`)
      const response = await post(path, {
        question,
        model,
        domain: company?.domain,
        skip_cache: 'true'
      })

      setResponse(response)
      setAgent({ ...agent, name, question, model, target })
    } catch (err) {
      toast.error('Error running agent')
    } finally {
      setLoadingResponse(false)
    }
  }, [company, question, model, agent, name, target])

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      setSubmitting(true)

      if (onSubmit) {
        event.preventDefault()
        await onSubmit({
          id: agent?.id,
          slug: agent?.slug,
          name,
          question,
          target,
          model
        })
      }
      setSubmitting(false)
    },
    [onSubmit, agent, name, question, model, target]
  )

  return (
    <>
      <form action={projectPath(`/settings/agents/${agent?.id || ''}`)} method="POST" onSubmit={handleSubmit}>
        <AuthenticityToken />
        {agent?.id && <input type="hidden" name="_method" value="PATCH" />}

        <Stack spacing={5} w="100%">
          <FormControl id="ai_agent[name]" isInvalid={!!nameError} isRequired>
            <FormLabel>Agent name</FormLabel>
            <Input
              type="text"
              bg="white"
              name="ai_agent[name]"
              placeholder="e.g. Company Products/Services"
              size="sm"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <FormErrorMessage>{nameError}</FormErrorMessage>
          </FormControl>

          <FormControl id="ai_agent[question]" isInvalid={!!questionError} isRequired>
            <FormLabel>Question/Prompt</FormLabel>
            <FormHelperText>
              Just ask the question you want answered &ndash; no need to provide instructions for how to do the task!
            </FormHelperText>
            <AutosizedTextarea
              name="ai_agent[question]"
              placeholder="e.g. What are the company's products and services?"
              size="sm"
              bg="white"
              minRows={4}
              maxRows={8}
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value)
                setSkipCache?.(true)
              }}
            />
            <FormErrorMessage>{questionError}</FormErrorMessage>
          </FormControl>
          {showTarget && (
            <FormControl id="ai_agent[target]" isRequired>
              <FormLabel>Research type</FormLabel>
              <ButtonGroup isAttached variant="outline" size="sm">
                <Button
                  type="button"
                  minWidth="180px"
                  variant="outline"
                  background="white"
                  isActive={target === 'companies'}
                  onClick={() => setTarget('companies')}
                >
                  Companies
                </Button>
                <Button
                  type="button"
                  minWidth="180px"
                  variant="outline"
                  background="white"
                  isActive={target === 'people'}
                  onClick={() => setTarget('people')}
                >
                  People
                </Button>
              </ButtonGroup>
              <input type="hidden" name="ai_agent[target]" value={target} defaultValue={target} />
              <FormHelperText>{TARGET_OPTIONS.find((option) => option.value === target)?.info}</FormHelperText>
            </FormControl>
          )}

          <Box>
            <Button
              size="sm"
              variant="link"
              iconSpacing={1.5}
              rightIcon={<IconChevronDown size={14} />}
              onClick={() => setShowAdvanced((prev) => !prev)}
              mb={2}
            >
              Advanced Options
            </Button>
            <Collapse in={showAdvanced}>
              <FormControl id="ai_agent[model]" isRequired>
                <FormLabel>Model</FormLabel>
                <Select
                  name="ai_agent[model]"
                  size="sm"
                  bg="white"
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value)
                    setSkipCache?.(true)
                  }}
                >
                  {MODEL_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Text fontSize="sm" color="gray.500" mt={2}>
                {MODEL_OPTIONS.find((option) => option.value === model)?.info}
              </Text>
            </Collapse>
          </Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap={3}
            pt={5}
            borderTop="1px solid"
            borderColor="gray.200"
          >
            {company && (
              <Tooltip label="Test the agent using the question and model you provided.">
                <Button
                  isDisabled={loadingResponse}
                  icon={<IconPlayerPlay size={16} />}
                  iconSpacing={1.5}
                  onClick={() => runAgent()}
                  as={Button}
                >
                  Test Agent
                </Button>
              </Tooltip>
            )}

            {agent?.id && showDeleteOption && (
              <Button size="sm" variant="outline" colorScheme="red" onClick={() => setIsDeleteOpen(true)}>
                Delete
              </Button>
            )}

            <Flex alignItems="center" gap={3} marginLeft="auto">
              {onCancel ? (
                <Button size="sm" variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
              ) : (
                <Button size="sm" variant="outline" as={Link} href={projectPath('/settings/agents')}>
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                size="sm"
                colorScheme="purple"
                isLoading={submitting}
                isDisabled={!canEditAgents || loadingResponse}
              >
                {agent?.id ? 'Update agent' : 'Create agent'}
              </Button>
            </Flex>
          </Flex>

          {(response || loadingResponse) && (
            <Stack borderWidth="1px" borderRadius="lg" p={4} background="gray.50" spacing={5}>
              <Text fontSize="12px" color="gray.400" textTransform="uppercase" fontWeight="semibold" mb={4}>
                Preview Run
              </Text>
              <AIAgentResponse response={response} loading={loadingResponse} agent={agent} />
            </Stack>
          )}
        </Stack>
      </form>

      <DeleteConfirmation
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        deletePath={projectPath(`/settings/agents/${agent?.id}`)}
        title="Delete AI Agent"
        confirmLabel="Delete Agent"
      >
        Are you sure you want to delete this AI agent? This action cannot be undone.
      </DeleteConfirmation>
    </>
  )
}

interface EditAgentModalProps extends UseDisclosureProps {
  ai_agent: AIAgent
  onChange?: (ai_agent: AIAgent) => void
}

export function EditAgentModal({ ai_agent, onChange, ...props }: EditAgentModalProps) {
  const { isOpen, onClose } = useDisclosure(props)
  const { mutateAsync: createAIAgent, isLoading: creating } = useCreateAIAgent()
  const { mutateAsync: updateAIAgent, isLoading: updating } = useUpdateAIAgent()

  const isLoading = creating || updating

  const onSubmit = useCallback(
    async (agent: AIAgent) => {
      try {
        let res: { ai_agent: AIAgent }

        if (agent?.id) {
          res = await updateAIAgent({
            id: agent.id,
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target
          })
        } else {
          res = await createAIAgent({
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target
          })
        }
        onClose()
        onChange?.(res.ai_agent)
        toast.success('Agent saved successfully!')
      } catch (err) {
        toast.error('Error saving agent', { description: (err as any)?.message })
      }
    },
    [onClose, createAIAgent, updateAIAgent, onChange]
  )

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      isCentered
    >
      <ModalOverlay zIndex="overlay" />
      <ModalContent zIndex="popover">
        <ModalCloseButton isDisabled={isLoading} />
        <ModalHeader fontSize="lg">{ai_agent?.id ? 'Edit your' : 'Create an'} AI agent</ModalHeader>
        <ModalBody pb={5}>
          <AIAgentForm ai_agent={ai_agent} onSubmit={onSubmit} onCancel={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface EditAgentDrawerProps extends UseDisclosureProps {
  ai_agent: AIAgent
  onChange: (agent: AIAgent, skipCache: boolean) => void
  showTarget: boolean
  showDeleteOption: boolean
  company?: Company
}

export const EditAgentDrawer = (props: EditAgentDrawerProps) => {
  const { isOpen, onClose } = useDisclosure(props)
  const { mutateAsync: createAIAgent, isLoading: creating } = useCreateAIAgent()
  const { mutateAsync: updateAIAgent, isLoading: updating } = useUpdateAIAgent()
  const [skipCache, setSkipCache] = useState(false)

  const isLoading = creating || updating

  const onSubmit = useCallback(
    async (agent: AIAgent) => {
      try {
        let res: { ai_agent: AIAgent }

        if (agent?.id) {
          res = await updateAIAgent({
            id: agent.id,
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target
          })
        } else {
          res = await createAIAgent({
            name: agent.name,
            question: agent.question,
            model: agent.model,
            target: agent.target
          })
        }
        onClose()
        props.onChange?.(res.ai_agent, skipCache)
        toast.success('Agent saved successfully!')
      } catch (err) {
        toast.error('Error saving agent', { description: (err as any)?.message })
      }
    },
    [onClose, props, updateAIAgent, createAIAgent, skipCache]
  )

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody py={6}>
          <AIAgentForm
            ai_agent={props.ai_agent}
            onSubmit={onSubmit}
            onCancel={onClose}
            showTarget={props.showTarget}
            showDeleteOption={props.showDeleteOption}
            company={props.company}
            setSkipCache={setSkipCache}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
