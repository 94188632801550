import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import { Box, Button, Circle, Code, Flex, Heading, HStack, Icon, Link, Spinner, Stack, Text } from '@chakra-ui/react'
import {
  IconAlertTriangle,
  IconArrowRight,
  IconCheck,
  IconClick,
  IconClockPlay,
  IconExternalLink,
  IconFileTypography,
  IconSend,
  IconTransform,
  IconTrendingUp,
  IconWorld
} from '@tabler/icons-react'
import { get, groupBy, omit, orderBy, uniqBy } from 'lodash'
import ms from 'ms'
import React, { useCallback, useEffect } from 'react'
import { toast } from 'sonner'
import { Notification, NotificationMatch } from '..'
import { concurrentCachedGET, post } from '../../../../lib/api'
import { duration } from '../../../../lib/duration'
import { pluralize } from '../../../../lib/pluralize'
import { KQLMatch } from '../../../../types/KQL'
import { DateTime, PageView, ProfileEvent } from '../../../../types/Profile'
import { Card } from '../../../ui/Card'
import { Iconify } from '../../../ui/Iconify'
import { JSONTree } from '../../../ui/json-tree'
import { StackedField } from '../../../ui/StackedField'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import {
  blocked,
  isLimitedAccount,
  RedactedAccountCell,
  RedactedText,
  useEntitlements
} from '../../../ui/useEntitlements'
import useLatestRef from '../../../ui/useLatestRef'
import { useCurrentUser } from '../../../ui/UserContext'
import { VirtualList } from '../../../ui/VirtualList'
import { Toggle } from '../../accounts/components/Toggle'
import { channelLogos } from '../../follow_rules/components/delivery-setup'
import { FormSubmission } from '../../forms/reports/submission-list'
import { AccountEventSession, AccountTrait } from '../../sessions'
import { icons } from '../../sessions/components/AccountEventFeed'
import { SessionActor } from '../../sessions/components/MaterializedSession'
import Time from '../../sessions/components/Time'
import { automationPath, notificationPath, notificationsPath, slackAlertPath } from '../lib/path-helper'
import { KQLObject, NotificationCard, NotificationCardProps } from '../show'

export function NotificationEntry({
  isPreview,
  isLog,
  ...notification
}: Notification & { isPreview?: boolean; isLog?: boolean }) {
  const [isPreviewing, setIsPreviewing] = React.useState(false)
  const [previewed, setPreviewed] = React.useState(false)
  const [failed, setFailed] = React.useState(false)
  const isFake = Boolean(notification.context?.fake)

  const notificationRef = useLatestRef(notification)

  useEffect(() => {
    if (previewed) {
      const timer = setTimeout(() => setPreviewed(false), 5000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [previewed])

  useEffect(() => {
    if (failed) {
      const timer = setTimeout(() => setFailed(false), 5000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [failed])

  const onNotificationSend = useCallback(() => {
    const notification = notificationRef.current
    const sentTo = Object.keys(omit(notification.context?.delivery_rules ?? {}, ['team_member', 'delay_minutes']))

    if (sentTo.length === 0) {
      toast.error('Missing Delivery Rules', {
        description: `You don't seem to have selected any destinations for you automation rules. Check your automation rules.`
      })

      return
    }

    setIsPreviewing(true)
    setPreviewed(false)
    post(notificationsPath('/preview-send'), {
      notification: notification
    })
      .then(() => {
        toast.success(
          `${pluralize(sentTo.length, 'Automation rule', 'Automation rules', false)} sent to: ${sentTo.join(', ')}`,
          {
            description: `Check your ${pluralize(sentTo.length, 'inbox', 'inboxes', false)}!`
          }
        )
        setIsPreviewing(false)
        setPreviewed(true)
      })
      .catch((error) => {
        setFailed(true)
        setIsPreviewing(false)

        toast.error('Failed to test automation', {
          description: `${error?.message} ${JSON.stringify(error.body, null, 2)}`
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [loading, setLoading] = React.useState(false)
  const [notificationDetails, setNotificationDetails] = React.useState<NotificationCardProps>()

  const expand = useCallback(() => {
    const path = notificationPath(notification.id)
    setLoading(true)

    concurrentCachedGET<NotificationCardProps>(path).then((res) => {
      setLoading(false)
      setNotificationDetails(res)
    })
  }, [notification.id])

  const entitlements = useEntitlements()
  const seenElement = {
    last_seen_at:
      notification.session?.account?.last_seen_at ??
      notification.session?.profile?.last_seen_at ??
      notification.session?.last_touched_at,
    limited: notification.session?.account?.limited ?? notification.session?.profile?.limited
  }

  const isRedacted = isLimitedAccount(entitlements, seenElement)

  const destinations = Object.keys(notification.context?.delivery_rules || {}).filter((key) => {
    return notification.context?.delivery_rules?.[key]?.delivered === true
  })

  if (isFake) {
    return (
      <Stack spacing="2" w="100%" maxW={'container.sm'} margin={'0 auto'} role="group">
        <Card p={0} px={4} py={4}>
          <Flex justifyContent={'space-between'} alignItems="center" px={4} py={4}>
            <Heading as="h6" size="xs">
              <HStack>
                <CompanyAvatar domain="getkoala.com" size="xs"></CompanyAvatar>
                <Text>{notification.context?.delivery_rules?.slack?.title || 'Someone from koala'}</Text>
              </HStack>
            </Heading>
            <Button
              leftIcon={
                previewed ? <IconCheck size={14} /> : failed ? <IconAlertTriangle size={14} /> : <IconSend size={14} />
              }
              isLoading={isPreviewing}
              size="sm"
              onClick={onNotificationSend}
              disabled={previewed || failed}
              colorScheme={previewed ? 'blue' : failed ? 'red' : 'purple'}
              variant={'outline'}
            >
              {previewed ? 'Sent' : failed ? 'Failed' : 'Send Test'}
            </Button>
          </Flex>
          <HStack
            w="100%"
            alignItems="flex-start"
            borderTop="1px solid"
            borderColor="gray.200"
            {...blocked(entitlements, seenElement)}
          >
            <Stack flex="1">
              <RedactedText redacted={isRedacted}>
                <NotificationMatches {...notification} isPreview />
              </RedactedText>
            </Stack>
          </HStack>
        </Card>
      </Stack>
    )
  }

  return (
    <Stack
      spacing="2"
      w="100%"
      maxW={notificationDetails ? 'container.md' : 'container.sm'}
      margin={'0 auto'}
      role="group"
    >
      {!isLog && (
        <Flex fontSize="xs" gap={2}>
          {destinations.length > 0 && (
            <HStack spacing={1}>
              {destinations.map((destination) => (
                <Iconify key={destination} icon={channelLogos[destination]} size={15} />
              ))}
            </HStack>
          )}

          {notification.follow_rule && !isPreview && (
            <HStack spacing={1}>
              {notification.follow_rule?.managed_by_id ? (
                <Link fontWeight="normal" isExternal href={slackAlertPath(notification.follow_rule.managed_by_id)}>
                  Slack Alert to #
                  {notification.context?.delivery_rules?.slack?.channel_name ||
                    notification.context?.delivery_rules?.slack?.channel_id ||
                    notification.follow_rule.slack_channel_name}
                </Link>
              ) : (
                <Link fontWeight="normal" isExternal href={automationPath(notification.follow_rule.id)}>
                  {notification.follow_rule?.name}
                </Link>
              )}
              <IconExternalLink size="12" />
            </HStack>
          )}
        </Flex>
      )}

      {!notificationDetails && (
        <Stack>
          <Card p={0}>
            <HStack justifyContent={'space-between'} alignItems="flex-start" px={4} py={4}>
              <RedactedAccountCell entitlements={entitlements} element={seenElement} flexProps={{ gap: 4 }}>
                {notification.session && <SessionActor compact hideTimestamps {...notification.session} />}
              </RedactedAccountCell>

              {isPreview ? (
                <Button
                  leftIcon={
                    previewed ? (
                      <IconCheck size={14} />
                    ) : failed ? (
                      <IconAlertTriangle size={14} />
                    ) : (
                      <IconSend size={14} />
                    )
                  }
                  isLoading={isPreviewing}
                  size="sm"
                  onClick={onNotificationSend}
                  disabled={previewed || failed}
                  colorScheme={previewed ? 'blue' : failed ? 'red' : 'purple'}
                  variant={'outline'}
                >
                  {previewed ? 'Sent' : failed ? 'Failed' : 'Send Test'}
                </Button>
              ) : (
                <Button
                  as={Link}
                  size="xs"
                  colorScheme={'purple'}
                  variant="ghost"
                  href={notificationPath(notification.id)}
                >
                  <TimeAgo time={notification.triggered_at} />
                </Button>
              )}
            </HStack>

            {hasMatches(notification) && (
              <HStack
                w="100%"
                alignItems="flex-start"
                borderTop="1px solid"
                borderColor="gray.200"
                {...blocked(entitlements, seenElement)}
              >
                <Stack flex="1">
                  <RedactedText redacted={isRedacted}>
                    <NotificationMatches {...notification} isPreview={false} />
                  </RedactedText>
                </Stack>
              </HStack>
            )}
            {(notification.recipient || notification.context.delivery_rules?.team_member?.email) &&
              notification.team_based && (
                <HStack fontSize={'xs'} spacing="1" justifyContent={'flex-end'} px={4} py={4}>
                  <Text fontWeight={'semibold'}>Routed to:</Text>
                  <Text>
                    {notification.recipient?.name} (
                    {notification.recipient?.email ?? notification.context.delivery_rules?.team_member?.email})
                  </Text>
                </HStack>
              )}
          </Card>
          {!isPreview && !isLog && (
            <Flex justifyContent={'center'}>
              <Button isLoading={loading} mt="3" size="xs" variant="ghost" onClick={expand}>
                Show Details
              </Button>
            </Flex>
          )}
        </Stack>
      )}

      {notificationDetails && (
        <Stack spacing="6">
          <Stack as={Card} spacing={4} px={5} py={4}>
            <NotificationCard {...notificationDetails} />
          </Stack>
          <Flex justifyContent={'center'}>
            <Button
              size="xs"
              variant="ghost"
              onClick={() => {
                setNotificationDetails(undefined)
              }}
            >
              Hide Details
            </Button>
          </Flex>
        </Stack>
      )}
    </Stack>
  )
}

function getOriginPath(value?: string) {
  try {
    const url = new URL(value || '')
    return url.hostname + url.pathname
  } catch (_err) {
    return value
  }
}

type TraitMatch = {
  class: string
  name: string
  trait: {
    name: string
    value: {
      updated_at: DateTime
      from: any
      to: any
    }
  }
}

export type VisitorAnalyticsMatch = {
  class: 'VisitorAnalytics'
  visitor_stats: {
    identified: {
      day: number
      week: number
      month: number
    }
    visitors: {
      day: number
      week: number
      month: number
    }
  }
}

function TraitTitle({ trait }: { trait: TraitMatch }) {
  return (
    <HStack>
      <Text>{trait?.name ?? trait.trait.name}</Text>
      {trait?.trait?.value && (
        <>
          <Code fontSize={'xs'}>{JSON.stringify(trait.trait.value.from)}</Code>
          <IconArrowRight size="14" />
          <Code fontSize={'xs'}>{JSON.stringify(trait.trait.value.to)}</Code>
        </>
      )}
    </HStack>
  )
}

function title(message: NotificationMatch) {
  const conditionKind = message.condition?.[0]?.kind

  if (conditionKind === 'account_trait') {
    return <TraitTitle trait={message.match as unknown as TraitMatch} />
  }

  if (conditionKind === 'profile_trait') {
    return <TraitTitle trait={message.match as unknown as TraitMatch} />
  }

  switch (message.match['class']) {
    case 'PageView':
      return getOriginPath((message.match as PageView).path)
    case 'FormSubmission':
      return getOriginPath(
        (message.match as unknown as FormSubmission).page_url || (message.match as unknown as FormSubmission).page_path
      )
    case 'Event':
      return (message.match as unknown as ProfileEvent).event
    case 'ProfileTrait':
      return <TraitTitle trait={message.match as unknown as TraitMatch} />
    case 'AccountTrait':
      return <TraitTitle trait={message.match as unknown as TraitMatch} />
    case 'VisitorAnalytics': {
      const { property, operator } = message.condition
      const val = get(message.match, property) as number
      const [variant, type, period] = property.split('.')

      let prefix: React.ReactElement | null = null
      if (variant.includes('delta')) {
        prefix = operator.includes('greater') ? <Text>Up</Text> : <Text>Down</Text>
      }

      return (
        <HStack>
          <Text>Visitor Analytics:</Text>
          <HStack spacing="1">
            {prefix}
            <Text>
              <b>{val}</b> {period.replace('day', 'dai')}ly {type === 'identified' ? 'identified' : ''}{' '}
              {pluralize(val, 'visitor', 'visitors', false)}
            </Text>
          </HStack>
        </HStack>
      )
    }
    case 'AccountEvent': {
      const event = message.match as unknown as AccountEventSession
      const type = event.source.charAt(0).toUpperCase() + event.source.slice(1)

      return (
        <HStack>
          <Text>
            {type} Event | {event.event}
          </Text>
        </HStack>
      )
    }
    case 'FocusTime': {
      const property = message.condition.property
      const val = get(message.match, property) as number
      let unit = message.condition.property.replace('trend.', '')
      if (unit === 'focus_time') {
        unit = 'day'
      }

      return (
        <HStack>
          <Text>Active Session Time</Text>
          {unit && <Text>({unit})</Text>}
          <Text>{val ? ms(val) : '—'}</Text>
        </HStack>
      )
    }
    default:
      return null
  }
}

const MatchDetails: React.FC<{ notificationId: string }> = ({ notificationId }) => {
  const [matches, setMatches] = React.useState<Record<string, any>[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const loadMatcheDetails = useCallback(() => {
    setIsLoading(true)
    concurrentCachedGET<Record<string, any>[]>(`${notificationPath(notificationId)}/match-details`)
      .then((res) => {
        setMatches(res)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [notificationId])

  useEffect(() => {
    loadMatcheDetails()
  }, [loadMatcheDetails])

  const items = matches.map((message) => {
    let deets: React.ReactNode = null

    switch (message?.class) {
      case 'PageView': {
        const pv = message as PageView

        deets = (
          <Stack spacing="3" fontSize="xs">
            <StackedField label="Page">
              <TextEllipsis tooltip maxW={'400px'}>
                {pv.url}
              </TextEllipsis>
            </StackedField>

            <StackedField label="Visit Start">
              <Time time={pv.visit_start} />
            </StackedField>

            <StackedField label="Active Session Time" fontWeight={'normal'}>
              {pv.focus_time ? <Text>{duration(pv.focus_time, { long: true })}</Text> : <Text>--</Text>}
            </StackedField>
          </Stack>
        )
        break
      }
      case 'FormSubmission':
        return null
      case 'Event':
      case 'AccountEvent': {
        const event = message as unknown as ProfileEvent
        deets = (
          <Stack spacing="3" fontSize="xs">
            {event.data?.properties && (
              <StackedField label="Properties">
                <Box ml="-4" mt="-2">
                  <JSONTree data={event.data.properties} />
                </Box>
              </StackedField>
            )}
          </Stack>
        )
        break
      }
      case 'ProfileTrait':
        return null
      case 'AccountTrait': {
        const tm = message as unknown as AccountTrait

        return (
          <Stack spacing="3" fontSize="xs">
            <StackedField label="Trait">
              <TextEllipsis tooltip maxW={'400px'}>
                {tm.name}
              </TextEllipsis>
            </StackedField>

            {tm.group_id && (
              <StackedField label="Group ID">
                <TextEllipsis tooltip maxW={'400px'}>
                  {tm.group_id}
                </TextEllipsis>
              </StackedField>
            )}

            {tm.group_name && (
              <StackedField label="Group Name">
                <TextEllipsis tooltip maxW={'400px'}>
                  {tm.group_name}
                </TextEllipsis>
              </StackedField>
            )}

            <StackedField label="Updated At">
              <Time time={tm.updated_at} />
            </StackedField>

            <StackedField label="Previous Value">
              <JSONTree data={tm.previous_value} />
            </StackedField>

            <StackedField label="Current Value">
              <JSONTree data={tm.current_value} />
            </StackedField>
          </Stack>
        )
      }
      case 'FocusTime': {
        return null
      }
      default:
        return null
    }

    if (deets === null) {
      return null
    }

    return deets
  })

  return (
    <Stack>
      <Stack fontSize="sm" spacing="4">
        {isLoading && <Spinner size="xs" color="purple.500" speed="0.65s" thickness="2px" />}
        {items.map((deets, index) => {
          return <Flex key={index}>{deets}</Flex>
        })}
      </Stack>
    </Stack>
  )
}

export function matchTimestamp(message: NotificationMatch) {
  switch (message.match['class']) {
    case 'PageView':
      return (message.match as PageView).visit_end ?? (message.match as PageView).visit_start
    case 'FormSubmission':
      return (message.match as unknown as FormSubmission).created_at
    case 'Event':
      return (message.match as unknown as ProfileEvent).sent_at
    case 'ProfileTrait':
      return (message.match as unknown as TraitMatch).trait.value.updated_at
    case 'AccountTrait':
      return (message.match as unknown as TraitMatch).trait.value.updated_at
    case 'AccountEvent':
      return (message.match as unknown as AccountEventSession).timestamp
    default:
      return null
  }
}

function icon(message: KQLMatch): React.ReactNode {
  switch (message.class) {
    case 'PageView':
      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconWorld} boxSize={4} marginX={0.25} />
        </Circle>
      )
    case 'FormSubmission':
      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconFileTypography} boxSize={4} marginX={0.25} />
        </Circle>
      )
    case 'Event':
      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconClick} boxSize={4} marginX={0.25} />
        </Circle>
      )
    case 'AccountTrait':
      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconTransform} boxSize={4} marginX={0.25} />
        </Circle>
      )
    case 'ProfileTrait':
      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconTransform} boxSize={4} marginX={0.25} />
        </Circle>
      )
    case 'VisitorAnalytics':
      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconTrendingUp} boxSize={4} marginX={0.25} />
        </Circle>
      )
    case 'FocusTime':
      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconClockPlay} boxSize={4} marginX={0.25} />
        </Circle>
      )
    case 'AccountEvent': {
      const event = message as unknown as AccountEventSession

      return (
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={icons[event.source] || IconWorld} boxSize={4} marginX={0.25} />
        </Circle>
      )
    }
    default:
      return null
  }
}

export function hasMatches(notification: Notification) {
  const matches = uniqBy(notification.context?.matches ?? [], (m) => [m.match.id, m.match.class].join('-'))
  if (!Array.isArray(matches) || matches.length === 0) {
    return false
  }

  if (matches.every((match) => match.match.id === 'empty')) {
    return false
  }

  return true
}

export function NotificationMatches(
  props: Notification & {
    isPreview?: boolean
    kql_objects?: Array<KQLObject>
  }
) {
  const matches = orderBy(
    uniqBy(props.context?.matches ?? [], (m) => [m.match.id, m.match.class].join('-')),
    (m) => matchTimestamp(m),
    'desc'
  )

  if (!Array.isArray(matches) || matches.length === 0) {
    return null
  }

  const grouped = groupBy(matches, (m) => title(m))
  const groupedKeys = Object.keys(grouped)

  return (
    <Stack>
      <VirtualList
        estimateSize={(index) => {
          const key = groupedKeys[index]
          const items = grouped[key]
          if (items.length === 1) {
            if (!title(items[0])) {
              return 0
            }
          }
          return 32
        }}
        items={groupedKeys}
        maxH={'800px'}
        containerStyles={{ paddingTop: '8px', paddingBottom: '8px' }}
        renderItem={(groupKey) => {
          const matches = grouped[groupKey]
          const count = matches.length

          if (count > 1) {
            const time = matchTimestamp(matches[matches.length - 1])

            return (
              <Stack
                key={groupKey}
                w="100%"
                justifyContent={'center'}
                py="1"
                px="4"
                minH="36px"
                _hover={{
                  bg: 'gray.50'
                }}
              >
                <HStack fontSize={'xs'} w="100%" justifyContent={'space-between'}>
                  <HStack>
                    {icon(matches[0].match)}
                    <Text fontWeight={'semibold'}>{count}x</Text>
                    <TextEllipsis tooltip maxW={'400px'}>
                      {title(matches[0])}
                    </TextEllipsis>
                  </HStack>
                  <HStack>{time && <TimeAgo time={time} />}</HStack>
                </HStack>
                {!props.isPreview && <MatchDetails notificationId={props.id} />}
              </Stack>
            )
          }

          const match = matches[0]
          const time = matchTimestamp(match)
          return (
            <Stack
              key={JSON.stringify(match)}
              w="100%"
              justifyContent={'center'}
              py="1"
              px="4"
              minH="36px"
              _hover={{
                bg: 'gray.50'
              }}
            >
              <HStack fontSize={'xs'} w="100%" justifyContent={'space-between'}>
                <HStack>
                  {icon(match.match)}
                  <TextEllipsis tooltip maxW={'400px'} flex="1">
                    {title(match)}
                  </TextEllipsis>
                </HStack>
                <HStack>{time && <TimeAgo time={time} />}</HStack>
              </HStack>
              {!props.isPreview && <MatchDetails notificationId={props.id} />}
            </Stack>
          )
        }}
      />
    </Stack>
  )
}

export function NotificationDebug(props: Notification) {
  const user = useCurrentUser()

  if (!user || !user.email?.includes('getkoala.com')) {
    return null
  }

  return (
    <Stack w="100%" paddingTop={2} borderTop="1px solid" borderColor="gray.100">
      <Toggle
        title={
          <Text fontSize="xs" fontWeight="medium">
            Debug [Internal Koala Only]
          </Text>
        }
      >
        <Flex fontSize="sm" bg="white" w="100%">
          <JSONTree data={props} />
        </Flex>
      </Toggle>
    </Stack>
  )
}
