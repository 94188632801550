import { Flex, Text } from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { UrlFilterParams } from '../data/use-url-filters'
import { usePermission } from './PermissionsContext'
import { useCurrentUser } from './UserContext'
import { UserSelector } from './UserSelector'

export function ViewAsUserSelector({
  facets,
  includeCrmUsers = false,
  onSelectedUserIdChange,
  children
}: {
  facets: UrlFilterParams
  includeCrmUsers?: boolean
  onSelectedUserIdChange?: (userId: string | null) => void
  children?: React.ReactNode
}) {
  const currentUser = useCurrentUser()
  const [selectedUserId, setSelectedUserId] = useState<string | null>(
    (facets.raw.user as string) ?? currentUser.id ?? null
  )
  const facetFilters = facets.facetFilters
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })

  const hasCurrentUserFilter = useMemo(
    () => JSON.stringify(facetFilters || {}).includes('Current user'),
    [facetFilters]
  )

  const onUserChange = useCallback(
    (userId?: string | null) => {
      setSelectedUserId(userId ?? null)
      if (onSelectedUserIdChange) {
        onSelectedUserIdChange(userId ?? null)
      }
      facets.updateAndResetPage('user', userId ?? null)
    },
    [facets, setSelectedUserId, onSelectedUserIdChange]
  )

  if (!canViewAsMember || !hasCurrentUserFilter) {
    return null
  }

  return (
    <>
      <Flex flex="none" alignItems="center" gap={1}>
        <Text fontSize="sm" color="gray.600">
          View as
        </Text>
        <UserSelector
          selectedUserId={selectedUserId}
          onChange={(userId) => onUserChange(userId)}
          includeCrmUsers={includeCrmUsers}
        />
      </Flex>

      {children}
    </>
  )
}
