import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Circle,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text
} from '@chakra-ui/react'
import {
  IconArrowDown,
  IconArrowUp,
  IconChartBar,
  IconChartLine,
  IconDotsVertical,
  IconLink,
  IconPlus
} from '@tabler/icons-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import useLocalStorageState from 'use-local-storage-state'
import { subscribeToChannel, SubscriptionEmitter } from '../../../channels/generic_channel'
import dayjs from '../../../lib/dayjs'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { useDashboardWidget } from '../../data/use-dashboard-widget'
import { facetQueryString } from '../../data/use-facets'
import { useUrlFilters } from '../../data/use-url-filters'
import { LightBgCard as Card } from '../../ui/Card'
import { CardHeader } from '../../ui/CardHeader'
import { green } from '../../ui/colors'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { HelpTooltip } from '../../ui/HelpTooltip'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import SelectInput from '../../ui/SelectInput'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { FunnelChart } from '../dashboards/components/funnel'
import { LookbackLimitUpgradeBanner } from '../dashboards/components/LookbackLimitUpgradeBanner'
import NoDataAvailable from '../dashboards/components/NoDataAvailable'
import { mergeParams } from '../icps/types'
import { BarGraph } from './components/BarChart'
import DateRangePicker, { getDefaultPresets } from './components/DateRangePicker'
import { LineGraph } from './components/LineGraph'
import { TopList } from './components/TopList'

interface Props {
  apps: Apps
  crm?: {
    app_instance: {
      app_module: string
    }
  }
  live: number
  metric: 'visitors' | 'page_views' | 'accounts' | 'signals'
  period: string
  data_available: boolean
  lookback_limit: string
  suggest_lookback_upgrade: boolean
}

function useOnlineCount(initialCount?: number) {
  const project = useCurrentProject()
  const subscription = React.useRef<SubscriptionEmitter | undefined>()
  const [onlineVisitors, setOnlineVisitors] = React.useState(initialCount ?? window.online_visitors ?? 0)

  React.useEffect(() => {
    if (subscription.current) {
      return
    }

    subscription.current = subscribeToChannel({
      channel: 'UsersChannel',
      project_slug: project?.slug,
      location: 'analytics'
    })

    const onData = (data) => {
      if (data.action === 'visitor' && typeof data.online === 'number') {
        setOnlineVisitors(data.online)
      }
    }

    subscription.current.on('received', onData)

    return () => {
      subscription.current?.off('received', onData)
      subscription.current?.unsubscribe()
      subscription.current = undefined
    }
  }, [project?.slug])

  return onlineVisitors
}

const emptyArray = []
function sourceDataset(selection: string) {
  switch (selection) {
    case 'Medium':
      return 'top_utm_mediums'
    case 'Campaign':
      return 'top_utm_campaigns'
    case 'Referrer':
      return 'top_referrers'
    case 'Source':
    default:
      return 'top_utm_sources'
  }
}

function mainDataset(selection: string) {
  switch (selection) {
    case 'page_views':
      return 'page_views_timeseries'
    case 'accounts':
      return 'accounts_timeseries'
    case 'signals':
      return 'intent_signals_timeseries'
    case 'visitors':
    default:
      return 'visitors_timeseries'
  }
}

export default function Show(props: Props) {
  if (!props.data_available) {
    return <NoDataAvailable />
  } else {
    return <ShowWithData {...props} />
  }
}

function ShowWithData(props: Props) {
  const onlineCount = useOnlineCount(props.live)
  const [metric, setMetric] = React.useState(props.metric)
  const [chartType, setChartType] = React.useState('bar')
  const [selectedSourceParam, setSourceParam] = React.useState('Referrer')
  const loadingMetric = React.useRef<string>(props.metric)

  const [period, setPeriod] = React.useState(props.period)
  const params = useMemo(() => ({ period }), [period])

  const facets = useUrlFilters({ initialRange: null })
  const facetQuery = useMemo(() => {
    const query = facetQueryString(facets.facetFilters).join('&')
    return query
  }, [facets.facetFilters])

  const [isHidingAccounts, setIsHidingAccounts] = useLocalStorageState('analytics:ignore_hidden:v2', {
    defaultValue: '0'
  })

  const lookbackLimit = dayjs(props.lookback_limit).toDate()
  const suggestUpgrade = props.suggest_lookback_upgrade
  const dataset = mainDataset(metric)
  const timeseries = useDashboardWidget(dataset, params, facetQuery, undefined, isHidingAccounts)

  const utmDataset = sourceDataset(selectedSourceParam)
  const visitors = useDashboardWidget('visitors', params, facetQuery, undefined, isHidingAccounts)
  const accounts = useDashboardWidget('account_associations', params, facetQuery, undefined, isHidingAccounts)
  const pages = useDashboardWidget('page_views', params, facetQuery, undefined, isHidingAccounts)
  const signals = useDashboardWidget('intent_signals', params, facetQuery, undefined, isHidingAccounts)

  const topSources = useDashboardWidget(utmDataset, params, facetQuery, undefined, isHidingAccounts)
  const topSignals = useDashboardWidget('top_intent_signals', params, facetQuery, undefined, isHidingAccounts)
  const topIdentified = useDashboardWidget('top_identified_visitors', params, facetQuery, undefined, isHidingAccounts)
  const topPages = useDashboardWidget('top_pages', params, facetQuery, undefined, isHidingAccounts)

  React.useEffect(() => {
    setMetric(props.metric)
    loadingMetric.current = props.metric
  }, [props.metric])

  React.useEffect(() => {
    setPeriod(props.period)
  }, [props.period])

  const selectMetric = React.useCallback(async (metric: string) => {
    if (metric === loadingMetric.current) {
      return
    }

    loadingMetric.current = metric
    setMetric(metric as Props['metric'])

    const path = mergeParams(window.location.toString(), { metric })
    // pushState to avoid triggering an extra fetch
    history.pushState({}, '', path)
  }, [])

  const updatePeriod = React.useCallback(async (period: string | undefined) => {
    if (period) {
      setPeriod(period)

      const path = mergeParams(window.location.toString(), { period })
      router.visit(path)
    }
  }, [])

  const largeScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const stickyRef = useRef<HTMLDivElement | null>(null)
  const [isSticky, setIsSticky] = useState(false)

  // Detect "sticky" state with IntersectionObserver
  useEffect(() => {
    if (stickyRef.current) {
      const computedStyle = window.getComputedStyle(stickyRef.current)
      const topOffset = parseInt(computedStyle.top, 10)

      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsSticky(entry.intersectionRatio < 1)
        },
        {
          rootMargin: `-${(isNaN(topOffset) ? 0 : topOffset) + 1}px 0px 0px 0px`,
          threshold: [1]
        }
      )

      observer.observe(stickyRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [largeScreen])

  const presets = getDefaultPresets().map((preset) => {
    if (suggestUpgrade && preset.range.from && preset.range.from < lookbackLimit) {
      preset.lockedReason = 'Upgrade to unlock'
    }
    return preset
  })

  return (
    <>
      <PageLayout size="full">
        <Flex
          ref={stickyRef}
          position="sticky"
          top={largeScreen ? `var(--nav-height)` : '0'}
          py={3}
          px={[4, 4, 6, 8]}
          mx={[-4, -4, -6, -8]}
          bg="white"
          borderBottom="1px solid"
          borderBottomColor={isSticky ? 'gray.200' : 'transparent'}
          shadow={isSticky ? 'md' : 'none'}
          zIndex="1"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={4}
        >
          <Flex flex={['1 1 100%', '1 1 100%', 'none']} alignItems="center" gap={4} position="relative">
            <PageTitle>Analytics</PageTitle>
            <Badge colorScheme="purple">Beta</Badge>
            <Box
              as={Link}
              href={projectPath('/live')}
              display="inline-flex"
              alignItems="baseline"
              gap={2}
              fontSize="sm"
              fontWeight="semibold"
              color="gray.600"
            >
              <Circle alignSelf="center" size="8px" bg={green} />
              <Text>{onlineCount} current visitors</Text>
            </Box>
          </Flex>

          <HStack alignItems="center" spacing={2}>
            <FilterPreview
              apps={props.apps}
              {...facets}
              kind="account"
              range="all"
              canClearFilters={false}
              shouldShowLastSeenFilter={false}
              hideFacetCounts
            >
              <Button size="sm" variant="outline" leftIcon={<IconPlus size={15} />} iconSpacing={1.5}>
                Add Filter
              </Button>
            </FilterPreview>
            <DateRangePicker
              period={period}
              min={lookbackLimit}
              presets={presets}
              onChange={updatePeriod}
              footer={
                <Box paddingX={4} paddingBottom={3}>
                  <Box fontSize="xs" fontStyle="italic" color="gray.500">
                    All times are in UTC
                  </Box>
                </Box>
              }
            />
            <Menu closeOnSelect={false}>
              <MenuButton
                size="sm"
                as={IconButton}
                aria-label="Options"
                icon={<IconDotsVertical size="14" />}
                variant="outline"
              />

              <MenuList zIndex="popover">
                <MenuItem>
                  <Checkbox
                    isChecked={isHidingAccounts === '1'}
                    onChange={(e) => setIsHidingAccounts(e.target.checked ? '1' : '0')}
                  >
                    Ignore hidden accounts
                  </Checkbox>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>

          {(isHidingAccounts === '1' || facets.isFiltering) && (
            <Flex w="100%" justifyContent="flex-end" alignItems="center" gap={1.5}>
              {isHidingAccounts === '1' && (
                <Checkbox
                  size="sm"
                  color="gray.500"
                  isChecked={isHidingAccounts === '1'}
                  onChange={(e) => setIsHidingAccounts(e.target.checked ? '1' : '0')}
                >
                  Ignoring hidden accounts and visitors
                </Checkbox>
              )}
              <HelpTooltip>
                Applying any account-level filters also removes anonymous activity that is not linked to any company.
              </HelpTooltip>
            </Flex>
          )}
        </Flex>

        {suggestUpgrade && <LookbackLimitUpgradeBanner source="web-analytics" />}

        <Grid width="100%" templateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={2}>
          <MetricTotal
            isActive={metric === 'visitors'}
            change={visitors.data?.data?.change}
            label="Visitors"
            isLoading={visitors.isLoading}
            onSelect={() => selectMetric('visitors')}
          >
            {visitors.data?.data?.total?.toLocaleString()}
          </MetricTotal>
          <MetricTotal
            isActive={metric === 'accounts'}
            change={accounts.data?.data?.change}
            label="Accounts"
            isLoading={accounts.isLoading}
            onSelect={() => selectMetric('accounts')}
          >
            {accounts.data?.data?.total?.toLocaleString()}
          </MetricTotal>
          <MetricTotal
            isActive={metric === 'page_views'}
            label="Page Views"
            change={pages.data?.data?.change}
            isLoading={pages.isLoading}
            onSelect={() => selectMetric('page_views')}
          >
            {pages.data?.data?.total?.toLocaleString()}
          </MetricTotal>
          <MetricTotal
            isActive={metric === 'signals'}
            label="Intent Signals"
            change={signals.data?.data?.change}
            isLoading={signals.isLoading}
            onSelect={() => selectMetric('signals')}
          >
            {signals.data?.data?.total?.toLocaleString()}
          </MetricTotal>
        </Grid>
        {metric === 'visitors' && (
          <Card padding={0}>
            <CardHeader
              paddingX={4}
              lineHeight={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              Visitors
              <ButtonGroup size="xs" variant="ghost" spacing={1}>
                <IconButton
                  aria-label="Toggle bar chart"
                  icon={<IconChartBar size={15} />}
                  mr="-px"
                  isActive={chartType === 'bar'}
                  onClick={() => setChartType('bar')}
                />
                <IconButton
                  aria-label="Toggle line chart"
                  icon={<IconChartLine size={15} />}
                  isActive={chartType === 'line'}
                  onClick={() => setChartType('line')}
                />
              </ButtonGroup>
            </CardHeader>
            <Box paddingX={4} paddingY={5}>
              {chartType === 'line' ? (
                <LineGraph
                  period={period}
                  label={{
                    returning_visitors: 'Return Visitors',
                    new_visitors: 'New Visitors'
                  }}
                  dataKeys={['returning_visitors', 'new_visitors']}
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                />
              ) : (
                <BarGraph
                  label={{
                    returning_visitors: 'Return Visitors',
                    new_visitors: 'New Visitors'
                  }}
                  period={period}
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                  xDataKey="timestamp"
                  yDataKeys={['returning_visitors', 'new_visitors']}
                />
              )}
            </Box>
          </Card>
        )}
        {metric === 'page_views' && (
          <Card padding={0}>
            <CardHeader
              paddingX={4}
              lineHeight={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              Page Views
              <ButtonGroup size="xs" variant="ghost" spacing={1}>
                <IconButton
                  aria-label="Toggle bar chart"
                  icon={<IconChartBar size={15} />}
                  mr="-px"
                  isActive={chartType === 'bar'}
                  onClick={() => setChartType('bar')}
                />
                <IconButton
                  aria-label="Toggle line chart"
                  icon={<IconChartLine size={15} />}
                  isActive={chartType === 'line'}
                  onClick={() => setChartType('line')}
                />
              </ButtonGroup>
            </CardHeader>
            <Box paddingX={4} paddingY={5}>
              {chartType === 'line' ? (
                <LineGraph
                  period={period}
                  label="Page Views"
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                />
              ) : (
                <BarGraph
                  label="Page Views"
                  period={period}
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                  xDataKey="timestamp"
                  yDataKeys={['value']}
                />
              )}
            </Box>
          </Card>
        )}
        {metric === 'signals' && (
          <Card padding={0}>
            <CardHeader
              paddingX={4}
              lineHeight={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              Intent Signals
              <ButtonGroup size="xs" variant="ghost" spacing={1}>
                <IconButton
                  aria-label="Toggle bar chart"
                  icon={<IconChartBar size={15} />}
                  mr="-px"
                  isActive={chartType === 'bar'}
                  onClick={() => setChartType('bar')}
                />
                <IconButton
                  aria-label="Toggle line chart"
                  icon={<IconChartLine size={15} />}
                  isActive={chartType === 'line'}
                  onClick={() => setChartType('line')}
                />
              </ButtonGroup>
            </CardHeader>
            <Box paddingX={4} paddingY={5}>
              {chartType === 'line' ? (
                <LineGraph
                  period={period}
                  label="Signals"
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                />
              ) : (
                <BarGraph
                  label="Signals"
                  period={period}
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                  xDataKey="timestamp"
                  yDataKeys={['value']}
                />
              )}
            </Box>
          </Card>
        )}
        {metric === 'accounts' && (
          <Card padding={0}>
            <CardHeader
              paddingX={4}
              lineHeight={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              Accounts
              <ButtonGroup size="xs" variant="ghost" spacing={1}>
                <IconButton
                  aria-label="Toggle bar chart"
                  icon={<IconChartBar size={15} />}
                  mr="-px"
                  isActive={chartType === 'bar'}
                  onClick={() => setChartType('bar')}
                />
                <IconButton
                  aria-label="Toggle line chart"
                  icon={<IconChartLine size={15} />}
                  isActive={chartType === 'line'}
                  onClick={() => setChartType('line')}
                />
              </ButtonGroup>
            </CardHeader>
            <Box paddingX={4} paddingY={5}>
              {chartType === 'line' ? (
                <LineGraph
                  period={period}
                  label={{
                    returning_accounts: 'Return Accounts',
                    new_accounts: 'New Accounts'
                  }}
                  dataKeys={['returning_accounts', 'new_accounts']}
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                />
              ) : (
                <BarGraph
                  label={{
                    returning_accounts: 'Return Accounts',
                    new_accounts: 'New Accounts'
                  }}
                  period={period}
                  data={timeseries.data?.data?.timeseries ?? emptyArray}
                  isLoading={timeseries.isLoading}
                  xDataKey="timestamp"
                  yDataKeys={['returning_accounts', 'new_accounts']}
                />
              )}
            </Box>
          </Card>
        )}

        {props.crm?.app_instance?.app_module === 'Apps::Salesforce::App' && false && (
          <FunnelChart
            apps={props.apps}
            name="Awareness -> Consideration -> Conversion"
            period={props.period}
            facetFilters={facets.facetFilters ?? {}}
            steps={[
              { id: '1', name: 'Any pageviews (Awareness)', filters: {} },
              {
                id: '2',
                name: 'Any intent signals (Consideration)',
                filters: {
                  'signal.name': { exists: 'true' }
                }
              },
              {
                id: '3',
                name: 'Active opportunities (Evaluation)',
                filters: {
                  'salesforce_opportunity.opportunities.IsClosed_boolean': 'false'
                }
              },
              {
                id: '4',
                name: 'Closed Won (Conversion)',
                filters: {
                  'salesforce_opportunity.opportunities.IsClosed_boolean': 'true',
                  'salesforce_opportunity.opportunities.IsWon_boolean': 'true'
                }
              }
            ]}
            accountIds={emptyArray}
            onSave={() => {}}
            onChangeName={() => {}}
          />
        )}

        <Grid
          width="100%"
          gridTemplateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, minmax(350px, 1fr))',
            'repeat(2, minmax(350px, 1fr))',
            'repeat(2, minmax(350px, 1fr))',
            'repeat(auto-fit, minmax(350px, 1fr))'
          ]}
          gap={6}
        >
          <Card p={0} overflow="hidden">
            <CardHeader paddingX={4} paddingBottom={3}>
              <Text lineHeight="24px">Top Pages</Text>
              <Flex fontSize="xs" marginTop={3} fontWeight="semibold" color="gray.500" justifyContent="space-between">
                <Text>Page</Text>
                <Text>Views</Text>
              </Flex>
            </CardHeader>
            <TopList
              items={(topPages.data?.data?.entries ?? emptyArray) as any}
              isLoading={topPages.isLoading}
              labelKey="path"
              dataKey="views"
              showBg
            />
          </Card>
          <Card p={0} overflow="hidden">
            <CardHeader paddingX={4} paddingBottom={3}>
              <Text lineHeight="24px">Top Contacts</Text>
              <Flex fontSize="xs" marginTop={3} fontWeight="semibold" color="gray.500" justifyContent="space-between">
                <Text>Contact</Text>
                <Text>Session Time</Text>
              </Flex>
            </CardHeader>
            <TopList
              items={(topIdentified.data?.data?.entries ?? emptyArray) as any}
              isLoading={topIdentified.isLoading}
              labelKey="name"
              dataKey="focus_time"
              formatter={formatShortDuration}
              showBg
            />
          </Card>
          <Card p={0} overflow="hidden">
            <CardHeader paddingX={4} paddingBottom={3}>
              <Flex alignItems="center" justifyContent="space-between" gap={4}>
                <Text lineHeight="24px">Top Sources</Text>
                <SelectInput
                  variant="outline"
                  size="xs"
                  items={['Source', 'Medium', 'Campaign', 'Referrer']}
                  selectedItem={selectedSourceParam}
                  onSelectedItemChange={(event) => {
                    setSourceParam(event.selectedItem)
                  }}
                  popperOptions={{
                    placement: 'bottom-end'
                  }}
                  usePortal
                />
              </Flex>
              <Flex fontSize="xs" marginTop={3} fontWeight="semibold" color="gray.500" justifyContent="space-between">
                <Text>{selectedSourceParam}</Text>
                <Text>Views</Text>
              </Flex>
            </CardHeader>
            <TopList
              items={(topSources.data?.data?.entries ?? []) as any}
              isLoading={topSources.isLoading}
              labelKey={selectedSourceParam === 'Referrer' ? 'referrer' : 'utm'}
              dataKey="page_views"
              labelRenderer={(props) => (
                <HStack>
                  {selectedSourceParam === 'Referrer' && (
                    <React.Fragment>
                      {props.label === 'Direct' ? (
                        <CompanyAvatar size="16px" fallbackIcon={<IconLink size={16} />} background="transparent" />
                      ) : (
                        <CompanyAvatar
                          size="16px"
                          src={`/web/companies/${props.label}/favicon`}
                          fallbackIcon={<IconLink size={16} />}
                          background="transparent"
                        />
                      )}
                    </React.Fragment>
                  )}
                  <Text flex="1 1 auto" isTruncated>
                    {props.label}
                  </Text>
                </HStack>
              )}
              showBg
            />
          </Card>
          <Card p={0} overflow="hidden">
            <CardHeader paddingX={4} paddingBottom={3}>
              <Text lineHeight="24px">Top Signals</Text>
              <Flex fontSize="xs" marginTop={3} fontWeight="semibold" color="gray.500" justifyContent="space-between">
                <Text>Signal</Text>
                <Text>Times</Text>
              </Flex>
            </CardHeader>
            <TopList
              items={(topSignals.data?.data?.entries ?? []) as any}
              isLoading={topSignals.isLoading}
              labelKey="name"
              dataKey="total"
              showBg
            />
          </Card>
        </Grid>
      </PageLayout>
    </>
  )
}

export const formatShortDuration = (seconds: number) => {
  const duration = dayjs.duration(Number(seconds), 'seconds')
  const format: string[] = []

  if (duration.days() > 0) {
    format.push('D[d]')
  }

  if (duration.hours() > 0) {
    format.push('H[h]')
  }

  if (duration.minutes() > 0) {
    format.push('m[m]')
  } else {
    format.push('s[s]')
  }

  return duration.format(format.join(' '))
}

interface MetricTotalProps {
  isActive?: boolean
  label: string
  change?: number
  isLoading?: boolean
  onSelect?: () => void
}

function MetricTotal(props: React.PropsWithChildren<MetricTotalProps>) {
  return (
    <Box
      flex="1"
      borderLeft="2px solid"
      borderColor={props.isActive ? '#4D32E4' : 'gray.200'}
      bg={props.isActive ? 'white' : 'transparent'}
      padding={4}
      cursor="pointer"
      onClick={props.onSelect}
    >
      <Heading size="xs" color={props.isActive ? '#4D32E4' : 'gray.500'}>
        {props.label}
      </Heading>
      <Flex gap={3} alignItems="baseline" fontWeight="semibold" paddingY={2}>
        {props.isLoading ? (
          <Skeleton height="20px" width="100px" startColor="background.light" endColor="gray.200" />
        ) : (
          <>
            <Text fontSize="xl" lineHeight={1}>
              {props.children}
            </Text>
            {!!props.change && (
              <Flex alignItems="center" gap={0.5} color={props.change > 0 ? 'green.500' : 'red.500'}>
                {props.change > 0 ? <IconArrowUp size={14} /> : <IconArrowDown size={14} />}
                <Text fontSize="sm" lineHeight={1}>
                  {Math.abs(Math.round(10 * props.change) / 10)}%
                </Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Box>
  )
}
