// Simple domain validation (not comprehensive, doesnt matter)
function isDomain(value?: string) {
  if (!value) return false
  if (value.length > 253) return false
  if (value.includes('localhost')) return false
  return value.includes('.') && value.split('.').length >= 2
}

export function getFaviconUrl(source: string, size: number = 32) {
  if (isDomain(source)) {
    return `https://www.google.com/s2/favicons?domain=${source}&sz=${size}`
  }
  return `/web/companies/${source}/favicon`
}
