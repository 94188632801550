import { Divider, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Stack } from '@chakra-ui/react'
import React from 'react'
import { Apps } from '../../../../types/App'
import { Play } from '../../../../types/Play'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { StepIcon } from '../../../ui/StepIcon'
import { AssignmentSetup } from './assignment-setup'
import { ProspectingSetup } from './prospecting-setup'
import { SourceSetup } from './source-setup'

export function PlayForm(props: { play: Partial<Play>; apps: Apps }) {
  return (
    <Stack
      spacing={8}
      divider={<Divider />}
      paddingBottom={8}
      marginBottom={8}
      borderBottom="1px solid"
      borderColor="gray.100"
    >
      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={1} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Play Details</Heading>

          <FormControl>
            <Input
              size="sm"
              name="play[name]"
              defaultValue={props.play.name}
              required
              placeholder="Play name (e.g. Pricing page visitors)"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Description/Goal (optional)</FormLabel>
            <AutosizedTextarea
              size="sm"
              name="play[description]"
              defaultValue={props.play.description}
              placeholder="Research the company, then send async ballpark offer"
            />
            <FormHelperText>
              Describe the goal of the play, and overall instructions for your reps executing the play
            </FormHelperText>
          </FormControl>
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={2} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Select an enrollment trigger</Heading>

          <SourceSetup
            apps={props.apps}
            targetType={props.play.target_type ?? 'companies'}
            targetConfig={props.play.target_config}
            triggerConfig={props.play.trigger_config}
          />
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={3} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Do you want to prospect for buyer personas?</Heading>

          <ProspectingSetup play={props.play} />
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={4} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Choose who to assign this play to</Heading>
          <AssignmentSetup play={props.play} apps={props.apps} />
        </Stack>
      </Flex>
    </Stack>
  )
}
