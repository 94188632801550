import EmptyState from '@app/components/ui/EmptyState'
import { TableFooter } from '@app/components/ui/TableFooter'
import { Account } from '@app/types/Account'
import { AccountView } from '@app/types/AccountView'
import { Apps } from '@app/types/App'
import { ProjectActions } from '@app/types/AppActions'
import { Crm } from '@app/types/Crm'
import { PageMeta } from '@app/types/PageMeta'
import { AvatarGroup, Box, Divider, Flex, Icon, useDisclosure, useCheckboxGroup } from '@chakra-ui/react'
import { IconChevronRight, IconCrosshair } from '@tabler/icons-react'
import startCase from 'lodash/startCase'
import React, { useCallback, useMemo, useRef, useEffect } from 'react'
import router from '../../../lib/router'
import { useUrlFilters } from '../../data/use-url-filters'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { defaultAccountColumns, defaultProfileColumns, useColumns } from '../../ui/ColumnSelector'
import { TopBarContent } from '../../ui/TopBarContext'
import { projectPath } from '../../ui/ProjectsContext'
import useLocation from '../../ui/useLocation'
import useTitle from '../../ui/useTitle'
import { PartialAccountView, ViewSelector } from '../../ui/ViewSelector'
import { FacetFilters } from '../accounts'
import { AccountList } from '../icps/icp/account-list'
import { HighlightedAccount, mergeParams } from '../icps/types'
import { ListContainer } from '../lists/components/ListContainer'
import { HighlightedProfile, ProfileList } from '../profiles/components/profile-list'
import { NewListModal } from './components/NewListModal'
import ViewDetails from './components/ViewDetails'
import { accountViewPath } from './lib/list-paths'
import { MCHeader } from '../mission_control/Header'
import Avatar from '../../ui/Avatar'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { SpaceHeader } from '../spaces/components/SpaceHeader'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'
import { BulkActionBar } from '../../ui/BulkActionBar'
import { BulkAddToSequenceMenu } from '@app/components/ui/BulkAddToSequenceMenu'
import { BulkAddToCrmModal } from '@app/components/ui/BulkAddToCrmModal'
import { useAIColumns } from '../ai_agents/components/useAIColumns'
import { ViewAsUserSelector } from '../../ui/ViewAsUserSelector'

type AccountWithStatus = Account & { status?: null | 'archived' | 'prospect' }

export interface AccountViewShowProps {
  project_actions: ProjectActions
  crm?: Crm
  apps: Apps
  columns: string[]
  ai_columns: string[]
  counts: Record<string, number>
  page_meta: PageMeta
  account_view: AccountView
  permissions: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  accounts: Array<AccountWithStatus | HighlightedProfile>
  facet_filters?: FacetFilters
  range?: AccountView['filters']['range']
  sort_by?: string
}

export default function Show(props: AccountViewShowProps) {
  const location = useLocation()
  const formRef = useRef<HTMLFormElement | null>(null)

  const checkboxes = useCheckboxGroup()

  useEffect(() => {
    checkboxes.setValue([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accounts])

  const facets = useUrlFilters({
    initialRange: props.account_view.filters.range ?? 'month',
    initialFacets: props.account_view.filters.facets,
    initialFocusTime: props.account_view.filters.focus_time,
    initialSortBy: props.account_view.filters.sort_by || props.sort_by,
    facetCloudPath: props.account_view.kind === 'profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  const profiles = props.accounts as HighlightedProfile[]
  const accounts = props.accounts as HighlightedAccount[]

  const entityType = props.account_view.kind === 'profile' ? 'visitor' : 'account'

  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const defaultColumns = props.account_view?.kind === 'profile' ? defaultProfileColumns : defaultAccountColumns

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.accounts,
    columns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key)
  })

  const {
    columns: aiColumns,
    loadingColumns: _aiLoadingColumns,
    onColumnChange: onAIColumnChange,
    onColumnRemove: _onAIColumnRemove
  } = useAIColumns({
    data: props.accounts,
    columns: props.ai_columns?.length ? props.ai_columns : [],
    initialColumns: props.ai_columns?.length ? props.ai_columns : []
  })

  const columnsChanged = useMemo(() => {
    // check that the columns are not the defaults / default order
    const persistedColumns = props.account_view?.display_settings?.display_columns
    const persistedAIColumns = props.account_view?.display_settings?.ai_columns
    const defaults = (props.account_view?.kind === 'profile' ? defaultProfileColumns : defaultAccountColumns).map(
      (c) => c.key
    )

    if (!persistedColumns) {
      return defaults.join(',') !== columns.join(',') || (persistedAIColumns || []).join(',') !== aiColumns.join(',')
    } else {
      return (
        persistedColumns.join(',') !== columns.join(',') || (persistedAIColumns || []).join(',') !== aiColumns.join(',')
      )
    }
  }, [columns, aiColumns, props.account_view])

  const { trackRecentNavItem } = useTrackRecentNavItems()

  const changeView = useCallback(
    (accountView: PartialAccountView | null) => {
      if (accountView?.id && accountView.class_name === 'StaticList') {
        const path = projectPath(`/lists/${accountView.id}`)
        trackRecentNavItem(`staticList:${accountView.id}`)
        router.visit(path)
      } else if (accountView && accountView.id !== props.account_view.id) {
        const path = accountViewPath(accountView)
        if (accountView.id) {
          trackRecentNavItem(`accountView:${accountView.id}`)
        }
        router.visit(path)
      }
    },
    [props.account_view, trackRecentNavItem]
  )

  const newListModal = useDisclosure()
  const onMissionControl = window.location.pathname.includes('/mission-control')

  useTitle(`${props.account_view.name} | ${startCase(entityType)} List | Koala`)

  return (
    <ListContainer paddingX={0} paddingY={0} gap={0} maxH="100%" minH="300px">
      {onMissionControl ? (
        <MCHeader space={props.account_view.space} viewId={props.account_view.id} apps={props.apps} />
      ) : props.account_view.space ? (
        <SpaceHeader
          space={props.account_view.space}
          viewId={props.account_view.id}
          apps={props.apps}
          facets={facets}
        />
      ) : (
        <TopBarContent>
          <Flex width="100%" alignItems="center" justifyContent="space-between" gap={4}>
            <Flex alignItems="center" gap={1.5}>
              <Breadcrumb
                paths={[
                  props.account_view.team
                    ? {
                        path: projectPath(`/settings/teams/${props.account_view.team.id}`),
                        title: props.account_view.team.name,
                        isCurrentPage: false
                      }
                    : {
                        path: projectPath(props.account_view.kind === 'profile' ? '/people' : '/accounts'),
                        title: props.account_view.kind === 'profile' ? 'People' : 'Accounts',
                        isCurrentPage: false
                      }
                ]}
                display={['none', 'flex']}
              />

              {props.account_view.id && (
                <>
                  <Icon as={IconChevronRight} color="gray.400" boxSize={4} display={['none', 'flex']} />

                  <Box>
                    <ViewSelector
                      selectedAccountViewId={props.account_view.id}
                      kind={
                        props.account_view.ownership === 'team' || props.account_view.ownership === 'space'
                          ? undefined
                          : props.account_view.kind
                      }
                      teamId={props.account_view.team_id}
                      spaceId={props.account_view.space_id}
                      ownership={['private', 'team', 'shared']}
                      includePrivate
                      onChange={changeView}
                      onCreateAccountview={newListModal.onOpen}
                    />

                    <NewListModal
                      {...newListModal}
                      teamId={props.account_view.team_id}
                      spaceId={props.account_view.space_id}
                    />
                  </Box>
                </>
              )}
            </Flex>
            <Flex alignItems="center" gap={2}>
              <ViewAsUserSelector facets={facets} includeCrmUsers={true}>
                <Divider orientation="vertical" h={6} borderColor="gray.300" />
              </ViewAsUserSelector>

              {(props.account_view.team?.members ?? []).length > 0 && (
                <AvatarGroup size="xs" fontSize="xs" max={4} spacing={-1.5}>
                  {props.account_view.team?.members?.map((member) => (
                    <Avatar
                      key={member.email}
                      src={member.image}
                      name={member.name || member.email}
                      borderWidth="2px"
                      boxSizing="content-box"
                    />
                  ))}
                </AvatarGroup>
              )}
              {['shared', 'team'].includes(props.account_view.ownership || 'unknown') && (
                <HelpTooltip variant="info" placement="left">
                  This is a shared list. Modifying it will update it for everyone.
                </HelpTooltip>
              )}
            </Flex>
          </Flex>
        </TopBarContent>
      )}

      <form
        action={location.pathname + location.search}
        method="POST"
        onSubmit={(e) => {
          if (!props.permissions.can_edit) {
            e.preventDefault()
          }
        }}
        ref={formRef}
      >
        <input type="hidden" value="PATCH" name="_method" />
        <AuthenticityToken />
        <ViewDetails
          apps={props.apps}
          counts={props.counts}
          account_view={props.account_view}
          columns={columns}
          ai_columns={aiColumns}
          onColumnChange={onColumnChange}
          columnsChanged={columnsChanged}
          permissions={props.permissions}
          hideMenu={['my_accounts', 'trending_accounts'].includes(props.account_view.view_type || 'unknown')}
          facets={facets}
          formRef={formRef}
          defaults={{
            range: 'month',
            sort_by: props.sort_by
          }}
        />
      </form>

      {props.accounts.length === 0 && (
        <EmptyState
          size="md"
          heading={`No ${entityType}s match these filters`}
          description="Adjust your filters or check back later"
          icon={IconCrosshair}
        />
      )}

      {props.accounts.length > 0 && (
        <>
          <Box display="flex" flexDirection="column" flex="1 1 auto" maxH="100%" minH="300px">
            {props.account_view.kind === 'profile' && (
              <ProfileList
                facetParams={facets}
                profiles={profiles}
                selected={checkboxes.value}
                getCheckboxProps={checkboxes.getCheckboxProps}
                onSelectAll={() => {
                  checkboxes.setValue((prev) =>
                    prev.length === props.accounts.length ? [] : props.accounts.map((p) => p.id)
                  )
                }}
                range={props.range ?? 'all'}
                apps={apps}
                columns={columns}
                sortingBy={facets.sortBy}
                openLinksInNewTab={onMissionControl}
                canAddColumns
                onColumnChange={onColumnChange}
                onColumnRemove={onColumnRemove}
                onSortChange={facets.setSortBy}
              />
            )}
            {props.account_view.kind === 'account' && (
              <AccountList
                accounts={accounts}
                facetParams={facets}
                selected={checkboxes.value}
                getCheckboxProps={checkboxes.getCheckboxProps}
                onSelectAll={() => {
                  checkboxes.setValue((prev) =>
                    prev.length === props.accounts.length ? [] : props.accounts.map((p) => p.id)
                  )
                }}
                range={props.range ?? 'all'}
                apps={apps}
                columns={columns}
                loadingColumns={loadingColumns}
                sortingBy={facets.sortBy}
                openLinksInNewTab={onMissionControl}
                canAddColumns
                onColumnChange={onColumnChange}
                onColumnRemove={onColumnRemove}
                onSortChange={facets.setSortBy}
                aiColumns={aiColumns}
                onAIColumnChange={onAIColumnChange}
                onRefresh={() => {
                  const path = window.location.toString()
                  router.visit(path, { fetch: true })
                }}
              />
            )}
            <TableFooter
              word={entityType}
              pageMeta={props.page_meta}
              page={props.page_meta.current_page}
              prevPath={mergeParams(window.location.toString(), {
                page: props.page_meta.prev_page?.toString() ?? '1'
              })}
              nextPath={mergeParams(window.location.toString(), {
                page: props.page_meta.next_page?.toString() ?? '1'
              })}
              px={[3, 4]}
              sticky
            />
          </Box>

          <BulkActionBar
            selectionCount={checkboxes.value.length ?? 0}
            onRemoveSelection={() => checkboxes.setValue([])}
          >
            <BulkAddToCrmModal
              selectedIds={checkboxes.value as string[]}
              apps={props.apps}
              onImport={() => checkboxes.setValue([])}
              recordType={props.account_view.kind}
            />

            {props.account_view.kind == 'profile' && (
              <BulkAddToSequenceMenu
                selectedRecords={profiles.filter((obj) => checkboxes.value.includes(obj.id))}
                apps={props.apps}
                recordType={'Profile'}
                recordLabel={'people'}
                skipAnonymousProfiles={true}
              />
            )}
          </BulkActionBar>
        </>
      )}
    </ListContainer>
  )
}
