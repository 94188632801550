import { LightBgCard } from '@app/components/ui/Card'
import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { AccountEventSession, AccountKqlEventSession } from '../'
import { SourceIcon } from '../../../ui/icons'
import AccountEventFeed from './AccountEventFeed'
import AccountKqlEventFeed from './AccountKqlEventFeed'

type AccountEvents = AccountEventSession | AccountKqlEventSession

interface AccountEventsGroupProps {
  source: string
  events: AccountEvents[]
}

const sources = {
  g2: {
    title: 'G2',
    color: 'g2.50'
  },
  github: {
    title: 'GitHub'
  },
  rb2b: {
    title: 'RB2B'
  },
  snowflake: {
    title: 'Snowflake'
  },
  slack: {
    title: 'Slack'
  },
  linkedin: {
    title: 'LinkedIn'
  },
  linkedin_xt: {
    title: 'LinkedIn'
  },
  discord: {
    title: 'Discord'
  }
}

export function AccountEventsGroup({ source, events }: AccountEventsGroupProps) {
  const sourceData = sources[source] || { title: source }

  return (
    <LightBgCard w="100%" bg="white" p={0} borderColor="gray.50" shadow="base">
      {Object.keys(sources).includes(source) && (
        <Box
          fontSize="xs"
          bg={sourceData.color || 'gray.100'}
          paddingY={2}
          paddingX={5}
          roundedTop="md"
          marginX="-1px"
          marginTop="-1px"
        >
          <Flex justifyContent="space-between" alignItems="center" gap={3}>
            <HStack>
              <SourceIcon source={source} size={20} />
              <Text fontWeight="medium">{sourceData.title} activity</Text>
            </HStack>
          </Flex>
        </Box>
      )}
      <Box py={2}>
        {events.map((event) => (
          <Box
            key={event.id}
            height="36px"
            _hover={{
              bg: 'gray.50'
            }}
            fontSize="xs"
          >
            <Flex w="100%" h="100%" px={[4, 4, 4, 5]}>
              {event.record_type === 'AccountEvent' ? (
                <AccountEventFeed event={event as AccountEventSession} />
              ) : event.record_type === 'AccountKqlEvent' ? (
                <AccountKqlEventFeed event={event as AccountKqlEventSession} />
              ) : null}
            </Flex>
          </Box>
        ))}
      </Box>
    </LightBgCard>
  )
}
