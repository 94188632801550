import { useQuery, useMutation, useQueryClient } from 'react-query'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { concurrentCachedGET, post, put } from '../../lib/api'
import { AIAgent, PersistedAIAgent } from '../../types/AIAgent'

interface AIAgentsResponse {
  agents: AIAgent[]
  my_agents: PersistedAIAgent[]
}

export function useAIAgents(target: 'companies' | 'people' = 'companies') {
  const project = useCurrentProject()
  const basePath = `/settings/agents.json?target=${target}`

  const path = projectPath(basePath)

  return useQuery<AIAgentsResponse>(['ai-agents', { projectId: project?.id, target }], () =>
    concurrentCachedGET<AIAgentsResponse>(path)
  )
}

interface CreateAIAgentParams {
  name: string
  question: string
  target: string
  slug?: string
  model?: string
}

interface CreateAIAgentResponse {
  ai_agent: AIAgent
  errors?: unknown
}

export function useCreateAIAgent() {
  const project = useCurrentProject()
  const queryClient = useQueryClient()

  return useMutation<CreateAIAgentResponse, unknown, CreateAIAgentParams>(
    (params) => post<CreateAIAgentResponse>(projectPath('/settings/agents'), { ai_agent: params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ai-agents', { projectId: project?.id }])
      }
    }
  )
}

interface UpdateAIAgentParams {
  id: string
  name: string
  question: string
  target: string
  model?: string
  slug?: string
}

interface UpdateAIAgentResponse {
  ai_agent: PersistedAIAgent
  errors?: unknown
}

export function useUpdateAIAgent() {
  const project = useCurrentProject()
  const queryClient = useQueryClient()

  return useMutation<UpdateAIAgentResponse, unknown, UpdateAIAgentParams>(
    ({ id, ...params }) => put(projectPath(`/settings/agents/${id}`), { ai_agent: params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ai-agents', { projectId: project?.id }])
      }
    }
  )
}
