import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import { IconMail } from '@tabler/icons-react'
import React from 'react'
import { EngagementEventData } from '..'
import { LightBgCard } from '../../../ui/Card'
import CircleIcon from '../../../ui/CircleIcon'
import { TimeAgo } from '../../../ui/TimeAgo'
import dayjs from '../../../../lib/dayjs'

interface EngagementActivityProps {
  event: EngagementEventData
}

function arrayWrap<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : value ? [value] : []
}

interface ParsedMailAddress {
  name?: string
  email: string
}

// Match either "Name <email@example.com>" or "email@example.com"
function parseEmailAddress(address: string): ParsedMailAddress {
  const match = address.match(/^(?:([^<]*?)\s*<(.+?)>|(.+))$/)

  if (!match) {
    return { email: address }
  }

  const [, name, emailInBrackets, emailOnly] = match
  const email = emailInBrackets || emailOnly

  return {
    name: name?.trim(),
    email: email.trim()
  }
}

export function EmailActivity({ event }: EngagementActivityProps) {
  const { from, to = [], cc = [], subject } = event.properties || {}

  const participants: ParsedMailAddress[] = [from, to, cc].flatMap((value) => arrayWrap(value).map(parseEmailAddress))

  return (
    <LightBgCard
      w="100%"
      fontSize="xs"
      bg="white"
      p={0}
      borderColor="gray.50"
      shadow="base"
      borderLeftColor="purple.500"
      borderLeftWidth="2px"
    >
      <Flex alignItems="center" py={[3, 3, 3, 4]} px={[4, 4, 4, 5]} gap={3} ml="-1px">
        <CircleIcon flex="none" icon={IconMail} color="gray.600" bg="gray.100" padding={2.5} iconSize={5} />

        <Box flex={1} isTruncated>
          <Flex gap={4} alignItems="center" justifyContent="space-between">
            <Text fontSize="sm" fontWeight="medium" isTruncated>
              {subject}
            </Text>
            <Tooltip label={dayjs(event.timestamp).format('ddd, MMM Do, YYYY HH:mm A')} openDelay={300}>
              <Text fontSize="xs" color="gray.500">
                {dayjs(event.timestamp).isSame(dayjs(), 'day') ? (
                  <TimeAgo time={event.timestamp} format="HH:mm A" />
                ) : (
                  <TimeAgo time={event.timestamp} format="MMM D, YYYY" />
                )}
              </Text>
            </Tooltip>
          </Flex>
          <Text fontSize="xs" color="gray.500" isTruncated>
            {participants.map(({ name, email }) => name || email).join(', ')}
          </Text>
        </Box>
      </Flex>
    </LightBgCard>
  )
}

export function EngagementActivity({ event }: EngagementActivityProps) {
  if (event.event_type === 'email') {
    return <EmailActivity event={event} />
  } else {
    return null
  }
}
