import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { Template } from '../../../../types/Template'
import { CrmUser, useCrmUsers } from '../../../data/use-crm-users'
import { useApplyTemplate } from '../../../data/use-templates'
import { User, useUsers } from '../../../data/use-users'
import { UserSelector, isCrmUser } from '../../../ui/UserSelector'

interface ApplyTemplateToUsersModalProps extends UseDisclosureProps {
  template: Template
  onApply?: (templateId: string) => void
}

const emptyArray = []

export function ApplyTemplateToUsersModal({ template, onApply, ...props }: ApplyTemplateToUsersModalProps) {
  const disclosure = useDisclosure(props)
  const [userIds, setUserIds] = useState<string[]>([])

  const users = useUsers()
  const crm = useCrmUsers()

  const allUsers = useMemo(() => {
    const all: Array<User | CrmUser> = [...(users.data?.users || emptyArray)]
    const emails = all.flatMap((u) => (u as User).emails)

    const crmUsers = crm.data?.users || emptyArray
    for (const user of crmUsers) {
      if (!emails.includes(user.email)) {
        all.push(user)
        emails.push(user.email)
      }
    }

    return all
  }, [users.data?.users, crm.data?.users])

  const { isLoading, mutateAsync } = useApplyTemplate()
  const onClose = disclosure.onClose

  const actualUserIds = useMemo(() => {
    return Array.from(new Set(userIds.filter(Boolean)))
  }, [userIds])

  const handleApply = useCallback(async () => {
    if (!actualUserIds.length) return
    if (!template?.id) return

    const ids = actualUserIds.map((id) => {
      const user = allUsers.find((u) => u.id === id)
      return isCrmUser(user) ? user.email : id
    })

    try {
      await mutateAsync({ templateId: template.id, userIds: ids })
      toast.success(`Applied this template to ${pluralize('users', ids.length, true)}!`)
      onClose()
      onApply?.(template.id)
    } catch (error) {
      toast.error('There was an issue applying this template...', { description: (error as Error).message })
    }
  }, [template.id, actualUserIds, allUsers, mutateAsync, onClose, onApply])

  const handleUserChange = useCallback((index: number, userId: string | null) => {
    setUserIds((prev) => {
      const newUserIds = [...prev]
      if (userId) {
        newUserIds[index] = userId
      } else {
        newUserIds.splice(index, 1)
      }
      return newUserIds
    })
  }, [])

  const handleRemoveUser = useCallback((index: number) => {
    setUserIds((prev) => prev.filter((_, i) => i !== index))
  }, [])

  return (
    <Modal {...disclosure} size="lg" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Apply a template</ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            <Text fontSize="sm" color="gray.600">
              You can apply a template to copy lists from the selected template to your teammates' Mission Control. If
              they already have modified their Mission Control, their pre-existing lists will not be changed/removed.
            </Text>
            <FormControl>
              <FormLabel>Who do you want to apply this template for?</FormLabel>
              <Stack spacing={3}>
                {[...userIds, ''].map((userId, idx) => (
                  <Flex width="100%" key={`${userId}:${idx}`} alignItems="center" gap={3}>
                    <Box flex="1 1 auto">
                      <UserSelector
                        users={allUsers}
                        selectedUserId={userId}
                        onChange={(newUserId) => handleUserChange(idx, newUserId)}
                        variant="outline"
                        popperOptions={{
                          matchWidth: true
                        }}
                      />
                    </Box>
                    <IconButton
                      aria-label="Remove user"
                      size="xs"
                      variant="ghost"
                      color="gray.500"
                      _hover={{ color: 'gray.800' }}
                      icon={<IconX size={16} />}
                      isDisabled={idx >= userIds.length}
                      onClick={() => handleRemoveUser(idx)}
                    />
                  </Flex>
                ))}
              </Stack>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} isDisabled={isLoading} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="purple"
            isLoading={isLoading}
            isDisabled={!actualUserIds.length}
            onClick={handleApply}
          >
            Apply template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
