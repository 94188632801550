import { BubbleTag } from '@app/components/ui/BubbleTag'
import { LightBgCard } from '@app/components/ui/Card'
import { JSONTree } from '@app/components/ui/json-tree'
import { StackedField } from '@app/components/ui/StackedField'
import { TextEllipsis } from '@app/components/ui/text-ellipsis'
import { Box, Circle, Flex, HStack, Icon, Text } from '@chakra-ui/react'
import { IconArrowRight, IconTrendingDown, IconTrendingUp } from '@tabler/icons-react'
import React from 'react'
import { useCurrentUser } from '../../../ui/UserContext'
import { trendColor, TrendIcon } from '../../accounts/components/Intent/IntentSummary'
import { IntentTrend } from '../../icps/types'
import HoverContext from './HoverContext'
import Time from './Time'

interface IntentTrendProperties {
  direction: 'up' | 'down'
  from: {
    trend_14d: IntentTrend
    score: number
  }
  to: {
    trend_14d: IntentTrend
    score: number
  }
}

interface SystemEventType {
  id: string
  account_id: string
  project_id: string
  source: string
  event_type: string
  event: string
  properties: IntentTrendProperties
  timestamp: string
}

function IntentTrendChange({ event }: { event: SystemEventType }): JSX.Element {
  const currentUser = useCurrentUser()
  const { properties } = event
  const { from, to } = properties
  const trendKey = to.trend_14d.toLowerCase() as keyof typeof trendColor
  const directionIcon = properties.direction === 'up' ? IconTrendingUp : IconTrendingDown

  return (
    <HStack spacing={4} fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={event.timestamp} />
      </Text>

      <HStack spacing={1} flex="1">
        <BubbleTag size="sm" variant="subtle" colorScheme={trendColor[trendKey]}>
          <TrendIcon trend={to.trend_14d} boxSize={3.5} />
          {event.event}
        </BubbleTag>
      </HStack>

      <HoverContext
        title={
          <HStack>
            <Circle color="gray.500" size={5} alignSelf="center" flex="none">
              <Icon as={directionIcon} boxSize={4} />
            </Circle>
            <TextEllipsis maxW="300px" tooltip flex="1">
              {event.event}
            </TextEllipsis>
          </HStack>
        }
      >
        <StackedField label="Change" fontWeight="normal">
          <Flex alignItems="center" gap={1}>
            {from.trend_14d}
            <Icon as={IconArrowRight} boxSize={4} />
            {to.trend_14d}
          </Flex>
        </StackedField>
        {event?.properties && currentUser?.isInternalUser && (
          <StackedField label="Properties (internal only)" fontWeight="normal" copyable={false}>
            <Box ml="-1" fontSize="xs">
              <JSONTree data={event.properties} />
            </Box>
          </StackedField>
        )}
        <StackedField label="Timestamp" fontWeight="normal">
          <Time time={event.timestamp} />
        </StackedField>
      </HoverContext>
    </HStack>
  )
}

interface SystemEventsProps {
  events: SystemEventType[]
}

export default function SystemEvents({ events }: SystemEventsProps): JSX.Element | null {
  const intentTrendEvents = events.filter((event) => event.event_type === 'intent_trend_change')

  if (intentTrendEvents.length === 0) {
    return null
  }

  const latestEvent = [...intentTrendEvents].sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  )[0]

  const DirectionIcon = latestEvent.properties.direction === 'up' ? IconTrendingUp : IconTrendingDown

  return (
    <LightBgCard w="100%" bg="white" p={0} borderColor="gray.50" shadow="base">
      <Box fontSize="xs" bg="gray.100" paddingY={2} paddingX={5} roundedTop="md" marginX="-1px" marginTop="-1px">
        <HStack spacing={2}>
          <DirectionIcon size={20} color="var(--chakra-colors-gray-600)" />
          <Text fontWeight="medium">Intent Trend</Text>
        </HStack>
      </Box>
      <Box py={2}>
        {intentTrendEvents.map((event) => (
          <Box
            key={event.id}
            height="36px"
            _hover={{
              bg: 'gray.50'
            }}
            fontSize="xs"
          >
            <Flex w="100%" h="100%" px={[4, 4, 4, 5]}>
              <IntentTrendChange event={event} />
            </Flex>
          </Box>
        ))}
      </Box>
    </LightBgCard>
  )
}
