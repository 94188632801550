import React from 'react'
import { HStack, Text, Box, Circle, Icon } from '@chakra-ui/react'
import HoverContext from './HoverContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { StackedField } from '../../../ui/StackedField'
import { JSONTree } from '../../../ui/json-tree'
import Time from './Time'
import { AccountEventSession } from '../'
import { IconClick, IconWorld } from '@tabler/icons-react'

const colors = {
  g2: 'g2.500'
}

export const icons = {
  g2: IconWorld
}

export default function AccountEventFeed({ event }: { event: AccountEventSession }) {
  return (
    <HStack spacing="4" fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={event.timestamp} />
      </Text>

      <HStack spacing={1} flex="1" w="120px">
        <Circle color={colors[event.source] || 'gray.500'} size={5} alignSelf="center" flex="none">
          <Icon as={icons[event.source] || IconClick} boxSize={4} marginX={0.25} />
        </Circle>
        <HydratedEventName event={event} />
      </HStack>

      <HoverContext
        title={
          <HStack>
            <Circle color={colors[event.source] || 'gray.500'} size={5} alignSelf="center" flex="none">
              <Icon as={icons[event.source] || IconClick} boxSize={4} />
            </Circle>

            <TextEllipsis maxW="300px" tooltip flex="1">
              {event.event}
            </TextEllipsis>
          </HStack>
        }
      >
        <StackedField label="Type" fontWeight={'normal'}>
          {event.source.charAt(0).toUpperCase() + event.source.slice(1)} Event
        </StackedField>

        {event?.properties && (
          <StackedField label={'Properties'} fontWeight={'normal'}>
            <Box ml="-1">
              <JSONTree data={event.properties} />
            </Box>
          </StackedField>
        )}

        <StackedField label="Timestamp" fontWeight={'normal'}>
          <Time time={event.timestamp} />
        </StackedField>
      </HoverContext>
    </HStack>
  )
}

function HydratedEventName({ event }: { event: AccountEventSession }) {
  if (!event.metadata?.event_excerpt) {
    return <Text>{event.event}</Text>
  }

  const event_excerpt = event.metadata?.event_excerpt

  return (
    <>
      {event_excerpt.length > 0 ? (
        <Text>
          {event.event}: {event_excerpt}
        </Text>
      ) : (
        <Text>{event.event}</Text>
      )}
    </>
  )
}
