import { Button, Flex, Heading, Link, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
export default function MarkdownText({ children }: { children: string }) {
  return (
    <Flex flexDirection="column" gap={4} fontSize="sm">
      <ReactMarkdown
        components={{
          h1: ({ node: _node, ...props }) => <Heading size="sm" {...props} />,
          h2: ({ node: _node, ...props }) => <Heading size="xs" {...props} />,
          h3: ({ node: _node, ...props }) => <Heading size="xs" {...props} />,
          li: ({ node: _node, ...props }) => <ListItem my="2" {...props} />,
          ul: ({ node: _node, ...props }) => <UnorderedList marginLeft={0} paddingLeft="1.5rem" {...props} />,
          ol: ({ node: _node, ...props }) => <OrderedList marginLeft={0} paddingLeft="1.5rem" {...props} />,
          a: ({ node: _node, ...props }: any) => (
            <Button
              as={Link}
              rightIcon={<IconExternalLink size={12} />}
              variant={'link'}
              isExternal
              size={'sm'}
              {...props}
            />
          )
        }}
      >
        {children}
      </ReactMarkdown>
    </Flex>
  )
}
