import { Box, Button, Divider, Flex, Heading, Link, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/react'
import { IconArrowLeft, IconCheck } from '@tabler/icons-react'
import React, { useState } from 'react'
import type Stripe from 'stripe'
import { LightBgCard } from '../../ui/Card'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import { PricingForm } from '../../ui/PricingForm'
import { projectPath } from '../../ui/ProjectsContext'
import SettingsHeader from '../../ui/SettingsHeader'
import { BillingHelp } from './components/billing-help'
import { billingManagePath } from './lib/path-helpers'
import { PlanData, plansV1, plansV2, plansV3, plansV4, PricingTable } from './PricingTable'
import { KoalaSubscription } from './show'

export const plansByVersion = (version?: 'v1' | 'v2' | 'v3' | 'v4' | null) => {
  switch (version) {
    case 'v4':
      return plansV4
    case 'v3':
      return plansV3
    case 'v2':
      return plansV2
    default:
      return plansV1
  }
}

export type Plan = 'team' | 'business' | 'free' | 'pro' | 'starter' | 'starter_plus' | 'growth'

interface Props {
  customer?: Stripe.Customer
  live_subscription?: Stripe.Subscription & { quantity: number; plan: Stripe.Plan }
  koala_subscription: KoalaSubscription
  plan?: Plan
  version: 'v2' | 'v3' | 'v4'
}

function BusinessUpgrade() {
  const [showPricingForm, setShowPricingForm] = useState(false)
  return (
    <>
      {showPricingForm && <PricingForm onClose={() => setShowPricingForm(false)} />}
      <Flex p="8" justifyContent={'center'} bg="gray.50" w="100%">
        <Stack spacing="4" as={LightBgCard} bg="white" fontSize={'sm'} p="8">
          <Heading size="sm">Need to change your plan?</Heading>
          <Divider />
          <Stack spacing="0" py="2">
            <Text>Need to make changes to your Business Subscription?</Text>
            <Text>Please contact our team so we can hear more about your use cases and upgrade your plan.</Text>
          </Stack>
          <Flex justifyContent={'flex-start'} w="100%">
            <Button size={'sm'} colorScheme={'purple'} onClick={() => setShowPricingForm(true)}>
              Chat with us
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </>
  )
}

export default function Show(props: Props) {
  const isTrialing = props.koala_subscription.status === 'trialing'
  const version = props.koala_subscription.version
  const plans = plansByVersion(version)
  const currentPlan = props.plan ? plans[props.plan] : null

  return (
    <PageLayout size="full">
      <Box>
        <Link
          flex="none"
          display="inline-flex"
          alignItems="center"
          gap={1}
          color="gray.500"
          fontSize="sm"
          href={projectPath('/settings/billing')}
        >
          <IconArrowLeft size={16} /> Back to your plan
        </Link>
      </Box>

      <SettingsHeader>
        <Heading size="lg" as="h1">
          Compare Plans
        </Heading>
        <PageDescription>
          Compare plan features and choose the right plan for your team.{' '}
          {currentPlan && `You're currently on the ${currentPlan.title} plan.`}
        </PageDescription>
      </SettingsHeader>

      {props.plan === 'business' ? (
        <Flex w="100%" pb="4">
          <BusinessUpgrade />
        </Flex>
      ) : (
        <>
          <BillingHelp />
          <Divider />
        </>
      )}

      <CurrentPlan planTier={props.plan} planVersion={version} koalaSubscription={props.koala_subscription} />

      <PricingTable
        koalaSubscription={props.koala_subscription}
        isTrialing={isTrialing}
        previouslyTrialed={props.koala_subscription.previous_trial}
        currentPlan={props.plan}
      />
    </PageLayout>
  )
}

interface CurrentPlanProps {
  planTier?: Plan
  planVersion: 'v1' | 'v2' | 'v3' | 'v4' | null
  koalaSubscription: KoalaSubscription
}

function CurrentPlan(props: CurrentPlanProps) {
  const plans = plansByVersion(props.planVersion)
  const currentPlan = (props.planTier ? plans[props.planTier] : undefined) as PlanData | undefined
  const canSelfManage = !props.koalaSubscription.product_sku.includes('invoiced') && props.planTier !== 'business'

  return (
    <Stack spacing={2}>
      <Heading size="sm">Current Plan</Heading>
      <LightBgCard padding={0} overflow="hidden">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap={4}
          padding={4}
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <Stack spacing={1}>
            <Heading size="sm">{currentPlan?.title || 'Untitled'} Plan</Heading>
            {currentPlan?.description && (
              <Text fontSize="xs" color="gray.500">
                {currentPlan.description}
              </Text>
            )}
          </Stack>

          {canSelfManage && (
            <Flex flex="none" gap={2}>
              <Button as={Link} href={billingManagePath()} size="sm" variant="outline" isExternal>
                Manage plan
              </Button>
            </Flex>
          )}
        </Flex>

        {(currentPlan?.features ?? []).length > 0 && (
          <Stack spacing={3} padding={4}>
            <List fontSize="sm" spacing="2">
              {currentPlan?.features?.map((feature) => (
                <ListItem key={feature} display="flex">
                  <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                  {feature}
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
      </LightBgCard>
    </Stack>
  )
}
