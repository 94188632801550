import { Button, Grid, HStack, Icon, Spinner, Square, Stack, Text } from '@chakra-ui/react'
import { IconCheck, IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import React from 'react'
import { useIntentSignals } from '../../../data/use-intent-signals'
import CircleIcon from '../../../ui/CircleIcon'
import DefinitionForm from '../../kql_definitions/components/DefinitionForm'
import { SignalType } from '../../kql_definitions/components/SignalType'
import { signalTypes } from '../../kql_definitions/components/triggers'
import { KqlDefinition } from '../../kql_definitions/types'

interface Props {
  skip: () => void
}

const signals = signalTypes.filter((s) => s.suggestions?.length)

export function SignalsForm(props: Props) {
  const { data, refetch } = useIntentSignals()
  const [def, setDef] = React.useState<Partial<KqlDefinition> | null>(null)

  return (
    <>
      <Stack w="100%" spacing="4">
        {def && (
          <Stack py="8">
            <DefinitionForm
              skipSwitch
              inline
              isRemovable={false}
              title={
                <HStack>
                  <Text>{def.name}</Text>
                </HStack>
              }
              onSubmitted={() => {
                setDef(null)
                refetch()
              }}
              definition={{
                id: def.id,
                enabled: true,
                name: def.name ?? '',
                trigger: {
                  conditions: def.trigger?.conditions ?? []
                }
              }}
              cta={({ hasConditions, isSubmitting }) => (
                <HStack w="100%" justifyContent={'space-between'}>
                  <Button
                    leftIcon={<IconChevronLeft size={14} />}
                    size="sm"
                    variant={'ghost'}
                    disabled={isSubmitting}
                    onClick={() => {
                      setDef(null)
                    }}
                  >
                    Go Back
                  </Button>
                  <Button
                    isLoading={isSubmitting}
                    disabled={!hasConditions}
                    type="submit"
                    size="sm"
                    colorScheme="purple"
                  >
                    Save Intent Signal
                  </Button>
                </HStack>
              )}
            />
          </Stack>
        )}
        {signals.map((s) => (
          <Stack key={s.kind + JSON.stringify(data)}>
            <HStack paddingX={1}>
              <Square size="8px" bg={`${s.colorScheme}.500`} rounded="sm" />
              <Text fontSize="xs" color="gray.500" textTransform="uppercase" fontWeight="medium">
                High Intent {s.label}
              </Text>
            </HStack>
            <Grid gap={2.5} gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))">
              {(s.suggestions ?? []).map((suggestion) => {
                if (def && def.name === suggestion.name) {
                  return null
                }

                const exists = data?.definitions.find((d) => {
                  return (
                    d.name === suggestion.name ||
                    JSON.stringify(d.trigger.conditions) === JSON.stringify(suggestion.conditions)
                  )
                })
                return (
                  <HStack
                    w="100%"
                    justifyContent={'space-between'}
                    key={suggestion.name}
                    border="1px solid"
                    borderColor="gray.200"
                    cursor={'pointer'}
                    rounded="lg"
                    bg="white"
                    padding={4}
                    transition="border-color 150ms ease-in-out"
                    _hover={{ borderColor: 'gray.300', shadow: 'sm' }}
                    tabIndex={0}
                    onClick={() => {
                      if (exists) {
                        setDef(exists)
                      } else {
                        setDef({
                          name: suggestion.name,
                          trigger: { conditions: suggestion.conditions }
                        })
                      }
                    }}
                  >
                    <SignalType
                      label={suggestion.name}
                      description={suggestion.description}
                      icon={s.icon}
                      brandLogo={s.brandLogo}
                      colorScheme={s.colorScheme}
                    />
                    {!data && <Spinner />}
                    {data && !exists && <Icon as={IconChevronRight} color="gray.500" />}
                    {exists && <CircleIcon icon={IconCheck} color="white" bg="purple.500" />}
                  </HStack>
                )
              })}
            </Grid>
          </Stack>
        ))}

        {!def && (
          <>
            {data?.definitions && data?.definitions.length > 0 && (
              <Button colorScheme={'purple'} onClick={props.skip} w="100%">
                Continue
              </Button>
            )}
            {data?.definitions && data?.definitions.length === 0 && (
              <Button variant={'ghost'} onClick={props.skip} w="100%">
                Setup Later
              </Button>
            )}
          </>
        )}
      </Stack>
    </>
  )
}
