import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { useCurrentProject } from '../ui/ProjectsContext'
import { projectPath } from '../ui/ProjectsContext'
import { useCurrentUser } from '../ui/UserContext'

interface InboxResponse {
  inbox_items: any[]
}

export function useInbox() {
  const project = useCurrentProject()
  const user = useCurrentUser()
  const path = projectPath('/inbox')

  return useQuery<InboxResponse>(
    ['inbox', { projectId: project?.id, userId: user?.id }],
    () => concurrentGET<InboxResponse>(path),
    {
      enabled: Boolean(project?.id),
      // Keep the previous data visible while fetching new data
      keepPreviousData: true
    }
  )
}
