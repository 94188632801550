import { Badge, Flex, Icon, Tag, Text } from '@chakra-ui/react'
import { IconFlame, IconSnowflake } from '@tabler/icons-react'
import React from 'react'
import { HoverCard } from '../../../ui/HoverCard'

export function WarmLeadBadge() {
  return (
    <Badge
      display="inline-flex"
      alignItems="center"
      gap={0.5}
      lineHeight="18px"
      variant="subtleBorder"
      bg="orange.50"
      borderColor="orange.200"
      px={1.5}
    >
      <Icon as={IconFlame} boxSize={3.5} color="orange.500" ml={-1} />
      <Text>Warm</Text>
    </Badge>
  )
}

export function ProspectBadge(props: { personas?: { id: string; name: string }[] }) {
  return (
    <HoverCard
      trigger="hover"
      matchWidth={false}
      hoverContent={
        <Flex flexDirection="column" gap={2} alignItems="flex-start" py={1} maxWidth="250px">
          <Text fontSize="13px" color="gray.600">
            This employee is being suggested based on a buyer persona match:
          </Text>

          {props.personas &&
            props.personas.slice(0, 2).map((persona) => (
              <Tag size="sm" fontSize={'xs'} rounded="full" colorScheme="purple" key={persona.id}>
                <Text fontSize="xs">{persona.name}</Text>
              </Tag>
            ))}
        </Flex>
      }
    >
      <Badge
        variant="subtleBorder"
        colorScheme="gray"
        borderColor="gray.200"
        px={1.5}
        display="inline-flex"
        alignItems="center"
        gap={0.5}
        lineHeight="18px"
      >
        <Icon as={IconSnowflake} boxSize={3.5} color="blue.300" ml={-1} />
        Prospect
      </Badge>
    </HoverCard>
  )
}
