import { Box, useTheme } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import dayjs from '../../../../../lib/dayjs'
import { fillMissingDays } from '../../../../../lib/fill-missing-days'
import { ChartTooltip } from '../../../analytics/components/ChartTooltip'
import { normalize } from './IntentSummary'

type FormatterFn = (value: any, name: string, _props: unknown) => string | [string, string]

interface IntentBarChartProps {
  data: any[]
  dataKey?: string
  label?: string
  max?: number
  height?: number
  width?: number | string
  granularity?: 'day' | 'week'
  xAxis?: boolean
  yAxis?: boolean
  tooltip?: boolean
  formatter?: FormatterFn | { [key: string]: FormatterFn }
  startDate?: string
}

const ticks = [0, 20, 50, 80, 100]
const formatDay = (timestamp) => dayjs.utc(timestamp).format('ddd, MMM D, YYYY')

export function IntentBarChart(props: IntentBarChartProps) {
  const { formatter: formatterFn } = props
  const theme = useTheme()

  const endDate = useMemo(() => {
    const dates = props.data.map((v) => dayjs.utc(v.date || v.day))
    let max = dayjs.max(dates)

    // use "today" if the last day is too far in the past
    if (!max || max.isBefore(dayjs.utc().subtract(2, 'days'))) {
      max = dayjs.utc()
    }

    return max
  }, [props.data])

  const data = useMemo(() => {
    const transformed = props.data.map((d) => ({
      ...d,
      day: d.date,
      [props.dataKey || 'value']: normalize(d[props.dataKey || 'value'], props.max)
    }))

    if (props.granularity === 'week') {
      return transformed
    } else {
      return fillMissingDays(
        transformed,
        props.startDate || dayjs.utc().subtract(4, 'weeks'),
        endDate,
        props.dataKey || 'value'
      )
    }
  }, [props.data, props.granularity, props.dataKey, props.max, props.startDate, endDate])

  const defaultFormatter = React.useCallback(
    (value, name, _props) => {
      name = name.split('.')[0]
      if (typeof formatterFn === 'function') {
        return formatterFn(value, name, _props)
      } else if (formatterFn && typeof formatterFn[name] === 'function') {
        return formatterFn[name](value, name, _props)
      } else {
        return [
          value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
        ] as any as [string, string]
      }
    },
    [formatterFn]
  )

  const marginProps = useMemo(() => ({ top: 0, right: 0, left: 0, bottom: props.xAxis ? 5 : 0 }), [props.xAxis])
  const tooltipFormatter = formatDay

  return (
    <Box width={props.width} minWidth="150px" bg={props.xAxis ? undefined : 'background.light'} rounded="lg">
      <ResponsiveContainer width="100%" height={props.height || (props.xAxis ? 80 : 40)}>
        <BarChart margin={marginProps} barCategoryGap="14%" data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="25%" stopColor={theme.colors.purple['600']} />
              <stop offset="100%" stopColor={theme.colors.purple['400']} />
            </linearGradient>
          </defs>

          <XAxis
            hide={!props.xAxis}
            dataKey="day"
            tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
            tickFormatter={(v) => dayjs.utc(v).format('MMM D')}
            tickMargin={10}
            stroke={theme.colors.gray['300']}
            height={props.xAxis ? undefined : 2}
            axisLine={{ stroke: theme.colors.gray['300'] }}
            interval="preserveStartEnd"
            padding={{ right: 4 }}
          />

          {props.tooltip && (
            <Tooltip
              contentStyle={{
                color: theme.colors.gray['700'],
                fontSize: 14,
                border: 'none',
                borderRadius: '4px',
                boxShadow: `0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 12%)`
              }}
              allowEscapeViewBox={{ y: true }}
              wrapperStyle={{ outline: 'none', zIndex: 1000 }}
              labelFormatter={tooltipFormatter}
              formatter={defaultFormatter}
              content={<ChartTooltip />}
            />
          )}

          {props.xAxis && <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={theme.colors.gray['200']} />}

          <YAxis
            hide={!props.yAxis}
            width={42}
            domain={[0, 100]}
            axisLine={false}
            ticks={ticks}
            tickLine={false}
            tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
            interval="preserveStartEnd"
          />

          <Bar
            dataKey={props.dataKey || 'value'}
            fill="url(#colorUv)"
            name="Score"
            isAnimationActive={false}
            radius={[3, 3, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
