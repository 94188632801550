import React from 'react'
import { HStack, Text, Box, Circle, Icon } from '@chakra-ui/react'
import HoverContext from './HoverContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { StackedField } from '../../../ui/StackedField'
import { JSONTree } from '../../../ui/json-tree'
import Time from './Time'
import { IconBolt } from '@tabler/icons-react'
import { AccountKqlEventSession } from '../'

export default function AccountKqlEventFeed({ event }: { event: AccountKqlEventSession }) {
  return (
    <HStack spacing="4" fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={event.timestamp} />
      </Text>

      <HStack spacing={1} flex="1" overflow="hidden">
        <Circle alignSelf="center" size={5} flex="none">
          <Icon as={IconBolt} fill="currentColor" strokeWidth="1.5" boxSize="18px" color="purple.500" />
        </Circle>
        <TextEllipsis maxW="100%" tooltip flex="1" fontWeight="semibold" color="purple.500">
          <HydratedEventName event={event} />
        </TextEllipsis>
      </HStack>

      <HoverContext
        title={
          <HStack>
            <Circle alignSelf="center" size={5} flex="none">
              <Icon as={IconBolt} fill="currentColor" strokeWidth="1.5" boxSize="18px" color="purple.500" />
            </Circle>
            <TextEllipsis maxW="500px" tooltip flex="1">
              {event.kql_definition_name}
            </TextEllipsis>
          </HStack>
        }
      >
        <StackedField label="Type" fontWeight={'normal'}>
          Account event
        </StackedField>

        {event?.context?.matches && (
          <StackedField label={'Signal matches'} fontWeight={'normal'}>
            <Box ml="-1">
              <JSONTree data={event.context?.matches} />
            </Box>
          </StackedField>
        )}

        <StackedField label="Triggered at" fontWeight={'normal'}>
          <Time time={event.timestamp} />
        </StackedField>
      </HoverContext>
    </HStack>
  )
}

function HydratedEventName({ event }: { event: AccountKqlEventSession }) {
  const matches_excerpt = (event.context?.matches ?? [])
    .map((e) => {
      return e.metadata?.event_excerpt
    })
    .filter(Boolean)

  if (matches_excerpt.length === 0) {
    return <Text>{event.kql_definition_name}</Text>
  }

  return (
    <>
      {matches_excerpt.length > 0 ? (
        <Text>
          {event.kql_definition_name}: {matches_excerpt.join(' | ')}
        </Text>
      ) : (
        <Text>{event.kql_definition_name}</Text>
      )}
    </>
  )
}
