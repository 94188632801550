import {
  Button,
  Flex,
  Heading,
  Icon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Stack,
  Text,
  UnorderedList,
  Wrap
} from '@chakra-ui/react'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { IconBoltFilled, IconSparkles } from '@tabler/icons-react'
import React from 'react'

interface UseAISummaryProps {
  path: string
}

interface UseAISummaryResponse {
  messages: string[]
  isLoading: boolean
  eventSource: () => void
}

export function useAISummary({ path }: UseAISummaryProps): UseAISummaryResponse {
  const [messages, setMessages] = React.useState<string[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const eventSource = () => {
    fetchEventSource(path, {
      onopen: async (res) => {
        setMessages([])
        if (res.ok && res.status === 200) {
          console.log('Connection made ', res)
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.log('Client side error ', res)
        }
      },
      onmessage: (e) => {
        setMessages((prev) => [...prev, e.data])
      },
      onerror: () => {
        console.log('Error occurred')
        setIsLoading(false)
      },
      onclose: () => {
        console.log('Connection closed')
        setIsLoading(false)
      }
    })
  }

  return { messages, isLoading, eventSource }
}

interface SummaryContentProps {
  messages: string[]
  isOpen: boolean
}

function isJSONString(str: string) {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export function AISummaryContent({ messages, isOpen }: SummaryContentProps) {
  const isJson = isJSONString(messages.join(''))
  if (isJson) {
    const data = JSON.parse(messages.join(''))
    return (
      <Stack spacing="4" py={3} px={8} textColor="blackAlpha.700" borderRadius="4px" borderColor="black">
        <Stack>
          <Heading size="xs">Summary: {data.tldr}</Heading>
          <Text fontSize={'sm'} color="gray.500">
            {data.summary}
          </Text>
        </Stack>

        <Stack>
          <Heading size="xs">Intent Topics</Heading>
          <Wrap>
            {data.topics.map((topic: { topic: string; bullet_points: string[] }) => (
              <Popover key={topic.topic} trigger="hover" isLazy lazyBehavior="unmount">
                <PopoverTrigger>
                  <Button
                    key={topic.topic}
                    colorScheme="purple"
                    variant="ghost"
                    bg="purple.50"
                    color="purple.500"
                    p="2"
                    size="xs"
                    rounded="full"
                    style={{
                      opacity: 1
                    }}
                    rightIcon={<IconBoltFilled size={12} />}
                  >
                    {topic.topic}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody fontSize="sm" fontWeight="normal" padding={4}>
                    <UnorderedList>
                      {topic.bullet_points.map((point) => (
                        <ListItem key={point}>{point}</ListItem>
                      ))}
                    </UnorderedList>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            ))}
          </Wrap>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack className={isOpen ? 'content-open' : 'content-close'} hidden={!isOpen} minH="250px">
      <Stack paddingY={3} paddingX={8} textColor="blackAlpha.700" borderRadius="4px" borderColor="black">
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    </Stack>
  )
}

interface IaSummaryButtonProps {
  leftText?: string
  summarized?: boolean
  onValueChange: (checked: boolean) => void
}

export default function AISummaryButton({ leftText, onValueChange, summarized }: IaSummaryButtonProps) {
  return (
    <Flex paddingTop={1} paddingBottom={3} paddingRight={4} justifyContent="flex-end">
      <Button
        size="xs"
        variant="ghost"
        color="gray.400"
        _hover={{ color: 'purple.500' }}
        leftIcon={summarized ? undefined : <Icon as={IconSparkles} size={16} />}
        iconSpacing={1}
        onClick={() => {
          onValueChange(!summarized)
        }}
      >
        {summarized ? 'Hide summary' : leftText || 'Summarize'}
      </Button>
    </Flex>
  )
}
