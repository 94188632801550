import { Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { PlayTargetConfig } from '../../../../types/Play'
import { GrayCard } from '../../../ui/Card'
import { SelectedFile } from '../../imports/components/SelectedFile'
import { Uploader } from '../../imports/components/Uploader'

export function CSVColdSource({ targetConfig }: { targetConfig: PlayTargetConfig | undefined }) {
  const [uniqIds, setUniqIds] = React.useState<string[]>(targetConfig?.config?.uniqIds ?? [])
  const [fileName, setFileName] = React.useState<string>(targetConfig?.config?.file_name ?? '')

  const [existingFile, setExistingFile] = React.useState<boolean>(targetConfig?.config?.file_name ? true : false)

  return (
    <Stack spacing={4}>
      {existingFile && (
        <>
          <GrayCard w="100%" justifyContent="center">
            <SelectedFile
              rows={uniqIds.map((id) => ({ id }))}
              file={
                {
                  name: fileName,
                  size: new Blob([uniqIds.join('\n')]).size,
                  type: 'text/csv',
                  webkitRelativePath: fileName,
                  lastModified: new Date().getTime()
                } as File
              }
              onReset={() => {
                setExistingFile(false)
              }}
            />
          </GrayCard>
          <Button size="sm" colorScheme={'blue'} onClick={() => setExistingFile(false)}>
            Replace file
          </Button>
        </>
      )}

      {!existingFile && (
        <Uploader
          onChange={({ uniqIds, files }) => {
            setUniqIds(uniqIds)
            if (files.length > 0) {
              setFileName(files[0].name)
              setExistingFile(true)
            }
          }}
          showPreview={false}
          import={{ name: 'CSV' }}
        />
      )}

      {uniqIds.length > 0 && fileName && (
        <>
          <input
            type="hidden"
            name="play[target_config][config]"
            value={JSON.stringify({ uniqIds, file_name: fileName })}
          />
        </>
      )}
    </Stack>
  )
}
