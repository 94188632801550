import { Box, Button, Flex, HStack, Icon, Link, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconBrandSnowflake, IconChevronDown, IconChevronUp, IconId } from '@tabler/icons-react'
import { isDate } from 'date-fns'
import { truncate } from 'lodash'
import React, { useMemo, useState } from 'react'
import createPersistedState from 'use-persisted-state'
import { Account } from '../../../../../types/Account'
import { ProfileRecord } from '../../../../../types/Profile'
import { DetailsCard } from '../../../../ui/Card'
import { CardHeading } from '../../../../ui/CardHeading'
import { JSONTree } from '../../../../ui/json-tree'
import SelectInput from '../../../../ui/SelectInput'
import { StackedField } from '../../../../ui/StackedField'
import { TextEllipsis } from '../../../../ui/text-ellipsis'
import { JSONValue } from '../../../kql_definitions/types'
import { SearchBar } from '../../facets/search-bar'
import { useUIState } from './useUIState'

export function isValidDate(dateString: string) {
  return isDate(dateString)
}

export function isURL(value: any) {
  return typeof value === 'string' && value.startsWith('http')
}

export function traitDataType(value: any) {
  if (typeof value === 'string' && isValidDate(value)) {
    return 'date'
  }

  if (value === 'undefined') {
    return 'undefined'
  }

  if (value === null || value === undefined) {
    return 'null'
  }

  return typeof value
}

interface TraitsCardProps {
  object: {
    id: string
    traits?: Account['traits'] | ProfileRecord['traits']
  }
  recordType: 'account' | 'profile'
  initiallyCollapsed?: boolean
}

interface TraitGroup {
  id: string
  name: string | undefined
  traits: {
    [key: string]: {
      value: JSONValue
      source: string
    }
  }
}

function usePersistedGroup(recordId: string, initial: string | null) {
  return createPersistedState<string | null>(`koala:group:${recordId}`)(initial)
}

export function TraitsCard(props: TraitsCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.traits ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { traits: true } })
    },
    onClose: () => {
      setUI({ open: { traits: false } })
    }
  })

  const groups = useMemo(() => {
    // this is account traits
    if (Array.isArray(props.object.traits)) {
      return props.object.traits.map((t) => ({ id: t.group_id, name: t.name, traits: t.traits ?? {} }))
    }

    // this is profile traits
    return [{ id: props.object.id, name: 'profile_traits_group', traits: props.object.traits ?? {} }]
  }, [props.object.traits, props.object.id])

  const total = useMemo(
    () =>
      groups.reduce(
        (acc: number, group: { traits: Record<string, { value: JSONValue; source: string }> }) =>
          acc + Object.keys(group.traits).length,
        0
      ) as number,
    [groups]
  )

  // find the group id that matches the record itself, else the first group
  const initialGroupId = groups.find((g) => g.id === props.object.id)?.id || groups[0]?.id || null

  const [selectedGroupId, setSelectedGroupId] = usePersistedGroup(props.object.id, initialGroupId)
  const selectedGroup = useMemo(
    () =>
      groups.find((g) => {
        if (g.id === selectedGroupId) return true
        if (g.name === selectedGroupId) return true
        if (!g.id && !selectedGroupId) return true
        return false
      }),
    [groups, selectedGroupId]
  )

  if (total === 0) {
    return null
  }

  return (
    <DetailsCard px={0}>
      <CardHeading px={5} icon={IconId} count={total} disclosure={cardDisclosure}>
        Traits
      </CardHeading>
      {cardDisclosure.isOpen && (
        <Stack spacing={3}>
          {groups.length > 1 && (
            <Box px={5}>
              <SelectInput
                size="sm"
                variant="outline"
                placeholder="Select a grouping"
                popperOptions={{
                  matchWidth: true
                }}
                items={groups}
                selectedItem={selectedGroup || null}
                itemToString={(i) => {
                  if (i.name) {
                    return `${i.name} (${i.id})`
                  }
                  return i.id
                }}
                onSelectedItemChange={({ selectedItem }) => {
                  setSelectedGroupId(selectedItem?.id)
                }}
              />
            </Box>
          )}
          {selectedGroup && <TraitGroup {...selectedGroup} traits={selectedGroup.traits} />}
        </Stack>
      )}
    </DetailsCard>
  )
}

interface TraitGroupProps {
  id: string
  name?: string
  traits: {
    [key: string]: {
      value: JSONValue
      source: string
    }
  }
  showName?: boolean
}

export function TraitGroup({ id, name, traits, showName }: TraitGroupProps) {
  const showAll = useDisclosure({ defaultIsOpen: Object.keys(traits).length < 4 })
  const entries = Object.entries(traits).sort((a, b) => a[0].localeCompare(b[0]))

  const [filter, setFilter] = useState<string>('')
  const filtered = useMemo(() => {
    return entries.filter(
      ([key, val]) =>
        key.toLowerCase().includes(filter.toLowerCase()) ||
        JSON.stringify(val).toLowerCase().includes(filter.toLowerCase())
    )
  }, [entries, filter])

  const firstSet = filtered.slice(0, 4)
  const displayed = showAll.isOpen ? filtered : firstSet

  return (
    <Box px={5}>
      {showName && (
        <Flex
          cursor="pointer"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          py={0.5}
          onClick={showAll.onToggle}
        >
          <Text fontSize="xs" textTransform="uppercase" color="purple.500" fontWeight="medium">
            {name || id}
          </Text>
          <Button
            variant="link"
            colorScheme="gray"
            size="xs"
            iconSpacing={0.5}
            rightIcon={showAll.isOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
          >
            {showAll.isOpen ? 'Show less' : 'Show more'}
          </Button>
        </Flex>
      )}
      <Stack spacing={3.5}>
        {/* dont repeat the `name` if we are already showing it */}
        {entries.length >= 10 && (
          <>
            <SearchBar size="sm" onChange={(e) => setFilter(e)} value={filter} />
          </>
        )}

        {displayed
          .filter((t) => (showName && !showAll.isOpen ? t[0] !== 'name' : true))
          .map(([key, trait]) => {
            const { value, source } = trait

            return (
              <StackedField
                key={key}
                width="100%"
                label={
                  <HStack wrap={'wrap'}>
                    {source === 'snowflake' ? (
                      <Tooltip label="Snowflake">
                        <Icon color="blue.400" as={IconBrandSnowflake} />
                      </Tooltip>
                    ) : null}
                    <TextEllipsis tooltip maxWidth="250px">
                      {key}
                    </TextEllipsis>
                    <Text fontSize="xs" color="gray.500">
                      ({traitDataType(value)})
                    </Text>
                  </HStack>
                }
              >
                {traitDataType(value) === 'object' ? (
                  <Box ml="-4" mt="-2">
                    <JSONTree data={value} />
                  </Box>
                ) : (
                  <TextEllipsis maxWidth="80%" tooltip>
                    {traitDataType(value) === 'number' ? value : null}

                    {traitDataType(value) === 'string' && !isURL(value) ? value : null}

                    {traitDataType(value) === 'boolean' ? JSON.stringify(value) : null}
                    {traitDataType(value) === 'date' ? new Date(value as string).toLocaleString() : null}
                    {traitDataType(value) === 'null' ? 'null' : null}
                    {isURL(value) ? (
                      <Link href={value as string} isExternal>
                        {truncate(value as string, { length: 45 })}
                      </Link>
                    ) : null}
                  </TextEllipsis>
                )}
              </StackedField>
            )
          })}

        {Object.keys(traits).length > firstSet.length && !showName && (
          <Box>
            <Button
              alignSelf="flex-start"
              variant="link"
              colorScheme="gray"
              size="xs"
              iconSpacing={1}
              rightIcon={showAll.isOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
              onClick={showAll.onToggle}
            >
              {showAll.isOpen ? 'Show less' : 'Show more'}
            </Button>
          </Box>
        )}
      </Stack>
    </Box>
  )
}
