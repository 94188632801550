import { Box, Button, Flex, FormControl, FormErrorMessage, Heading, Input, Link, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import KoalaLogo from '../../../ui/KoalaLogo'

interface Props {
  errors?: {
    [key: string]: string[]
  }
}

export default function New(props: Props) {
  const errors = React.useMemo(() => props.errors ?? window.component_props.errors, [props])

  return (
    <Box minHeight="100%" background="white">
      <Flex
        marginX="auto"
        paddingTop="15vh"
        maxWidth="100%"
        width="520px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={10}
        paddingX={5}
      >
        <KoalaLogo kind="mark" size="64px" />
        <Heading size="lg">Forgot your password?</Heading>
        <Box width="100%" maxWidth="400px" marginX="auto">
          <form action="/users/password" method="post" data-koala-collect="off">
            <AuthenticityToken />
            <Stack spacing={6} direction="column">
              <Text fontSize="sm" color="gray.600" textAlign="center">
                Enter the email address associated with your account and we'll send you a link to reset your password.
              </Text>

              <FormControl id="email" isInvalid={!!errors?.email?.length}>
                <Input type="email" name="user[email]" isRequired autoFocus placeholder="name@company.com" />
                <FormErrorMessage>{errors?.email?.join(', ')}</FormErrorMessage>
              </FormControl>

              <Button type="submit" colorScheme="purple" size="lg" fontSize="md">
                Continue
              </Button>

              <Text textAlign="center" fontSize="sm" color="gray.500">
                <Link variant="primary" href="/login">
                  Return to log in
                </Link>
              </Text>
            </Stack>
          </form>
        </Box>
      </Flex>
    </Box>
  )
}
