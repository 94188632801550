import {
  Button,
  Heading,
  HStack,
  Link,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { projectPath } from '../../../ui/ProjectsContext'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { usePermission } from '../../../ui/PermissionsContext'

interface ModalProps {
  email: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => unknown
}

function EmailModal(props: ModalProps) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl" preserveScrollBarGap>
      <form
        data-koala-collect="off"
        action={projectPath(`/settings/billing/update-email`)}
        method="POST"
        onSubmit={props.onSubmit}
      >
        <AuthenticityToken />

        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Billing Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={8}>
              <FormControl id="billing_email">
                <FormLabel>Email</FormLabel>
                <Input type="email" name="email" id="billing_email" defaultValue={props.email} autoComplete="off" />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="purple">
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export function BillingEmail(props: { email: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [defaultBillingEmail, setDefaultBillingEmail] = useState(props.email)
  const { hasPermission: canChangeBilling } = usePermission({ on: 'project', action: 'can_manage_accounts' })

  const onSubmit = React.useCallback(
    async (event) => {
      event.preventDefault()

      const form = event.target as HTMLFormElement
      const newEmail = form.email.value.trim()

      try {
        await postForm(form.action, new FormData(form))

        setDefaultBillingEmail(newEmail)
        toast.success('Billing email updated!')
        onClose()
      } catch (error) {
        toast.error('There was an issue updating your billing email', {
          description: (error as any)?.message
        })
      }
    },
    [onClose]
  )

  return (
    <HStack w="100%" justifyContent={'flex-end'}>
      <FormControl>
        <FormLabel>
          <Heading size="sm">Billing email:</Heading>
        </FormLabel>
        <InputGroup size="md">
          <Input type="email" size="md" fontSize="sm" readOnly value={defaultBillingEmail} background="gray.50" />
          <InputRightElement width="5.5em">
            {canChangeBilling && (
              <Button size="sm" as={Link} variant="outline" onClick={onOpen}>
                Change
              </Button>
            )}
          </InputRightElement>
        </InputGroup>
        <FormHelperText>The email address that manages the billing for this workspace.</FormHelperText>
      </FormControl>
      <EmailModal email={defaultBillingEmail} isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />
    </HStack>
  )
}
