import { useAIAgents } from '@app/components/data/use-ai-agents'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { TextEllipsis } from '@app/components/ui/text-ellipsis'
import { concurrentCachedGET, concurrentGET } from '@app/lib/api'
import { Account } from '@app/types/Account'
import { ProfileRecord as Profile } from '@app/types/Profile'
import {
  AvatarGroup,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconFlareFilled, IconPlayerPlay, IconSettings } from '@tabler/icons-react'
import pMap from 'p-map'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useState } from 'react'
import Avatar from '@app/components/ui/Avatar'
import { AIAgent } from '@app/types/AIAgent'
import { getFaviconUrl } from '../../lib/favicon'
import { EditAgentDrawer } from '../pages/ai_agents/components/AIAgentForm'
import { AIStaleState } from './AIStaleState'
import { useCachedAgents } from '../pages/ai_agents/components/AIAgentColumns'
import { AIAgentResponse } from '../pages/ai_agents/components/AIAgentResponse'

interface CompanyAIProps {
  account: Account
  profile?: Profile
  target: 'companies'
}

interface PeopleAIProps {
  account?: Account
  profile: Profile
  target: 'people'
}

type AIProps = CompanyAIProps | PeopleAIProps

interface BaseAgentProps {
  agent: {
    id: string
    slug: string
    name: string
    question: string
    runFromParent?: () => Promise<void>
  }
  runningAgents: Set<string>
}

interface CompanyAgentCardProps extends CompanyAIProps, BaseAgentProps {}
interface PeopleAgentCardProps extends PeopleAIProps, BaseAgentProps {}

type AgentCardProps = (CompanyAgentCardProps | PeopleAgentCardProps) & {
  cachedResponse?: any
  isCachedLoading?: boolean
}

function AgentCard(props: AgentCardProps) {
  const { account, profile, target, runningAgents, isCachedLoading, cachedResponse } = props
  const [isRunning, setIsRunning] = useState(isCachedLoading)
  const [response, setResponse] = useState(cachedResponse)
  const [isExpanded, setIsExpanded] = useState(false)
  const [agent, setAgent] = useState(props.agent)
  const [isEditingAgent, setIsEditingAgent] = useState(false)

  const runAgent = async (skipCache: boolean) => {
    if (runningAgents.has(agent.id)) return

    setIsRunning(true)
    setResponse(null)

    try {
      const queryParam = target === 'companies' ? `domain=${account?.domain}` : `profile_id=${profile?.id}`
      const path = projectPath(`/assistants/${agent?.slug}/run-async?${queryParam}&skip_cache=${skipCache}`)
      const res = await concurrentGET<{ job_id: string; result: any }>(path)
      const { job_id } = res
      let result = res.result

      const resultPath = projectPath(`/assistants/${agent?.slug}/research-result/${job_id}`)

      while (!result) {
        await new Promise((resolve) => setTimeout(resolve, 2_500))
        const { result: newResult } = await concurrentGET<{ job_id: string; result: any }>(resultPath)
        result = newResult
      }

      setResponse(result)
    } finally {
      setIsRunning(false)
    }
  }

  const runFromParent = useCallback(async () => {
    if (response) return
    setIsRunning(true)

    const queryParam = target === 'companies' ? `domain=${account?.domain}` : `profile_id=${profile?.id}`
    try {
      const path = projectPath(`/assistants/${agent?.slug}/run-async?${queryParam}`)
      const response = await concurrentCachedGET<{ job_id: string }>(path)

      const resultPath = projectPath(`/assistants/${agent?.slug}/research-result/${response.job_id}`)
      let { result } = await concurrentGET<{ job_id: string; result: any }>(resultPath)

      while (!result) {
        await new Promise((resolve) => setTimeout(resolve, 2_500))
        const { result: newResult } = await concurrentGET<{ job_id: string; result: any }>(resultPath)
        result = newResult
      }

      setResponse(result)
    } finally {
      setIsRunning(false)
    }
  }, [response, agent?.slug, account?.domain, setResponse, profile?.id, target])

  useEffect(() => {
    ;(agent as any).runFromParent = runFromParent
  }, [agent, runFromParent])

  return (
    <Flex
      position="relative"
      borderWidth="1px"
      rounded="lg"
      paddingY={[2, 2.5]}
      paddingX={3}
      gap="1"
      height="100%"
      flexDirection="column"
      alignSelf="stretch"
      justifyContent="space-between"
      role="group"
    >
      <TextEllipsis fontSize="13px" color="gray.600" maxW="100%" tooltip fontWeight="semibold" pr={6}>
        {agent.name}
      </TextEllipsis>
      {!response && (
        <IconButton
          position="absolute"
          top={1.5}
          right={1.5}
          size="xs"
          aria-label="Run agent"
          isDisabled={!!response || isRunning || runningAgents.has(agent.id)}
          icon={
            isRunning || runningAgents.has(agent.id) ? (
              <Spinner size="xs" thickness="1.5px" color="gray.700" />
            ) : (
              <Icon as={IconPlayerPlay} boxSize={3.5} />
            )
          }
          onClick={() => runAgent(false)}
        />
      )}
      {response && (
        <>
          <Text lineHeight={'1.2'} fontSize="13px" noOfLines={3}>
            {response.stale && <AIStaleState marginRight={1} verticalAlign="top" />}
            {response.display_answer}
          </Text>
          <Flex pt={2} mt="auto" justifyContent="space-between">
            <Tooltip label="View full report">
              <Button
                variant="outline"
                leftIcon={
                  <AvatarGroup size="16px" max={3} spacing={-1}>
                    {(response.sources || []).map((source) => {
                      let hostname = source.url

                      try {
                        hostname = source.url.startsWith('http')
                          ? new URL(source.url).hostname
                          : new URL(`https://${source.url}`).hostname
                      } catch (error) {
                        console.error(error)
                      }

                      return (
                        <Avatar
                          key={source.url}
                          name={hostname}
                          rounded="full"
                          src={getFaviconUrl(hostname, 16)}
                          outline="1.5px solid white"
                        />
                      )
                    })}
                  </AvatarGroup>
                }
                iconSpacing={1.5}
                size="xs"
                rounded="md"
                height={7}
                onClick={() => setIsExpanded(true)}
              >
                {pluralize('source', response.sources?.length ?? 0, true)}
              </Button>
            </Tooltip>
          </Flex>

          <EditAgentDrawer
            onClose={() => setIsEditingAgent(false)}
            isOpen={isEditingAgent}
            ai_agent={agent as AIAgent}
            showTarget={false}
            showDeleteOption={false}
            onChange={(agent, skipCache) => {
              setAgent(agent as AgentCardProps['agent'])
              runAgent(skipCache)
              setIsExpanded(false)
            }}
            company={account}
          />

          {isExpanded && (
            <AgentResponseDrawer
              response={response}
              agent={agent as AIAgent}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              setIsEditingAgent={setIsEditingAgent}
            />
          )}
        </>
      )}
    </Flex>
  )
}

export function AgentResponseDrawer(props: {
  response: any
  agent: AIAgent
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  setIsEditingAgent?: (isEditingAgent: boolean) => void
}) {
  const { response, agent, isExpanded, setIsExpanded, setIsEditingAgent } = props

  return (
    <Drawer size="lg" isOpen={isExpanded} onClose={() => setIsExpanded(false)} placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody py={6}>
          <Stack spacing={8} px="2">
            <Stack>
              <Box>
                <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="semibold">
                  Question
                </Text>
                <Text fontSize="md" color="gray.700" fontWeight="semibold">
                  {agent.question}
                </Text>
              </Box>
            </Stack>
            <AIAgentResponse response={response} loading={false} agent={agent} />
          </Stack>
        </DrawerBody>
        {setIsEditingAgent && (
          <DrawerFooter>
            <Button variant="outline" size="sm" onClick={() => setIsEditingAgent(true)}>
              Edit Agent
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export function AISummaryCard({ account, profile, target }: AIProps) {
  const { data: agents, isLoading } = useAIAgents(target)
  const [isRunningAll, setIsRunningAll] = useState(false)
  const [runningAgents, setRunningAgents] = useState<Set<string>>(new Set())
  const [cachedResponses, setCachedResponses] = useState<Record<string, any>>({})

  const { data: cachedAgentData, isLoading: isCachedLoading } = useCachedAgents({
    domains: account ? [account.domain] : [],
    profileIds: profile ? [profile.id] : [],
    agentSlugs: agents?.my_agents.map((a) => a.slug) || [],
    target: target
  })

  useEffect(() => {
    if (cachedAgentData && !isCachedLoading) {
      if (cachedAgentData.cached_agents.length) {
        const parsed = cachedAgentData.cached_agents.reduce(
          (acc, cache) => {
            acc[cache.agent_id] = JSON.parse(cache.response)
            return acc
          },
          {} as Record<string, any>
        )

        setCachedResponses(parsed)
      }
    }
  }, [cachedAgentData, isCachedLoading])

  const runAllAgents = async () => {
    if (!agents?.my_agents.length) return

    setIsRunningAll(true)
    setRunningAgents(new Set(agents.my_agents.map((a) => a.id)))

    try {
      // Run agents in parallel but track individual completion
      await pMap(
        agents.my_agents,
        async (agent) => {
          try {
            await (agent as any).runFromParent?.()
          } finally {
            setRunningAgents((prev) => {
              const next = new Set(prev)
              next.delete(agent.id)
              return next
            })
          }
        },
        { concurrency: 10 }
      )
    } finally {
      setIsRunningAll(false)
      setRunningAgents(new Set())
    }
  }

  if (isLoading) {
    return <Spinner size="sm" thickness="1.5px" color="gray.500" />
  }

  if (!agents?.my_agents.length) {
    return null
  }

  return (
    <Stack spacing={4}>
      <Flex justify="space-between" align="center">
        <HStack>
          <Icon as={IconFlareFilled} boxSize={4} color="purple.500" />
          <Text fontSize="sm" fontWeight="semibold">
            AI Agents
          </Text>
        </HStack>
        <HStack spacing={2}>
          <Button
            size="xs"
            leftIcon={
              isRunningAll ? (
                <Spinner size="xs" thickness="1.5px" color="gray.700" />
              ) : (
                <Icon as={IconPlayerPlay} boxSize={3.5} />
              )
            }
            onClick={runAllAgents}
            isDisabled={isRunningAll}
            variant="ghost"
          >
            {isRunningAll ? `Running ${runningAgents.size} remaining...` : 'Run All'}
          </Button>

          <IconButton
            size="xs"
            aria-label="Configure agents"
            variant="ghost"
            as={Link}
            href={projectPath('/settings/agents')}
            isExternal
            icon={<Icon as={IconSettings} boxSize={3.5} />}
          />
        </HStack>
      </Flex>
      <Grid gridAutoRows="auto" gridTemplateColumns={['1fr', 'repeat(auto-fit, minmax(260px, 1fr))']} gap={[2, 2, 3]}>
        {agents?.my_agents.map((agent) => (
          <AgentCard
            key={[agent.id, cachedResponses[agent.id], isCachedLoading].join('-')}
            agent={agent}
            account={account!}
            profile={profile!}
            target={target}
            runningAgents={runningAgents}
            cachedResponse={cachedResponses[agent.id]}
            isCachedLoading={isCachedLoading}
          />
        ))}
      </Grid>
    </Stack>
  )
}
