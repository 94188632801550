import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import pluralize from 'pluralize'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { patch } from '../../../../lib/api'
import { InboxItem } from '../../../../types/Inbox'
import { projectPath } from '../../../ui/ProjectsContext'
import { UserSelector } from '../../../ui/UserSelector'

interface ReassignModalProps extends UseDisclosureProps {
  items: InboxItem[]
  onReassign: (items: InboxItem[]) => void
}

export function ReassignModal({ items, onReassign, ...props }: ReassignModalProps) {
  const disclosure = useDisclosure(props)
  const [userId, setUserId] = useState(items?.[0]?.user_id || null)
  const [loading, setLoading] = useState(false)
  const itemIds = items?.map((i) => i.id)

  const onClose = disclosure.onClose

  const handleReassign = useCallback(() => {
    setLoading(true)
    patch(projectPath(`/inbox-items/reassign`), {
      user_id: userId,
      item_ids: itemIds
    })
      .then(() => {
        setLoading(false)
        onClose()
        onReassign(items)
      })
      .catch((error) => {
        setLoading(false)
        toast.error(`There was an issue reassigning this lead`, { description: error?.message })
      })
  }, [items, itemIds, onClose, userId, onReassign])

  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reassign {pluralize('lead', items?.length || 0)}</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Who do you want to assign this to?</FormLabel>
            <UserSelector
              selectedUserId={userId || null}
              onChange={(newAssigneeId) => setUserId(newAssigneeId)}
              allowUnassigned
              variant="outline"
              popperOptions={{ matchWidth: true }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" onClick={disclosure.onClose} isDisabled={loading}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={handleReassign} isLoading={loading} ml={3}>
            Reassign
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
