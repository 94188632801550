import { Box, Divider, Flex, FlexProps, Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import {
  Icon as TablerIcon,
  IconBolt,
  IconBriefcase,
  IconCalendar,
  IconCalendarTime,
  IconClockPlay,
  IconCurrencyDollar,
  IconStar,
  IconUsers,
  IconWorld
} from '@tabler/icons-react'
import ms from 'ms'
import * as React from 'react'
import { ordinalSuffix } from '../../../../lib/ordinal-suffix'
import { Account } from '../../../../types/Account'
import { Apps, CatalogApp } from '../../../../types/App'
import { ProjectActions } from '../../../../types/AppActions'
import { KQLByDay } from '../../../../types/KQL'
import type { Company, ProfileRecord } from '../../../../types/Profile'
import { Iconify } from '../../../ui/Iconify'
import { TimeAgo } from '../../../ui/TimeAgo'
import { isLimitedAccount, useEntitlements } from '../../../ui/useEntitlements'
import { AccountDescription } from '../../accounts/components/AccountDescription'
import { AccountIntegrationSidebar } from '../../accounts/components/AccountIntegrationSidebar'
import { AccountMatchMetadata } from '../../accounts/components/AccountMatchMetadata'
import { AccountSummary } from '../../accounts/components/AccountSummary'
import { IntentBarChart } from '../../accounts/components/Intent/IntentBarChart'
import { getScoreLabel, IntentTrendLabel, scoreLabelColor } from '../../accounts/components/Intent/IntentSummary'
import { grade } from '../../icps/icp/breakdown'
import { KqlDefinition } from '../../kql_definitions/types'
import { VisitorDetails } from './VisitorDetails'

interface Props {
  profile: ProfileRecord | null
  apps?: Apps
  catalog?: CatalogApp[]
  signalsByDay: KQLByDay[]
  signalDefinitions: KqlDefinition[]
  projectActions?: ProjectActions
}

export default function Profile(props: Props) {
  const stacked = useBreakpointValue({ base: true, sm: true, md: true, lg: true, xl: false })
  const showCompany = useBreakpointValue({ base: false, sm: false, md: true, lg: true, xl: true })
  const { profile, apps, catalog, projectActions } = props

  if (!profile) {
    return null
  }

  const account = profile.account
  const company = profile.account?.company

  return (
    <Flex flex="1 1 auto" maxHeight="100%" alignItems="flex-start">
      <Box
        display="flex"
        flexGrow={1}
        flexShrink={1}
        flexBasis={stacked || !showCompany ? '100%' : '75%'}
        maxHeight="100%"
        minW="320px"
        {...(stacked && {
          padding: [4, 4, 6, 8],
          overflow: 'auto',
          overscrollBehavior: 'contain',
          scrollBehavior: 'smooth'
        })}
      >
        <VisitorDetails
          profile_id={profile.id}
          profile={profile}
          apps={apps}
          catalog={catalog}
          stacked={stacked}
          signalsByDay={props.signalsByDay}
          signalDefinitions={props.signalDefinitions}
        />
      </Box>

      {showCompany && account && company?.domain && (
        <AccountSidebar
          account={account}
          company={company}
          profile={profile}
          apps={apps}
          projectActions={projectActions}
        />
      )}
    </Flex>
  )
}

interface PropertyProps extends FlexProps {
  label: string
  icon?: TablerIcon
}

function Property({ label, icon, children, ...flexProps }: PropertyProps) {
  if (!children) {
    return null
  }

  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      wrap="wrap"
      {...flexProps}
      alignItems="flex-start"
      fontSize="sm"
      gap="2"
      lineHeight="20px"
    >
      <Flex flex="none" alignItems="center" gap={2}>
        {icon && (
          <Box flex="none" color="gray.500" bg="gray.100" rounded="lg" padding={1}>
            <Iconify icon={icon} size={16} strokeWidth={2} />
          </Box>
        )}
        <Text color="gray.500" whiteSpace="nowrap">
          {label}:
        </Text>
      </Flex>
      <Box flex="1" pt="2px">
        {children}
      </Box>
    </Flex>
  )
}

interface AccountSidebarProps {
  account: Account
  company: Company
  profile: ProfileRecord
  apps?: Apps
  projectActions?: ProjectActions
}

function AccountSidebar({ account, company, profile, apps, projectActions }: AccountSidebarProps) {
  const fit = grade(account.fit_grade, account.fit_grade_letter)

  const scoreLabel = getScoreLabel(account.intent?.score)

  const entitlements = useEntitlements()
  const isRedacted = React.useMemo(() => {
    if (!entitlements) return false
    return isLimitedAccount(entitlements, account)
  }, [entitlements, account])

  return (
    <Box
      flex="1 1 25%"
      minWidth="340px"
      maxWidth="400px"
      maxHeight="100%"
      overflow="auto"
      paddingY={[4, 4, 6, 8]}
      paddingRight={[4, 4, 6, 8]}
      paddingLeft={[4, 4, 2]}
      overscrollBehavior="contain"
      scrollBehavior="smooth"
    >
      <Stack width="100%" spacing={4}>
        <Stack
          spacing={0}
          rounded="lg"
          border="1px solid"
          borderColor="gray.200"
          bg="white"
          shadow="xs"
          overflow="hidden"
          divider={<Divider />}
        >
          <Box paddingX={5} paddingY={4}>
            {profile.account_association && profile.account && profile.account_association?.match_type === 'ip' && (
              <Box marginBottom={4} px={2} py={1} mx={-2} bg="gray.50" rounded="lg">
                <AccountMatchMetadata
                  hasEmail={!!profile.email}
                  association={profile.account_association}
                  account={profile.account}
                />
              </Box>
            )}

            <AccountSummary domain={company.domain} name={company.name} logo={company.logo} />
          </Box>

          {(company.description ||
            company.founded_year ||
            company.metrics?.estimatedAnnualRevenue ||
            company.category?.industry) && (
            <Stack spacing={2} fontSize="sm" padding={5}>
              <Box pb={2}>
                <AccountDescription {...account} />
              </Box>
              <Property label="Website" icon={IconWorld}>
                <Link href={`https://${company.domain}`} isExternal>
                  {company.domain}
                </Link>
              </Property>
              <Property label="Founded" icon={IconCalendar}>
                {company.founded_year}
              </Property>
              <Property label="Revenue" icon={IconCurrencyDollar}>
                {company.metrics?.estimatedAnnualRevenue}
              </Property>
              <Property label="Industry" icon={IconBriefcase}>
                {company.category?.industry}
              </Property>
            </Stack>
          )}

          <Stack spacing={2} padding={5}>
            <Property label="Intent" icon={IconBolt}>
              <Flex alignItems="center" gap={3}>
                <Text color={`${scoreLabelColor[scoreLabel || 'none']}.500`}>{scoreLabel || 'Not enough data'}</Text>
              </Flex>
            </Property>
            {account.fit_grade && (
              <Property label="ICP Score" icon={IconStar}>
                {fit.letter} ({ordinalSuffix(account.fit_grade)} percentile)
              </Property>
            )}
            <Property label="Visitors" icon={IconUsers}>
              {account.visitor_stats?.visitors?.month ?? 0}
            </Property>
            <Property label="Activity" icon={IconClockPlay}>
              {ms(account.focus_time_trend?.month?.current?.value ?? 0, { long: true })}
            </Property>
            <Property label="Last Seen" icon={IconCalendarTime}>
              <TimeAgo time={account.last_seen_at} fallback="—" />
            </Property>

            {account.intent && (
              <Box flex="none" width="100%" pt={2}>
                <Box position="relative" bg="background.light" rounded="lg" pt={6}>
                  <Box position="absolute" right={0} top={0} py={1} px={1.5}>
                    <IntentTrendLabel intent={account.intent} showLabel showAnyTrend size="sm" />
                  </Box>
                  <IntentBarChart data={account.intent.trend ?? []} dataKey="score" height={56} />
                </Box>
              </Box>
            )}
          </Stack>
        </Stack>

        <AccountIntegrationSidebar
          account={account}
          apps={apps ?? {}}
          actions={projectActions ?? {}}
          isRedacted={isRedacted}
          initiallyCollapsed
        />
      </Stack>
    </Box>
  )
}
