import { Button, Grid, Heading, HStack, IconButton, Link, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconExternalLink, IconRefresh } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import type { App } from '../../../../types/App'
import { PersonRecord } from '../../../../types/Person'
import { ProfileRecord } from '../../../../types/Profile'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { SfContactIcon, SfLeadIcon } from '../../../ui/icons'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useUIState } from './useUIState'
import { post } from '../../../../lib/api'
import { toast } from 'sonner'
import { profilePath } from '../lib/path'

interface SalesforceCardProps {
  app: App
  record?: PersonRecord
  profile?: ProfileRecord
  permalink?: string
  recordType: 'Contact' | 'Lead'
  initiallyCollapsed?: boolean
  isImportingContact?: boolean
  isImportingLead?: boolean
  onRequestImportContact?: (app: App) => void
  onRequestImportLead?: (app: App) => void
}

export function SalesforceCard(props: SalesforceCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.salesforce ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { salesforce: true } })
    },
    onClose: () => {
      setUI({ open: { salesforce: false } })
    }
  })

  const [refreshRecordLoading, setRefreshRecordLoading] = useState(false)
  const [personRecord, setPersonRecord] = useState<PersonRecord | undefined>(props.record)

  const refreshRecord = useCallback(async () => {
    if (!props.profile?.id) return

    setRefreshRecordLoading(true)
    try {
      const path = profilePath({ id: props.profile.id }, `/actions/refresh?app=Salesforce&type=${props.recordType}`)
      const response = await post<{ profile: ProfileRecord }>(path)

      if (props.recordType === 'Contact') {
        setPersonRecord(response.profile.salesforce_contact_person)
      } else {
        setPersonRecord(response.profile.salesforce_lead_person)
      }

      toast(
        'Salesforce contact details updated. Please note that it may take a few moments for the changes to take effect on your searches and filters'
      )
    } finally {
      setRefreshRecordLoading(false)
    }
  }, [props.profile?.id, props.recordType])

  return (
    <DetailsCard>
      <CardHeading icon={props.recordType === 'Contact' ? SfContactIcon : SfLeadIcon} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>Salesforce {props.recordType}</Text>
          {props.app?.connected && (
            <Tooltip label="Refresh Salesforce record" placement="top" hasArrow arrowSize={6}>
              <IconButton
                variant="ghost"
                size="xs"
                aria-label="Refresh"
                icon={<IconRefresh size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                isLoading={refreshRecordLoading}
                onClick={(e) => {
                  e.preventDefault()
                  refreshRecord()
                }}
              />
            </Tooltip>
          )}
          {props.permalink && (
            <Tooltip label="View in Salesforce" placement="top" hasArrow arrowSize={6}>
              <IconButton
                aria-label="View in Salesforce"
                as={Link}
                href={props.permalink}
                isExternal
                size="xs"
                variant="link"
                icon={<IconExternalLink size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                onClick={() => {
                  window.ko?.track('Salesforce Visit Action', {
                    app: 'salesforce',
                    record: personRecord
                  })
                }}
              />
            </Tooltip>
          )}
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : personRecord ? (
        <Stack spacing={4}>
          <StackedField label="Email">{personRecord.email}</StackedField>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <StackedField label="Name">{personRecord.name?.full_name || personRecord.name?.given_name}</StackedField>
            <StackedField label="Location">
              {[personRecord.geo?.city, personRecord.geo?.country, personRecord.geo?.country]
                .filter(Boolean)
                .join(', ')}
            </StackedField>

            <StackedField label="Owner">{personRecord.crm_owner_name}</StackedField>

            <StackedField label="Last Change Detected">
              <TimeAgo time={personRecord.updated_at} mode="calendar" />
            </StackedField>

            <StackedField label="Last Successful Sync">
              <TimeAgo time={personRecord.last_finished_integration_sync?.stopped_at} mode="calendar" />
              {personRecord.last_finished_integration_sync && (
                <HelpTooltip>
                  <Stack>
                    <Heading size="xs">Last Successful Sync</Heading>
                    <Text>
                      Koala last pulled your Salesforce account for changes{' '}
                      <TimeAgo time={personRecord.last_finished_integration_sync?.started_at} />.
                    </Text>
                  </Stack>
                </HelpTooltip>
              )}
            </StackedField>
          </Grid>
          {props.app?.actions?.sf_import_contacts?.enabled && (
            <Stack>
              <Tooltip
                label={`Enrich your Salesforce ${props.recordType} record with data from Koala`}
                placement="top"
                hasArrow
                arrowSize={6}
              >
                <Button
                  variant="outline"
                  width="full"
                  size="sm"
                  isLoading={props.isImportingContact || props.isImportingLead}
                  onClick={() => {
                    window.ko?.track(`Clicked Salesforce Update ${props.recordType}`, {
                      app: 'salesforce',
                      record: personRecord
                    })
                    if (props.recordType === 'Contact') {
                      props.onRequestImportContact?.(props.app!)
                    } else {
                      props.onRequestImportLead?.(props.app!)
                    }
                  }}
                >
                  Enrich Salesforce record
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      ) : props.app.connected ? (
        <Stack>
          <Text fontSize="xs" color="gray.500">
            No contact or leads linked yet
          </Text>
          {props.profile?.email && props.app.actions?.sf_import_contacts?.enabled && props.onRequestImportContact && (
            <Button
              variant="outline"
              width="full"
              size="sm"
              isLoading={props.isImportingContact}
              onClick={() => {
                window.ko?.track('Clicked Salesforce Import Contact', {
                  app: 'salesforce',
                  record: personRecord
                })
                props.onRequestImportContact?.(props.app!)
              }}
            >
              Create contact
            </Button>
          )}
          {props.profile?.email && props.app.actions?.sf_import_leads?.enabled && props.onRequestImportLead && (
            <Button
              variant="outline"
              width="full"
              size="sm"
              isLoading={props.isImportingLead}
              onClick={() => {
                window.ko?.track('Clicked Salesforce Import Lead', {
                  app: 'salesforce',
                  record: personRecord
                })
                props.onRequestImportLead?.(props.app!)
              }}
            >
              Create lead
            </Button>
          )}
        </Stack>
      ) : null}
    </DetailsCard>
  )
}
