import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconCircleCheck } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { PricingForm } from '../../../ui/PricingForm'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import { billingCheckoutPath } from '../lib/path-helpers'
import { plansV4 } from '../PricingTable'
import { KoalaSubscription } from '../show'
import { ReferralProgramModal } from './referral-program'

interface Props {
  subscription: KoalaSubscription
  skipPricing?: boolean
}

function PlanCard(
  props: Props & { planName: 'growth' | 'business'; priceVariation: 'annual' | 'monthly'; onClose: () => void }
) {
  const nextPlan = plansV4[props.planName]
  const priceVariation = props.priceVariation
  const [isRedirecting, setIsRedirecting] = useState(false)

  const shouldShowPricing = useMemo(() => {
    return !props.skipPricing
  }, [props.skipPricing])

  return (
    <Stack
      w="100%"
      spacing="4"
      _first={{
        borderRight: '1px solid #E2E8F0'
      }}
      _last={{
        borderRight: 'none'
      }}
    >
      <Stack spacing="1" px={8}>
        <Heading size="md" fontWeight="semibold">
          Upgrade to {nextPlan.title}
        </Heading>

        <Text pb="4" h="80px" fontSize="sm" color="gray.600">
          {nextPlan.description}
        </Text>

        {props.skipPricing && (nextPlan.annualMonthlyPrice === 'custom' || nextPlan.basePrice === 'custom') && (
          <>
            <Flex>
              <Text fontSize="sm">Reach out for a quote.</Text>
            </Flex>
          </>
        )}
      </Stack>

      <Stack spacing="8" px="8" w="100%">
        {shouldShowPricing && (
          <Stack>
            {typeof nextPlan.annualMonthlyPrice === 'number' && typeof nextPlan.basePrice === 'number' && (
              <Flex>
                <Heading as="span" letterSpacing="tight" size="xl">
                  <Text as="span" fontSize=".72em" lineHeight={1.42} verticalAlign="top">
                    $
                  </Text>
                  {priceVariation === 'annual'
                    ? nextPlan.annualMonthlyPrice.toLocaleString()
                    : nextPlan.basePrice.toLocaleString()}
                </Heading>
                <Text
                  as="span"
                  alignSelf="flex-end"
                  fontSize="xs"
                  fontWeight="medium"
                  lineHeight={1.33}
                  marginLeft="2"
                  transform={`translateY(-6px)`}
                  color="gray.500"
                >
                  per month
                  {priceVariation === 'annual' && (
                    <>
                      <br />
                      billed annually
                    </>
                  )}
                </Text>
              </Flex>
            )}

            {!props.skipPricing && (nextPlan.annualMonthlyPrice === 'custom' || nextPlan.basePrice === 'custom') && (
              <HStack spacing="1" alignItems="baseline">
                <Heading size="xl">Custom</Heading>
              </HStack>
            )}

            <Flex fontSize="xs" color="gray.500">
              {priceVariation === 'annual' && nextPlan.annualSubtitle ? nextPlan.annualSubtitle : nextPlan.subtitle}
            </Flex>
          </Stack>
        )}

        <Stack spacing="1" w="100%">
          {!props.skipPricing && (
            <Collapse in={isRedirecting}>
              <Center py="4">
                <Heading size="xs">Redirecting you to Stripe to finish your transaction</Heading>
              </Center>
            </Collapse>
          )}

          <Stack>
            {nextPlan.title === 'Business' ? (
              <Button
                mt={shouldShowPricing ? 0 : '2'}
                colorScheme={'purple'}
                variant={'solid'}
                isLoading={isRedirecting}
                onClick={(_e) => {
                  window.open('https://getkoala.com/book-a-demo', '_blank')
                }}
              >
                Talk to sales
              </Button>
            ) : (
              <Button
                mt={shouldShowPricing ? 0 : '2'}
                variant="outline"
                isLoading={isRedirecting}
                onClick={(_e) => {
                  setIsRedirecting(true)
                  window.setTimeout(() => {
                    // @ts-ignore I just want to redirect!
                    window.location = billingCheckoutPath(`?plan=${props.planName}&version=v4&price=${priceVariation}`)
                  })
                }}
              >
                {props.subscription.trial_available ? 'Start 14-day trial' : 'Finish checkout in Stripe'}
              </Button>
            )}
          </Stack>
        </Stack>

        <List fontSize="sm" spacing="2" pt={props.skipPricing ? 0 : '4'}>
          {nextPlan.title === 'Starter' && (
            <Heading size="xs" fontWeight="semibold">
              Everything in Free, plus:
            </Heading>
          )}

          {nextPlan.title === 'Growth' && (
            <Heading size="xs" fontWeight="semibold">
              Everything in Free, plus:
            </Heading>
          )}

          {nextPlan.title === 'Pro' && (
            <Heading size="xs" fontWeight="semibold">
              Everything in Starter, plus:
            </Heading>
          )}

          {nextPlan.title === 'Business' && (
            <Heading size="xs" fontWeight="semibold">
              Everything in our Growth plan, plus:
            </Heading>
          )}

          {nextPlan.features.map((feature) => (
            <ListItem key={feature} display="flex">
              <ListIcon as={IconCircleCheck} boxSize={4} color="green.500" marginY={0.5} />
              {feature}
            </ListItem>
          ))}
        </List>
      </Stack>
    </Stack>
  )
}

export function CheckoutModal(props: Props & { onClose: () => void; upgrade_to?: 'business' | 'growth' }) {
  const [showPricingForm, setShowPricingForm] = useState(false)
  const [priceVariation, setPriceVariation] = useState<'annual' | 'monthly'>('annual')

  return (
    <Modal
      isOpen={true}
      onClose={props.onClose}
      size={props.subscription.plan === 'starter' || props.upgrade_to ? 'xl' : '4xl'}
    >
      <ModalOverlay backdropFilter="blur(8px)" />

      <ModalContent rounded="2xl" pb="8">
        <ModalCloseButton />
        <ModalBody p="0" w="100%">
          <Stack alignItems="center" py={10}>
            <Box width="auto">
              <SegmentedControl size="sm">
                <Button
                  width="140px"
                  isActive={priceVariation === 'annual'}
                  onClick={() => setPriceVariation('annual')}
                >
                  Annually
                </Button>
                <Button
                  width="140px"
                  isActive={priceVariation === 'monthly'}
                  onClick={() => setPriceVariation('monthly')}
                >
                  Monthly
                </Button>
              </SegmentedControl>
            </Box>
          </Stack>
          <Stack w="100%" px="8" pt="8">
            <HStack w="100%" justifyContent={'center'} alignItems="flex-start" pb="6">
              {!props.upgrade_to && props.subscription.plan !== 'growth' && (
                <PlanCard planName="growth" priceVariation={priceVariation} {...props} />
              )}
              {!props.upgrade_to && <PlanCard planName="business" priceVariation={priceVariation} {...props} />}
              {props.upgrade_to && (
                <PlanCard
                  skipPricing={props.upgrade_to === 'business'}
                  priceVariation={priceVariation}
                  planName={props.upgrade_to}
                  {...props}
                />
              )}
            </HStack>

            {props.upgrade_to !== 'business' && (
              <Button
                colorScheme="purple"
                variant="link"
                width="auto"
                onClick={() => {
                  setShowPricingForm(true)
                }}
              >
                Got questions? Send us a message
              </Button>
            )}

            {showPricingForm && (
              <PricingForm
                onClose={() => {
                  setShowPricingForm(false)
                }}
              />
            )}
          </Stack>
          {/* only show referral option to free, since other plans are not limited on Accounts */}
          {props.subscription.plan === 'free' && props.upgrade_to !== 'business' && (
            <ReferralProgramModal>
              <Center textAlign={'center'} py={2}>
                <Link maxW="75%" fontWeight={'normal'} fontSize="xs" color="gray.500">
                  Not ready to upgrade? Learn how you can unlock more accounts with Koala's referral program.
                </Link>
              </Center>
            </ReferralProgramModal>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
