import {
  Button,
  Flex,
  FormControl,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  HStack,
  Icon,
  Image,
  Stack,
  Heading
} from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import { Apps } from '../../types/App'
import { useImportProfilesToCrm, useImportAccountsToCrm } from '../data/use-import-to-crm'
import { projectPath } from './ProjectsContext'
import { CardRadioGroup } from './CardRadioGroup'
import { SalesforceIcon, SfContactIcon, SfLeadIcon } from './icons'
import { HubSpotIcon } from './icons/HubspotIcons'
import { HeroImage } from '../pages/welcome/components/HeroImage'
import crmImg from '../pages/welcome/components/crm.png'
import React, { useMemo, useState, useCallback } from 'react'
import { ExternalToast, toast } from 'sonner'
import pluralize from 'pluralize'

const toastOptions: ExternalToast = {
  dismissible: true,
  closeButton: true,
  position: 'bottom-center'
}

const addToCrmActions = [
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Leads in Salesforce
        </Text>
        <SfLeadIcon boxSize={4} />
      </Flex>
    ),
    value: 'salesforce-leads',
    kind: 'profile'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Contacts in Salesforce
        </Text>
        <SfContactIcon boxSize={4} />
      </Flex>
    ),
    value: 'salesforce-contacts',
    kind: 'profile'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Contacts in HubSpot
        </Text>
        <HubSpotIcon boxSize={4} color="hubspot" />
      </Flex>
    ),
    value: 'hubspot-contacts',
    kind: 'profile'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Companies in HubSpot
        </Text>
        <HubSpotIcon boxSize={4} color="hubspot" />
      </Flex>
    ),
    value: 'hubspot-companies',
    kind: 'account'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Accounts in Salesforce
        </Text>
        <SalesforceIcon boxSize={4} color="salesforce" />
      </Flex>
    ),
    value: 'salesforce-accounts',
    kind: 'account'
  }
]

const NoIntegrations = () => (
  <>
    <HeroImage src={crmImg} objectFit="contain" objectPosition="top" />

    <Stack px="8" spacing="4" mb={4}>
      <Stack>
        <Heading size="md">Connect your CRM</Heading>
        <Text fontSize="sm" color="gray.600" marginY={6}>
          Koala's two-way sync will allow you to easily push contacts or leads into your CRM and more.
        </Text>
      </Stack>

      <HStack w="100%" justifyContent={'space-around'}>
        {[
          {
            name: 'Salesforce',
            href: '/apps/salesforce',
            image: 'https://cdn.cdnlogo.com/logos/s/3/salesforce.svg'
          },
          {
            name: 'HubSpot',
            href: '/apps/hubspot',
            image: 'https://cdn.cdnlogo.com/logos/h/24/hubspot.svg'
          }
        ].map((app) => (
          <Button
            key={app.name}
            href={projectPath(app.href)}
            isExternal
            variant="outline"
            size="md"
            fontSize="sm"
            fontWeight="medium"
            as={Link}
            leftIcon={<Image src={app.image} boxSize={6} />}
            rightIcon={<Icon as={IconArrowRight} boxSize={5} color="gray.500" />}
          >
            Configure {app.name}
          </Button>
        ))}
      </HStack>
    </Stack>
  </>
)

interface BulkAddToCrmProps {
  selectedIds: string[]
  apps: Apps
  recordType?: 'profile' | 'account'
  onImport?: (records: any[]) => void
}

export function BulkAddToCrmModal(props: BulkAddToCrmProps) {
  const { onImport } = props
  const disclosure = useDisclosure()
  const onClose = disclosure.onClose

  const [action, setAction] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const availableActions = useMemo(() => {
    const available: any[] = []

    for (const app of Object.values(props.apps || {})) {
      if (!(app.valid || app.client_valid)) continue

      if (app.app_module === 'Apps::Hubspot::App') {
        if (app.actions?.hs_import_contacts ?? true) {
          available.push(addToCrmActions.find((a) => a.value === 'hubspot-contacts'))
        }
        if (app.actions?.hs_import_accounts ?? true) {
          available.push(addToCrmActions.find((a) => a.value === 'hubspot-companies'))
        }
      }

      if (app.app_module === 'Apps::Salesforce::App') {
        if (app.actions?.sf_import_leads ?? true) {
          available.push(addToCrmActions.find((a) => a.value === 'salesforce-leads'))
        }
        if (app.actions?.sf_import_contacts ?? true) {
          available.push(addToCrmActions.find((a) => a.value === 'salesforce-contacts'))
        }
        if (app.actions?.sf_import_accounts ?? true) {
          available.push(addToCrmActions.find((a) => a.value === 'salesforce-accounts'))
        }
      }
    }

    return available.filter((a) => a.kind == props.recordType)
  }, [props.apps, props.recordType])

  const { mutateAsync: importProfilesToCrm } = useImportProfilesToCrm()
  const { mutateAsync: importAccountsToCrm } = useImportAccountsToCrm()

  const onSubmit = useCallback(async () => {
    let type: 'contact' | 'lead' | 'account' | undefined
    let app_module: 'Salesforce' | 'Hubspot' | undefined

    if (action === 'salesforce-leads') {
      app_module = 'Salesforce'
      type = 'lead'
    } else if (action === 'salesforce-contacts') {
      app_module = 'Salesforce'
      type = 'contact'
    } else if (action === 'salesforce-accounts') {
      app_module = 'Salesforce'
      type = 'account'
    } else if (action === 'hubspot-contacts') {
      app_module = 'Hubspot'
      type = 'contact'
    } else if (action === 'hubspot-companies') {
      app_module = 'Hubspot'
      type = 'account'
    }

    if (!app_module) {
      return
    }

    const count = props.selectedIds.length
    if (count === 0) return

    try {
      setSubmitting(true)

      if (type == 'contact' || type == 'lead') {
        const res = await importProfilesToCrm({ profile_ids: props.selectedIds, app_module, type })
        onImport?.(res.profiles)
      } else if (type == 'account') {
        const res = await importAccountsToCrm({ account_ids: props.selectedIds, app_module, type })
        onImport?.(res.accounts)
      }

      onClose()
      toast.success(`Imported ${count} ${pluralize(type || 'person', count)} to ${app_module}`, toastOptions)
    } catch (_err) {
      toast.error(`Failed to import ${type}s to CRM`, toastOptions)
    } finally {
      setSubmitting(false)
    }
  }, [props.selectedIds, action, importProfilesToCrm, importAccountsToCrm, onImport, onClose])

  return (
    <>
      <Button size="sm" variant="outline" onClick={disclosure.onOpen}>
        Add to CRM
      </Button>

      <Modal {...disclosure} size="lg">
        <ModalOverlay />
        <ModalContent>
          {availableActions.length === 0 ? (
            <NoIntegrations />
          ) : (
            <>
              <ModalCloseButton />
              <ModalHeader fontSize="md">Add to CRM</ModalHeader>
              <ModalBody>
                <FormControl size="sm" isRequired>
                  <CardRadioGroup
                    size="sm"
                    checkPlacement="left"
                    direction="column"
                    showUncheckedIcon
                    value={action || ''}
                    onChange={setAction}
                    options={availableActions}
                  />
                </FormControl>
              </ModalBody>
            </>
          )}
          <ModalFooter gap={3}>
            <Button size="sm" variant="outline" onClick={disclosure.onClose}>
              Cancel
            </Button>
            {availableActions.length > 0 && (
              <Button size="sm" colorScheme="purple" onClick={onSubmit} isLoading={submitting} isDisabled={!action}>
                Add to CRM
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
