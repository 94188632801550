import { Flex, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Portal, Text } from '@chakra-ui/react'
import { IconCircleCheckFilled, IconExternalLink, IconPlus } from '@tabler/icons-react'
import * as React from 'react'
import { useMemo } from 'react'
import CompanyAvatar from './CompanyAvatar'
import { projectPath } from './ProjectsContext'

interface WorkspaceMenuProps {
  project?: {
    id: string
    name: string
    slug: string
  }
  canManageMembers?: boolean
}

export function WorkspaceMenu({ project, canManageMembers, children }: React.PropsWithChildren<WorkspaceMenuProps>) {
  // sort the current project to the top
  const projects = useMemo(
    () => Array.from(window.projects || []).sort((a) => (a.id === project?.id ? -1 : 1)),
    [project]
  )

  return (
    <Menu placement="bottom-start" isLazy lazyBehavior="keepMounted" autoSelect={false} offset={[10, 8]}>
      <MenuButton
        flex="1 1 auto"
        minW="55px"
        width="100%"
        aria-label="Workspace Menu"
        tabIndex={0}
        _focusVisible={{
          boxShadow: 'outline',
          outline: '2px solid transparent',
          outlineOffset: '2px'
        }}
      >
        {children}
      </MenuButton>

      <Portal>
        <MenuList shadow="lg" zIndex="overlay" fontSize="13px">
          {projects.map((p) => (
            <MenuItem
              key={p.id}
              as="a"
              href={`/projects/${p.slug}`}
              icon={
                <CompanyAvatar
                  size="16px"
                  padding="1.5px"
                  background="white"
                  rounded="md"
                  name={p.name}
                  domain={p.domain}
                />
              }
              iconSpacing={1.5}
            >
              <Flex flex="1 1 auto" alignItems="center" justifyContent="space-between" gap={1.5} isTruncated>
                <Text isTruncated>{p.name || p.slug}</Text>
                {p.id === project?.id && (
                  <Icon as={IconCircleCheckFilled} boxSize="16px" color="purple.500" flex="none" />
                )}
              </Flex>
            </MenuItem>
          ))}

          <MenuItem as="a" href="/join">
            <Flex gap={1.5} alignItems="center">
              <Flex flex="none" alignItems="center" justifyContent="center" width="16px" height="16px">
                <IconPlus size={14} />
              </Flex>
              <Text>Create or join workspace</Text>
            </Flex>
          </MenuItem>

          <MenuDivider />

          {project && (
            <MenuItem as="a" href={projectPath('/settings')}>
              Settings
            </MenuItem>
          )}
          {project && canManageMembers && (
            <MenuItem as="a" href={projectPath('/settings/members')}>
              Invite and manage members
            </MenuItem>
          )}

          <MenuDivider />

          <MenuItem
            as="a"
            href="https://getkoala.com/docs"
            target="_blank"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
          >
            Docs
            <Icon as={IconExternalLink} color="gray.400" boxSize={3.5} />
          </MenuItem>
          <MenuItem
            as="a"
            href="https://getkoala.com/blog?type=product-updates"
            target="_blank"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
          >
            Changelog <Icon as={IconExternalLink} color="gray.400" boxSize={3.5} />
          </MenuItem>

          <MenuDivider />

          <MenuItem
            as="a"
            href="/logout"
            onClick={() => {
              window.ko?.reset()
            }}
          >
            Log out
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
