import {
  Box,
  Collapse,
  Button,
  Flex,
  Grid,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  UnorderedList,
  ListItem
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import React, { useState } from 'react'
import { getFaviconUrl } from '../../../../lib/favicon'
import { AIAgent } from '../../../../types/AIAgent'
import { Card } from '../../../ui/Card'
import { LoadingPhrases } from '../../../ui/LoadingPhrases'
import MarkdownText from '../../../ui/MarkdownText'
import { TimeAgo } from '../../../ui/TimeAgo'

interface Props {
  response: any
  loading: boolean
  agent: AIAgent | null
}

export const AIAgentResponse = ({ response, loading, agent }: Props) => {
  const [showReasoning, setShowReasoning] = useState(false)

  if (loading) {
    return <LoadingPhrases isLoading={loading} />
  }

  return (
    <>
      {response?.short_answer && (
        <>
          <Stack>
            <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
              Short Answer
            </Text>
            <MarkdownText>{response.short_answer}</MarkdownText>
          </Stack>
          <Stack>
            <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
              Long Answer
            </Text>
            <Box fontSize="15px" lineHeight={1.5}>
              <MarkdownText>{response.detailed_answer}</MarkdownText>
            </Box>
          </Stack>

          {(response.sources || []).length > 0 && (
            <Stack>
              <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                Sources
              </Text>
              <Grid gridAutoRows="1fr" gridTemplateColumns="repeat(3, minmax(0, 1fr))" gap={2}>
                {response.sources.map((source) => {
                  let hostname = source.url

                  try {
                    hostname = source.url.startsWith('http')
                      ? new URL(source.url).hostname
                      : new URL(`https://${source.url}`).hostname
                  } catch (error) {
                    console.error(error)
                  }

                  return (
                    <Card
                      key={source.url}
                      as={LinkBox}
                      display="flex"
                      flexDirection="column"
                      alignSelf="stretch"
                      justifyContent="space-between"
                      p={2}
                      borderRadius="md"
                      bg="gray.50"
                    >
                      <LinkOverlay href={source.url} isExternal>
                        <Text fontSize="xs" fontWeight="medium">
                          {source.title}
                        </Text>
                      </LinkOverlay>
                      <Flex alignItems="center" gap={1}>
                        <Image width="16px" height="16px" rounded="full" src={getFaviconUrl(hostname)} />
                        <Text
                          flex="1 1 auto"
                          minW="100px"
                          fontSize="xs"
                          fontWeight="medium"
                          color="gray.500"
                          isTruncated
                        >
                          {hostname}
                        </Text>
                      </Flex>
                    </Card>
                  )
                })}
              </Grid>
            </Stack>
          )}
          <Box>
            {agent?.model && (
              <Text color="gray.400" fontSize="sm">
                Model: {agent.model}
              </Text>
            )}

            {response.cache_generated_at && (
              <Text color="gray.400" fontSize="sm" marginTop={1}>
                Response generated: <TimeAgo time={response.cache_generated_at} />
              </Text>
            )}
          </Box>

          {response.reasoning_steps && (
            <Stack>
              <Button
                color="gray.400"
                textTransform="uppercase"
                fontWeight="medium"
                fontSize="11px"
                size="sm"
                variant="link"
                iconSpacing={1.5}
                rightIcon={showReasoning ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
                onClick={() => setShowReasoning((prev) => !prev)}
                mb={2}
                display="flex"
                justifyContent="flex-start"
                marginBottom={0}
              >
                Reasoning
              </Button>
              <Collapse in={showReasoning}>
                <UnorderedList fontSize="sm" marginStart={4}>
                  {response.reasoning_steps.map((step) => (
                    <ListItem key={step}>
                      <Text>{step}</Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Collapse>
            </Stack>
          )}
        </>
      )}
    </>
  )
}
