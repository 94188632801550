import { TableFooter } from '@app/components/ui/TableFooter'
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
  useDisclosure,
  useCheckboxGroup
} from '@chakra-ui/react'
import { IconAlignJustified, IconChevronRight, IconDotsVertical, IconLayoutColumns } from '@tabler/icons-react'
import React, { useCallback, useMemo, useEffect } from 'react'
import router from '../../../lib/router'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { PageMeta } from '../../../types/PageMeta'
import { FacetParams } from '../../data/use-facets'
import { UrlFilterParams, useUrlFilters } from '../../data/use-url-filters'
import { ColumnManagementPopover, defaultAccountColumns, TableMode, useColumns } from '../../ui/ColumnSelector'
import DownloadCsvMenuItem from '../../ui/DownloadCsvButtons'
import { BuildingIcon } from '../../ui/icons'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TopBarContent } from '../../ui/TopBarContext'
import { PartialAccountView, ViewSelector } from '../../ui/ViewSelector'
import { SaveAccountView } from '../account_views/components/AccountViewForm'
import { NewListModal } from '../account_views/components/NewListModal'
import { accountViewPath } from '../account_views/lib/list-paths'
import { AccountList } from '../icps/icp/account-list'
import { ListContainer, useListsNav } from '../lists/components/ListContainer'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'
import { VisitorTabs } from '../profiles/components/visitor-tabs'
import { AccountEmptyState, NoPixelEmptyState, NoRevealEmptyState } from './components/empty-states/AccountEmptyState'
import { FilterPreview } from './components/FilterPreview'
import { BulkActionBar } from '../../ui/BulkActionBar'
import { BulkAddToCrmModal } from '@app/components/ui/BulkAddToCrmModal'
import { useAIColumns } from '../ai_agents/components/useAIColumns'
import { ViewAsUserSelector } from '../../ui/ViewAsUserSelector'

type RangeString = `${string}..${string}`

export type FacetValue = string | number
export type FacetOperator = 'not' | 'all' | 'gte' | 'lte' | 'lt' | 'gt' | 'exists' | 'prefix' | 'contains'
export type NotFacet = { not: FacetValue[] }
export type AllFacet = { all: FacetValue[] }
export type GteFacet = { gte: FacetValue }
export type LteFacet = { lte: FacetValue }
export type ExistsFacet = { exists: FacetValue }
export type RangeFacet = { gte?: FacetValue; lte?: FacetValue }
export type PrefixFacet = { prefix: FacetValue }
export type ContainsFacet = { contains: FacetValue }
export type Facet =
  | FacetValue[]
  | NotFacet
  | AllFacet
  | GteFacet
  | LteFacet
  | ExistsFacet
  | RangeFacet
  | PrefixFacet
  | ContainsFacet
  | FacetValue
export type FacetCloud = Record<string, Record<string, number>>
export type FacetFilters = Record<string, Facet> | { _or: Array<FacetFilters> }
export type NumericFilter = number | RangeString | RangeFacet | GteFacet | LteFacet

export type MappingDataType = 'boolean' | 'keyword' | 'float' | 'double' | 'ip' | 'long' | 'date'

export interface FacetMapping {
  facet: string
  type: MappingDataType
  label?: string
  values?: {
    [facetValue: string]: number
  }
}

export interface FacetMappings {
  [facet: string]: Omit<FacetMapping, 'facet'>
}

export interface AccountBrowsingProps {
  crm?: Crm
  page_meta: PageMeta
  total_in_workspace?: number
  total_hidden?: number
  facet_filters: FacetFilters
  columns: string[]
  ai_columns: string[]
  sort_by?: string
  accounts: Account[]
  apps: Apps
  permissions?: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  data_flowing: boolean
  has_company_matches: boolean
  selected_range?: FacetParams['range']
}

const allAccountsList = {
  name: 'All Accounts',
  slug: 'all-accounts',
  kind: 'account'
} as const

export default function Index(props: AccountBrowsingProps) {
  const navContext = useListsNav()
  const facets: UrlFilterParams = useUrlFilters({
    initialRange: props.selected_range ?? 'any',
    initialSortBy: props.sort_by
  })

  const isDirty = !facets.onlyDefaults
  const checkboxes = useCheckboxGroup()
  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const hasCompanies = useMemo(
    () => props.has_company_matches && (props.total_in_workspace ?? 0) > 0,
    [props.has_company_matches, props.total_in_workspace]
  )

  useEffect(() => {
    checkboxes.setValue([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accounts])

  const onRefresh = useCallback(() => {
    router.visit(window.location.toString(), {
      fetch: true
    })
  }, [])

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.accounts,
    columns: props.columns?.length ? props.columns : defaultAccountColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultAccountColumns.map((c) => c.key)
  })

  const {
    columns: aiColumns,
    loadingColumns: _aiLoadingColumns,
    onColumnChange: onAIColumnChange,
    onColumnRemove: _onAIColumnRemove
  } = useAIColumns({
    data: props.accounts,
    columns: props.ai_columns?.length ? props.ai_columns : [],
    initialColumns: props.ai_columns?.length ? props.ai_columns : []
  })

  const { trackRecentNavItem } = useTrackRecentNavItems()

  const changeView = useCallback(
    (accountView: PartialAccountView | null) => {
      if (accountView?.id && accountView?.slug) {
        const path = accountViewPath(accountView)
        trackRecentNavItem(`accountView:${accountView.id}`)
        router.visit(path)
      } else if (accountView?.id && accountView.class_name === 'StaticList') {
        const path = projectPath(`/lists/${accountView.id}`)
        trackRecentNavItem(`staticList:${accountView.id}`)
        router.visit(path)
      }
    },
    [trackRecentNavItem]
  )

  const newListModal = useDisclosure()

  return (
    <ListContainer paddingX={0} paddingY={0} gap={0} maxH="100%" minH="300px">
      <TopBarContent onlyNewNav>
        <Flex width="100%" justifyContent="space-between">
          <Flex alignItems="center" gap={1.5}>
            <SmallPageHeading size="xs" fontWeight="medium">
              Accounts
            </SmallPageHeading>

            <Icon as={IconChevronRight} color="gray.400" boxSize={4} />
            <Box>
              <ViewSelector
                placeholder={allAccountsList}
                kind="account"
                ownership={['private', 'shared']}
                includePrivate
                onChange={changeView}
                onCreateAccountview={newListModal.onOpen}
              />
              <NewListModal {...newListModal} />
            </Box>
          </Flex>

          <ViewAsUserSelector facets={facets} />
        </Flex>
      </TopBarContent>

      <Flex w="100%" alignItems="center" justifyContent="space-between" gap={2} py={3} px={4}>
        <PageTitle skipRendering>Accounts</PageTitle>
        <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
          <Flex alignItems="center" gap={3}>
            {navContext.offScreen ? (
              <IconAlignJustified size={20} onClick={navContext.onOpen} />
            ) : (
              <BuildingIcon boxSize={5} />
            )}
            <Heading display="inline-flex" gap={4} alignItems="baseline" size="md">
              Accounts{'  '}
              <Box whiteSpace="nowrap">
                <Text as="span">{props.page_meta.total_count.toLocaleString()}</Text>
              </Box>
            </Heading>
          </Flex>
        </HStack>

        <Flex gap={2} alignItems="center">
          <VisitorTabs kind="account" />

          <ColumnManagementPopover
            audienceKind="account"
            apps={apps}
            selectedColumns={columns}
            onChange={onColumnChange}
          >
            <IconButton
              aria-label="Edit columns"
              variant="outline"
              size="sm"
              flex="none"
              icon={<IconLayoutColumns size={18} />}
            />
          </ColumnManagementPopover>

          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              icon={<IconDotsVertical size={16} />}
              variant="outline"
              borderColor="gray.200"
            />
            <MenuList fontSize="sm" zIndex="popover">
              <DownloadCsvMenuItem
                url={`${window.location.pathname}/export.csv${window.location.search}`}
                isMenuDisabled={
                  window.location.pathname.endsWith('/feed') || window.location.pathname.endsWith('/live')
                }
                apps={apps}
                audienceKind="account"
                initialColumns={columns}
                allowColumnSelection
              />

              <MenuDivider />
              <TableMode />
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" gap={4} px={4} pb={3}>
        <FilterPreview {...facets} kind="account" apps={props.apps} shouldShowTerritoryFilter />

        {isDirty && (
          <Box marginLeft="auto">
            <SaveAccountView type="account" isFiltering={facets.isFiltering} permissions={props.permissions} />
          </Box>
        )}
      </Flex>

      {props.accounts.length > 0 && (
        <>
          <Box display="flex" flexDirection="column" flex="1 1 auto" maxH="100%" minH="300px">
            <AccountList
              accounts={props.accounts}
              facetParams={facets}
              selected={checkboxes.value}
              getCheckboxProps={checkboxes.getCheckboxProps}
              onSelectAll={() => {
                checkboxes.setValue((prev) =>
                  prev.length === props.accounts.length ? [] : props.accounts.map((p) => p.id)
                )
              }}
              apps={apps}
              range={facets.range ?? 'week'}
              sortingBy={facets.sortBy ?? 'overall_grade'}
              onRefresh={onRefresh}
              onSortChange={facets.setSortBy}
              columns={columns}
              loadingColumns={loadingColumns}
              canAddColumns
              onColumnChange={onColumnChange}
              onColumnRemove={onColumnRemove}
              aiColumns={aiColumns}
              onAIColumnChange={onAIColumnChange}
            />

            <TableFooter
              word="account"
              pageMeta={props.page_meta}
              page={(facets.page ?? 1) as number}
              setPage={facets.setPage}
              px={[3, 4]}
              sticky
            />
          </Box>

          <BulkActionBar
            selectionCount={checkboxes.value.length ?? 0}
            onRemoveSelection={() => checkboxes.setValue([])}
          >
            <BulkAddToCrmModal
              selectedIds={checkboxes.value as string[]}
              apps={props.apps}
              onImport={() => checkboxes.setValue([])}
              recordType={'account'}
            />
          </BulkActionBar>
        </>
      )}

      {props.accounts.length === 0 && props.data_flowing && hasCompanies && <AccountEmptyState facets={facets} />}
      {props.accounts.length === 0 && !props.data_flowing && <NoPixelEmptyState />}
      {props.accounts.length === 0 && props.data_flowing && !hasCompanies && <NoRevealEmptyState />}
    </ListContainer>
  )
}
