import {
  Badge,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  MenuButton,
  Menu,
  Stack,
  Text,
  useDisclosure,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { IconDotsVertical, IconTrash } from '@tabler/icons-react'
import React from 'react'
import { toast } from 'sonner'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import GoogleLogo from '../devise/registrations/components/google.svg'

interface Mailbox {
  id: string
  app_module: string
  created_at: string
  updated_at: string
  connected?: boolean
  info: {
    name: string
    email: string
    image: string
  }
}

interface Props {
  mailbox?: Mailbox
}

export default function MailboxSettings({ mailbox }: Props) {
  const project = useCurrentProject()
  const disconnect = useDisclosure()

  const connectGmail = async () => {
    try {
      const returnTo = encodeURIComponent(window.location.pathname)
      window.location.href = `/auth/gmail?project=${project?.slug}&app_id=gmail&state=${returnTo}`
    } catch (error) {
      console.error('Error requesting Gmail scope:', error)
      toast.error('Failed to connect Google account')
    }
  }

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb offscreen />

      <SettingsHeader>
        <PageTitle>Connected Mailbox</PageTitle>
        <PageDescription>
          Connect your Gmail account to Koala to send messages and track sales engagements.
        </PageDescription>
      </SettingsHeader>

      {mailbox ? (
        <Flex alignItems="center" gap={2} rounded="md" p={3} border="1px solid" borderColor="gray.200">
          <Image src={GoogleLogo} boxSize="32px" />
          <Text flex="1 1 auto" fontSize="sm" fontWeight="medium">
            {mailbox?.info?.email}
          </Text>
          {/* show that it is connected or not */}
          <Badge variant="regular" colorScheme={mailbox.connected ? 'green' : 'gray'}>
            {mailbox.connected ? 'Connected' : 'Not connected'}
          </Badge>

          <Menu placement="bottom-end">
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<IconDotsVertical size={14} />}
              variant="ghost"
              size="xs"
            />
            <MenuList>
              <MenuItem icon={<IconTrash size={14} />} color="red.500" onClick={disconnect.onOpen} fontSize="sm">
                Disconnect mailbox
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Heading size="sm">Connect your mailbox</Heading>
            <Text fontSize="sm" color="gray.500">
              Connect your Gmail account to Koala to send messages and track sales engagements.
            </Text>
          </Stack>

          <Button
            alignSelf="flex-start"
            onClick={connectGmail}
            size="sm"
            variant="outline"
            leftIcon={<Image src={GoogleLogo} boxSize="16px" />}
          >
            Connect Google Account
          </Button>
        </Stack>
      )}

      <DeleteConfirmation
        isOpen={disconnect.isOpen}
        onClose={disconnect.onClose}
        title="Disconnect mailbox"
        confirmLabel="Disconnect"
        deletePath={projectPath(`/settings/mailboxes/${mailbox?.id}`)}
      >
        Are you sure you want to disconnect your Google account? This will remove access to your Gmail and calendar and
        prevent any further email tracking.
      </DeleteConfirmation>
    </PageLayout>
  )
}
