import { Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { Play } from '../../../types/Play'
import { Apps } from '../../../types/App'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TopBarContent } from '../../ui/TopBarContext'
import { PlayForm } from './components/play-form'

export default function New({ apps }: { apps: Apps }) {
  const play: Partial<Play> = {}

  return (
    <PageLayout>
      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/plays'), title: 'Plays' },
            { path: projectPath('/plays/new'), title: 'New Play', isCurrentPage: true }
          ]}
        />
      </TopBarContent>

      <Stack spacing={1}>
        <PageTitle>Create New Play</PageTitle>
        <PageDescription>Tell us what your play should look like.</PageDescription>
      </Stack>

      <Stack as={'form'} spacing="6" action={projectPath('/plays')} method="post">
        <PlayForm play={play} apps={apps} />

        <Button alignSelf="flex-start" type="submit" colorScheme={'purple'}>
          Create Play
        </Button>

        <AuthenticityToken />
      </Stack>
    </PageLayout>
  )
}
