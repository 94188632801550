import { Circle, Flex, FlexProps, HStack, Icon, Link, Stack, Tooltip, Text } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'
import Avatar from './Avatar'
import { LinkedinBoxIcon } from './icons'

type Props = FlexProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    src?: string | null
    name?: string | null
    email?: string | null
    iconRight?: boolean
    showAvatar?: boolean
    linkedinUrl?: string | null
    waterfallEnabled?: boolean
  }

const hoverStyle = {
  background: 'white',
  shadow: 'sm',

  '& .hover-icon': {
    display: 'flex',
    opacity: 1
  }
}

export function PersonBubble({
  src,
  name,
  email,
  href,
  iconRight,
  showAvatar = true,
  linkedinUrl,
  waterfallEnabled,
  ...rest
}: Props) {
  return (
    <Flex
      as={href ? 'a' : undefined}
      href={href}
      display="inline-flex"
      alignItems="center"
      gap={1.5}
      rounded="lg"
      paddingLeft="3px"
      paddingRight={href ? '5px' : '3px'}
      marginLeft="-3px"
      marginRight={href ? '-5px' : '-3px'}
      paddingY="3px"
      maxW="100%"
      isTruncated
      fontWeight="medium"
      _hover={hoverStyle}
      title={name || email || undefined}
      {...rest}
    >
      {showAvatar !== false && <Avatar size="tiny" name={name} src={src} />}

      <Stack spacing={0}>
        <HStack>
          <Text fontSize="sm" minWidth="40px" lineHeight="1.2" fontWeight="inherit" isTruncated>
            {name || email}
          </Text>
          {waterfallEnabled && linkedinUrl && (
            <Tooltip label={`https://${linkedinUrl.replace(/https?:\/\//, '')}`}>
              <Link
                display="flex"
                flex="none"
                alignItems="center"
                color="linkedin.700"
                isExternal
                href={`https://${linkedinUrl.replace(/https?:\/\//, '')}`}
                onClick={(e) => e.stopPropagation()}
              >
                <LinkedinBoxIcon boxSize="18px" />
              </Link>
            </Tooltip>
          )}
        </HStack>
        {waterfallEnabled && name && email && (
          <Text fontWeight="normal" color="gray.500" fontSize="xs">
            {email}
          </Text>
        )}
      </Stack>

      {iconRight !== false && href && (
        <Circle flex="none" className="hover-icon" display="none" opacity={0} size="18px" bg="gray.100">
          <Icon as={IconArrowRight} boxSize={3.5} color="gray.600" />
        </Circle>
      )}
    </Flex>
  )
}
