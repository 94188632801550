import { Box, Center, Collapse, Flex, Heading, IconButton, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import router from '../../../../lib/router'
import { Play } from '../../../../types/Play'
import { usePlay } from '../../../data/use-plays'
import { projectPath } from '../../../ui/ProjectsContext'
import { Card } from '../../../ui/Card'
import { User } from '../../../ui/UserContext'
import { PlayItemsTable } from '../../plays/components/play-items-table'

interface PlayCardProps {
  play: Play
  selectedUser?: Partial<User> | null
}

export function PlayCard({ play, selectedUser }: PlayCardProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
  const { data, isLoading } = usePlay(play.id, selectedUser?.id)

  return (
    <Card p={0}>
      <Flex
        alignItems="center"
        gap={2}
        paddingX={3}
        paddingY={4}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        onClick={onToggle}
        cursor="pointer"
      >
        <IconButton
          size="sm"
          variant="ghost"
          rounded="full"
          aria-label="Toggle"
          icon={isOpen ? <IconChevronDown size={16} /> : <IconChevronRight size={16} />}
          onClick={onToggle}
        />

        <Stack flex="1" spacing={0}>
          <Heading size="sm">
            {play.name} {data?.stats?.pending ? `(${data.stats.pending.toLocaleString()})` : ''}
          </Heading>
          <Text fontSize="13px" color="gray.500">
            {play.description}
          </Text>
        </Stack>

        <IconButton
          size="sm"
          variant="ghost"
          rounded="full"
          aria-label="View play details"
          icon={<IconExternalLink size={16} />}
          onClick={(e) => {
            e.stopPropagation()
            let path = projectPath(`/plays/${play.id}`)

            if (selectedUser?.id) {
              path += `?user=${selectedUser.id}`
            }

            router.visit(path)
          }}
        />
      </Flex>

      <Collapse in={isOpen}>
        <Box>
          {isLoading && (
            <Center height="100px">
              <Spinner />
            </Center>
          )}

          {!isLoading && (
            <PlayItemsTable items={data?.play_items ?? []} recordType={play.target_type} status="pending" />
          )}
        </Box>
      </Collapse>
    </Card>
  )
}
