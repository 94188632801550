import {
  Button,
  FormControl,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React, { useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import router from '../../../../lib/router'
import { GrayCard } from '../../../ui/Card'
import { CompanySelector, PartialCompany } from '../../../ui/CompanySelector'

interface MergeCompanyModalProps {
  isOpen: boolean
  onClose: () => void
  domain: string
}

export function MergeCompanyModal({ isOpen, onClose, domain }: MergeCompanyModalProps) {
  const [primaryCompany, setPrimaryCompany] = useState<PartialCompany | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleMerge = async () => {
    if (!primaryCompany) return

    setIsLoading(true)
    try {
      await post(`/admin/kdb/${domain}/merge`, {
        primary_domain: primaryCompany.domain
      })

      toast.success('Started company merge!', { description: 'Redirecting you to the primary company page...' })

      setIsLoading(false)
      onClose()

      // Redirect to the primary company's page
      router.visit(`/admin/kdb/${primaryCompany.domain}`)
    } catch (error: any) {
      setIsLoading(false)

      toast.error('Failed to merge companies', {
        description: error?.body?.error || error?.message || 'An unknown error occurred'
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Merge Company</ModalHeader>
        <ModalBody>
          <VStack spacing={6}>
            <Text fontSize="sm">
              Select the primary company to merge <strong>{domain}</strong> into. Data will be merged, and accounts will
              be repointed to the primary company.
            </Text>

            <FormControl>
              <CompanySelector
                selectedCompany={primaryCompany}
                onChange={(company) => {
                  setPrimaryCompany(company)
                }}
                showClearButton
              />
            </FormControl>

            <GrayCard width="100%" border="1px solid" borderColor="gray.200" py={4} px={4}>
              <Grid
                width="100%"
                columnGap={4}
                fontWeight="medium"
                alignItems="center"
                gridTemplateColumns="1fr 20px 1fr"
              >
                <Stack textAlign="center" spacing={1}>
                  <Text fontSize="10px" color="gray.500" textTransform="uppercase">
                    Domain Alias
                  </Text>
                  <Text fontSize="sm">{domain}</Text>
                </Stack>
                <Icon as={IconArrowRight} boxSize={4} color="gray.600" />
                <Stack textAlign="center" spacing={1}>
                  <Text fontSize="10px" color="gray.500" textTransform="uppercase">
                    Primary Company
                  </Text>
                  <Text fontSize="sm" color={primaryCompany ? 'inherit' : 'gray.400'}>
                    {primaryCompany?.domain || '...'}
                  </Text>
                </Stack>
              </Grid>
            </GrayCard>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="red"
            onClick={handleMerge}
            isLoading={isLoading}
            isDisabled={!primaryCompany || primaryCompany.domain === domain}
          >
            Merge Companies
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
