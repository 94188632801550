import { DetailsCard } from '@app/components/ui/Card'
import MarkdownText from '@app/components/ui/MarkdownText'
import { post } from '@app/lib/api'
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Portal,
  SkeletonText,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import {
  IconArrowLeft,
  IconArrowRight,
  IconClockPlay,
  IconMailPlus,
  IconMinus,
  IconSparkles,
  IconTrash,
  IconWand,
  IconX
} from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import GoogleLogo from '../pages/devise/registrations/components/google.svg'
import { AutosizedTextarea } from './AutosizedTextarea'
import { ConfirmDialog } from './ConfirmDialog'
import { getCurrentProject, projectPath } from './ProjectsContext'

interface EmailVariationResponse {
  email: {
    content?: string
    subject?: string
  }
}

interface EmailProps {
  to?: string
  subject?: string
  body?: string
}

// Define a type for the settings
interface EmailSettingsType {
  tone: string
  sentences: number
  additionalNotes: string
}

// Add this interface near the top with other interfaces
interface EmailVersion {
  subject?: string
  body?: string
  isAiGenerated?: boolean
  timestamp: number
}

const EmailSettings = ({
  settings,
  onSettingsChange,
  email,
  onEmailUpdate,
  accountId
}: {
  settings: EmailSettingsType
  onSettingsChange: (newSettings: EmailSettingsType) => void
  email: EmailProps
  onEmailUpdate: (updates: Partial<EmailProps>) => void
  accountId: string
}) => {
  const [wordCount, setWordCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [editableEmail, setEditableEmail] = useState<Partial<EmailProps>>({})
  const [emailVersions, setEmailVersions] = useState<EmailVersion[]>([])
  const [currentVersionIndex, setCurrentVersionIndex] = useState<number>(0)

  const MAX_WORDS = 30

  const handleSettingsChange = (field: keyof EmailSettingsType, value: string | number) => {
    const newSettings = { ...settings, [field]: value }
    onSettingsChange(newSettings)
  }

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0)
    if (words.length <= MAX_WORDS) {
      handleSettingsChange('additionalNotes', text)
      setWordCount(words.length)
    }
  }

  const handleGenerateEmail = async () => {
    setLoading(true)
    const { tone, sentences, additionalNotes } = settings
    const message = email?.body

    // Save current version before generating new one
    const currentVersion: EmailVersion = {
      subject: email.subject,
      body: email.body,
      isAiGenerated: false,
      timestamp: Date.now()
    }

    if (!emailVersions.length) {
      setEmailVersions([currentVersion])
    }

    await post<EmailVariationResponse>(projectPath('/inbox/email-variations'), {
      settings: {
        tone,
        sentences,
        message,
        additional_notes: additionalNotes,
        account_id: accountId
      }
    })
      .then((response) => {
        if (response.email.content || response.email.subject) {
          const generatedEmail = {
            body: response.email.content,
            subject: response.email.subject
          }

          // Add new AI version to history
          const newVersion: EmailVersion = {
            ...generatedEmail,
            isAiGenerated: true,
            timestamp: Date.now()
          }

          setEmailVersions((prev) => [...prev, newVersion])
          setCurrentVersionIndex(emailVersions.length)
          setEditableEmail(generatedEmail)
          setShowPreview(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleVersionChange = (index: number) => {
    setCurrentVersionIndex(index)
    const version = emailVersions[index]
    setEditableEmail({
      subject: version.subject,
      body: version.body
    })
    setShowPreview(true)
  }

  const handleEditableChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setEditableEmail((prev) => ({ ...prev, [name]: value }))
  }

  const handleConfirm = () => {
    onEmailUpdate(editableEmail)
    setShowPreview(false)
    setEditableEmail({})
  }

  const handleReject = () => {
    setShowPreview(false)
  }

  return (
    <Stack spacing={4} w="100%" position="relative" minH="100%">
      {loading && <SkeletonText noOfLines={10} />}
      {showPreview && (
        <>
          <DetailsCard p={4} borderColor="purple.500" borderWidth="1px">
            <VStack align="stretch" spacing={3}>
              <Heading size="xs" fontWeight={'semibold'}>
                AI Generated Suggestion
              </Heading>
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="bold">
                  Subject:
                </FormLabel>
                <Input size="sm" name="subject" value={editableEmail.subject || ''} onChange={handleEditableChange} />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="bold">
                  Message:
                </FormLabel>
                <AutosizedTextarea
                  size="sm"
                  name="body"
                  value={editableEmail.body || ''}
                  onChange={handleEditableChange}
                  minHeight="100px"
                />
              </FormControl>
              <HStack spacing={2} justify="flex-end">
                <Button size="sm" onClick={handleReject}>
                  Reject
                </Button>
                <Button size="sm" colorScheme="purple" onClick={handleConfirm}>
                  Accept
                </Button>
              </HStack>
            </VStack>
          </DetailsCard>
          {showPreview && emailVersions.length > 0 && (
            <Flex mb={4} justifyContent="flex-end">
              <HStack spacing={2} mb={2}>
                <Text fontSize="xs" color="gray.500">
                  Version History ({currentVersionIndex + 1}/{emailVersions.length}):
                </Text>
                <IconButton
                  size="xs"
                  isDisabled={currentVersionIndex === 0}
                  onClick={() => handleVersionChange(currentVersionIndex - 1)}
                  icon={<Icon as={IconArrowLeft} size={'12'} />}
                  aria-label="Previous"
                />
                <IconButton
                  size="xs"
                  isDisabled={currentVersionIndex === emailVersions.length - 1}
                  onClick={() => handleVersionChange(currentVersionIndex + 1)}
                  icon={<Icon as={IconArrowRight} size={'12'} />}
                  aria-label="Next"
                />
              </HStack>
            </Flex>
          )}
        </>
      )}

      <FormControl w="100%">
        <FormLabel>Choose style</FormLabel>
        <HStack spacing={1} align="center" justify="center">
          {['professional', 'friendly', 'casual'].map((tone) => {
            return (
              <Button
                key={tone}
                onClick={() => handleSettingsChange('tone', tone)}
                variant={settings.tone === tone ? 'solid' : 'outline'}
                colorScheme="gray"
                h="auto"
                p={3}
                size="sm"
                w="33%"
              >
                {tone.charAt(0).toUpperCase() + tone.slice(1)}
              </Button>
            )
          })}
        </HStack>
      </FormControl>

      <FormControl>
        <FormLabel>Number of sentences</FormLabel>
        <Slider
          value={settings.sentences || 1}
          min={1}
          max={5}
          step={1}
          onChange={(value) => handleSettingsChange('sentences', value)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text fontSize="sm" color="gray.600" mt={1}>
          {settings.sentences} sentence{settings.sentences !== 1 ? 's' : ''}
        </Text>
      </FormControl>

      <FormControl>
        <FormLabel>Extra instructions</FormLabel>
        <Textarea size="sm" value={settings.additionalNotes} onChange={handleNotesChange} resize="none" rows={4} />
        <Text fontSize="xs" mt={1} color={wordCount >= MAX_WORDS ? 'red.500' : 'gray.600'}>
          {MAX_WORDS - wordCount} words remaining
        </Text>
      </FormControl>

      <Button
        onClick={handleGenerateEmail}
        // colorScheme="purple"
        size="md"
        variant="outline"
        loadingText="Generating..."
        isLoading={loading}
        leftIcon={<Icon as={IconSparkles} />}
      >
        Generate Email
      </Button>
    </Stack>
  )
}

interface EmailComposerProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  email?: EmailProps
  onRequestAiSuggestions?: () => void
  onRequestRewrite?: () => void
}

const EmailComposer = ({ onChange, email, onRequestAiSuggestions, onRequestRewrite }: EmailComposerProps) => {
  return (
    <Flex flex="1 0 auto" flexDirection="column" gap={4} fontSize="sm">
      <FormControl>
        <FormLabel fontSize="xs">To</FormLabel>
        <Input
          size="sm"
          fontWeight="medium"
          name="to"
          value={email?.to || ''}
          onChange={onChange}
          placeholder="recipient@example.com"
          isReadOnly
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="xs">Subject</FormLabel>
        <Input
          size="sm"
          fontWeight="medium"
          name="subject"
          value={email?.subject || ''}
          onChange={onChange}
          placeholder="Email subject"
        />
      </FormControl>
      <FormControl display="flex" flexDirection="column" flex="1 1 auto">
        <Flex justify="space-between" align="center" mb={1.5}>
          <FormLabel fontSize="xs" mb={0}>
            Message
          </FormLabel>
          <Flex gap={2}>
            {onRequestRewrite && (
              <Button
                size="xs"
                variant="ghost"
                colorScheme="purple"
                leftIcon={<Icon as={IconWand} boxSize={3.5} />}
                iconSpacing={1.5}
                onClick={onRequestRewrite}
              >
                Rewrite with AI
              </Button>
            )}
            {onRequestAiSuggestions && (
              <Button
                size="xs"
                variant="ghost"
                colorScheme="purple"
                leftIcon={<Icon as={IconSparkles} boxSize={3.5} />}
                iconSpacing={1.5}
                onClick={onRequestAiSuggestions}
              >
                AI suggestions
              </Button>
            )}
          </Flex>
        </Flex>
        <Textarea
          size="sm"
          name="body"
          value={email?.body || ''}
          onChange={onChange}
          placeholder="Type your message here..."
          minHeight="100px"
          flex="1 1 auto"
        />
      </FormControl>
    </Flex>
  )
}

interface EmailComposerPopupProps {
  connected: boolean
  email?: EmailProps
  suggestedEmails?: EmailProps[]
  disabled?: boolean
  onEmailSent?: () => void
  accountId?: string
  buttonProps?: ButtonProps
}

export function EmailComposerPopup({
  connected = false,
  email,
  suggestedEmails,
  disabled,
  onEmailSent,
  accountId,
  buttonProps
}: EmailComposerPopupProps) {
  const [sidePanel, setSidePanel] = useState<'suggestions' | 'rewrite' | null>(null)
  const [isMinimized, setIsMinimized] = useState(false)
  const [sending, setSending] = useState(false)

  const openSuggestionsPanel = useCallback(() => setSidePanel('suggestions'), [])
  const openRewritePanel = useCallback(() => setSidePanel('rewrite'), [])

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      setIsMinimized(false)

      if (suggestedEmails?.length) {
        openSuggestionsPanel()
      }
    }
  })
  const [currentEmail, setCurrentEmail] = useState(email)
  const currentProjectSlug = getCurrentProject()?.slug
  const [settings, setSettings] = useState<EmailSettingsType>({
    tone: 'professional',
    sentences: 2,
    additionalNotes: ''
  })

  const requestGmailScope = async () => {
    try {
      const returnTo = encodeURIComponent(window.location.pathname)
      window.location.href = `/auth/gmail?project=${currentProjectSlug}&app_id=gmail&state=${returnTo}`
    } catch (error) {
      console.error('Error requesting Gmail scope:', error)
      toast.error('Failed to request Gmail permissions')
    }
  }

  const handleSend = useCallback(async () => {
    setSending(true)

    await post(projectPath('/apps/gmail'), {
      email: {
        to: currentEmail?.to,
        subject: currentEmail?.subject,
        body: currentEmail?.body
      },
      account_id: accountId
    })
      .then(() => {
        toast.success('Email sent!')
        onEmailSent?.()
        // reset state and close the composer!
        setCurrentEmail((prev) => ({ ...prev, subject: '', body: '' }))
        setSidePanel(null)
        setIsMinimized(false)
        onClose()
      })
      .catch((error) => {
        const errorMessage = error.body?.message || 'An unexpected error occurred'
        toast.error('Failed to send email', { description: errorMessage })
      })
      .finally(() => {
        setSending(false)
      })
  }, [currentEmail, accountId, onEmailSent, onClose])

  const handleSettingsChange = useCallback((newSettings: EmailSettingsType) => {
    setSettings(newSettings)
  }, [])

  const handleComposerChange = useCallback((e) => {
    const { name, value } = e.target
    setCurrentEmail((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleEmailUpdate = useCallback((updates: Partial<EmailProps>) => {
    setCurrentEmail((prev) => ({ ...prev, ...updates }))
  }, [])

  const confirmReplace = useDisclosure()
  const [selectedSuggestion, setSelectedSuggestion] = useState<EmailProps | null>(null)

  const handleReplaceEmail = useCallback(
    (email: EmailProps) => {
      setSelectedSuggestion(email)
      confirmReplace.onOpen()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmReplace.onOpen]
  )

  const handleConfirmReplace = useCallback(() => {
    if (selectedSuggestion) {
      setCurrentEmail(selectedSuggestion)
      setSelectedSuggestion(null)
    }
  }, [selectedSuggestion])

  return (
    <Box>
      {!connected ? (
        <Button
          onClick={requestGmailScope}
          size="sm"
          variant="outline"
          leftIcon={<Image src={GoogleLogo} boxSize="16px" />}
          {...buttonProps}
        >
          Connect Google Account
        </Button>
      ) : (
        <Button
          onClick={onOpen}
          title="Start a draft"
          variant="outline"
          size="sm"
          isDisabled={disabled}
          leftIcon={<Icon as={IconMailPlus} boxSize={4} />}
          iconSpacing={1.5}
          {...buttonProps}
        >
          Compose email
        </Button>
      )}

      {isOpen && (
        <Portal>
          <Flex
            position="fixed"
            bottom={0}
            right={4}
            gap={2}
            height={isMinimized ? 'auto' : '700px'}
            maxHeight={['80vh', '70vh']}
            zIndex="popover"
          >
            {!isMinimized && sidePanel === 'suggestions' && (
              <Box
                width="380px"
                height="full"
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                shadow="heavy"
                roundedTop="md"
                display="flex"
                flexDirection="column"
              >
                {/* Header */}
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                  pl={4}
                  pr={2}
                  py={2}
                  borderBottomWidth="1px"
                  borderColor="gray.200"
                  bg="gray.50"
                  roundedTop="md"
                >
                  <Heading size="xs" fontWeight="semibold" display="flex" alignItems="center" gap={2}>
                    <Icon as={IconSparkles} boxSize={4} />
                    AI Suggestions
                  </Heading>

                  <Flex alignItems="center" gap={2}>
                    <IconButton
                      icon={<Icon as={IconX} boxSize={4} />}
                      size="xs"
                      variant="ghost"
                      aria-label="Close"
                      onClick={() => setSidePanel(null)}
                    />
                  </Flex>
                </Flex>

                <Box flex="1 1 auto" p={4} overflow="auto" cursor="pointer">
                  {(suggestedEmails || []).map((email) => (
                    <DetailsCard
                      key={email.subject}
                      borderColor={selectedSuggestion?.subject == email.subject ? 'purple.500' : 'gray.200'}
                      mb={4}
                      onClick={() => handleReplaceEmail(email)}
                    >
                      <Heading size="xs" fontWeight="semibold">
                        {email.subject}
                      </Heading>
                      <MarkdownText>{email.body || ''}</MarkdownText>
                    </DetailsCard>
                  ))}
                </Box>
              </Box>
            )}

            {!isMinimized && sidePanel === 'rewrite' && (
              <Box
                width="380px"
                height="full"
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                shadow="heavy"
                roundedTop="md"
                display="flex"
                flexDirection="column"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                  pl={4}
                  pr={2}
                  py={2}
                  borderBottomWidth="1px"
                  borderColor="gray.200"
                  bg="gray.50"
                  roundedTop="md"
                >
                  <Heading size="xs" fontWeight="semibold" display="flex" alignItems="center" gap={2}>
                    <Icon as={IconWand} boxSize={4} />
                    Rewrite with AI
                  </Heading>
                  <IconButton
                    icon={<Icon as={IconX} boxSize={4} />}
                    size="xs"
                    variant="ghost"
                    aria-label="Close"
                    onClick={() => setSidePanel(null)}
                  />
                </Flex>

                <Box flex="1 1 auto" p={4} overflow="auto">
                  <EmailSettings
                    settings={settings}
                    onSettingsChange={handleSettingsChange}
                    email={currentEmail || {}}
                    onEmailUpdate={handleEmailUpdate}
                    accountId={accountId || ''}
                  />
                </Box>
              </Box>
            )}

            <Box
              width={isMinimized ? 'auto' : '540px'}
              minWidth={isMinimized ? '300px' : 'auto'}
              maxWidth="calc(100vw - 32px)"
              height={isMinimized ? 'auto' : 'full'}
              bg="white"
              border="1px solid"
              borderColor="gray.200"
              shadow="heavy"
              roundedTop="md"
              display="flex"
              flexDirection="column"
            >
              {/* Header */}
              <Flex
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                pl={4}
                pr={2}
                py={2}
                borderBottomWidth="1px"
                borderColor="gray.200"
                bg="gray.50"
                roundedTop="md"
                cursor="pointer"
                onClick={() => setIsMinimized((prev) => !prev)}
              >
                <Heading size="xs" fontWeight="semibold">
                  New message
                </Heading>

                <Flex alignItems="center" gap={2}>
                  <IconButton
                    icon={<Icon as={IconMinus} boxSize={4} />}
                    size="xs"
                    variant="ghost"
                    aria-label={isMinimized ? 'Maximize' : 'Minimize'}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsMinimized((prev) => !prev)
                      if (!isMinimized) {
                        // Close side panels when minimizing
                        setSidePanel(null)
                      }
                    }}
                  />
                  <IconButton
                    icon={<Icon as={IconX} boxSize={4} />}
                    size="xs"
                    variant="ghost"
                    aria-label="Close"
                    onClick={(e) => {
                      e.stopPropagation()
                      onClose()
                    }}
                  />
                </Flex>
              </Flex>

              {/* Only show body and footer when not minimized */}
              {!isMinimized && (
                <>
                  {/* Existing body section */}
                  <Flex flex="1 1 auto" flexDirection="column" minHeight="200px" px={4} py={2} overflow="auto">
                    <EmailComposer
                      onChange={handleComposerChange}
                      email={currentEmail}
                      onRequestAiSuggestions={suggestedEmails?.length ? openSuggestionsPanel : undefined}
                      onRequestRewrite={openRewritePanel}
                    />
                  </Flex>

                  {/* Existing footer section */}
                  <Flex flex="none" px={4} py={2} borderTop="1px solid" borderColor="gray.200" gap={2}>
                    <Tooltip label="Schedule send (coming soon)">
                      <Box>
                        <Button
                          size="sm"
                          variant="outline"
                          leftIcon={<Icon as={IconClockPlay} boxSize={4} />}
                          iconSpacing={1.5}
                          isDisabled
                        >
                          Schedule send
                        </Button>
                      </Box>
                    </Tooltip>
                    <Button size="sm" colorScheme="blue" onClick={handleSend} isLoading={sending}>
                      Send
                    </Button>
                    <Tooltip label="Discard draft">
                      <IconButton
                        aria-label="Discard draft"
                        size="sm"
                        variant="ghost"
                        onClick={onClose}
                        ml="auto"
                        icon={<Icon as={IconTrash} boxSize={4} />}
                      />
                    </Tooltip>
                  </Flex>
                </>
              )}
            </Box>
          </Flex>
        </Portal>
      )}

      <ConfirmDialog
        isOpen={confirmReplace.isOpen}
        onClose={confirmReplace.onClose}
        onConfirm={handleConfirmReplace}
        title="Replace email content?"
        confirmLabel="Continue"
        isCentered
      >
        This will replace your current email content with the selected suggestion. Are you sure you want to continue?
      </ConfirmDialog>
    </Box>
  )
}
