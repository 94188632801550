import { Box, Divider, Flex, HStack, Icon, IconButton, Stack, Text } from '@chakra-ui/react'
import { IconFileTypeCsv, IconLayoutColumns, IconLayoutRows, IconTrash } from '@tabler/icons-react'
import React from 'react'
import { MiddotDivider } from '../../../ui/Middot'
import SquareIcon from '../../../ui/SquareIcon'
import { fileType } from '../lib/file-type-lable'
import { humanReadableSize } from '../lib/human-file-size'

interface Props {
  columns?: string[]
  rows: any[]
  file: File | null
  onReset: () => void
}

export function SelectedFile({ columns, file, rows, onReset }: Props) {
  if (!file) {
    return null
  }

  return (
    <Box fontSize="sm" bg="white" border="1px solid" borderColor="gray.200" rounded="lg" p={3} maxW="600px">
      <Stack>
        <Flex gap={3} alignItems="center" pr={2}>
          <SquareIcon icon={IconFileTypeCsv} color="gray.500" border="1px solid" borderColor="gray.200" p={2} />
          <Stack flex="1 1 auto" spacing={0.5} isTruncated>
            <Text fontWeight="medium" isTruncated>
              {file.name}
            </Text>
            <HStack fontSize="xs" fontWeight="medium" color="gray.500" divider={<MiddotDivider />} spacing={1}>
              <Text>{fileType(file.type)} file</Text>
              <Text>{humanReadableSize(file.size)}</Text>
            </HStack>
          </Stack>

          <IconButton
            aria-label="Remove file"
            variant="ghost"
            size="xs"
            ml={5}
            icon={<Icon as={IconTrash} boxSize={4} />}
            onClick={onReset}
          />
        </Flex>
        <Divider />
        <Stack spacing={1}>
          {columns && (
            <Flex maxW="100%" gap={1} alignItems="center" color="gray.500" isTruncated>
              <Icon flex="none" as={IconLayoutColumns} boxSize={3.5} />
              <Text flex="none" as="span">
                Columns found:
              </Text>
              <Text
                flex="none"
                as="span"
                color="gray.700"
                fontWeight="semibold"
                css={{ fontVariantNumeric: 'tabular-nums' }}
              >
                {columns.length.toLocaleString()}
              </Text>{' '}
              <Text minW="60px" isTruncated>
                ({columns.join(', ')})
              </Text>
            </Flex>
          )}
          <Flex gap={1} alignItems="center" color="gray.500">
            <Icon as={IconLayoutRows} boxSize={3.5} />
            Rows found:
            <Text as="span" color="gray.700" fontWeight="semibold" css={{ fontVariantNumeric: 'tabular-nums' }}>
              {rows.length.toLocaleString()}
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  )
}
