import {
  Badge,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import {
  IconArrowRightSquare,
  IconCopy,
  IconDotsVertical,
  IconExternalLink,
  IconGauge,
  IconPlus,
  IconTrash
} from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { toast } from 'sonner'
import { del, put } from '../../../lib/api'
import router from '../../../lib/router'
import { PageMeta } from '../../../types/PageMeta'
import { DateTime } from '../../../types/Profile'
import { useBillingMetrics } from '../../data/use-billing-metrics'
import { openUpgradeFlow } from '../../ui/billing-banners/accounts-banner'
import { FeatureLockout } from '../../ui/billing-banners/feature-lockout'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { Iconify } from '../../ui/Iconify'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { useEntitlements } from '../../ui/useEntitlements'
import ActionTemplates from '../automations/components/ActionTemplates'
import { KoalaSubscription } from '../billing/show'
import { AutomationsEntitlements, Usage } from '../billing/v2'
import { friendlyNumber } from '../billing/v3'
import { mergeParams } from '../icps/types'
import { FollowRule } from '../notifications'
import { automationPath, automationsPath } from '../notifications/lib/path-helper'
import { channelLogos } from './components/delivery-setup'

interface Props {
  follow_rules: FollowRule[]
  stats: Record<string, number>
  skipped_stats: Record<string, number>
  timestamps: Record<string, DateTime>
  page_meta: PageMeta
}

export function FollowRulesTable(props: {
  follow_rules: FollowRule[]
  stats?: Record<string, number>
  skipped_stats?: Record<string, number>
  timestamps?: Record<string, DateTime>
}) {
  const hasSkips = useMemo(() => {
    return Object.values(props.skipped_stats ?? {}).some((v) => v > 0)
  }, [props.skipped_stats])

  return (
    <TableContainer fontSize="sm" w="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th isTruncated>Name</Th>
            <Th>Destinations</Th>
            {(props.stats || props.timestamps) && <Th isNumeric>Stats (7d)</Th>}
            {hasSkips && <Th isNumeric>Skipped (7d)</Th>}
            <Th />
          </Tr>
        </Thead>
        <Tbody fontSize={'xs'}>
          {props.follow_rules.map((follow_rule) => (
            <Tr
              key={follow_rule.id}
              _hover={{
                bg: 'gray.50'
              }}
              role="group"
            >
              <Td>
                <HStack spacing="4">
                  <Switch
                    size="sm"
                    defaultChecked={follow_rule.enabled}
                    onChange={async (e) => {
                      await put(automationPath(follow_rule.id, `/status`), {
                        follow_rule: {
                          enabled: e.target.checked
                        }
                      }).then(() => {
                        toast.success(`Automation ${follow_rule.name} ${e.target.checked ? 'enabled' : 'disabled'}`)
                      })
                    }}
                  />
                  <Stack py="6" spacing="1">
                    <Link href={automationPath(follow_rule.id, 'edit')}>
                      <Text maxW="300px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {follow_rule.name}
                      </Text>
                    </Link>
                    <HStack fontSize={'xs'} spacing="1">
                      <Text>
                        <TimeAgo time={follow_rule.updated_at} />
                      </Text>
                      <MiddotDivider />
                      <Text>{follow_rule.recipient?.name}</Text>
                      {follow_rule.managed_by_id && (
                        <Tooltip label="You're seeing this because you're a Koala admin. This is only here for debugging.">
                          <Badge>ADMIN ONLY</Badge>
                        </Tooltip>
                      )}
                    </HStack>
                  </Stack>
                </HStack>
              </Td>
              <Td>
                <Stack fontSize={'xs'}>
                  {Object.keys(follow_rule.delivery_rules ?? {}).map((channel) => {
                    const isDirectEmail = follow_rule.delivery_rules?.email?.to?.includes?.('{{team_member.email}}')
                    const isChannel = follow_rule.slack_channel_name

                    return (
                      <HStack key={channel}>
                        <Iconify icon={channelLogos[channel]} size={16} />
                        {channel === 'email' && (
                          <>
                            {isDirectEmail ? (
                              <Text>{follow_rule.recipient?.email ?? 'Direct Email'}</Text>
                            ) : (
                              <Text>{follow_rule.delivery_rules?.email?.to}</Text>
                            )}
                          </>
                        )}
                        {channel === 'slack' && <>{!!isChannel && <Text>#{isChannel}</Text>}</>}
                      </HStack>
                    )
                  })}
                </Stack>
              </Td>
              {(props.stats || props.timestamps) && (
                <Td isNumeric>
                  {props.stats && props.stats[follow_rule.id] > 0 && (
                    <Link fontSize={'xs'} href={automationPath(follow_rule.id, '/logs')} color="gray.600">
                      {props.stats[follow_rule.id] ?? ''}
                      &times;{' '}
                    </Link>
                  )}

                  {props.timestamps && props.timestamps[follow_rule.id] && (
                    <Link href={automationPath(follow_rule.id, '/logs')} color="gray.500" fontSize={'xs'}>
                      (<TimeAgo time={props.timestamps[follow_rule.id]} />)
                    </Link>
                  )}

                  {!props.stats?.[follow_rule.id] && !props.timestamps?.[follow_rule.id] && <Text>--</Text>}
                </Td>
              )}
              {hasSkips && (
                <Td isNumeric>
                  {props.skipped_stats && props.skipped_stats[follow_rule.id] > 0 && (
                    <Text fontSize={'xs'} color="gray.500">
                      {props.skipped_stats[follow_rule.id] ?? ''}
                      &times;{' '}
                    </Text>
                  )}

                  {!props.skipped_stats?.[follow_rule.id] && <Text>--</Text>}
                </Td>
              )}
              <Td>
                <HStack>
                  <Menu size="sm" autoSelect={false} placement="bottom-end">
                    <MenuButton
                      as={IconButton}
                      aria-label="Actions"
                      icon={<IconDotsVertical size={16} />}
                      variant="ghost"
                      size="xs"
                    />
                    <MenuList zIndex="popover">
                      <MenuItem
                        as="a"
                        icon={<IconCopy size={16} />}
                        href={automationsPath(`/new?from_rule=${follow_rule.id}`)}
                      >
                        Clone
                      </MenuItem>
                      <MenuItem
                        icon={<IconTrash size={16} />}
                        onClick={async (e) => {
                          e.preventDefault()

                          if (confirm('Are you sure?')) {
                            await del(automationPath(follow_rule.id)).then(() => {
                              toast.success('Rule deleted')
                              router.visit(automationsPath())
                            })
                          }
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

interface AutomationLimitWarningProps {
  entitlements?: AutomationsEntitlements
  usage: Usage
  subscription?: KoalaSubscription
}

export function AutomationLimitWarning(props: AutomationLimitWarningProps) {
  const limit = props.entitlements?.automation_action_runs ?? 0
  const used = props.usage.automation_actions?.current?.delivered ?? 0
  const skipped = props.usage.automation_actions?.current?.limited ?? 0

  const overLimit = useMemo(() => limit && used >= limit, [limit, used])
  const recurring = useMemo(() => props.subscription?.plan && props.subscription?.plan !== 'free', [props.subscription])

  const hasUnlimitedAutomations = useMemo(() => {
    return (
      props.entitlements?.automations ||
      props.entitlements?.automations_preview ||
      !props.entitlements?.automation_action_runs
    )
  }, [props.entitlements])

  const progressPct = useMemo(() => {
    if (!used || !limit) {
      return 0
    }

    return Math.round((used / limit) * 100)
  }, [used, limit])

  if (hasUnlimitedAutomations) {
    return null
  }

  const colorScheme = progressPct >= 100 ? 'red' : progressPct >= 90 ? 'orange' : 'purple'
  const almostOut = progressPct >= 90

  return (
    <Card bg="white" alignItems="flex-start" rounded="lg" w="100%" p={0}>
      <Stack spacing={0} w="100%">
        {almostOut && (
          <HStack
            spacing={3}
            fontSize="sm"
            paddingX={4}
            paddingY={3}
            bg="orange.50"
            color="orange.900"
            fontWeight="medium"
            roundedTop="lg"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <CircleIcon icon={IconGauge} iconSize={5} padding={2} bg="orange.200" color="orange.900" />
            <Text>
              Your workspace is {!overLimit ? 'almost ' : ''}out of Action credits
              {recurring ? ' this billing period' : ''}.{' '}
              <Button
                variant="link"
                color="inherit"
                fontWeight="semibold"
                rounded="none"
                onClick={() => openUpgradeFlow()}
                borderBottom="1px dotted"
                _hover={{ textDecoration: 'none' }}
              >
                Upgrade
              </Button>{' '}
              to unlock more.
            </Text>
          </HStack>
        )}
        <Flex
          w="100%"
          gap={[3, 4, 6, 10]}
          px={4}
          py={4}
          pl={almostOut ? 16 : 4}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={2} flex="1" minW="300px" maxW="700px" py={1}>
            <Flex gap={2} justifyContent="space-between" alignItems="baseline" lineHeight={1}>
              <Text flex="none" fontSize="sm" fontWeight="semibold">
                Action credits
              </Text>

              <Flex gap={2} alignItems="baseline" fontSize="xs">
                {skipped > 0 && (
                  <>
                    <Tooltip label="These are the number of actions that were skipped due to your credit limit.">
                      <Text
                        cursor="help"
                        color="gray.500"
                        borderBottom="1px dotted transparent"
                        _hover={{ borderBottom: '1px dotted currentColor' }}
                      >
                        {friendlyNumber(skipped)} skipped
                      </Text>
                    </Tooltip>
                    <MiddotDivider />
                  </>
                )}

                <Flex flex="none" gap={1} alignItems="baseline">
                  <Text fontWeight="semibold">{friendlyNumber(used)}</Text>
                  <Text display={['block', 'block', 'none']} color="gray.500">
                    / {friendlyNumber(limit)}
                  </Text>
                  <Text display={['none', 'none', 'block']} color="gray.500">
                    of {friendlyNumber(limit)} {recurring ? 'actions/mo' : 'trial credits'}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Progress colorScheme={colorScheme} bg="gray.200" value={used} max={limit} rounded="md" size="xs" />
          </Stack>

          <Button
            flex="none"
            colorScheme={progressPct >= 90 ? 'purple' : undefined}
            variant={progressPct >= 90 ? 'solid' : 'outline'}
            size="sm"
            onClick={() => {
              openUpgradeFlow()
            }}
          >
            Unlock more credits
          </Button>
        </Flex>
      </Stack>
    </Card>
  )
}

export default function FollowRules(props: Props) {
  const metrics = useBillingMetrics({ automations: true })
  const metricsData = useMemo(() => metrics.data, [metrics.data])
  const project = useCurrentProject()
  const entitlements = useEntitlements()
  const subscription = metricsData?.koala_subscription

  const limit = project?.automation_rate_limits_enabled
    ? (metricsData?.entitlements?.automation_action_runs ?? 0)
    : null

  const canAccess = useMemo(
    () => (typeof limit === 'number' && limit > 0) || entitlements?.automations,
    [limit, entitlements?.automations]
  )

  return (
    <PageLayout size="md">
      <Breadcrumb
        paths={[
          { path: projectPath('/automations/overview'), title: 'Automations' },
          { path: automationsPath(), title: 'Actions' }
        ]}
      />

      <HStack justifyContent={'space-between'}>
        <PageTitle>Actions</PageTitle>

        {props.follow_rules.length > 0 && (
          <Button
            as={Link}
            href={automationsPath('/new')}
            size="sm"
            colorScheme={'purple'}
            leftIcon={<IconPlus size="14" />}
          >
            New Action
          </Button>
        )}
      </HStack>

      <Stack spacing="8">
        {props.follow_rules.length > 0 ? (
          <>
            {!!limit && metricsData?.usage && (
              <AutomationLimitWarning
                entitlements={metricsData?.entitlements}
                usage={metricsData.usage}
                subscription={subscription}
              />
            )}

            <FollowRulesTable
              follow_rules={props.follow_rules}
              stats={props.stats}
              timestamps={props.timestamps}
              skipped_stats={props.skipped_stats}
            />

            <TableFooter
              pageMeta={props.page_meta}
              page={props.page_meta.current_page}
              prevPath={mergeParams(window.location.toString(), {
                page: props.page_meta.prev_page?.toString()
              })}
              nextPath={mergeParams(window.location.toString(), {
                page: props.page_meta.next_page?.toString()
              })}
              sticky
            />
          </>
        ) : (
          <>
            {canAccess && (
              <>
                <EmptyState limit={limit} />
                <ActionTemplates />
              </>
            )}

            {!canAccess && <RequestAccessState />}
          </>
        )}
      </Stack>
    </PageLayout>
  )
}

interface EmptyStateProps {
  limit: number | null
}

function RequestAccessState() {
  return (
    <FeatureLockout
      locked
      blockTitle="Actions is not available on your plan"
      upgradeTo="business"
      blockedChildren={
        <Text fontSize="sm" textAlign="center" color="gray.600">
          Actions is the easy way to sync Koala data wherever you need it. Trigger actions from intent signals to
          automate your sales motion across your sales stack.{' '}
        </Text>
      }
    />
  )
}

function EmptyState(props: EmptyStateProps) {
  return (
    <Stack
      width="full"
      rounded="lg"
      bg="gray.50"
      border="1px solid"
      borderColor="gray.200"
      padding="40px"
      alignItems="center"
      spacing={6}
    >
      <CircleIcon icon={IconArrowRightSquare} iconSize={7} padding={2} colorScheme="purple" />
      <Stack alignItems="center" spacing={1} maxW="650px">
        <Heading size="md">Actions: Sync intent anywhere</Heading>
        <Text fontSize="sm" textAlign="center" color="gray.600">
          Actions is the easy way to sync Koala data wherever you need it. Trigger actions from intent signals to
          automate your sales motion across your sales stack.{' '}
        </Text>
        {typeof props.limit === 'number' && props.limit > 0 && (
          <Text fontSize="sm" textAlign="center" color="gray.600" pt={4}>
            Your plan includes {props.limit.toLocaleString()} Action credits to get you started.{' '}
            <Link
              display="inline-flex"
              alignItems="center"
              gap={1}
              href="https://getkoala.com/docs/automations/credits"
              isExternal
              borderBottom="1px dotted"
              _hover={{ textDecoration: 'none' }}
            >
              Learn more
              <IconExternalLink size={14} />
            </Link>
          </Text>
        )}
      </Stack>
      <Button as={Link} href={automationsPath('/new')} leftIcon={<IconPlus size={14} />} size="sm" colorScheme="purple">
        Create your first Action
      </Button>
    </Stack>
  )
}
