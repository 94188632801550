import type { AnonymousProfile, QualificationResult } from './collect'
import { IgnoredForm } from '../analytics/forms'
import { fetchJson } from './fetch'

interface SdkSettings {
  autocapture?: boolean
  autotrack_arcade?: boolean
  autotrack_calendly?: boolean
  autotrack_drift?: boolean
  autotrack_intercom?: boolean
  autotrack_navattic?: boolean
  autotrack_qualified?: boolean
  autotrack_fullstory?: boolean
  autotrack_posthog_screen_recording?: boolean
  segment_auto_track?: 'on' | 'off'
  form_collection?: 'on' | 'off'
  websocket_connection?: 'on' | 'off'
  querystring_collection?: 'on' | 'off' | null | undefined
  authorized_referrers?: string[]
  ignored_forms?: IgnoredForm[]
  geo_allowed?: boolean
  cookie_defaults?: {
    domain?: string
  }
}

export interface BootstrapData {
  profile_id: string
  qualification: QualificationResult
  sdk_settings: SdkSettings
  edge_api?: boolean
  a?: AnonymousProfile | null
}

export async function bootstrap(publicKey: string, profileId?: string): Promise<BootstrapData> {
  let url = `/web/projects/${publicKey}`
  if (profileId) {
    url += `?profile_id=${profileId}`
  }

  return fetchJson(url)
}
