import {
  Box,
  Button,
  ButtonProps,
  chakra,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackDirection,
  Text,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
  VStack,
  Link,
  HStack
} from '@chakra-ui/react'
import React from 'react'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from './icons'
import { projectPath } from './ProjectsContext'

const RadioBox = chakra('div', {
  baseStyle: {
    flex: '1 1 auto',
    borderWidth: '1.5px',
    borderRadius: 'lg',
    cursor: 'pointer',
    transition: 'all 150ms cubic-bezier(0, 0, 0.2, 1)',
    _disabled: { opacity: 0.6, cursor: 'not-allowed' },
    _focusVisible: {
      boxShadow: 'outline',
      outline: '2px solid transparent',
      outlineOffset: '2px'
    }
  }
})

interface CardRadioProps extends UseRadioProps {
  label: string | React.ReactNode
  description?: string
  icon?: React.ReactNode
  hint?: string
  hintLink?: string
  appModule?: string
  colorScheme?: ButtonProps['colorScheme']
  showUncheckedIcon?: boolean
  checkPlacement?: 'left' | 'right'
  size?: 'sm' | 'md' | 'lg'
}

const padding = {
  sm: 3,
  md: 4,
  lg: 5
}

function CardRadio(props: CardRadioProps) {
  const { label, isDisabled, appModule, icon, description, size = 'md', showUncheckedIcon, checkPlacement } = props
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio(props)
  const id = useId()
  const colorScheme = props.colorScheme ?? 'purple'

  const checkedStyles = {
    borderColor: `${colorScheme}.500`,
    colorScheme: colorScheme
  }

  const hoverStyles = {
    borderColor: 'gray.300',
    shadow: 'sm'
  }

  const content = (
    <label style={{ width: '100%', display: 'flex' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox
        {...getCheckboxProps()}
        _hover={isDisabled ? undefined : hoverStyles}
        _checked={checkedStyles}
        id={id}
        bg="white"
        tabIndex={0}
        padding={padding[size]}
        display="flex"
        flexDirection="column"
      >
        <Flex
          gap="2"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection={checkPlacement === 'left' ? 'row-reverse' : 'row'}
        >
          <HStack spacing={2}>
            {icon && (
              <Flex flex="none" px={1.5}>
                {icon}
              </Flex>
            )}
            <VStack flex="1" alignItems="left" spacing={size === 'lg' ? 2 : 0.5}>
              {typeof label === 'string' ? (
                <Text
                  fontWeight="medium"
                  fontSize={size === 'lg' ? 'md' : 'sm'}
                  lineHeight={size === 'lg' ? '20px' : '18px'}
                >
                  {label}
                </Text>
              ) : (
                label
              )}
              {description && (
                <Text fontSize={size === 'lg' ? 'sm' : 'xs'} color="gray.600">
                  {description}
                </Text>
              )}
            </VStack>
          </HStack>
          {showUncheckedIcon ? (
            <Icon
              flex="none"
              as={state.isChecked ? CheckboxCircleIcon : CheckboxBlankCircleIcon}
              boxSize="18px"
              color={state.isChecked ? `${colorScheme}.600` : `gray.300`}
            />
          ) : (
            <Box
              display="flex"
              flex="none"
              visibility={state.isChecked ? 'visible' : 'hidden'}
              color={`${colorScheme}.500`}
            >
              <CheckboxCircleIcon size={20} />
            </Box>
          )}
        </Flex>
      </RadioBox>
    </label>
  )

  if (isDisabled && appModule) {
    const appName = appModule?.split('::')[1]
    const snakeCaseApp = appName?.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/^_/, '')
    return (
      <Popover trigger="hover" placement="top">
        <PopoverTrigger>{content}</PopoverTrigger>
        <PopoverContent width="auto" p={3}>
          <PopoverArrow />
          <Stack spacing={2}>
            <Text fontSize="sm">{`${appName} is not configured`}</Text>
            {appModule && (
              <Button as={Link} href={projectPath(`/apps/${snakeCaseApp}`)} size="sm" variant="outline">
                Configure integration
              </Button>
            )}
          </Stack>
        </PopoverContent>
      </Popover>
    )
  } else {
    return content
  }
}

export interface ButtonRadioGroupProps extends UseRadioGroupProps {
  direction?: StackDirection
  gridTemplateColumns?: string
  size?: 'sm' | 'md' | 'lg'
  colorScheme?: ButtonProps['colorScheme']
  showUncheckedIcon?: boolean
  checkPlacement?: 'left' | 'right'
  spacing?: string
  options: Array<{
    icon?: React.ReactNode
    label: string | React.ReactNode
    value: string
    description?: string
    appModule?: string
    isDisabled?: boolean
  }>
}

export function CardRadioGroup(props: ButtonRadioGroupProps) {
  const { options, direction, gridTemplateColumns, size = 'lg', showUncheckedIcon, checkPlacement, ...rest } = props
  const { getRadioProps, getRootProps } = useRadioGroup(rest)
  return (
    <Box
      display="grid"
      gridAutoRows="1fr"
      gridTemplateColumns={
        gridTemplateColumns || (direction === 'column' ? '1fr' : 'repeat(auto-fit, minmax(200px, 1fr))')
      }
      gap={props.spacing || '3'}
      width="100%"
      {...getRootProps()}
    >
      {options.map((option) => {
        if (!option) {
          return null
        }
        return (
          <CardRadio
            key={option.value}
            size={size}
            checkPlacement={checkPlacement}
            showUncheckedIcon={showUncheckedIcon}
            icon={option.icon}
            description={option.description}
            appModule={option.appModule}
            label={option.label}
            colorScheme={props.colorScheme}
            isDisabled={option.isDisabled}
            {...getRadioProps({ value: option.value })}
          />
        )
      })}
    </Box>
  )
}
