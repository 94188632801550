import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconCheck, IconExternalLink, IconPlus, IconSwitchHorizontal, IconTrash, IconUsers } from '@tabler/icons-react'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { AccountView } from '../../../../types/AccountView'
import { Apps } from '../../../../types/App'
import { useAccountViews } from '../../../data/use-account-views'
import { filtersAsText, useFacets } from '../../../data/use-facets'
import { ButtonRadioGroupProps, CardRadioGroup } from '../../../ui/CardRadioGroup'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { BuildingIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { useSearchParams } from '../../../ui/useSearchState'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { validFilter } from '../../accounts/facets/categories'
import { accountViewPath } from '../../account_views/lib/list-paths'
import { FollowRule } from '../../notifications'

interface TerritorySetupProps {
  territoryRules?: FollowRule['territory_rules']
  followRule?: FollowRule
  targetType?: 'Profile' | 'Account'
  apps?: Apps
  onFacetsChange?: (facets: FacetFilters) => void
}

type TerritoryPreset = 'smart-list' | 'custom'

function defaultPreset(rule?: FollowRule): TerritoryPreset {
  if (Object.keys(rule?.territory_rules?.facets || {}).length > 0) {
    return 'custom'
  }

  return 'smart-list'
}

export function TSetup(props: TerritorySetupProps) {
  const { searchParams } = useSearchParams()

  const [filterGroups, setFilterGroups] = React.useState<{ id: number; rules: FacetFilters }[]>([{ id: 1, rules: {} }])
  const [condition, setCondition] = React.useState<'AND' | 'OR'>('OR')

  useEffect(() => {
    const [firstKey] = Object.keys(props.followRule?.territory_rules?.facets ?? {})
    if (firstKey === '_or' || firstKey === '_and') {
      setCondition(firstKey.replace('_', '').toUpperCase() as 'AND' | 'OR')
      const parsedGroups = props.followRule?.territory_rules?.facets?.[firstKey]?.map((group, index) => ({
        id: index + 1,
        rules: group
      }))
      setFilterGroups(parsedGroups)
    } else {
      setFilterGroups([{ id: 1, rules: props.followRule?.territory_rules?.facets || {} }])
    }
  }, [props.followRule?.territory_rules])

  const filteredRules = useCallback(
    (rules) => {
      if (props.targetType === undefined) {
        return rules
      }

      if (rules === null || rules === undefined) {
        return rules
      }

      // filter out any rules where the category doesn't match
      const kind = props.targetType.toLowerCase()
      const filteredFacets: FacetFilters = {}

      Object.keys(rules).forEach((facet) => {
        if (validFilter(kind, facet)) {
          filteredFacets[facet] = rules![facet]
        }
      })

      return filteredFacets
    },
    [props.targetType]
  )

  const [listId, setListId] = React.useState(
    props.followRule?.account_view_id || props.followRule?.territory_rules?.smart_list
  )
  const [preset, setPreset] = React.useState<TerritoryPreset>(defaultPreset(props.followRule))

  const formParams: FollowRule['territory_rules'] = useMemo(() => {
    if (preset === 'smart-list') {
      return {
        smart_list: listId
      }
    }

    let facets: any = filterGroups?.[0]?.rules
    if (filterGroups && filterGroups.length > 1) {
      facets = { [`_${condition.toLowerCase()}`]: filterGroups!.map((group) => group.rules) }
    }

    return { facets: facets || {} }
  }, [preset, filterGroups, listId, condition])

  const targetLabel =
    props.targetType === 'Profile' ? 'Visitors' : props.targetType === 'Account' ? 'Accounts' : 'Accounts or Visitors'

  return (
    <Stack>
      <Stack pb="4">
        <CardRadioGroup
          size="sm"
          value={preset}
          onChange={(e) => {
            // reset filters on change
            setFilterGroups([{ id: 1, rules: {} }])
            setPreset(e as TerritoryPreset)
          }}
          options={
            [
              {
                label: 'Lists',
                value: 'smart-list',
                description: `Use an existing list to define the ${targetLabel} that should match your filter criteria.`
              },
              {
                label: 'Custom Filters',
                value: 'custom',
                description: `Define your own criteria for filtering ${targetLabel}.`
              }
            ].filter(Boolean) as ButtonRadioGroupProps['options']
          }
        />
      </Stack>

      <Flex py="4" direction={'column'} gap="1">
        {preset !== 'smart-list' && (
          <>
            <Flex direction={'row'}>
              {filterGroups && filterGroups.length > 1 && (
                <Flex direction="column" alignItems="center" marginRight={4}>
                  <Box
                    flexGrow={1}
                    borderLeft="2px"
                    width={8}
                    borderTop="2px"
                    borderTopLeftRadius="md"
                    borderColor="gray.200"
                    marginLeft="30px"
                  />
                  <Button
                    size="sm"
                    variant="outline"
                    colorScheme="purple"
                    alignSelf="center"
                    leftIcon={<Icon as={IconSwitchHorizontal} />}
                    onClick={() => {
                      const newCondition = condition === 'AND' ? 'OR' : 'AND'
                      setCondition(newCondition)
                    }}
                    minWidth="80px"
                  >
                    {condition}
                  </Button>
                  <Box
                    flexGrow={1}
                    width={8}
                    borderLeft="2px"
                    borderBottom="2px"
                    borderBottomLeftRadius="md"
                    borderColor="gray.200"
                    marginLeft="30px"
                  />
                </Flex>
              )}

              <Flex direction="column" flexGrow={1}>
                {filterGroups?.map((group) => (
                  <Box
                    key={JSON.stringify({ props, preset, territoryRules: group.rules })}
                    mb={4}
                    backgroundColor="gray.50"
                    borderRadius="md"
                    borderColor="gray.200"
                    borderWidth={1}
                    position="relative"
                  >
                    <Box p={2} paddingRight={filterGroups.length > 1 ? 8 : 2}>
                      <TerritorySetup
                        key={JSON.stringify({ props, preset, territoryRules: group.rules })}
                        {...props}
                        apps={props.apps}
                        territoryRules={{ facets: filteredRules(group.rules) }}
                        onFacetsChange={(appliedFilters) => {
                          const updatedFilterGroups = filterGroups.map((g) =>
                            g.id === group.id ? { ...g, rules: appliedFilters || {} } : g
                          )
                          setFilterGroups(updatedFilterGroups)
                        }}
                      />
                    </Box>
                    {filterGroups.length > 1 && (
                      <IconButton
                        size="sm"
                        variant="ghost"
                        colorScheme="red"
                        position="absolute"
                        top={2}
                        right={1}
                        onClick={() => {
                          setFilterGroups((prevGroups) => prevGroups.filter((g) => g.id !== group.id))
                        }}
                        aria-label="Remove filter group"
                      >
                        <Icon as={IconTrash} size={16} />
                      </IconButton>
                    )}
                  </Box>
                ))}
              </Flex>
            </Flex>
            <Flex justifyContent="flex-end">
              <Button
                mt={2}
                size="sm"
                variant="ghost"
                width="fit-content"
                leftIcon={<IconPlus size={16} />}
                onClick={() => {
                  setFilterGroups((prevGroups) => [
                    ...prevGroups,
                    { id: prevGroups[prevGroups.length - 1].id + 1, rules: {} }
                  ])
                }}
              >
                Add Filter Group
              </Button>
            </Flex>
          </>
        )}
        {preset === 'smart-list' && (
          <SmartListSetup
            listId={listId}
            onListChange={(listId) => {
              setListId(listId)
            }}
          />
        )}

        {searchParams['debug'] && (
          <pre>
            <Text>{JSON.stringify(formParams, null, 2)}</Text>
          </pre>
        )}

        <input type="hidden" name="follow_rule[territory_rules]" value={JSON.stringify(formParams)} />
        <input type="hidden" name="follow_rule[account_view_id]" value={listId || ''} />
      </Flex>
    </Stack>
  )
}

interface SmartListSetupProps {
  listId?: string
  allowEveryone?: boolean
  onListChange: (listId?: string) => void
}

export function SmartListSetup(props: SmartListSetupProps) {
  const { data, isLoading } = useAccountViews()
  const lists = useMemo(() => data?.account_views ?? [], [data?.account_views])
  const selectedList = useMemo(() => lists.find((l) => l.id === props.listId), [props.listId, lists])
  const allowEveryone = props.allowEveryone ?? true

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Stack>
      <FormControl>
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <FormLabel>List</FormLabel>
          {selectedList && (
            <Link
              display="inline-flex"
              alignItems="center"
              gap={1}
              isExternal
              fontSize="xs"
              href={
                selectedList.record_type == 'StaticList'
                  ? projectPath(`/lists/${selectedList.id}`)
                  : accountViewPath(selectedList)
              }
              color="purple.500"
            >
              View "{selectedList.name}"
              <IconExternalLink size={14} />
            </Link>
          )}
        </Flex>
        <ComboboxWithSearch
          items={lists}
          selectedItem={selectedList || (allowEveryone ? everyone : null)}
          onChange={(selectedItem) => {
            props.onListChange(selectedItem?.id ?? undefined)
          }}
          filterItem={(a, val) => a.name.toLowerCase().includes(val)}
          itemToString={(item) => item?.name || ''}
          itemRenderer={AudiencePreview}
          selectButtonRenderer={(props) => <AudiencePreview {...props} fallbackToEveryone={allowEveryone} />}
        />
      </FormControl>

      {selectedList && selectedList.record_type !== 'StaticList' && (
        <FormControl pt={4}>
          <FormLabel>Selected list filters</FormLabel>
          <Stack w="100%">
            <ListFilterPreview key={selectedList.id} {...selectedList} />
          </Stack>
        </FormControl>
      )}
    </Stack>
  )
}

const everyone: AudienceOption = {
  id: '',
  name: 'Everyone',
  summary: 'Any visitor that triggers one of your selected intent signals (except global exclusions)',
  kind: 'account' as const,
  filters: { facets: {} }
}

interface AudienceOption {
  id?: string
  name: string
  summary?: string
  filters: {
    facets?: {}
  }
  kind: 'account' | 'profile'
  class_name?: string
}

interface AudiencePreviewProps {
  item: AudienceOption | null
  selectedItem?: AudienceOption | null
  isToggleButton?: boolean
  fallbackToEveryone?: boolean
}

function AudiencePreview(props: AudiencePreviewProps) {
  const audience = props.item || (props.fallbackToEveryone ? everyone : null)
  const filterSummary = filtersAsText(audience?.filters ?? {})
  const isStaticList = audience?.class_name === 'StaticList'

  if (!audience) {
    return (
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="sm" fontWeight="medium" color="gray.500">
          Select a saved list
        </Text>
      </Flex>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" width="100%">
      <HStack width="100%" spacing={2}>
        <Center flex="none" width={8}>
          <Icon as={audience.kind === 'profile' ? IconUsers : BuildingIcon} color="gray.600" boxSize={5} />
        </Center>
        <Box flex="1">
          <Text fontSize="sm" fontWeight="medium">
            {audience.name}
          </Text>
          <Text fontSize="xs" fontStyle="italic" color="gray.500">
            {isStaticList
              ? audience.kind === 'profile'
                ? 'All people from this list'
                : 'All accounts from this list'
              : filterSummary ||
                audience.summary ||
                `No applicable filters (all ${audience.kind === 'profile' ? 'visitors' : 'accounts'})`}
          </Text>
        </Box>
      </HStack>
      {props.selectedItem?.id === audience.id && (
        <Icon as={IconCheck} flex="none" color="purple.500" boxSize={4} marginRight={2} />
      )}
    </Flex>
  )
}

function ListFilterPreview(list: AccountView) {
  const fakeFacets = useFacets({
    facet_filters: list.filters?.facets ?? {}
  })

  if (Object.keys(list.filters?.facets ?? {}).length === 0) {
    return (
      <Text fontSize="sm" color="gray.500">
        No additional filters applied
      </Text>
    )
  }

  return <FilterPreview {...fakeFacets} kind={list.kind} isEditable={false} />
}

export function TerritorySetup(props: TerritorySetupProps) {
  const { targetType, apps, onFacetsChange, territoryRules } = props

  const territoryFacets = useFacets({
    facet_filters: territoryRules?.facets ?? {},
    facetCloudPath: targetType === 'Profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud',
    onFilterChange: onFacetsChange
  })

  // if the incoming territoryRules change, apply the filters since useFacets doesn't update on props change
  useUpdateEffect(() => {
    const changed = !isEqual(territoryFacets.facetFilters, territoryRules?.facets)
    if (changed) {
      territoryFacets.setFacetFilters(territoryRules?.facets ?? {})
    }
  }, [territoryRules?.facets])

  return (
    <Flex>
      <FilterPreview
        {...territoryFacets}
        kind={targetType === 'Profile' ? 'profile' : 'account'}
        apps={apps}
        canClearFilters={false}
        shouldShowIntentFilters={false}
        shouldShow3rdPartyFilters={false}
      />
    </Flex>
  )
}
