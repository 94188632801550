import { Button, Flex, Heading, Img, Stack } from '@chakra-ui/react'
import { IconLockOpen } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { useCurrentUser } from '../UserContext'
import useUpdateEffect from '../useUpdateEffect'
import { openUpgradeFlow } from './accounts-banner'
import img from './assets/billing-lock.svg'

interface Props {
  locked?: boolean
  img?: string
  children?: React.ReactNode
  blockedChildren: React.ReactNode
  blockTitle?: React.ReactNode
  upgradeTo?: 'business' | 'growth'
}

export function FeatureLockout(props: Props) {
  const user = useCurrentUser()
  const [locked, setLocked] = React.useState(props.locked)

  useUpdateEffect(() => {
    setLocked(props.locked)
  }, [props.locked])

  const onUnlockTemporarily = useCallback(() => {
    if (user.isInternalUser) {
      setLocked(false)
    }
  }, [user.isInternalUser])

  return (
    <>
      {locked && (
        <Stack w="100%" rounded="16px" shadow="sm" borderWidth={'thin'} maxW="700px" mx="auto">
          <Flex
            justify={'center'}
            w="100%"
            rounded={'16px 16px 0 0'}
            background="linear-gradient(180deg, rgba(77, 50, 228, 0.08) 0%, rgba(77, 50, 228, 0.00) 65.62%)"
          >
            <Img w="100%" src={props.img || img} objectFit={'fill'} pb="4" />
          </Flex>
          <Stack
            p="8"
            pt="2"
            px="14"
            justify={'center'}
            textAlign="center"
            spacing="4"
            minH="200px"
            alignItems={'stretch'}
          >
            <Heading size="lg" fontWeight={'semibold'}>
              {props.blockTitle || 'This feature is unavailable on your plan'}
            </Heading>
            <Stack w="100%" color="gray.600" flex="1" py="4">
              {props.blockedChildren}
            </Stack>
            <Flex justify={'center'}>
              {props.upgradeTo !== 'business' && props.upgradeTo && (
                <Button size="md" colorScheme="purple" variant="solid" onClick={() => openUpgradeFlow(props.upgradeTo)}>
                  Upgrade to unlock
                </Button>
              )}
              {props.upgradeTo === 'business' && (
                <Button size="md" colorScheme="purple" variant="solid" onClick={() => openUpgradeFlow(props.upgradeTo)}>
                  Talk to Sales
                </Button>
              )}
            </Flex>

            {user.isInternalUser && (
              <Button
                size="md"
                alignSelf="center"
                variant="outline"
                onClick={onUnlockTemporarily}
                iconSpacing={1.5}
                leftIcon={<IconLockOpen size={16} />}
              >
                Internal Preview
              </Button>
            )}
          </Stack>
        </Stack>
      )}
      {!locked && <>{props.children}</>}
    </>
  )
}
