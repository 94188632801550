import { HStack, FormHelperText, Box, FormLabel, Input, FormControl, VStack, Button, Link } from '@chakra-ui/react'
import React, { useCallback, useRef, useState } from 'react'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
interface Props {
  open_ai_key_present: boolean
}

export default function Configuration({ open_ai_key_present }: Props) {
  const apiKeyRef = useRef<HTMLInputElement>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isChangingKey, setIsChangingKey] = useState(false)

  const defaultValue = open_ai_key_present ? '***********************' : ''

  const handleSubmit = useCallback(() => {
    setIsSaving(true)
  }, [])

  const handleCancel = useCallback(() => {
    setIsChangingKey(false)
    if (apiKeyRef.current) {
      apiKeyRef.current.value = defaultValue
    }
  }, [defaultValue])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          { path: projectPath('/settings/agents'), title: 'Agents' },
          { path: window.location.toString(), title: 'Configuration' }
        ]}
      />

      <SettingsHeader divider={false}>
        <PageTitle size="md">AI Agents Configuration</PageTitle>
        <PageDescription>Configure your AI agents.</PageDescription>
      </SettingsHeader>

      <form method="POST" action={projectPath(`/apps/open_ai`)} onSubmit={handleSubmit}>
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />
        <input type="hidden" name="return_to" value={projectPath('/settings/agents/configuration')} />

        <VStack spacing={6} alignItems="normal">
          <FormControl id="app_instance_settings[access_token]">
            <FormLabel>OpenAI API Key</FormLabel>

            <HStack>
              <Input
                bg="gray.50"
                w="100%"
                type="password"
                disabled={!isChangingKey}
                defaultValue={defaultValue}
                name="app_instance_settings[access_token]"
                ref={apiKeyRef}
                placeholder="sk-..."
              />
              {!isChangingKey && (
                <Button
                  variant={'ghost'}
                  colorScheme="purple"
                  size="sm"
                  onClick={() => {
                    setIsChangingKey(true)
                    if (apiKeyRef.current) {
                      apiKeyRef.current.value = ''
                      apiKeyRef.current.focus()
                    }
                  }}
                >
                  Change
                </Button>
              )}
            </HStack>

            <FormHelperText>
              You can find your Secret API key on the{' '}
              <Link href="https://platform.openai.com/api-keys" isExternal>
                OpenAI API key page
              </Link>
              .
            </FormHelperText>
          </FormControl>

          <Box>
            <Button type="button" size="sm" variant="outline" isDisabled={!isChangingKey} onClick={handleCancel} mr={2}>
              Cancel
            </Button>
            <Button type="submit" size="sm" colorScheme="purple" isLoading={isSaving} isDisabled={!isChangingKey}>
              Save
            </Button>
          </Box>
        </VStack>
      </form>
    </PageLayout>
  )
}
