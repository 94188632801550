import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import {
  IconAlarmSnooze,
  IconArrowBackUp,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconUserOff,
  IconX
} from '@tabler/icons-react'
import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import React, { useCallback, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { toast } from 'sonner'
import dayjs from '../../../../lib/dayjs'
import { PlayItem } from '../../../../types/Play'
import { Company } from '../../../../types/Profile'
import {
  useCompletePlayItem,
  useDisqualifyPlayItem,
  useReassignPlayItem,
  useResetPlayItem,
  useSnoozePlayItem
} from '../../../data/use-plays'
import Avatar from '../../../ui/Avatar'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { CompanyBubble } from '../../../ui/CompanyBubble'
import EmptyState from '../../../ui/EmptyState'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { CustomTable } from '../../../ui/Table'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { UserSelector } from '../../../ui/UserSelector'
import { accountPath } from '../../accounts/lib/account-path'
import { profilePath } from '../../profiles/lib/path'

interface PlayItemsTableProps {
  items: PlayItem[]
  recordType: 'people' | 'companies'
  status?: string | null
  assigneeId?: string | null
  onRemoveItem?: (itemId: string, status: string) => void
  onUpdateItem?: (itemId: string, updatedItem: PlayItem) => void
}

interface TableMeta {
  onComplete?: (itemId: string) => void
  onSnooze?: (itemId: string) => void
  onDisqualify?: (itemId: string) => void
  onReset?: (itemId: string) => void
  onUpdate?: (itemId: string, updatedItem: PlayItem) => void
}

const personColumns: ColumnDef<PlayItem, unknown>[] = [
  {
    id: 'id',
    accessorFn: (row) => row,
    size: 500,
    header: 'Name',
    cell: ({ getValue, table }) => {
      const item = getValue<PlayItem>()
      const record = item?.record
      if (!record) {
        return null
      }

      const meta = table.options.meta as TableMeta

      return (
        <Flex flex="1" minW="400px" alignItems="center" justifyContent="space-between" gap={4} py={1}>
          <Flex flex="1" alignItems="center" gap={2.5} isTruncated>
            <Avatar
              src={
                record.image || record.koala_prospect?.id
                  ? projectPath(`/prospects/${record.koala_prospect.id}/avatar`)
                  : undefined
              }
              name={record.name || record.full_name || record.display_name}
              flex="none"
              size="sm"
            />
            {/* <Box flex="none" position="relative" p={1.5}>
              <CompanyAvatar domain={record.company?.domain} size="36px" />
              <Avatar
                src={
                  record.image || record.koala_prospect?.id
                    ? projectPath(`/prospects/${record.koala_prospect.id}/avatar`)
                    : undefined
                }
                name={record.name || record.full_name || record.display_name}
                size="xs"
                position="absolute"
                right={0}
                bottom={0}
                border="1.5px solid white"
              />
            </Box> */}

            <Stack spacing={0.5} isTruncated>
              <Flex alignItems="center" gap={2} isTruncated>
                <Link
                  display="inline-flex"
                  href={profilePath(record)}
                  isExternal
                  _hover={{ textDecoration: 'underline' }}
                >
                  <TextEllipsis fontSize="sm" fontWeight="medium" maxW="100%" tooltip>
                    {record.name || record.full_name || record.display_name || record.email || 'Anonymous'}
                  </TextEllipsis>
                </Link>

                {record.linkedin_url && (
                  <Tooltip label={`https://${record.linkedin_url.replace(/https?:\/\//, '')}`}>
                    <Link
                      display="flex"
                      flex="none"
                      alignItems="center"
                      color="linkedin.700"
                      isExternal
                      href={`https://${record.linkedin_url.replace(/https?:\/\//, '')}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        window.ko?.track('LinkedIn Profile Visit Action', {
                          app: 'linkedin',
                          email: record.email
                        })
                      }}
                    >
                      <LinkedinBoxIcon boxSize="18px" />
                    </Link>
                  </Tooltip>
                )}
              </Flex>
              <Text fontSize="13px" color="gray.500" isTruncated>
                {record.title || record.email || record.simple_location}
              </Text>
            </Stack>
          </Flex>

          <Flex alignItems="center" gap={2} ml="auto">
            {/* {record.email && mailbox.data?.connected && (
              <EmailComposerPopup
                email={{ to: record.email }}
                connected={mailbox.data?.connected}
                accountId={record.account_id}
                onEmailSent={refetchProfileData}
                buttonProps={{
                  size: 'md',
                  width: 'full'
                }}
              />
            )} */}

            <ButtonGroup size="sm" variant="outline" isAttached>
              {item.status === 'completed' ? (
                <ResetButton itemId={item.id} onSuccess={meta.onReset} />
              ) : (
                <CompleteButton itemId={item.id} onSuccess={meta.onComplete} />
              )}
              <SnoozeButton itemId={item.id} onSuccess={meta.onSnooze} />
              {item.status === 'disqualified' ? (
                <ResetButton itemId={item.id} onSuccess={meta.onReset} />
              ) : (
                <DisqualifyButton itemId={item.id} onSuccess={meta.onDisqualify} />
              )}
            </ButtonGroup>
          </Flex>
        </Flex>
      )
    }
  },
  {
    id: 'company',
    accessorFn: (row) => row.record.company,
    header: 'Company',
    size: 200,
    cell: ({ getValue }) => {
      const company = getValue<Company>()
      return (
        <Box maxW="200px">
          {company && (
            <CompanyBubble
              name={company.name}
              domain={company.domain}
              href={accountPath({ company })}
              target="_blank"
              maxW="180px"
            />
          )}
        </Box>
      )
    }
  },
  {
    id: 'company_hq',
    accessorFn: (row) => row.record.company?.geo,
    header: 'Company HQ',
    size: 200,
    cell: ({ getValue }) => {
      const geo = getValue<Company['geo']>() || {}
      const location = [geo.state || geo.stateCode, geo.country || geo.countryCode].filter(Boolean).join(', ')
      return location
    }
  },
  {
    id: 'assignee',
    accessorFn: (row) => row.user_id,
    header: 'Assigned to',
    size: 200,
    cell: ({ row, table, getValue }) => {
      const itemId = row.original.id
      const assigneeId = getValue<string | null>()
      const meta = table.options.meta as TableMeta

      return <AssigneeSelect itemId={itemId} userId={assigneeId} onUpdate={meta.onUpdate} />
    }
  },
  {
    id: 'enrolled_at',
    accessorFn: (row) => row.created_at,
    header: 'Added',
    size: 200,
    cell: ({ getValue }) => {
      const createdAt = getValue<string>()
      return <TimeAgo time={createdAt} mode="relative" canToggle={false} />
    }
  },
  {
    id: 'empty',
    header: '',
    cell: () => {
      return <Box />
    },
    meta: {
      cellProps: {
        width: 'auto'
      }
    }
  }
]

const companyColumns: ColumnDef<PlayItem, unknown>[] = [
  {
    id: 'id',
    accessorFn: (row) => row,
    size: 500,
    header: 'Name',
    cell: ({ getValue, table }) => {
      const item = getValue<PlayItem>()
      const record = item?.record
      if (!record) {
        return null
      }

      const meta = table.options.meta as TableMeta

      return (
        <Flex flex="1" minW="400px" alignItems="center" justifyContent="space-between" gap={4} py={1}>
          <Flex flex="1" alignItems="center" gap={2.5} isTruncated>
            <CompanyAvatar domain={record.company?.domain} name={record.name || record.domain} flex="none" size="sm" />

            <Stack spacing={0.5} isTruncated>
              <Flex alignItems="center" gap={2} isTruncated>
                <Link
                  display="inline-flex"
                  href={accountPath(record)}
                  isExternal
                  _hover={{ textDecoration: 'underline' }}
                >
                  <TextEllipsis fontSize="sm" fontWeight="medium" maxW="100%" tooltip>
                    {record.company?.name || record.company?.domain}
                  </TextEllipsis>
                </Link>
              </Flex>
              {record.company?.name && (
                <Text fontSize="13px" color="gray.500" isTruncated>
                  {record.company?.domain}
                </Text>
              )}
            </Stack>
          </Flex>

          <Flex alignItems="center" gap={2} ml="auto">
            <ButtonGroup size="sm" variant="outline" isAttached>
              {item.status === 'completed' ? (
                <ResetButton itemId={item.id} onSuccess={meta.onReset} />
              ) : (
                <CompleteButton itemId={item.id} onSuccess={meta.onComplete} />
              )}
              <SnoozeButton itemId={item.id} onSuccess={meta.onSnooze} />
              {item.status === 'disqualified' ? (
                <ResetButton itemId={item.id} onSuccess={meta.onReset} />
              ) : (
                <DisqualifyButton itemId={item.id} onSuccess={meta.onDisqualify} />
              )}
            </ButtonGroup>
          </Flex>
        </Flex>
      )
    }
  },
  {
    id: 'company_hq',
    accessorFn: (row) => row.record.company?.geo,
    header: 'Company HQ',
    size: 200,
    cell: ({ getValue }) => {
      const geo = getValue<Company['geo']>() || {}
      const location = [geo.state || geo.stateCode, geo.country || geo.countryCode].filter(Boolean).join(', ')
      return location
    }
  },
  {
    id: 'linkedin_url',
    accessorFn: (row) => row.record.company?.linkedin_url,
    header: () => {
      return (
        <Flex alignItems="center" gap={1}>
          <LinkedinBoxIcon size={16} color="linkedin.700" />
          <Text>LinkedIn</Text>
        </Flex>
      )
    },
    size: 200,
    cell: ({ getValue }) => {
      const linkedinUrl = getValue<string | null>()

      if (linkedinUrl) {
        return (
          <Link variant="dotted" href={linkedinUrl} isExternal>
            {linkedinUrl.split('/').reverse()[0]}
          </Link>
        )
      } else {
        return null
      }
    }
  },
  {
    id: 'assignee',
    accessorFn: (row) => row.user_id,
    header: 'Assigned to',
    size: 200,
    cell: ({ row, table, getValue }) => {
      const itemId = row.original.id
      const assigneeId = getValue<string | null>()
      const meta = table.options.meta as TableMeta

      return <AssigneeSelect itemId={itemId} userId={assigneeId} onUpdate={meta.onUpdate} />
    }
  },
  {
    id: 'enrolled_at',
    accessorFn: (row) => row.created_at,
    header: 'Added',
    size: 200,
    cell: ({ getValue }) => {
      const createdAt = getValue<string>()
      return <TimeAgo time={createdAt} mode="relative" canToggle={false} />
    }
  },
  {
    id: 'empty',
    header: '',
    cell: () => {
      return <Box />
    },
    meta: {
      cellProps: {
        width: 'auto'
      }
    }
  }
]

export function PlayItemsTable({ items, recordType, status, onUpdateItem, onRemoveItem }: PlayItemsTableProps) {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})

  if (!items?.length) {
    return (
      <EmptyState
        size="sm"
        icon={IconUserOff}
        heading={status ? `No ${status} leads yet` : 'No leads yet'}
        description={status ? `There are no ${status} items in this play yet.` : 'There are no leads in this play yet.'}
      />
    )
  }

  return (
    <CustomTable
      data={items ?? []}
      columns={recordType === 'companies' ? companyColumns : personColumns}
      // enableRowSelection
      selectedRows={selectedRows}
      onRowSelectionChange={setSelectedRows}
      stickyFirstColumn
      meta={{
        onComplete: onRemoveItem,
        onSnooze: onRemoveItem,
        onDisqualify: onRemoveItem,
        onReset: onRemoveItem,
        onUpdate: onUpdateItem
      }}
    />
  )
}

interface ActionButtonProps {
  itemId: string
  onSuccess?: (itemId: string, status: string) => void
}

function ResetButton({ itemId, onSuccess }: ActionButtonProps) {
  const { mutate: resetItem, isLoading } = useResetPlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'pending')
    },
    onError: (error) => {
      toast.error('There was an issue resetting this lead', { description: error.message })
    }
  })

  return (
    <Tooltip label="Undo">
      <IconButton
        aria-label="Reset"
        icon={<IconArrowBackUp size={16} />}
        bg="white"
        onClick={() => resetItem({ itemId })}
        isLoading={isLoading}
      />
    </Tooltip>
  )
}

function CompleteButton({ itemId, onSuccess }: ActionButtonProps) {
  const { mutate: completeItem, isLoading } = useCompletePlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'completed')
    },
    onError: (error) => {
      toast.error('There was an issue marking this lead as complete', { description: error.message })
    }
  })

  return (
    <Tooltip label="Mark Completed">
      <IconButton
        aria-label="Complete"
        icon={<IconCheck size={16} />}
        bg="white"
        onClick={() => completeItem({ itemId })}
        isLoading={isLoading}
      />
    </Tooltip>
  )
}

const snoozePeriods = [
  {
    label: 'in 1 hour',
    value: 'in 1 hour'
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow, 9am',
    // show 9am for the 3-letter day
    preview: () => `${dayjs().add(1, 'day').format('ddd')}, 9:00am`
  },
  {
    label: 'Next week',
    value: 'next week, Mon 9am',
    preview: () => `Mon, 9:00am`
  },
  {
    label: 'Next month',
    value: 'next month, 9am',
    preview: () => `${dayjs().add(1, 'month').format('ddd, MMM DD')}`
  }
]

function SnoozeButton({ itemId, onSuccess }: ActionButtonProps) {
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

  const { mutate: snoozeItem, isLoading } = useSnoozePlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'snoozed')
    },
    onError: (error) => {
      toast.error('There was an issue snoozing this lead', { description: error.message })
    }
  })

  const handleDateSelect = useCallback(() => {
    if (!selectedDate) return

    // Set to 9am on the selected date
    const snoozeDate = dayjs(selectedDate).hour(9).minute(0).second(0)
    snoozeItem({ itemId, until: snoozeDate.format() })
    setIsPickerOpen(false)
  }, [selectedDate, snoozeItem, itemId])

  return (
    <>
      <Menu>
        <Tooltip label="Snooze">
          <MenuButton
            as={IconButton}
            aria-label="Snooze"
            icon={<IconAlarmSnooze size={16} />}
            bg="white"
            mx="-px"
            isLoading={isLoading}
          />
        </Tooltip>
        <Portal>
          <MenuList zIndex="popover">
            {snoozePeriods.map((option) => (
              <MenuItem key={option.label} onClick={() => snoozeItem({ itemId, until: option.value })}>
                {option.label}
                <Text color="gray.400" ml="auto">
                  {option.preview?.()}
                </Text>
              </MenuItem>
            ))}
            <MenuDivider />
            <MenuItem onClick={() => setIsPickerOpen(true)}>Pick a date</MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <Modal size="sm" isOpen={isPickerOpen} onClose={() => setIsPickerOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Snooze until...</ModalHeader>
          <ModalBody pb={6}>
            <DayPicker
              mode="single"
              defaultMonth={dayjs().add(1, 'day').toDate()}
              fromDate={dayjs().add(1, 'day').toDate()}
              toDate={dayjs().add(90, 'days').toDate()}
              selected={selectedDate}
              onSelect={setSelectedDate}
              components={{
                IconLeft: () => <IconChevronLeft size={16} />,
                IconRight: () => <IconChevronRight size={16} />
              }}
              numberOfMonths={1}
            />
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsPickerOpen(false)} mr={3}>
              Cancel
            </Button>
            <Button size="sm" variant="solid" colorScheme="purple" onClick={handleDateSelect}>
              Snooze
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const disqualifyReasons: [string, string][] = [
  ['Wrong territory', 'not_my_territory'],
  ['Not ICP', 'not_in_icp'],
  ['Bad lead', 'bad_lead'],
  ['Already working', 'already_working']
]

function DisqualifyButton({ itemId, onSuccess }: ActionButtonProps) {
  const [feedback, setFeedback] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { mutate: disqualifyItem, isLoading } = useDisqualifyPlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'disqualified')
      setIsModalOpen(false)
    },
    onError: (error) => {
      toast.error('There was an issue disqualifying this lead', { description: error.message })
    }
  })

  const handleDismiss = useCallback(
    (reason: string) => {
      disqualifyItem({ itemId, reason, feedback: reason === 'other' ? feedback : '' })
    },
    [disqualifyItem, itemId, feedback]
  )

  return (
    <>
      <Menu>
        <Tooltip label="Disqualify">
          <MenuButton
            as={IconButton}
            aria-label="Disqualify"
            bg="white"
            icon={<IconX size={16} />}
            isLoading={isLoading}
          />
        </Tooltip>
        <Portal>
          <MenuList>
            {disqualifyReasons.map(([label, reason]) => (
              <MenuItem key={reason} onClick={() => disqualifyItem({ itemId, reason })}>
                {label}
              </MenuItem>
            ))}
            <MenuDivider />
            <MenuItem onClick={() => setIsModalOpen(true)}>Other</MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Disqualify lead</ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              <Text fontSize="sm" color="gray.700">
                Totally optional, we'd love to know why you're disqualifying this lead.
              </Text>
              <Textarea
                placeholder="Share your feedback..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                size="sm"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button size="sm" variant="solid" colorScheme="purple" onClick={() => handleDismiss('other')} ml={3}>
              Disqualify
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

interface AssigneeSelectProps {
  itemId: string
  userId: string | null
  onUpdate?: (itemId: string, updatedItem: PlayItem) => void
}

function AssigneeSelect({ itemId, userId, onUpdate }: AssigneeSelectProps) {
  const reassignMutation = useReassignPlayItem({
    onSuccess: (updatedItem) => {
      onUpdate?.(itemId, updatedItem)
    },
    onError: (error) => {
      toast.error('There was an issue reassigning this lead', { description: error.message })
    }
  })

  const handleChange = useCallback(
    (newUserId: string | null) => {
      reassignMutation.mutate({ itemId, userId: newUserId })
    },
    [reassignMutation, itemId]
  )

  return <UserSelector selectedUserId={userId} onChange={handleChange} allowUnassigned />
}
