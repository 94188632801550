import { FilterPreview } from '@app/components/pages/accounts/components/FilterPreview'
import { TimeAgo } from '@app/components/ui/TimeAgo'
import { AccountView } from '@app/types/AccountView'
import { Apps } from '@app/types/App'
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Square,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconChevronDown,
  IconCircleArrowRight,
  IconCirclePlus,
  IconCopy,
  IconDotsVertical,
  IconEdit,
  IconLayoutColumns,
  IconTrash,
  IconUsers
} from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { ColumnManagementPopover, TableMode } from '../../../ui/ColumnSelector'
import { ConfirmDialog } from '../../../ui/ConfirmDialog'
import DownloadCsvMenuItem from '../../../ui/DownloadCsvButtons'
import { BuildingIcon, SlackIcon } from '../../../ui/icons'
import { MiddotDivider } from '../../../ui/Middot'
import { NumberEasing } from '../../../ui/NumberEasing'
import useLocation from '../../../ui/useLocation'
import useUnsavedChangesPrompt from '../../../ui/useUnsavedChangesPrompt'
import { KoalaWeeklyPreviewAlert, viewingKoalaWeekly } from '../../digests/components/koala-weekly-preview-alert'
import { useListsNav } from '../../lists/components/ListContainer'
import { PlayPauseButton } from '../../profiles/components/play-pause-button'
import { accountViewPath } from '../lib/list-paths'
import { viewChanged } from '../lib/view-changed'
import { AccountViewModal } from './AccountViewForm'
import AccountViewTabs from './AccountViewTabs'
import CloneViewModal from './CloneViewModal'
import DeleteViewConfirmationModal from './DeleteViewConfirmationModal'
import { MoveToSpaceModal } from './MoveToSpaceModal'
import { SlackAlertsSidesheet } from './SlackAlertsSidesheet'

interface Props {
  loading?: boolean
  apps: Apps
  columns?: string[]
  ai_columns?: string[]
  onColumnChange?: (columns: any[]) => void
  columnsChanged?: boolean
  account_view: AccountView
  hideMenu?: boolean
  permissions: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  facets?: UrlFilterParams
  counts?: Record<string, number>
  isLive?: boolean
  paused?: boolean
  setPaused?: (paused: boolean) => void
  defaults?: {
    range?: string
    sort_by?: string
  }
  onDiscardChanges?: () => void
  formRef?: React.RefObject<HTMLFormElement>
}

export default function ViewDetails(props: Props) {
  const loc = useLocation()
  const isKoalaWeekly = useMemo(() => viewingKoalaWeekly(loc), [loc])
  const [modalToShow, setModalToShow] = React.useState<'update' | 'delete' | 'clone' | 'move' | 'save_as' | null>(null)
  const facets = props.facets
  const viewCreator = props.account_view.created_by_user?.name ?? props.account_view.created_by_user?.email
  const slackDisclosure = useDisclosure()
  const onDiscardChanges = props.onDiscardChanges

  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const onCloseModal = useCallback(() => {
    setModalToShow(null)
  }, [])

  const handleEditClick = useCallback(() => {
    setModalToShow('update')
  }, [])

  const handleMoveClick = useCallback(() => {
    setModalToShow('move')
  }, [])

  const handleCloneClick = useCallback(() => {
    setModalToShow('clone')
  }, [])

  const handleDeleteClick = useCallback(() => {
    setModalToShow('delete')
  }, [])

  const handleSaveAsClick = useCallback(() => {
    setModalToShow('save_as')
  }, [])

  const canEditView = props.permissions.can_edit
  const canDestroyView = props.permissions.can_destroy
  const canCloneView = props.permissions.can_create
  const isAccountView = props.account_view.kind === 'account'

  const viewDiff = useMemo(() => {
    return viewChanged(
      props.account_view?.filters || {},
      { range: facets?.range, focus_time: facets?.focusTime, sort_by: facets?.sortBy, facets: facets?.facetFilters },
      { ...props.defaults, facets: {} }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.account_view?.filters,
    facets?.range,
    facets?.focusTime,
    facets?.sortBy,
    facets?.facetFilters,
    props.defaults?.range,
    props.defaults?.sort_by
  ])

  const isDirty = useMemo(() => {
    return (viewDiff ?? []).length > 0 || props.columnsChanged
  }, [viewDiff, props.columnsChanged])

  const hasWarnableChanges = useMemo(() => {
    // Set the fields that are dirtiable, but don't warrant a warning on page leave
    const nonWarnablePaths = ['sort_by']
    return viewDiff?.some((d) => !nonWarnablePaths.includes(d.path?.[0]))
  }, [viewDiff])

  const navContext = useListsNav()

  const showColumnSelection =
    !props.isLive && !window.location.pathname.endsWith('/feed') && !window.location.pathname.endsWith('/live')

  const { skipRef } = useUnsavedChangesPrompt({ hasChanges: canEditView && hasWarnableChanges && !props.hideMenu })

  const discardChanges = useCallback(() => {
    skipRef.current = true

    if (onDiscardChanges) {
      onDiscardChanges()
    } else {
      window.location.href = accountViewPath(props.account_view)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.account_view, onDiscardChanges])

  const confirmDisclosure = useDisclosure()
  const shared =
    ['shared', 'space'].includes(props.account_view.ownership || 'unknown') && !props.account_view.space?.owner_id

  return (
    <Box>
      <HStack width="100%" justifyContent="space-between" flexWrap={['nowrap', 'wrap']} gap="4" py={3} px={[3, 4]}>
        <Flex alignItems="center" gap={4} minW="150px">
          <Square
            display={['none', 'none', 'flex']}
            size="40px"
            padding={1.5}
            rounded="xl"
            color="white"
            bgGradient={isAccountView ? 'linear(to-br, purple.300, purple.600)' : 'linear(to-br, blue.300, blue.600)'}
          >
            {isAccountView ? <BuildingIcon boxSize={5} /> : <IconUsers size={20} />}
          </Square>

          <Stack spacing="0">
            <HStack>
              <Heading
                display="inline-flex"
                gap={1.5}
                alignItems="center"
                size="md"
                lineHeight={1.42}
                onClick={!isKoalaWeekly && !navContext.isOpen ? navContext.onOpen : undefined}
              >
                {props.account_view.name}
                {!isKoalaWeekly && navContext.offScreen && (
                  <Icon as={IconChevronDown} color="purple.500" boxSize="18px" />
                )}
              </Heading>
              {isKoalaWeekly && <Text color="gray.500">(Koala Weekly Preview)</Text>}
              {props.isLive && <Badge colorScheme={props.paused ? 'gray' : 'green'}>Live</Badge>}
            </HStack>

            <HStack
              display={['none', 'flex']}
              fontSize="xs"
              divider={<MiddotDivider />}
              flexWrap="wrap"
              lineHeight={1.6}
            >
              {props.counts && (
                <Box whiteSpace="nowrap">
                  <Text as="span" fontWeight="medium">
                    <NumberEasing
                      value={props.counts.all}
                      speed={1000}
                      decimals={0}
                      ease="quadOut"
                      transitionWidth
                      render={(n) => Math.floor(n).toLocaleString()}
                    />
                  </Text>{' '}
                  <Text as="span" color="gray.600">
                    {pluralize(isAccountView ? 'Account' : 'Visitor', props.counts.all)}
                  </Text>
                </Box>
              )}
              {viewCreator && (
                <Text color="gray.600" isTruncated>
                  Created by{' '}
                  <Text as="span" fontWeight="semibold">
                    {viewCreator}
                  </Text>{' '}
                  <TimeAgo time={props.account_view.created_at} />
                </Text>
              )}
            </HStack>
          </Stack>
        </Flex>

        {!isKoalaWeekly && !props.hideMenu && (
          <Flex gap={2} alignItems="center">
            {props.isLive && (
              <PlayPauseButton paused={props.paused} onToggle={(mode) => props.setPaused?.(mode === 'paused')} />
            )}

            <AccountViewTabs view={props.account_view} />

            {showColumnSelection && (
              <ColumnManagementPopover
                audienceKind={props.account_view.kind}
                apps={apps}
                selectedColumns={props.columns}
                onChange={props.onColumnChange}
              >
                <IconButton
                  aria-label="Edit list"
                  variant="outline"
                  size="sm"
                  flex="none"
                  icon={<IconLayoutColumns size={18} />}
                />
              </ColumnManagementPopover>
            )}

            <Menu>
              <MenuButton
                size="sm"
                as={IconButton}
                icon={<IconDotsVertical size={16} />}
                variant="outline"
                borderColor="gray.200"
              />
              <MenuList fontSize="sm" zIndex="popover">
                {!props.account_view.space_id && (
                  <MenuItem icon={<SlackIcon boxSize={4} />} onClick={slackDisclosure.onOpen}>
                    Configure Slack Alerts
                  </MenuItem>
                )}
                <MenuItem isDisabled={!canEditView} icon={<IconEdit size={16} />} onClick={handleEditClick}>
                  List settings…
                </MenuItem>
                <MenuItem isDisabled={!canEditView} icon={<IconCircleArrowRight size={16} />} onClick={handleMoveClick}>
                  Move to Space…
                </MenuItem>
                <MenuItem isDisabled={!canCloneView} icon={<IconCopy size={16} />} onClick={handleCloneClick}>
                  Clone…
                </MenuItem>
                <DownloadCsvMenuItem
                  isMenuDisabled={
                    props.isLive ||
                    window.location.pathname.endsWith('/feed') ||
                    window.location.pathname.endsWith('/live')
                  }
                  apps={apps}
                  audienceKind={props.account_view.kind}
                  initialColumns={props.columns}
                  allowColumnSelection
                />
                <MenuItem
                  icon={<IconTrash size={16} />}
                  color="red.500"
                  isDisabled={!canDestroyView}
                  onClick={handleDeleteClick}
                >
                  Delete
                </MenuItem>

                <MenuDivider />

                <TableMode />
              </MenuList>
            </Menu>
          </Flex>
        )}
      </HStack>

      {isKoalaWeekly && (
        <Flex>
          <KoalaWeeklyPreviewAlert />
        </Flex>
      )}

      {facets && (
        <Flex justifyContent="space-between" gap={4} pt={2} pb={3} px={[3, 4]}>
          <FilterPreview
            isEditable={!isKoalaWeekly}
            {...facets}
            canClearFilters={false}
            facetValuesPath={props.isLive ? '/people/live-facets' : undefined}
            kind={props.account_view.kind}
            excludedKeys={isAccountView ? [] : ['visitor_stats.identified.month', 'visitor_stats.visitors.month']}
            apps={props.apps}
            shouldShowTerritoryFilter={!isKoalaWeekly && isAccountView}
            range={isKoalaWeekly ? undefined : facets.range}
          />

          {canEditView && isDirty && !isKoalaWeekly && (
            <>
              <input type="hidden" name="account_view[name]" value={props.account_view.name} />

              {props.columns?.length ? (
                props.columns.map((column) => (
                  <input
                    key={column}
                    type="hidden"
                    name="account_view[display_settings][display_columns][]"
                    value={column}
                  />
                ))
              ) : (
                <input type="hidden" name="account_view[display_settings][display_columns][]" value="" />
              )}

              {props.ai_columns?.length ? (
                props.ai_columns.map((column) => (
                  <input
                    key={column}
                    type="hidden"
                    name="account_view[display_settings][ai_columns][]"
                    value={column}
                  />
                ))
              ) : (
                <input type="hidden" name="account_view[display_settings][ai_columns][]" value="" />
              )}

              <Flex gap={2} alignItems="center">
                <Button flex="none" variant="ghost" size="sm" onClick={discardChanges}>
                  Discard changes
                </Button>

                <ButtonGroup size="sm" isAttached>
                  <Button
                    flex="none"
                    colorScheme={isAccountView ? 'purple' : 'blue'}
                    type={shared ? 'button' : 'submit'}
                    onClick={shared ? confirmDisclosure.onOpen : undefined}
                    isDisabled={!canEditView}
                    isLoading={props.loading}
                  >
                    Save
                  </Button>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      minW={6}
                      icon={<IconChevronDown size={14} />}
                      flex="none"
                      colorScheme={isAccountView ? 'purple' : 'blue'}
                      isDisabled={!canEditView}
                      isLoading={props.loading}
                    />
                    <Portal>
                      <MenuList zIndex="popover">
                        <MenuItem icon={<IconCirclePlus size={16} />} iconSpacing={1.5} onClick={handleSaveAsClick}>
                          Save as new list
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </ButtonGroup>

                <ConfirmDialog
                  title="Save changes?"
                  confirmLabel="Yes, save changes"
                  {...confirmDisclosure}
                  variant="submit"
                  form={props.formRef}
                >
                  This list is shared with other users. Are you sure you want to save changes for everyone?
                </ConfirmDialog>
              </Flex>
            </>
          )}
        </Flex>
      )}

      {modalToShow === 'save_as' && (
        <CloneViewModal
          view={props.account_view}
          saveAs
          onClose={onCloseModal}
          onComplete={() => {
            skipRef.current = true
          }}
        />
      )}
      {modalToShow === 'clone' && <CloneViewModal view={props.account_view} onClose={onCloseModal} />}
      {modalToShow === 'delete' && <DeleteViewConfirmationModal view={props.account_view} onClose={onCloseModal} />}
      {modalToShow === 'move' && <MoveToSpaceModal view={props.account_view} isOpen onClose={onCloseModal} />}
      {modalToShow === 'update' && (
        <AccountViewModal view={props.account_view} kind={props.account_view.kind} isOpen onClose={onCloseModal} />
      )}

      <SlackAlertsSidesheet apps={props.apps} view={props.account_view} {...slackDisclosure} />
    </Box>
  )
}
