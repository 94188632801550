import { Icon } from '@chakra-ui/react'

import { Tooltip } from '@chakra-ui/react'
import { IconHistoryToggle } from '@tabler/icons-react'
import React from 'react'

export const AIStaleState = (props) => {
  return (
    <Tooltip label="The response you are looking at is potentially outdated">
      <Icon as={IconHistoryToggle} boxSize={3.5} {...props} />
    </Tooltip>
  )
}
