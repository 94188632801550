import { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import router from '../../../../lib/router'
import { hasColumnData, hasColumns, sameColumns } from '../../../ui/ColumnSelector'
import usePrevious from '../../../ui/usePrevious'
import { mergeParams } from '../../icps/types'

interface UseAIColumnsProps {
  data: any[]
  columns: string[]
  initialColumns?: string[]
}

export function useAIColumns({ data, columns: controlledColumns, initialColumns }: UseAIColumnsProps) {
  const [aiColumns, setAIColumnsRaw] = useState<string[]>(controlledColumns)
  const [initial, _setInitial] = useState<string[]>(initialColumns ?? [])
  const [loadingColumns, setLoadingColumns] = useState<string[]>([])
  const prevColumns = usePrevious(aiColumns)

  const setAIColumns: React.Dispatch<React.SetStateAction<string[]>> = useCallback((newColumns) => {
    if (typeof newColumns === 'function') {
      setAIColumnsRaw(newColumns)
    } else {
      setAIColumnsRaw((prev) => {
        if (Array.isArray(prev) && Array.isArray(newColumns) && prev.toString() == newColumns.toString()) {
          return prev
        } else {
          return newColumns
        }
      })
    }
  }, [])

  useUpdateEffect(() => {
    setAIColumns(controlledColumns)
  }, [controlledColumns.toString()])

  const onColumnChange = useCallback(
    (columns: any[]) => {
      setAIColumns(columns.map((c) => (typeof c === 'string' ? c : c?.key)).filter(Boolean))
    },
    [setAIColumns]
  )

  const onColumnRemove = useCallback(
    (column: string | any) => {
      const key = typeof column === 'string' ? column : (column?.key as string | undefined)
      if (key) {
        setAIColumns((columns) => (columns || []).filter((c) => c !== key))
      }
    },
    [setAIColumns]
  )

  useUpdateEffect(() => {
    const url = mergeParams(window.location.toString(), { ai_columns: aiColumns.join(',') })

    // no change, no need to update the url or fetch
    if (sameColumns(initial, aiColumns) && !window.location.search.includes('ai_columns=')) {
      return
    }

    // if the column order changes but not the columns just update the url without fetching
    if (sameColumns(prevColumns, aiColumns)) {
      window.history.replaceState({}, '', url)
      return
    }

    // if a column was removed... no need to refetch either
    if (prevColumns && prevColumns.length > aiColumns.length) {
      // window.history.replaceState({}, '', url)
      router.visit(url, { fetch: false })
      return
    }

    // if we already have the data, no need to fetch
    if (hasColumns(data, aiColumns)) {
      // window.history.replaceState({}, '', url)
      router.visit(url, { fetch: false })
      return
    }

    // otherwise, fetch the new columns
    const missingColumns = aiColumns.filter((c) => {
      return !prevColumns?.includes(c) && !hasColumnData(data, c)
    })

    setLoadingColumns(missingColumns)
    router.visit(url)
  }, [prevColumns, aiColumns, initial])

  useUpdateEffect(() => {
    setLoadingColumns([])
  }, [data])

  return {
    columns: aiColumns,
    loadingColumns,
    setAIColumns,
    onColumnChange,
    onColumnRemove
  }
}
