import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { Apps } from '../../types/App'
import { UserAppInstance } from '../../types/UserAppInstance'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { useCurrentUser } from '../ui/UserContext'

interface Data {
  apps?: Apps
}

export function useApps() {
  const project = useCurrentProject()
  const basePath = '/apps.json'

  const path = projectPath(basePath)

  return useQuery<Data>(['apps', { projectId: project?.id }], () => concurrentGET<{ apps: Apps }>(path), {
    enabled: Boolean(project?.id)
  })
}

interface UserAppsResponse {
  user_apps: UserAppInstance[]
}

export function useUserApps() {
  const project = useCurrentProject()
  const currentUser = useCurrentUser()
  const basePath = `/apps/user_apps.json`
  const path = projectPath(basePath)

  return useQuery<UserAppsResponse>(
    ['user_apps', { projectId: project?.id, userId: currentUser?.id }],
    () => concurrentGET(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}
