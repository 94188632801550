import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import {
  IconCopy,
  IconDotsVertical,
  IconEdit,
  IconExternalLink,
  IconGauge,
  IconListDetails,
  IconMailBolt,
  IconPlus,
  IconTrash
} from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { toast } from 'sonner'
import { del, put } from '../../../lib/api'
import { formatNumber } from '../../../lib/number-format'
import router from '../../../lib/router'
import { AutoOutboundRule } from '../../../types/AutoOutbound'
import { PageMeta } from '../../../types/PageMeta'
import { useBillingMetrics } from '../../data/use-billing-metrics'
import { openUpgradeFlow } from '../../ui/billing-banners/accounts-banner'
import { FeatureLockout } from '../../ui/billing-banners/feature-lockout'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { useCurrentProject } from '../../ui/ProjectsContext'
import SquareIcon from '../../ui/SquareIcon'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { useEntitlements } from '../../ui/useEntitlements'
import { KoalaSubscription } from '../billing/show'
import { AutomationsEntitlements, friendlyNumber, Usage } from '../billing/v2'
import { mergeParams } from '../icps/types'
import { automationsPath } from '../notifications/lib/path-helper'
import {
  autoOutboundEditPath,
  autoOutboundLogsPath,
  autoOutboundNewFromRulePath,
  autoOutboundNewPath,
  autoOutboundPath,
  autoOutboundRulePath
} from './lib/path-helpers'

interface Props {
  auto_outbound_rules: AutoOutboundRule[]
  page_meta: PageMeta
}

export default function Index(props: Props) {
  const project = useCurrentProject()
  const metrics = useBillingMetrics({ automations: true })
  const metricsData = useMemo(() => metrics.data, [metrics.data])
  const limit = project?.automation_rate_limits_enabled ? (metricsData?.entitlements?.auto_outbound_runs ?? 0) : null
  const entitlements = useEntitlements()
  const subscription = metricsData?.koala_subscription

  const canAccess = useMemo(
    () => (typeof limit === 'number' && limit > 0) || entitlements?.automations,
    [limit, entitlements?.automations]
  )

  return (
    <PageLayout size="md">
      <Breadcrumb
        paths={[
          { path: automationsPath('/overview'), title: 'Automations' },
          { path: autoOutboundPath(), title: 'Auto Outbound' }
        ]}
      />
      <HStack w="100%">
        <PageTitle w="100%">Auto Outbound</PageTitle>
        {props.auto_outbound_rules.length > 0 && (
          <Button
            as={Link}
            href={autoOutboundNewPath()}
            colorScheme="purple"
            size="sm"
            flex="none"
            leftIcon={<IconPlus size={14} />}
          >
            New Playbook
          </Button>
        )}
      </HStack>

      {props.auto_outbound_rules.length > 0 ? (
        <>
          {!!limit && metricsData?.usage && (
            <AutoOutboundLimitWarning
              entitlements={metricsData.entitlements}
              usage={metricsData.usage}
              subscription={subscription}
            />
          )}

          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>
                    <Flex gap={1.5}>
                      Status
                      <HelpTooltip variant="info" placement="top">
                        When enabled, Koala will evaluate this playbook against prospects in your workspace.
                      </HelpTooltip>
                    </Flex>
                  </Th>
                  <Th>
                    <Flex gap={1.5}>
                      Autopilot
                      <HelpTooltip variant="info" placement="top">
                        When enabled, Koala will automatically sync prospects to your destination based on the
                        playbook's criteria, without requiring manual intervention.
                      </HelpTooltip>
                    </Flex>
                  </Th>
                  <Th isNumeric>
                    <Flex gap={1.5} justifyContent="flex-end">
                      Prospects
                      <HelpTooltip variant="info" placement="top">
                        The number of prospects that matched all eligibility criteria in Koala and were either pending,
                        synced with your destination, or failed to sync with your destination.
                      </HelpTooltip>
                    </Flex>
                  </Th>
                  <Th isNumeric>
                    <Flex gap={1.5} justifyContent="flex-end">
                      Logs (30d)
                      <HelpTooltip variant="info" placement="top">
                        The total number of times this playbook was triggered by intent and evaluated in the past 30
                        days (NOTE: ineligible prospects logs are removed after 7 days).
                      </HelpTooltip>
                    </Flex>
                  </Th>
                  <Th isNumeric></Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.auto_outbound_rules.map((rule) => (
                  <Tr key={rule.id}>
                    <Td width="280px">
                      <HStack spacing={3} paddingY={2}>
                        <SquareIcon icon={IconMailBolt} iconSize={5} padding={2} colorScheme="purple" rounded="lg" />
                        <Stack spacing={0.5}>
                          <Link href={autoOutboundRulePath(rule.id)}>{rule.name}</Link>
                          <Text fontSize="xs" color="gray.600">
                            Updated <TimeAgo time={rule.updated_at} />{' '}
                          </Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td width="1px">{rule.enabled ? 'Active' : 'Inactive'}</Td>
                    <Td width="1px">
                      <Switch
                        defaultChecked={rule.autopilot}
                        value="1"
                        onChange={async (e) => {
                          await put(autoOutboundRulePath(rule.id, `/status`), {
                            auto_outbound: {
                              autopilot: e.target.checked
                            }
                          }).then(() => {
                            toast.success(`Autopilot ${e.target.checked ? 'enabled' : 'disabled'}`)
                          })
                        }}
                      />
                    </Td>
                    <Td width="1px" isNumeric color={rule.prospect_stats?.matched ? 'green.500' : 'gray.700'}>
                      <Link href={autoOutboundRulePath(rule.id)}>
                        {formatNumber(rule.prospect_stats?.matched ?? '')}
                      </Link>
                    </Td>
                    <Td width="1px" isNumeric>
                      <Link href={autoOutboundLogsPath({ rule_id: rule.id })}>
                        {formatNumber(rule.stats?.total ?? '')}
                      </Link>
                    </Td>
                    <Td isNumeric width="1px">
                      <Menu size="sm" autoSelect={false} placement="bottom-end">
                        <MenuButton
                          as={IconButton}
                          aria-label="Actions"
                          icon={<IconDotsVertical size={16} />}
                          variant="ghost"
                          size="xs"
                        />
                        <MenuList zIndex={'dropdown'}>
                          <MenuItem icon={<IconEdit size={16} />} as={Link} href={autoOutboundEditPath(rule.id)}>
                            Edit playbook
                          </MenuItem>
                          <MenuItem as="a" icon={<IconCopy size={16} />} href={autoOutboundNewFromRulePath(rule.id)}>
                            Clone
                          </MenuItem>
                          <MenuItem
                            icon={<IconListDetails size={16} />}
                            as={Link}
                            href={autoOutboundLogsPath({ rule_id: rule.id })}
                          >
                            View Logs
                          </MenuItem>
                          <MenuItem
                            icon={<IconTrash size={16} />}
                            onClick={(e) => {
                              e.preventDefault()

                              if (confirm('Are you sure?')) {
                                del(autoOutboundRulePath(rule.id)).then(() => {
                                  router.visit(autoOutboundPath())
                                })
                              }
                            }}
                          >
                            Delete Playbook
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          <TableFooter
            pageMeta={props.page_meta}
            page={props.page_meta.current_page}
            nextPath={mergeParams(window.location.toString(), {
              page: (props.page_meta.current_page + 1).toString()
            })}
            prevPath={mergeParams(window.location.toString(), {
              page: (props.page_meta.current_page - 1).toString()
            })}
            sticky
          />
        </>
      ) : (
        <>
          {canAccess && <EmptyState limit={limit} />}
          {!canAccess && <RequestAccessState />}
        </>
      )}
    </PageLayout>
  )
}

function RequestAccessState() {
  return (
    <FeatureLockout
      locked
      blockTitle="Auto Outbound is not available on your plan"
      upgradeTo="business"
      blockedChildren={
        <Text fontSize="sm" textAlign="center" color="gray.600">
          Automatically engage the right prospects when the moment is right. Auto Outbound syncs high-intent prospects
          to outbound sequences using your existing outreach tools.
        </Text>
      }
    />
  )
}

interface AutoOutboundLimitWarningProps {
  entitlements?: AutomationsEntitlements
  usage: Usage
  subscription?: KoalaSubscription
}

export function AutoOutboundLimitWarning(props: AutoOutboundLimitWarningProps) {
  const limit = props.entitlements?.auto_outbound_runs ?? 0
  const used = props.usage.auto_outbounds?.current.delivered ?? 0
  const skipped = props.usage.auto_outbounds?.current.skipped ?? 0

  const overLimit = useMemo(() => limit && used >= limit, [limit, used])
  const recurring = useMemo(() => props.subscription?.plan && props.subscription?.plan !== 'free', [props.subscription])

  const hasUnlimitedAutomations = useMemo(() => {
    return (
      props.entitlements?.automations ||
      props.entitlements?.automations_preview ||
      !props.entitlements?.auto_outbound_runs
    )
  }, [props.entitlements])

  const progressPct = useMemo(() => {
    if (!used || !limit) {
      return 0
    }

    return Math.round((used / limit) * 100)
  }, [used, limit])

  if (hasUnlimitedAutomations) {
    return null
  }

  const colorScheme = progressPct >= 100 ? 'red' : progressPct >= 90 ? 'orange' : 'purple'
  const almostOut = progressPct >= 90

  return (
    <Card bg="white" alignItems="flex-start" rounded="lg" w="100%" p={0}>
      <Stack spacing={0} w="100%">
        {almostOut && (
          <HStack
            spacing={3}
            fontSize="sm"
            paddingX={4}
            paddingY={3}
            bg="orange.50"
            color="orange.900"
            fontWeight="medium"
            roundedTop="lg"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <CircleIcon icon={IconGauge} iconSize={5} padding={2} bg="orange.200" color="orange.900" />
            <Text>
              Your workspace is {!overLimit ? 'almost ' : ''}out of Autopilot credits
              {recurring ? ' this billing period' : ''}.{' '}
              <Button
                variant="link"
                color="inherit"
                fontWeight="semibold"
                rounded="none"
                onClick={() => openUpgradeFlow()}
                borderBottom="1px dotted"
                _hover={{ textDecoration: 'none' }}
              >
                Upgrade
              </Button>{' '}
              to unlock more.
            </Text>
          </HStack>
        )}
        <Flex
          w="100%"
          gap={[3, 4, 6, 10]}
          px={4}
          py={4}
          pl={almostOut ? 16 : 4}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={2} flex="1" minW="300px" maxW="700px" py={1}>
            <Flex gap={2} justifyContent="space-between" alignItems="baseline" lineHeight={1}>
              <Text flex="none" fontSize="sm" fontWeight="semibold">
                Autopilot credits
              </Text>

              <Flex gap={2} alignItems="baseline" fontSize="xs">
                {skipped > 0 && (
                  <>
                    <Tooltip label="These are the number of prospects that were skipped due to your credit limit.">
                      <Text
                        cursor="help"
                        color="gray.500"
                        borderBottom="1px dotted transparent"
                        _hover={{ borderBottom: '1px dotted currentColor' }}
                      >
                        {friendlyNumber(skipped)} skipped
                      </Text>
                    </Tooltip>
                    <MiddotDivider />
                  </>
                )}

                <Flex flex="none" gap={1} alignItems="baseline">
                  <Text fontWeight="semibold">{friendlyNumber(used)}</Text>
                  <Text display={['block', 'block', 'none']} color="gray.500">
                    / {friendlyNumber(limit)}
                  </Text>
                  <Text display={['none', 'none', 'block']} color="gray.500">
                    of {friendlyNumber(limit)} credits included
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Progress colorScheme={colorScheme} bg="gray.200" value={used} max={limit} rounded="md" size="xs" />
          </Stack>

          <Button
            flex="none"
            colorScheme={progressPct >= 90 ? 'purple' : undefined}
            variant={progressPct >= 90 ? 'solid' : 'outline'}
            size="sm"
            onClick={() => {
              openUpgradeFlow()
            }}
          >
            Unlock more credits
          </Button>
        </Flex>
      </Stack>
    </Card>
  )
}

interface EmptyStateProps {
  limit: number | null
}

function EmptyState(props: EmptyStateProps) {
  return (
    <Stack
      width="full"
      rounded="lg"
      bg="gray.50"
      border="1px solid"
      borderColor="gray.200"
      padding="40px"
      alignItems="center"
      spacing={6}
    >
      <CircleIcon icon={IconMailBolt} iconSize={7} padding={2} colorScheme="purple" />
      <Stack alignItems="center" spacing={1} maxW="650px">
        <Heading size="md">Put your pipeline on Autopilot</Heading>
        <Text fontSize="sm" textAlign="center" color="gray.600">
          Automatically engage the right prospects when the moment is right. Auto Outbound syncs high-intent prospects
          to outbound sequences using your existing outreach tools.
        </Text>
        {typeof props.limit === 'number' && props.limit > 0 && (
          <Text fontSize="sm" textAlign="center" color="gray.600" pt={4}>
            Your plan includes {props.limit.toLocaleString()} Autopilot credits to get you started.{' '}
            <Link
              display="inline-flex"
              alignItems="center"
              gap={1}
              href="https://getkoala.com/docs/automations/credits"
              isExternal
              borderBottom="1px dotted"
              _hover={{ textDecoration: 'none' }}
            >
              Learn more
              <IconExternalLink size={14} />
            </Link>
          </Text>
        )}
      </Stack>
      <Button as={Link} href={autoOutboundNewPath()} size="sm" colorScheme="purple">
        Create your first campaign
      </Button>
    </Stack>
  )
}
