import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Link,
  MenuButton,
  Menu,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  Portal,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import {
  IconAdjustmentsHorizontal,
  IconCheck,
  IconChevronDown,
  IconEdit,
  IconFlareFilled,
  IconPlayerPlay,
  IconPlus,
  IconTrash,
  IconBuilding,
  IconUser
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import { del, get, post } from '../../../lib/api'
import router from '../../../lib/router'
import { AIAgent } from '../../../types/AIAgent'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { CompanySelector, PartialCompany } from '../../ui/CompanySelector'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import EmptyState from '../../ui/EmptyState'
import { useFeatureRequest } from '../../ui/FeedbackForm'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { TopBarContent } from '../../ui/TopBarContext'
import { titleize } from '../accounts/facets/filter-cloud'
import { EditAgentModal } from './components/AIAgentForm'
import { AIAgentResponse } from './components/AIAgentResponse'

interface Props {
  agents: Array<AIAgent & { category?: string }>
  my_agents: AIAgent[]
  access_requested?: boolean
  agents_entitlements: {
    enabled: boolean
    // TODO add agent limit
  }
}

export default function Index(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedAgent, setSelectedAgent] = React.useState<Props['agents'][0] | null>(null)
  const [agentToEdit, setAgentToEdit] = React.useState<AIAgent | null>(null)
  const [agentToDelete, setAgentToDelete] = React.useState<AIAgent | null>(null)
  const [selectedCompany, setSelectedCompany] = React.useState<PartialCompany | null>(null)
  const [isLoading, setIsLoading] = React.useState<Record<string, boolean>>({})
  const [myAgents, setMyAgents] = React.useState<Props['my_agents']>(props.my_agents)
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null)

  const { mutateAsync: requestFeature, isLoading: requestingFeature } = useFeatureRequest()
  const [requested, setRequested] = React.useState(props.access_requested || false)

  const myAgentsSlugs = useMemo(() => myAgents.map((agent) => agent.slug), [myAgents])

  useEffect(() => {
    setMyAgents(props.my_agents)
  }, [props.my_agents])

  const handleCardClick = useCallback(
    (agent: Props['agents'][0]) => {
      setSelectedAgent(agent)
      onOpen()
    },
    [onOpen]
  )

  const onAdd = useCallback(
    (agent: Props['agents'][0]) => {
      // Prevent adding if already loading or already added
      if (isLoading[agent.slug] || myAgentsSlugs.includes(agent.slug)) {
        return
      }

      setIsLoading((prev) => ({
        ...prev,
        [agent.slug]: true
      }))

      post<{ agents: Props['agents'] }>(projectPath('/settings/agents/add.json'), {
        ai_agent: {
          slug: agent.slug,
          name: agent.name,
          question: agent.question,
          target: agent.target
        }
      })
        .then((res) => {
          toast.success('AI Agent was successfully added.')
          // Find the newly added agent in the response
          const newAgent = res.agents.find((a) => a.slug === agent.slug)
          if (newAgent) {
            setMyAgents((prev) => [...prev, newAgent])
          }
          onClose()
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setIsLoading((prev) => ({
            ...prev,
            [agent.slug]: false
          }))
        })
    },
    [onClose, isLoading, myAgentsSlugs]
  )

  const handleDelete = useCallback(
    (agent: Props['agents'][0]) => {
      setIsLoading((prev) => ({
        ...prev,
        [agent.slug]: true
      }))

      return del<{ agents: Props['agents'] }>(projectPath(`/settings/agents/${agent.slug}`))
        .then((res) => {
          toast.success('AI Agent was successfully removed.')
          setMyAgents(res.agents)
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setIsLoading((prev) => ({
            ...prev,
            [agent.slug]: false
          }))
        })
    },
    [setMyAgents]
  )

  const groupAgentsByCategory = (agents: Props['agents']) => {
    return agents.reduce(
      (acc, agent) => {
        const category = agent.category || 'My Agents'
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(agent)
        return acc
      },
      {} as Record<string, Props['agents']>
    )
  }

  const groupedAgents = useMemo(() => groupAgentsByCategory(props.agents), [props.agents])
  const categories = useMemo(() => ['All', ...Object.keys(groupedAgents)], [groupedAgents])

  const filteredAgents = useMemo(() => {
    if (selectedCategory === 'My Agents') {
      return myAgents
    }
    if (!selectedCategory || selectedCategory === 'All') {
      return props.agents
    }
    return props.agents.filter((agent) => agent.category === selectedCategory)
  }, [props.agents, selectedCategory, myAgents])

  const getCategoryCount = useCallback(
    (category: string) => {
      if (category === 'All') {
        return props.agents.length
      }
      if (category === 'My Agents') {
        return myAgents.length
      }
      return props.agents.filter((agent) => agent.category === category).length
    },
    [props.agents, myAgents]
  )

  if (!props.agents_entitlements?.enabled) {
    return (
      <PageLayout size="md" flush flex="1 1 auto" minHeight="300px">
        <TopBarContent>
          <Flex width="100%" alignSelf="center" gap={3} justifyContent="space-between">
            <SettingsBreadCrumb paths={[{ title: 'AI Agents', path: projectPath('/settings/agents') }]} />
          </Flex>
        </TopBarContent>

        <PageTitle skipRendering>AI Agents</PageTitle>

        <Flex flex="1 1 auto" maxHeight="100%" alignItems="flex-start" bg="gray.50">
          <Stack width="100%" spacing={8}>
            <Box maxWidth="600px" margin="auto">
              <EmptyState
                size="lg"
                icon={<CircleIcon icon={IconFlareFilled} iconSize={6} padding={2} colorScheme="purple" />}
                heading="Streamline your account research with AI Agents"
                description="Let AI agents do the heavy lifting of account research while your team focuses on building relationships and closing deals."
                // TODO make this say "Upgrade to Growth"
                ctaText={requested ? 'Access Requested' : 'Request Access'}
                onClick={async () => {
                  if (requestingFeature || requested) return
                  try {
                    await requestFeature({ feature: 'ai_agents' })
                    setRequested(true)
                  } catch (e) {
                    // do nothing
                  }
                }}
                isLoading={requestingFeature}
                isDisabled={requested}
              />
            </Box>
            <PreviewGrid agents={props.agents} />
          </Stack>
        </Flex>
      </PageLayout>
    )
  }

  return (
    <PageLayout size="md">
      <SettingsBreadCrumb paths={[{ title: 'AI Agents', path: projectPath('/settings/agents') }]} />

      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <PageTitle>AI Agents</PageTitle>
          <Button
            as={Link}
            href={projectPath('/settings/agents/new')}
            iconSpacing={1}
            leftIcon={<IconPlus size={16} />}
            marginLeft="auto"
            alignSelf="center"
            size="sm"
            flex="none"
            colorScheme="purple"
            onClick={(e) => {
              if (e.ctrlKey || e.metaKey || e.shiftKey) {
                return true
              }

              e.preventDefault()
              setAgentToEdit({
                name: '',
                question: '',
                slug: '',
                target: 'companies'
              })
              return false
            }}
          >
            New agent
          </Button>

          <IconButton
            aria-label="Configuration"
            icon={<IconAdjustmentsHorizontal size={16} />}
            as={Link}
            href={projectPath('/settings/agents/configuration')}
            variant="outline"
            size="sm"
          />
        </Flex>
        <PageDescription>
          Configure AI agents for your workspace to automatically perform account research and find key details to help
          your team.
        </PageDescription>
      </SettingsHeader>

      <Flex gap={8}>
        <VStack
          align="stretch"
          spacing={0}
          minW="200px"
          position="sticky"
          top="24px"
          alignSelf="flex-start"
          display={{ base: 'none', md: 'flex' }}
        >
          {categories.map((category) => (
            <Button
              key={category}
              variant="ghost"
              justifyContent="flex-start"
              py={1.5}
              px={3}
              fontSize="sm"
              fontWeight="medium"
              color="gray.700"
              backgroundColor={selectedCategory === category ? 'gray.100' : 'transparent'}
              _hover={{ bg: 'gray.50' }}
              onClick={() => setSelectedCategory(category === 'All' ? null : category)}
              w="100%"
              minW="230px"
            >
              <HStack justify="space-between" width="100%" gap="4">
                <Text fontWeight={selectedCategory === category ? 'semibold' : 'normal'}>{titleize(category)}</Text>
                <Tag
                  size="sm"
                  fontSize="xs"
                  colorScheme={category === 'My Agents' ? 'purple' : 'gray'}
                  fontWeight={selectedCategory === category ? 'semibold' : 'normal'}
                >
                  {getCategoryCount(category)}
                </Tag>
              </HStack>
            </Button>
          ))}
        </VStack>

        <Stack spacing={8} flex={1}>
          {selectedCategory === 'My Agents' ? (
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="medium">
                My Agents
              </Text>
              <Button
                iconSpacing={1}
                leftIcon={<IconPlus size={16} />}
                size="sm"
                colorScheme="purple"
                as={Link}
                variant="outline"
                href={projectPath('/settings/agents/new')}
              >
                Add Agent
              </Button>
            </Flex>
          ) : null}
          <Grid
            gridAutoRows="1fr"
            gridTemplateColumns="repeat(auto-fill, minmax(250px, 300px))"
            alignItems="stretch"
            gap={3}
            justifyContent="start"
          >
            {filteredAgents.map((agent) => (
              <Card
                position="relative"
                key={agent.slug}
                textAlign="center"
                p={5}
                gap={1}
                cursor="pointer"
                role="group"
                _hover={{ shadow: 'md', borderColor: 'purple.500' }}
                style={{ aspectRatio: '16 / 9' }}
                display="flex"
                flexDirection="column"
                justifyContent={'center'}
                alignItems="center"
                maxW="300px"
                w="100%"
              >
                <Flex flex="1" flexDirection="column" gap={2} pb="6">
                  <Text fontSize="xs" color="gray.500" fontWeight="medium">
                    {agent.name}
                  </Text>
                  <Text fontSize="sm" fontWeight="medium">
                    {agent.question}
                  </Text>
                </Flex>
                <Flex
                  position="absolute"
                  justifyContent="space-between"
                  alignItems="center"
                  bottom="3"
                  left="3"
                  right="3"
                  gap={4}
                >
                  <Flex alignItems="center" gap={1}>
                    <Tooltip label={titleize(agent.target)}>
                      <Flex alignItems="center" color="gray.400" _groupHover={{ color: 'purple.500' }}>
                        <Icon as={agent.target === 'companies' ? IconBuilding : IconUser} boxSize={4} />
                      </Flex>
                    </Tooltip>
                    {myAgentsSlugs.includes(agent.slug) && (
                      <Flex alignItems="center" gap={1} color="gray.400" _groupHover={{ color: 'purple.500' }}>
                        <Icon as={IconFlareFilled} boxSize={3.5} />
                        <Text fontSize="xs" fontWeight="medium">
                          Added
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <Flex
                    p={1}
                    gap={0.5}
                    bg="white"
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="md"
                    marginLeft="auto"
                  >
                    <Tooltip label="Test Agent">
                      <IconButton
                        size="xs"
                        aria-label="Test"
                        colorScheme="gray"
                        variant="ghost"
                        onClick={() => handleCardClick(agent)}
                        icon={<IconPlayerPlay size={15} />}
                      />
                    </Tooltip>
                    {myAgentsSlugs.includes(agent.slug) ? (
                      <>
                        <Tooltip label="Edit agent">
                          <IconButton
                            size="xs"
                            colorScheme="gray"
                            variant="ghost"
                            icon={<IconEdit size={15} />}
                            aria-label="Edit"
                            as={Link}
                            href={projectPath(`/settings/agents/${agent.id || agent.slug}`)}
                            onClick={(e) => {
                              // prevent the link from navigating
                              // unless opening in a new tab
                              if (e.ctrlKey || e.metaKey || e.shiftKey) {
                                return true
                              }

                              e.preventDefault()
                              setAgentToEdit(agent)
                              return false
                            }}
                          />
                        </Tooltip>
                        <Tooltip label="Remove agent">
                          <IconButton
                            size="xs"
                            colorScheme="red"
                            variant="ghost"
                            icon={<IconTrash size={15} />}
                            aria-label="Remove"
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              setAgentToDelete(agent)
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip label="Add agent">
                        <IconButton
                          size="xs"
                          aria-label="Add Agent"
                          isDisabled={isLoading[agent.slug] || myAgentsSlugs.includes(agent.slug)}
                          variant="ghost"
                          isLoading={isLoading[agent.slug]}
                          icon={myAgentsSlugs.includes(agent.slug) ? <IconCheck size={15} /> : <IconPlus size={15} />}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            if (!isLoading[agent.slug] && !myAgentsSlugs.includes(agent.slug)) {
                              onAdd(agent)
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Grid>
        </Stack>
      </Flex>

      <AgentModal
        isOpen={isOpen}
        onClose={onClose}
        onAdd={() => {
          if (!selectedAgent) return
          onAdd(selectedAgent)
          router.visit(window.location.pathname, { fetch: true })
        }}
        selectedAgent={selectedAgent}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />

      {agentToEdit && (
        <EditAgentModal
          isOpen
          onClose={() => setAgentToEdit(null)}
          onChange={() => {
            router.visit(window.location.pathname, { fetch: true })
          }}
          ai_agent={agentToEdit}
        />
      )}

      <DeleteConfirmation
        isOpen={!!agentToDelete}
        onClose={() => setAgentToDelete(null)}
        onConfirm={(event) => {
          event.preventDefault()
          if (agentToDelete) {
            return handleDelete(agentToDelete)
          }
        }}
        title="Delete AI Agent"
        confirmLabel="Delete Agent"
      >
        Are you sure you want to delete this AI agent? This action cannot be undone.
      </DeleteConfirmation>
    </PageLayout>
  )
}

interface AgentModalProps {
  isOpen: boolean
  onClose: () => void
  selectedAgent: AIAgent | null
  selectedCompany: PartialCompany | null
  setSelectedCompany: (company: PartialCompany | null) => void
  onAdd: () => void
}

export function AgentModal({
  isOpen,
  onClose,
  selectedAgent,
  selectedCompany,
  setSelectedCompany,
  onAdd
}: AgentModalProps) {
  const [loading, setLoading] = React.useState(false)
  const [response, setResponse] = React.useState<any>(null)

  useEffect(() => {
    setResponse(null)
    setLoading(false)
  }, [selectedAgent, selectedCompany])

  const runAgent = useCallback(
    async (skipCache = false) => {
      if (!selectedCompany?.domain) return

      setLoading(true)
      setResponse(null)

      const path = selectedAgent?.id
        ? projectPath(`/assistants/${selectedAgent?.slug}?domain=${selectedCompany?.domain}&skip_cache=${skipCache}`)
        : projectPath(
            `/assistants/run-template/${selectedAgent?.slug}?domain=${selectedCompany?.domain}&skip_cache=${skipCache}`
          )
      const response = await get(path)

      setResponse(response)
      setLoading(false)
    },
    [selectedAgent, selectedCompany]
  )

  return (
    <Modal size="2xl" isOpen={isOpen && selectedAgent !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={6}>
          <Stack spacing={8}>
            <Stack>
              <Box>
                <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                  Question
                </Text>
                <Text fontSize="md" color="gray.700" fontWeight="medium">
                  {selectedAgent?.question}
                </Text>
              </Box>
              <CompanySelector selectedCompany={selectedCompany} onChange={setSelectedCompany} />
            </Stack>
            <AIAgentResponse response={response} loading={loading} agent={selectedAgent} />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<IconPlus size={16} />} onClick={onAdd}>
              Enable Agent
            </Button>

            <ButtonGroup size="sm" isAttached>
              <Button
                size="sm"
                colorScheme="purple"
                onClick={() => runAgent(false)}
                isLoading={loading}
                isDisabled={!selectedCompany}
                leftIcon={<IconPlayerPlay size={16} />}
              >
                Test Agent
              </Button>
              <Menu>
                <MenuButton
                  as={IconButton}
                  minW={6}
                  icon={<IconChevronDown size={14} />}
                  isDisabled={!selectedCompany || loading}
                  flex="none"
                  colorScheme="purple"
                />
                <Portal>
                  <MenuList zIndex="popover">
                    <Tooltip label="We'll ignored previously cached answers for this agent">
                      <MenuItem
                        icon={<IconPlayerPlay size={16} />}
                        iconSpacing={1.5}
                        onClick={() => runAgent(true)}
                        isDisabled={!selectedCompany}
                        as={Button}
                      >
                        Test Agent from scratch
                      </MenuItem>
                    </Tooltip>
                  </MenuList>
                </Portal>
              </Menu>
            </ButtonGroup>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const PreviewGrid = ({ agents }: { agents: Props['agents'] }) => {
  return (
    <Box position="relative" maxHeight="500px" overflowY="hidden" padding={10}>
      <Box transform="perspective(800px) rotateX(24deg)" transformOrigin="top">
        <Grid
          gridAutoRows="1fr"
          gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
          alignItems="stretch"
          gap={3}
          justifyContent="start"
        >
          {agents.map((agent) => (
            <Card
              key={agent.slug}
              position="relative"
              textAlign="center"
              p={5}
              gap={1}
              style={{ aspectRatio: '16 / 9' }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="300px"
              w="100%"
              opacity={0.85}
            >
              <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="stretch" gap={2}>
                <Text fontSize="sm" fontWeight="medium">
                  {agent.question}
                </Text>
              </Flex>
            </Card>
          ))}
        </Grid>
      </Box>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        height="100%"
        bgGradient="linear(to-t, gray.50 0%, transparent 80%)"
        pointerEvents="none"
      />
    </Box>
  )
}
