import {
  Box,
  Button,
  Code,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconCirclePlus, IconCopy, IconDots, IconEdit, IconSelector, IconTrash } from '@tabler/icons-react'
import { deepEqual } from 'fast-equals'
import update from 'immutability-helper'
import { nanoid } from 'nanoid'
import * as React from 'react'
import { postForm } from '../../../../lib/api'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { projectPath } from '../../../ui/ProjectsContext'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import { kqlDefinitionNewFromPath } from '../path-helpers'
import { KqlDefinition, ConditionType } from '../types'
import { addIdentity, removeIdentity } from './add-identity'
import { SelectSignalTypeModal, SignalTypeSelector } from './SelectSignalTypeModal'
import { SignalType } from './SignalType'
import { SuggestedSignals } from './SuggestedSignals'
import { KQLTrigger } from './Trigger'
import { signalTypes, triggers } from './triggers'
import { Apps } from '../../../../types/App'

interface DefinitionFormProps {
  definition: KqlDefinition
  errors?: Record<string, string[]>
  skipSwitch?: boolean
  inline?: boolean
  onSubmitted?: (id?: string) => unknown
  isRemovable?: boolean
  cta?: (args: { hasConditions: boolean; isSubmitting: boolean }) => React.ReactNode
  title?: React.ReactNode
  apps?: Apps
}

function defaultTargetType(conditions: ConditionType[]): 'Profile' | 'Account' {
  if (conditions.some((condition) => condition.kind.includes('account'))) {
    return 'Account'
  }

  return 'Profile'
}

function profileOnlyRule(conditions: ConditionType[]): boolean {
  return conditions.some((condition) =>
    ['page_view', 'form_submission', 'form_fill', 'profile_trait', 'event'].includes(condition.kind)
  )
}

function accountOnlyRule(conditions: ConditionType[]): boolean {
  return conditions.some((condition) => ['g2_event'].includes(condition.kind))
}

export default function DefinitionForm(props: DefinitionFormProps) {
  const disclosure = useDisclosure()
  const onClose = disclosure.onClose
  const createDefinitionPath = projectPath('/signals')
  const updateDefinitionPath = projectPath(`/signals/${props.definition.id}`)
  const [name, setName] = React.useState(props.definition.name || '')
  const [enabled, setEnabled] = React.useState(props.definition.enabled ?? true)
  const [trigger, setTrigger] = React.useState(addIdentity(props.definition.trigger ?? { conditions: [] }))
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  React.useEffect(() => {
    setName(props.definition.name)
  }, [props.definition.name])

  React.useEffect(() => {
    setEnabled(props.definition.enabled)
  }, [props.definition.enabled])

  React.useEffect(() => {
    const newTrigger = props.definition.trigger ?? { conditions: [] }
    setTrigger((prev) => {
      if (deepEqual(removeIdentity(prev), newTrigger)) {
        return prev
      } else {
        return addIdentity(newTrigger)
      }
    })
  }, [props.definition])

  const hasConditions = Boolean(trigger.conditions?.length)
  const existing = Boolean(props.definition.id)
  const path = existing ? updateDefinitionPath : createDefinitionPath

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      const data = new FormData(e.target as HTMLFormElement)
      e.preventDefault()

      setIsSubmitting(true)
      postForm<{ definition: KqlDefinition }>(path, data).then((res) => {
        setIsSubmitting(false)
        props.onSubmitted?.(res?.definition?.id)
      })
    },
    [path, props]
  )

  const applyTemplate = React.useCallback((signal: Partial<KqlDefinition>) => {
    if (signal.name) {
      setName(signal.name)
    }

    if (signal.trigger) {
      setTrigger(addIdentity(signal.trigger))
    }
  }, [])

  const onSelectSignalType = React.useCallback(
    (kind) => {
      const signal = signalTypes.find((s) => s.kind === kind)
      const operator = triggers.find((t) => t.kind === kind && t.property === signal?.defaultProperty)?.defaultOperator

      onClose()
      setName(props.definition.name || '')
      setTrigger((trigger) => {
        if (!trigger || !trigger.conditions) {
          trigger = { conditions: [] }
        }

        return update(trigger, {
          conditions: {
            $push: [{ id: nanoid(), kind, property: signal?.defaultProperty, operator: operator || 'is' }]
          }
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose]
  )

  const conditions: ConditionType[] | undefined = React.useMemo(() => trigger.conditions || [], [trigger.conditions])
  const [targetType, setTargetType] = React.useState(
    props.definition.id ? props.definition.target_type : defaultTargetType(conditions ?? [])
  )

  React.useEffect(() => {
    setTargetType(props.definition.id ? props.definition.target_type : defaultTargetType(conditions ?? []))
  }, [props.definition, conditions])

  if (!hasConditions) {
    return (
      <Stack spacing={8}>
        {(!props.inline || !disclosure.isOpen) && (
          <SignalType
            icon={IconCirclePlus}
            colorScheme="purple"
            label="Select a type of Intent Signal"
            description="Build from scratch"
            as="button"
            cursor="pointer"
            alignItems="center"
            border="1px solid"
            borderColor="gray.200"
            rounded="lg"
            padding={3}
            _hover={{ borderColor: 'gray.300', shadow: 'sm' }}
            onClick={disclosure.onOpen}
          >
            <Icon as={IconSelector} boxSize={5} color="gray.400" _groupHover={{ color: 'gray.600' }} />
          </SignalType>
        )}
        {props.inline && disclosure.isOpen && (
          <SignalTypeSelector onSelect={onSelectSignalType} onCancel={disclosure.onClose} apps={props.apps} />
        )}
        {!props.inline && <SelectSignalTypeModal {...disclosure} onSelect={onSelectSignalType} apps={props.apps} />}
        {!existing && (!props.inline || !disclosure.isOpen) && (
          <>
            <Divider />
            <SuggestedSignals apps={props.apps || {}} onSelect={existing || props.inline ? applyTemplate : undefined} />
          </>
        )}
      </Stack>
    )
  }

  return (
    <Stack
      spacing={4}
      justifyContent="space-between"
      w="100%"
      opacity={isSubmitting ? 0.5 : 1}
      pointerEvents={isSubmitting ? 'none' : 'auto'}
    >
      <form action={path} method="POST" onSubmit={props.inline ? onSubmit : undefined}>
        {existing && <input type="hidden" name="_method" value="PUT" />}
        <AuthenticityToken />

        <Stack alignItems="flex-start" spacing="8" w="100%">
          {existing && (
            <HStack w="100%" alignItems="flex-start" justifyContent={'space-between'} spacing="8">
              <Name name={name} setName={setName} isDisabled={isSubmitting} />

              {props.skipSwitch || !existing ? (
                <input type="hidden" name="kql_definition[enabled]" value="true" />
              ) : (
                <FormControl flex="1" justifyContent={'flex-end'}>
                  <FormLabel htmlFor="kql_definition[enabled]">Enabled?</FormLabel>
                  <input type="hidden" name="kql_definition[enabled]" value={enabled ? 'true' : 'false'} />
                  <Switch
                    id="kql_definition[enabled]"
                    isChecked={enabled}
                    onChange={(e) => setEnabled(e.target.checked)}
                  />
                </FormControl>
              )}
            </HStack>
          )}

          <Box width="100%">
            <FormLabel>{props.title ?? 'Conditions'}</FormLabel>
            <input type="hidden" name="kql_definition[trigger]" value={JSON.stringify(removeIdentity(trigger))} />
            <KQLTrigger isRemovable={props.isRemovable} trigger={trigger} updateTrigger={setTrigger} />
          </Box>

          {!!conditions && !profileOnlyRule(conditions) && !accountOnlyRule(conditions) && (
            <FormControl>
              <FormLabel>Target</FormLabel>
              <input type="hidden" name="kql_definition[target_type]" value={targetType} />

              <SegmentedControl display="inline-flex" width="auto" size="sm">
                <Button minWidth="180px" isActive={targetType === 'Profile'} onClick={() => setTargetType('Profile')}>
                  Visitors
                </Button>

                <Button
                  minWidth="180px"
                  isActive={targetType === 'Account'}
                  onClick={() => setTargetType('Account')}
                  disabled={profileOnlyRule(conditions)}
                >
                  Accounts
                </Button>
              </SegmentedControl>

              <FormHelperText>
                {targetType === 'Profile' ? "This signal will be triggered for each visitor's activity." : ''}
                {targetType === 'Account'
                  ? "This signal will be evaluated at the Account level. Use this when you're sending Account level events into Koala."
                  : ''}
              </FormHelperText>
            </FormControl>
          )}

          {!!conditions && accountOnlyRule(conditions) && (
            <input type="hidden" name="kql_definition[target_type]" value="Account" />
          )}

          {!existing && <Name name={name} setName={setName} isDisabled={isSubmitting} />}

          {props.cta &&
            props.cta({
              hasConditions,
              isSubmitting
            })}

          {!props.cta && (
            <Button
              alignSelf={props.inline ? 'flex-end' : 'flex-start'}
              type="submit"
              size="sm"
              colorScheme="purple"
              isDisabled={!hasConditions}
              isLoading={isSubmitting}
            >
              {existing ? 'Save Changes' : 'Create Intent Signal'}
            </Button>
          )}
        </Stack>
      </form>
    </Stack>
  )
}

interface NameProps {
  name: string
  setName: (name: string) => void
  isDisabled?: boolean
}

function Name({ name, setName, isDisabled }: NameProps) {
  return (
    <FormControl flex="1 auto">
      <FormLabel htmlFor="kql_definition[name]">Name</FormLabel>
      <InputGroup size="sm">
        <InputLeftAddon
          fontStyle="italic"
          fontSize="sm"
          color="gray.500"
          background="white"
          // eslint-disable-next-line react/no-children-prop
          children="Someone…"
        />
        <Input
          isDisabled={isDisabled}
          size="sm"
          rounded="md"
          background="white"
          borderLeft="1px solid transparent"
          placeholder="Viewed Pricing"
          name="kql_definition[name]"
          isRequired
          id="kql_definition[name]"
          value={name || ''}
          onChange={(e) => setName(e.target.value)}
        />
      </InputGroup>
      <FormHelperText fontSize="xs">
        We recommend using a pattern like{' '}
        <Code fontSize="0.95em" color="inherit" bg="transparent">
          {'`{Action} {Object}`'}
        </Code>{' '}
        that describes the signal in the past tense.{' '}
        <HelpTooltip
          trigger={
            <Text textDecoration="underline" color="purple.500">
              Learn more
            </Text>
          }
        >
          <Stack spacing={3}>
            <Text>We recommend making this clear to your entire team what this intent signal is all about.</Text>
            <Text>
              Don't use a code phrase that only you understand, since we'll be using this text in notifications and on
              profile pages.
            </Text>
            <Text>The clearer it is for everyone, the better!</Text>
          </Stack>
        </HelpTooltip>
      </FormHelperText>
    </FormControl>
  )
}

export function OptionsMenu(props: { id?: string; deletePath: string; showEdit?: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Menu autoSelect={false} placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="xs"
          aria-label="Options"
          variant="ghost"
          icon={<Icon as={IconDots} boxSize={4} />}
        />
        <MenuList zIndex="popover">
          {props.showEdit && (
            <MenuItem icon={<IconEdit size={16} />} as="a" href={projectPath(`/signals/${props.id}`)}>
              Edit
            </MenuItem>
          )}
          <MenuItem icon={<IconCopy size={16} />} as="a" href={kqlDefinitionNewFromPath(props.id)}>
            Clone
          </MenuItem>
          <MenuItem color="red.500" icon={<IconTrash size={16} />} onClick={onOpen}>
            Delete Signal…
          </MenuItem>
        </MenuList>
      </Menu>

      <DeleteConfirmation
        isOpen={isOpen}
        onClose={onClose}
        title="Are you sure?"
        confirmLabel="Yes, delete this Intent Signal"
        deletePath={props.deletePath}
      >
        This will delete this intent signal definition and all its events.
      </DeleteConfirmation>
    </>
  )
}
