import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  Input,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconCalendarTime,
  IconLink,
  IconSparkles,
  IconTransferIn,
  IconUser,
  IconUsers,
  IconWorld
} from '@tabler/icons-react'
import Fuse from 'fuse.js'
import { groupBy, sortBy, uniq } from 'lodash'
import React, { useMemo } from 'react'
import { BuildingIcon } from '../../../../ui/icons'
import { TextEllipsis } from '../../../../ui/text-ellipsis'
import { VirtualList } from '../../../../ui/VirtualList'
import { Toggle } from '../../../accounts/components/Toggle'
import { humanize } from '../../../accounts/facets/filter-cloud'
import { NotificationVariables } from './use-notification-variables'

function icon(item: { key: string; label: string }) {
  if (item.key.includes('focus_time')) {
    return <Icon as={IconCalendarTime} color="pink.400" boxSize={3.5} />
  }

  if (item.key.includes('page_views')) {
    return <Icon as={IconWorld} color="pink.400" boxSize={3.5} />
  }

  if (item.key.includes('first_seen') || item.key.includes('last_seen')) {
    return <Icon as={IconSparkles} color="pink.400" boxSize={3.5} />
  }

  if (item.key.includes('permalink')) {
    return <Icon as={IconLink} color="pink.400" boxSize={3.5} />
  }

  if (item.key.startsWith('visitor.')) {
    return <Icon as={IconUser} color="blue.400" boxSize={3.5} />
  }

  if (item.key.startsWith('team_member')) {
    return <Icon as={IconUsers} color="green.400" boxSize={3.5} />
  }

  if (item.key.includes('salesforce')) {
    return <Img src="https://cdn.cdnlogo.com/logos/s/3/salesforce.svg" w="4" />
  }

  if (item.key.includes('hubspot')) {
    return <Img src="https://cdn.cdnlogo.com/logos/h/24/hubspot.svg" w="4" />
  }

  if (item.key.includes('outreach')) {
    return <Img src="https://www.outreach.io/_resources/img/logomark_sm.min.svg" w="4" />
  }

  if (item.key.startsWith('account.')) {
    return <Icon as={BuildingIcon} color="purple.500" boxSize={3.5} />
  }

  return null
}

export type Ingredient = {
  key: string
  label: string
  humanLabel: string
}

interface Props {
  onSelect: (item: Ingredient) => void
  selected?: Ingredient
  children?: (props: { onOpen: () => void }) => React.ReactChild
  targetType: 'Account' | 'Profile'
}

export function variableFieldOptions(props: NotificationVariables, targetType?: Props['targetType']) {
  const { accountMappings, visitorMappings, signalMappings } = props

  // remove array fields
  const keys = uniq(
    accountMappings.concat(visitorMappings, signalMappings).map((v) => v.replace(/(.\d+.?)/, ''))
  ).filter((k) => {
    if (targetType === 'Account') {
      return !k.startsWith('visitor.')
    }

    return true
  })

  const options = keys.map((key) => {
    const label = key
      .replace('account.intent.', 'account.')
      .replace('visitor.intent.', 'visitor.')
      .replace('page_views', 'page_views')
      .replace('focus_time', 'focus_time')
      .replace('account.apps.', '')
      .replace('signal_type', 'type')
      .replace('_data', '')
      .replace('account.company', 'company')
      .replace('visitor_stats.identified', 'identified_visitors')
      .replace('visitor_stats.visitors', 'total_visitors')
      .replace('metrics', '')
      .replace('_stats', '')
      .replace('crm_fields', '')
      .replace('account_traits_values.', 'account_traits.')
      .replace('profile_traits_values.', 'profile_traits.')

    const text = humanize(label)
      .replace('Day', ' (past day)')
      .replace('Week', ' (past week)')
      .replace('Month', ' (past month)')
      .replace(humanize(key), '')
      .replace('Account ', '')
      .replace('Hubspot Deals', '')
      .replace('Salesforce Opportunities', '')

    return {
      key,
      label,
      humanLabel: humanize(label),
      text
    }
  })

  return sortBy(options, 'label')
}

export function variableFieldOptionsByType(props: NotificationVariables, targetType?: Props['targetType']) {
  const options = variableFieldOptions(props, targetType)
  return groupBy(options, (k) => {
    if (k.key.includes('salesforce')) {
      return 'salesforce'
    }

    if (k.key.includes('hubspot')) {
      return 'hubspot'
    }

    if (k.key.includes('outreach')) {
      return 'outreach'
    }

    if (k.key.includes('account.company')) {
      return 'company'
    }

    if (k.key.includes('account.intent')) {
      return 'intent'
    }

    if (k.key.includes('signal.')) {
      return 'signal'
    }

    const key = k.key.split('.')[0]
    return key
  })
}

export function IngredientSelector(props: Props & NotificationVariables) {
  const [filter, setFilter] = React.useState<string>('')

  const options = useMemo(() => {
    return variableFieldOptions(props, props.targetType)
  }, [props])

  const fuse = useMemo(() => {
    return new Fuse(options, {
      keys: ['humanLabel'],
      threshold: 0.3
    })
  }, [options])

  const keys = useMemo(() => {
    if (filter.length === 0) {
      return options
    }

    return fuse.search(filter).map((r) => r.item)
  }, [fuse, filter, options])

  const byType = useMemo(() => {
    return groupBy(keys, (k) => {
      if (k.key.includes('salesforce') && !k.key.includes('salesforce_data.opportunities')) {
        return 'salesforce'
      }

      if (k.key.includes('hubspot') && !k.key.includes('hubspot_data.deals')) {
        return 'hubspot'
      }

      if (k.key.includes('hubspot_data.deals')) {
        return 'hubspot deals'
      }

      if (k.key.includes('salesforce_data.opportunities')) {
        return 'salesforce opportunities'
      }

      if (k.key.includes('outreach')) {
        return 'outreach'
      }

      if (k.key.startsWith('signal.')) {
        return 'signal'
      }

      const key = k.key.split('.')[0]
      return key
    })
  }, [keys])

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {props.children && props.children({ onOpen })}
      {!props.children && (
        <Button size="xs" variant={'outline'} colorScheme={'purple'} onClick={onOpen}>
          Add Field
        </Button>
      )}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Stack>
              <Heading size="md">Select Fields</Heading>
              <Input
                size="sm"
                rounded="md"
                value={filter}
                placeholder="Search"
                onChange={(e) => setFilter(e.target.value)}
                autoFocus
              />
            </Stack>
          </DrawerHeader>

          <DrawerBody>
            <Stack>
              <Text fontSize={'sm'} pb="4">
                Select one or more fields from the following list to include them in your message
              </Text>
              <Stack w="100%" gap="4">
                {Object.keys(byType).map((key) => {
                  return (
                    <Stack key={key} bg="gray.50" p="4" rounded="md">
                      <Toggle
                        defaultIsOpen={true}
                        forceIsOpen={!!filter && byType[key].length > 0 ? true : undefined}
                        title={<Heading size="xs">{humanize(key)} Fields</Heading>}
                      >
                        {!!filter && byType[key].length > 0 && <Heading size="xs">{humanize(key)} Fields</Heading>}
                        <VirtualList
                          // show full list if there is only one category visible
                          maxH={Object.keys(byType).length > 1 ? '300px' : 'calc(100vh - 275px)'}
                          estimateSize={(i) => {
                            const item = byType[key][i]
                            let title = item.text
                            if (title === '' || title === undefined) {
                              title = humanize(item.label.replace(key, ''))
                            }

                            title = title.replace(humanize(key), '')
                            if (title === '') {
                              return 0
                            }

                            return 32
                          }}
                          items={byType[key].sort()}
                          renderItem={(item) => {
                            let title = item.text
                            if (title === '' || title === undefined) {
                              title = humanize(item.label.replace(key + '.', ''))
                            }

                            title = title.replace(humanize(key), '')
                            if (title === '') {
                              return null
                            }

                            return (
                              <HStack
                                w="100%"
                                justifyContent="space-between"
                                px="2"
                                cursor={'pointer'}
                                _hover={{
                                  bg: 'gray.50'
                                }}
                                onClick={() => props.onSelect(item)}
                              >
                                <HStack>
                                  {icon(item)}
                                  <TextEllipsis tooltip maxW={'200px'} fontSize={'xs'}>
                                    {title}
                                  </TextEllipsis>
                                </HStack>
                                <IconButton
                                  aria-label="Insert Field"
                                  icon={<IconTransferIn size="10" />}
                                  size="xs"
                                  variant={'ghost'}
                                />
                              </HStack>
                            )
                          }}
                        />
                      </Toggle>
                    </Stack>
                  )
                })}
              </Stack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
