import {
  Button,
  Heading,
  HStack,
  Link,
  ListItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList
} from '@chakra-ui/react'
import React from 'react'
import { StaticList } from '../../../../types/StaticList'
import { User } from '../../../data/use-users'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'

export interface TrackedPost {
  id: string
  post_url: string
  reactions_count: number
  comments_count: number
  static_lists: StaticList[]
  slug_name: string
  post_activity_id: string
  tracked_by: User
  updated_at: string
}

interface Props {
  trackedPosts: TrackedPost[]
}

export function PostsList(props: Props) {
  return (
    <Stack spacing={8}>
      <Stack spacing={1}>
        <Heading size="md" fontWeight="semibold">
          Tracked Posts
        </Heading>

        <Text fontSize="sm" color="gray.500">
          Posts you&apos;ve tracked, the last time they were updated, and the number of reactions and comments.
        </Text>
      </Stack>

      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Post ID</Th>
              <Th>Post URL</Th>
              <Th>Lists</Th>
              <Th isNumeric>Reactions / Comments</Th>
              <Th isNumeric>Last Updated</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="xs">
            {props.trackedPosts.map((post) => (
              <Tr key={post.id}>
                <Td fontSize="xs">
                  <TextEllipsis maxW="80px" tooltip>
                    {post.post_activity_id}
                  </TextEllipsis>
                </Td>
                <Td>
                  <HelpTooltip
                    mode="popover"
                    opacity={1}
                    trigger={
                      <Link color="gray.600" isExternal href={post.post_url} fontWeight="normal" wordBreak="break-word">
                        <HStack spacing={2} alignItems="flex-start">
                          <LinkedinBoxIcon color="linkedin.700" size={16} flexShrink={0} />
                          <Text>
                            {post.slug_name.length > 20
                              ? `${post.slug_name.slice(0, 15)}...${post.slug_name.slice(-25)}`
                              : post.slug_name}
                          </Text>
                        </HStack>
                      </Link>
                    }
                  >
                    <Stack spacing="4">
                      <HStack>
                        <LinkedinBoxIcon color="linkedin.700" size={16} flexShrink={0} />
                        <Heading size="xs">Post URL</Heading>
                      </HStack>
                      <Link href={post.post_url} isExternal fontWeight="normal" color="gray.600" fontSize="sm">
                        {post.post_url.replace('https://www.', 'https://')}
                      </Link>
                    </Stack>
                  </HelpTooltip>
                </Td>
                <Td>
                  <UnorderedList>
                    {post.static_lists.map((list) => (
                      <ListItem key={list.id}>
                        <Link color="gray.600" href={projectPath(`/lists/${list.id}`)} fontWeight="normal">
                          <TextEllipsis>{list.name}</TextEllipsis>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Td>
                <Td isNumeric>
                  {post.reactions_count} / {post.comments_count}
                </Td>
                <Td isNumeric color="gray.500">
                  <TimeAgo time={post.updated_at} /> ({post.tracked_by?.name})
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
