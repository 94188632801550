import {
  Box,
  Code,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconArrowsShuffle, IconX } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { Apps } from '../../../../types/App'
import { Play } from '../../../../types/Play'
import { facetQueryString, useFacets } from '../../../data/use-facets'
import { useUsers } from '../../../data/use-users'
import { GrayCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { SalesforceIcon } from '../../../ui/icons'
import { UserSelector } from '../../../ui/UserSelector'
import { FilterPreview } from '../../accounts/components/FilterPreview'

const emptyObject = {}
const emptyArray = []

export const AssignmentSetup = (props: { play: Partial<Play>; apps: Apps }) => {
  const [assignmentType, setAssignmentType] = React.useState(props.play?.assignment_config?.assignment_type ?? 'shared')

  const assignees = props.play?.assignment_config?.assignees?.map((a) => a.id) ?? []

  const facets = useFacets({
    facet_filters: props.play?.assignment_config?.filters || emptyObject
  })

  const filters = useMemo(
    () => facetQueryString(facets.facetFilters || props.play?.assignment_config?.filters, ''),
    [facets.facetFilters, props.play?.assignment_config?.filters]
  )

  const [assigneeIds, setAssigneeIds] = useState(assignees)

  const handleAssigneeChange = useCallback((index: number, userId: string | null) => {
    setAssigneeIds((prev) => {
      const newUserIds = [...prev]
      if (userId) {
        newUserIds[index] = userId
      } else {
        newUserIds.splice(index, 1)
      }
      return newUserIds
    })
  }, [])

  const handleRemoveAssignee = useCallback((index: number) => {
    setAssigneeIds((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const [routingType, setRoutingType] = useState(props.play?.assignment_config?.routing_type ?? 'round_robin')

  const users = useUsers()

  return (
    <FormControl>
      <Stack spacing="6" as={GrayCard}>
        <FormControl id="play[assignment_config][assignment_type]" isRequired>
          <FormLabel>Assignment</FormLabel>
          <RadioGroup
            defaultValue={assignmentType}
            name="play[assignment_config][assignment_type]"
            onChange={(e) => setAssignmentType(e as 'shared' | 'specific_users')}
          >
            <Stack spacing={5} direction="row">
              <Radio value="shared">
                <Text fontSize="sm">Everyone</Text>
              </Radio>
              <Radio value="specific_users">
                <Text fontSize="sm">Specific users</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        {assignmentType === 'specific_users' && (
          <FormControl isRequired>
            {assigneeIds.map((id) => (
              <input type="hidden" key={id} name="play[assignment_config][assignees][][id]" value={id} />
            ))}
            <FormLabel>Selected users</FormLabel>
            <Stack spacing={2}>
              {[...assigneeIds, ''].map((assigneeId, idx) => (
                <Flex width="100%" key={`${assigneeId}:${idx}`} alignItems="center" gap={3}>
                  <Box flex="1 1 auto">
                    <UserSelector
                      users={users.data?.users || emptyArray}
                      selectedUserId={assigneeId}
                      onChange={(newAssigneeId) => handleAssigneeChange(idx, newAssigneeId)}
                      popperOptions={{ matchWidth: true }}
                      variant="outline"
                    />
                  </Box>
                  <IconButton
                    aria-label="Remove user"
                    size="xs"
                    variant="ghost"
                    color="gray.500"
                    _hover={{ color: 'gray.800' }}
                    icon={<IconX size={16} />}
                    isDisabled={idx >= assigneeIds.length}
                    onClick={() => handleRemoveAssignee(idx)}
                  />
                </Flex>
              ))}
            </Stack>
          </FormControl>
        )}

        <FormControl id="play[assignment_config][filters]" isRequired>
          <FormLabel>How should we route the leads?</FormLabel>

          <Stack spacing={4}>
            <CardRadioGroup
              size="sm"
              gridTemplateColumns="repeat(2, 1fr)"
              name="play[assignment_config][routing_type]"
              options={[
                {
                  label: 'CRM Owner',
                  value: 'crm_owner',
                  icon: <SalesforceIcon size={16} />,
                  description: 'Assign leads based on the CRM owner. Unowned leads will be delivered via Round Robin.'
                },
                {
                  label: 'Round Robin',
                  value: 'round_robin',
                  icon: <IconArrowsShuffle size={16} />,
                  description: 'Assign leads to your reps in a round robin fashion.'
                }
              ]}
              value={routingType}
              onChange={(e) => {
                setRoutingType(e as 'crm_owner' | 'round_robin')
              }}
            />

            {routingType === 'crm_owner' && (
              <Box pt={2}>
                <FilterPreview
                  {...facets}
                  kind="account"
                  apps={props.apps}
                  canClearFilters={false}
                  topFilters={emptyArray}
                  shouldShowIntentFilters={false}
                  shouldShow3rdPartyFilters={false}
                  shouldShowActiveVisitorsFilters={false}
                  shouldShowLastSeenFilter={false}
                  shouldShowUserAttributeFilters={false}
                  shouldShowTraits={false}
                  range={undefined}
                />

                <FormHelperText pt="2">
                  You can use ownership fields with the <Code color="pink.600">Current user</Code> value which is scoped
                  to the current viewer.
                </FormHelperText>
                <input type="hidden" name="play[assignment_config][filters]" value={filters.join('&')} />
              </Box>
            )}
          </Stack>
        </FormControl>
      </Stack>
    </FormControl>
  )
}
