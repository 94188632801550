import { useMutation, UseMutationOptions, useQuery } from 'react-query'
import { concurrentGET, patch } from '../../lib/api'
import { Play, PlayItem } from '../../types/Play'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface PlayResponse {
  play_items: PlayItem[]
  play: Play
  stats: {
    total: number
    pending: number
    completed: number
    disqualified: number
  }
}

export function usePlay(playId: string, userId?: string, status?: string) {
  const project = useCurrentProject()

  const searchParams = new URLSearchParams()
  if (userId) {
    searchParams.set('user', userId)
  }

  if (status) {
    searchParams.set('status', status)
  }

  const path = projectPath(`/plays/${playId}?${searchParams.toString()}`).toString()

  return useQuery<PlayResponse>(
    ['play', { projectId: project?.id, playId, userId: userId }],
    () => concurrentGET<PlayResponse>(path),
    {
      enabled: Boolean(project?.id),
      // Keep the previous data visible while fetching new data
      keepPreviousData: true
    }
  )
}

interface DisqualifyItemParams {
  itemId: string
  reason: string
  feedback?: string
}

export function useDisqualifyPlayItem(options?: UseMutationOptions<void, Error, DisqualifyItemParams>) {
  return useMutation<void, Error, DisqualifyItemParams>(async ({ itemId, reason, feedback }) => {
    await patch(projectPath(`/play-items/${itemId}/disqualify`), {
      reason,
      feedback: reason === 'other' ? feedback : ''
    })
  }, options)
}

interface ResetItemParams {
  itemId: string
}

export function useResetPlayItem(options?: UseMutationOptions<void, Error, ResetItemParams>) {
  return useMutation<void, Error, ResetItemParams>(async ({ itemId }) => {
    await patch(projectPath(`/play-items/${itemId}/reset`), {})
  }, options)
}

interface SnoozeItemParams {
  itemId: string
  until: string
}

export function useSnoozePlayItem(options?: UseMutationOptions<void, Error, SnoozeItemParams>) {
  return useMutation<void, Error, SnoozeItemParams>(async ({ itemId, until }) => {
    await patch(projectPath(`/play-items/${itemId}/snooze`), {
      until
    })
  }, options)
}

interface CompleteItemParams {
  itemId: string
}

export function useCompletePlayItem(options?: UseMutationOptions<void, Error, CompleteItemParams>) {
  return useMutation<void, Error, CompleteItemParams>(async ({ itemId }) => {
    await patch(projectPath(`/play-items/${itemId}/complete`), {})
  }, options)
}

interface ReassignItemParams {
  itemId: string
  userId: string | null
}

export function useReassignPlayItem(options?: UseMutationOptions<PlayItem, Error, ReassignItemParams>) {
  return useMutation<PlayItem, Error, ReassignItemParams>(async ({ itemId, userId }) => {
    const response = await patch<{ play_item: PlayItem }>(projectPath(`/play-items/${itemId}/reassign`), {
      user_id: userId
    })
    return response.play_item
  }, options)
}

interface ReassignMultipleItemsParams {
  itemIds: string[]
  userId: string
}

export function useReassignMultiplePlayItems(options?: UseMutationOptions<void, Error, ReassignMultipleItemsParams>) {
  return useMutation<void, Error, ReassignMultipleItemsParams>(async ({ itemIds, userId }) => {
    await patch(projectPath(`/play-items/reassign`), { item_ids: itemIds, user_id: userId })
  }, options)
}
