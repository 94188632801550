import {
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'

import {
  IconAlertTriangle,
  IconBolt,
  IconExternalLink,
  IconPlus,
  IconTrendingDown,
  IconTrendingUp,
  IconX
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useIntentSignals } from '../../../data/use-intent-signals'
import CircleIcon from '../../../ui/CircleIcon'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import { KqlDefinition } from '../types'
import { DefinitionFormModal } from './DefinitionFormModal'
import { SignalType } from './SignalType'
import { TablerIcon } from '@tabler/icons-react'

const emptyArray = []

export const anyIntent: KqlDefinition = {
  name: 'Any Activity',
  enabled: true,
  trigger: { conditions: [] },
  signal_type: 'multiple'
}

export const anySignal: KqlDefinition = {
  name: 'Any Intent Signal',
  enabled: true,
  trigger: { conditions: [] },
  signal_type: 'multiple'
}

// Define our trend types and helper functions
const INTENT_TRENDS = {
  Cooling: {
    trend: 'Cooling',
    definition: {
      name: 'Started Cooling',
      enabled: true,
      trigger: { conditions: [] },
      signal_type: 'system_event'
    } as KqlDefinition,
    colorScheme: 'blue',
    icon: IconTrendingDown
  },
  Heating: {
    trend: 'Heating',
    definition: {
      name: 'Started Heating',
      enabled: true,
      trigger: { conditions: [] },
      signal_type: 'system_event'
    } as KqlDefinition,
    colorScheme: 'orange',
    icon: IconTrendingUp
  },
  Surging: {
    trend: 'Surging',
    definition: {
      name: 'Started Surging',
      enabled: true,
      trigger: { conditions: [] },
      signal_type: 'system_event'
    } as KqlDefinition,
    colorScheme: 'red',
    icon: IconTrendingUp
  }
} as const
export type IntentTrend = keyof typeof INTENT_TRENDS

// Generate all signals
export const INTENT_TREND_SIGNALS = Object.entries(INTENT_TRENDS).map(([trend, value]) => ({
  trend,
  definition: value.definition,
  colorScheme: value.colorScheme,
  icon: value.icon
}))

interface Props extends UseDisclosureProps {
  onChange?: (signalIds: string[], anyIntent: boolean, anySignal: boolean, intentChanges: IntentTrend[]) => void
  selectedSignalIds?: string[]
  restrictedSignalTypes?: string[]
  anyIntent?: boolean
  anySignal?: boolean
  intentChanges?: IntentTrend[]
  selectedAudienceKind?: 'account' | 'profile'
  skipAnyIntent?: boolean
}

export function SelectSignalsModal(props: Props) {
  const disclosure = useDisclosure(props)
  const createModal = useDisclosure()
  const onClose = disclosure.onClose
  const onChange = props.onChange
  const restrictedSignalTypes = props.restrictedSignalTypes ?? emptyArray

  const { data, isLoading, refetch } = useIntentSignals()
  const signals = useMemo(() => data?.definitions ?? [], [data?.definitions])

  const [anyIntentSelected, setAnyIntentSelected] = useState(props.anyIntent ?? false)
  const [anySignalSelected, setAnySignalSelected] = useState(props.anySignal ?? false)

  const [trendsDisabled, setTrendsDisabled] = useState(
    anyIntentSelected || anySignalSelected || props.selectedAudienceKind !== 'account'
  )
  const [intentChangesSelected, setIntentChangesSelected] = useState<IntentTrend[]>(
    trendsDisabled ? [] : (props.intentChanges ?? [])
  )

  const [selected, setSelected] = useState(props.selectedSignalIds ?? [])
  const allSelected = selected.length === signals.length

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 150)

  const displayedSignals = useMemo(() => {
    return signals.filter((s) => {
      if (debouncedSearch) {
        return s.name.toLowerCase().includes(debouncedSearch.toLowerCase())
      }

      return true
    })
  }, [debouncedSearch, signals])

  useEffect(() => {
    setSelected(props.selectedSignalIds ?? [])
  }, [props.selectedSignalIds])

  useEffect(() => {
    setAnyIntentSelected(props.anyIntent ?? false)
  }, [props.anyIntent])

  useEffect(() => {
    setTrendsDisabled(anyIntentSelected || anySignalSelected || props.selectedAudienceKind !== 'account')
  }, [anyIntentSelected, anySignalSelected, props.selectedAudienceKind])

  useEffect(() => {
    if (!trendsDisabled) {
      setIntentChangesSelected(props.intentChanges ?? [])
    }
  }, [props.intentChanges, trendsDisabled])

  const toggleAll = useCallback(() => {
    if (allSelected) {
      setSelected([])
    } else {
      setSelected(
        signals.filter((s) => !restrictedSignalTypes.includes(s.signal_type ?? '')).map((s) => s.id) as string[]
      )
    }
  }, [restrictedSignalTypes, allSelected, signals])

  const multipleIntentSelected = useMemo(
    () => anyIntentSelected || anySignalSelected,
    [anyIntentSelected, anySignalSelected]
  )

  const onApply = useCallback(() => {
    onChange?.(selected, anyIntentSelected, anySignalSelected, intentChangesSelected)
    onClose()
  }, [selected, anyIntentSelected, anySignalSelected, intentChangesSelected, onChange, onClose])

  return (
    <Modal size="2xl" {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select one or more Intent Signals</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxHeight="70vh" overflow="auto">
          {isLoading ? (
            <Spinner color="gray.400" thickness="1.5px" size="sm" />
          ) : signals.length ? (
            <Stack spacing={4}>
              <InputGroup size="sm">
                <InputLeftElement width="8" pointerEvents="none" color="gray.500">
                  <SearchIcon boxSize={4} />
                </InputLeftElement>
                <Input
                  size="sm"
                  width="100%"
                  background="white"
                  outline="none"
                  placeholder="Search for intent signals"
                  autoFocus
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <InputRightElement
                  width="8"
                  color="gray.400"
                  visibility={search ? 'visible' : 'hidden'}
                  cursor={search ? 'pointer' : 'default'}
                  pointerEvents={search ? undefined : 'none'}
                  _hover={{ color: 'gray.800' }}
                  onClick={() => setSearch('')}
                >
                  <Icon as={IconX} boxSize={4} />
                </InputRightElement>
              </InputGroup>
              <Stack>
                {displayedSignals.length > 0 && (
                  <Button
                    alignSelf="flex-start"
                    size="xs"
                    variant="unstyled"
                    onClick={toggleAll}
                    marginLeft={3}
                    isDisabled={anyIntentSelected}
                  >
                    {allSelected ? 'Deselect all' : 'Select all'}
                  </Button>
                )}

                {/* support "Any intent" as an option */}
                {!props.skipAnyIntent && (
                  <>
                    <Signal
                      signal={anyIntent}
                      checked={anyIntentSelected}
                      info="Any pageview, form submission or custom event"
                      disabled={anySignalSelected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAnyIntentSelected(true)
                          setSelected([])
                          setIntentChangesSelected([])
                        } else {
                          setAnyIntentSelected(false)
                        }
                      }}
                    />
                    <Signal
                      signal={anySignal}
                      checked={anySignalSelected}
                      disabled={anyIntentSelected}
                      info="Any intent signal from profile or account"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAnySignalSelected(true)
                          setSelected([])
                          setIntentChangesSelected([])
                        } else {
                          setAnySignalSelected(false)
                        }
                      }}
                    />
                  </>
                )}
                {INTENT_TREND_SIGNALS.map(({ trend, definition, colorScheme, icon }) => (
                  <Signal
                    key={trend}
                    signal={{ ...definition }}
                    colorScheme={colorScheme}
                    icon={icon}
                    checked={intentChangesSelected.includes(trend as IntentTrend)}
                    info={`Intent ${definition.name}`}
                    disabled={trendsDisabled}
                    disabledReason={
                      trendsDisabled && !(anyIntentSelected || anySignalSelected)
                        ? 'This signal type is not available for your selected audience.'
                        : undefined
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setIntentChangesSelected([...intentChangesSelected, trend as IntentTrend])
                      } else {
                        setIntentChangesSelected(intentChangesSelected.filter((selected) => selected !== trend))
                      }
                    }}
                  />
                ))}
                <CheckboxGroup value={selected} onChange={(v) => setSelected(v as string[])}>
                  {displayedSignals.map((signal) => (
                    <Signal
                      key={signal.id || signal.name}
                      signal={signal}
                      info={
                        signal === anyIntent
                          ? 'Any pageview, form submission or custom event'
                          : signal === anySignal
                            ? 'Any defined intent signals for people or accounts'
                            : undefined
                      }
                      disabled={restrictedSignalTypes.includes(signal.signal_type ?? '') || multipleIntentSelected}
                      disabledReason={
                        multipleIntentSelected ? '' : 'This signal type is not available for your selected audience.'
                      }
                    />
                  ))}
                </CheckboxGroup>
                {!displayedSignals.length && (
                  <Center minHeight={24}>
                    <Text textAlign="center" fontSize="sm" color="gray.500">
                      No matching Intent Signals found. Try searching with a different keyword or{' '}
                      <Button variant="link" colorScheme="purple" onClick={createModal.onOpen}>
                        create a new intent signal
                      </Button>
                    </Text>
                  </Center>
                )}
              </Stack>
            </Stack>
          ) : (
            <>
              <Stack
                width="full"
                rounded="lg"
                bg="gray.50"
                border="1px solid"
                borderColor="gray.200"
                paddingY="40px"
                paddingX="24px"
                alignItems="center"
                spacing={6}
              >
                <CircleIcon icon={IconBolt} iconSize={8} colorScheme="purple" />
                <Stack alignItems="center" spacing={1}>
                  <Heading size="sm">No intent signals yet</Heading>
                  <Text fontSize="sm" textAlign="center">
                    Looks like you don't have any Intent Signals defined yet. Create one to get started
                  </Text>
                </Stack>
                <Button leftIcon={<IconPlus size={14} />} size="sm" colorScheme="purple" onClick={createModal.onOpen}>
                  Create Intent Signal
                </Button>
              </Stack>

              <Stack paddingTop={4}>
                <Text fontSize="sm" fontWeight="medium" textAlign="center">
                  or trigger on any activity...
                </Text>
                {/* support "Any intent" as an option */}
                <Signal
                  signal={anyIntent}
                  checked={anyIntentSelected}
                  info="Any pageview, form submission or custom event"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAnyIntentSelected(true)
                      setSelected([])
                      onChange?.([], true, false, [])
                      onClose()
                    } else {
                      setAnyIntentSelected(false)
                      onChange?.([], false, false, [])
                    }
                  }}
                />

                <Signal
                  signal={anySignal}
                  checked={anySignalSelected}
                  info="Any intent signal"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAnySignalSelected(true)
                      setSelected([])
                      onChange?.([], false, true, [])
                      onClose()
                    } else {
                      setAnySignalSelected(false)
                      onChange?.([], false, false, [])
                    }
                  }}
                />
              </Stack>
            </>
          )}

          <DefinitionFormModal {...createModal} onComplete={refetch} />
        </ModalBody>
        <ModalFooter>
          {!isLoading && signals.length !== 0 && (
            <Button variant="outline" size="sm" onClick={createModal.onOpen} mr="auto">
              Create an Intent Signal
            </Button>
          )}
          {signals.length > 0 && (
            <Button size="sm" colorScheme="purple" onClick={onApply} isDisabled={isLoading}>
              Done
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface SignalProps {
  signal: KqlDefinition
  name?: string
  checked?: boolean
  disabled?: boolean
  disabledReason?: string
  info?: string
  icon?: TablerIcon | React.FC
  colorScheme?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function Signal(props: SignalProps) {
  return (
    <Flex alignItems="center" rounded="lg" border="1px solid" borderColor="gray.200" bg="white" paddingRight={3}>
      <Checkbox
        flex="1 1 auto"
        name={props.name}
        isChecked={props.checked}
        isDisabled={props.disabled && !props.checked}
        value={props.signal.id || ''}
        padding={3}
        gap={2}
        onChange={props.onChange}
      >
        <HStack flex="1">
          <HStack flex="1">
            <SignalType
              label={`${props.signal.name}${props.signal.enabled === false ? ' (paused)' : ''}`}
              signalType={props.signal.signal_type}
              icon={props?.icon}
              colorScheme={props?.colorScheme}
              color={props.signal.enabled === false ? 'gray.500' : undefined}
              compact
            />
            {props.info && (
              <HelpTooltip placement="top" variant="info">
                {props.info}
              </HelpTooltip>
            )}
          </HStack>
        </HStack>
      </Checkbox>

      {props.disabled && props.disabledReason && (
        <Tooltip label={props.disabledReason} placement="top" hasArrow>
          <Icon flex="none" as={IconAlertTriangle} boxSize={4} color="orange.600" marginRight={3} />
        </Tooltip>
      )}

      {props.signal.id && (
        <Link
          flex="none"
          href={projectPath(`/signals/${props.signal.id}`)}
          color="gray.400"
          _hover={{ color: 'gray.600' }}
          display="inline-flex"
          isExternal
        >
          <Icon as={IconExternalLink} boxSize={4} />
        </Link>
      )}
    </Flex>
  )
}
