import {
  Box,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowsDiagonal, IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { SearchIcon } from '../../../ui/icons/SearchIcon'

export const Toggle: React.FunctionComponent<{
  title: React.ReactNode | ((isOpen: boolean) => React.ReactNode)
  defaultIsOpen?: boolean
  forceIsOpen?: boolean
  disabled?: boolean
  mode?: 'toggle' | 'modal' | 'drawer'
  showIcon?: boolean
  toggleIcon?: (isOpen: boolean) => React.ReactElement
  drawerProps?: Partial<DrawerProps>
}> = (props) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({ defaultIsOpen: props.defaultIsOpen })
  const mode = props.mode ?? 'toggle'

  useEffect(() => {
    if (props.forceIsOpen === true) {
      onOpen()
    }

    if (props.forceIsOpen === false) {
      onClose()
    }
  }, [props.forceIsOpen, onOpen, onClose])

  return (
    <Box w="100%" pointerEvents={props.disabled ? 'none' : undefined} opacity={props.disabled ? '0.5' : undefined}>
      <HStack w="100%" justifyContent="flex-start" onClick={onToggle} cursor="pointer" spacing="1">
        {!props.forceIsOpen && mode === 'toggle' && (
          <>
            {props.showIcon !== false && (
              <IconButton
                size="tiny"
                variant="ghost"
                aria-label="Toggle"
                color="gray.500"
                icon={
                  props.toggleIcon ? (
                    props.toggleIcon(isOpen)
                  ) : isOpen ? (
                    <IconChevronDown size="14" />
                  ) : (
                    <IconChevronRight size="14" />
                  )
                }
              />
            )}

            <Flex flex="1" userSelect="none">
              {typeof props.title === 'function' ? props.title(isOpen) : props.title}
            </Flex>
          </>
        )}

        {mode === 'modal' && (
          <IconButton
            onClick={onToggle}
            aria-label="Maximize"
            icon={<IconArrowsDiagonal size="12" />}
            variant="ghost"
            size="xs"
          />
        )}

        {mode === 'drawer' && (
          <>
            <Flex>{typeof props.title === 'function' ? props.title(isOpen) : props.title}</Flex>
            {props.showIcon !== false && (
              <IconButton
                onClick={onToggle}
                aria-label="Maximize"
                icon={<SearchIcon size="12" />}
                variant="ghost"
                size="xs"
              />
            )}
          </>
        )}
      </HStack>

      {mode === 'toggle' && (
        <Collapse in={props.forceIsOpen ? props.forceIsOpen : isOpen}>
          {props.children && (
            <Box w="100%" pl={!props.showIcon ? undefined : '1.5'} paddingTop={2}>
              {props.children}
            </Box>
          )}
        </Collapse>
      )}

      {mode === 'modal' && (
        <Modal isOpen={isOpen} onClose={onToggle} size="5xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{typeof props.title === 'function' ? props.title(isOpen) : props.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody p="6">{props.children}</ModalBody>
          </ModalContent>
        </Modal>
      )}

      {mode === 'drawer' && (
        <Drawer isOpen={isOpen} placement="right" onClose={onToggle} size="lg" {...props.drawerProps}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{typeof props.title === 'function' ? props.title(isOpen) : props.title}</DrawerHeader>
            <DrawerBody>{props.children}</DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  )
}
