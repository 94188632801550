import {
  Button,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Play } from '../../../../types/Play'
import { usePersonas } from '../../../data/use-personas'
import { Persona, PersonaSelector } from '../../prospects/personas'

export function ProspectingSetup({ play }: { play: Partial<Play> }) {
  const [option, setOption] = useState<'warm_prospects' | 'cold_prospects'>(
    play?.prospecting_config?.option ?? 'warm_prospects'
  )

  const { data: personas, isLoading: personasLoading } = usePersonas()

  useEffect(() => {
    if (personas && play?.prospecting_config?.personas) {
      setSelectedPersonas(
        personas.personas.filter((persona) => play?.prospecting_config?.personas?.includes(persona.id ?? ''))
      )
    }
  }, [personas, play?.prospecting_config?.personas])

  const [selectedPersonas, setSelectedPersonas] = useState<Persona[]>([])
  const [addingPersona, setAddingPersona] = useState(false)

  return (
    <Stack>
      <FormControl>
        <RadioGroup
          as={Stack}
          spacing={1}
          size="sm"
          value={option}
          onChange={(value) => setOption(value as 'warm_prospects' | 'cold_prospects')}
          name="play[prospecting_config][option]"
        >
          <Radio value="warm_prospects">
            <Text>No, don't search for additional prospects</Text>
          </Radio>
          <Radio value="cold_prospects">
            <Text>Yes, search for additional prospects for enrolled companies</Text>
          </Radio>
        </RadioGroup>

        {option === 'cold_prospects' && personasLoading && (
          <Flex p="4">
            <Spinner size="sm" />
          </Flex>
        )}

        {option === 'cold_prospects' && selectedPersonas.length === 0 && !personasLoading && (
          <Stack p="4">
            <PersonaSelector
              selectedPersona={null}
              onSelect={(persona) => {
                if (persona) {
                  setSelectedPersonas([persona])
                }
              }}
            />
          </Stack>
        )}

        {option === 'cold_prospects' && selectedPersonas.length > 0 && !personasLoading && (
          <Stack spacing={3} p="4">
            {selectedPersonas.map((persona, index) => (
              <HStack key={persona.id}>
                <PersonaSelector
                  key={persona.id}
                  selectedPersona={persona}
                  onSelect={(persona) => {
                    if (persona) {
                      setSelectedPersonas((prev) => {
                        const newPersonas = [...prev]
                        newPersonas.splice(index, 1)
                        return newPersonas
                      })
                    }
                  }}
                />
                <IconButton
                  icon={<IconX size={16} />}
                  size="sm"
                  variant="ghost"
                  aria-label="Remove persona"
                  onClick={() =>
                    setSelectedPersonas((prev) => {
                      const newPersonas = [...prev]
                      newPersonas.splice(index, 1)
                      return newPersonas
                    })
                  }
                />
                <input type="hidden" name="play[prospecting_config][personas][]" value={persona.id} />
              </HStack>
            ))}
            {addingPersona && (
              <PersonaSelector
                selectedPersona={null}
                onSelect={(persona) => {
                  if (persona) {
                    setSelectedPersonas([...selectedPersonas, persona])
                    setAddingPersona(false)
                  }
                }}
              />
            )}
            <Flex justifyContent="flex-end" pt="2">
              <Button size="sm" colorScheme={'blue'} onClick={() => setAddingPersona(true)}>
                Add more
              </Button>
            </Flex>
          </Stack>
        )}
      </FormControl>
    </Stack>
  )
}
