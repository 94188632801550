import React from 'react'
import { GrayCard } from '../../ui/Card'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { AIAgentForm } from './components/AIAgentForm'

interface Props {
  ai_agent: {
    id?: string
    name: string
    slug: string
    question: string
    target: string
    model?: string
  }
}

export default function Show(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          { path: projectPath('/settings/agents'), title: 'AI Agents' },
          { path: window.location.toString(), title: props.ai_agent.name }
        ]}
      />

      <SettingsHeader divider={false}>
        <PageTitle size="md">{props.ai_agent.name}</PageTitle>
      </SettingsHeader>

      <GrayCard w="100%">
        <AIAgentForm {...props} />
      </GrayCard>
    </PageLayout>
  )
}
